import {NgModel} from './ngModel';

export const AboutMe = NgModel.extend({
    _name: 'AboutMe',
    defaults: {
        headline: null,
        bio: null,
        aboutMe: null,
    },
    isComplete: function() {
        return true;
    },
    validateData: function() {
        var errors = {};
        this.set('errors', errors);
        return errors;
    },
});
