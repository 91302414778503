import {RegistrationData} from 'model/registrationData';
import {RegistrationSettings} from 'model/registrationSettings';
import {Degrees as DegreeCollection} from '../collection/degrees';
import {Concentrations as ConcentrationCollection} from '../collection/concentrations';
import {Schools as SchoolCollection} from '../collection/schools';
import {Roles as RoleCollection} from '../collection/roles';
import {Industries as IndustryCollection} from '../collection/industries';
import {Languages as LanguageCollection} from '../collection/languages';
import {Users as UserCollection} from '../../control/partner/collection/users';
import {Employers as EmployerCollection} from '../collection/employers';

export const RegistrationService = [
    '$rootScope', '$q', '$http', '$window', '$timeout', 'TimezonesService',
    function($rootScope, $q, $http, $window, $timeout, TimezonesService) {
        const registrationDataPromise = {};
        const registrationData = {};
        let settingsData = null;
        let settingsDataPromise = null;

        function _prefixComplete(data, collection) {
            const deferred = $q.defer();

            if (!data.keyword) {
                // if there's no prefix, resolve an empty array
                $timeout(function() {
                    deferred.resolve(new collection([]));
                }, 0);
            } else {
                $http.get('/api/search/filter.json', {params: data}).then(function(responseObj) {
                    deferred.resolve(new collection(responseObj.data.data.items));
                }, deferred.reject);
            }

            return deferred.promise;
        }

        return {
            getRegistrationData: function(userid, section = null) {
                if (!userid) {
                    // we need some value as default so we can cache by userid
                    userid = 0;
                }

                const userSectionId = `${section}-${userid}`;

                if (registrationDataPromise[userSectionId]) {
                    return registrationDataPromise[userSectionId];
                }

                const deferred = $q.defer();

                if (registrationData[userSectionId]) {
                    deferred.resolve(registrationData[userSectionId]);
                } else {
                    let options = {};
                    if (userid) {
                        options = {data: {userid: userid}};
                    }

                    options.section = section;
                    registrationDataPromise[userSectionId] = deferred.promise;
                    registrationData[userSectionId] = new RegistrationData({}, {parse: true});
                    registrationData[userSectionId].fetch(options).then(function() {
                        delete registrationDataPromise[userSectionId];
                        deferred.resolve(registrationData[userSectionId]);
                    }, function() {
                        // Could not load old data. Whatever, carry on
                        delete registrationDataPromise[userSectionId];
                        deferred.resolve(registrationData[userSectionId]);
                    });
                }

                return deferred.promise;
            },
            getRegistrationDataForProfile(userid) {
                return this.getRegistrationData(userid, 'profile');
            },
            getRegistrationDataForOfferings(userid) {
                return this.getRegistrationData(userid, 'offerings');
            },
            getRegistrationDataForAvailability(userid) {
                return this.getRegistrationData(userid, 'availability');
            },
            flushCache: function(userid) {
                delete registrationData[userid || 0];
            },

            // This function is going to give us information that will control our on-boarding flow
            // Think things like whitelabel settings, required affinity groups, etc
            getRegistrationSettings: function() {
                if (settingsDataPromise) {
                    return settingsDataPromise;
                }

                const deferred = $q.defer();

                settingsDataPromise = deferred.promise;

                if (settingsData) {
                    deferred.resolve(settingsData);
                } else {
                    settingsData = new RegistrationSettings();
                    settingsData.fetch().then(function() {
                        settingsDataPromise = undefined;
                        deferred.resolve(settingsData);
                    });
                }

                return settingsDataPromise;
            },
            degreesPrefixComplete: function(prefix) {
                return _prefixComplete({keyword: prefix, filter: 'degrees'}, DegreeCollection);
            },
            concentrationsPrefixComplete: function(prefix) {
                return _prefixComplete({keyword: prefix, filter: 'concentrations'}, ConcentrationCollection);
            },
            employersPrefixComplete: function(prefix) {
                return _prefixComplete({keyword: prefix, filter: 'employers'}, EmployerCollection);
            },
            employersJobsPrefixComplete: function(prefix) {
                return _prefixComplete({keyword: prefix, filter: 'employers-jobs'}, EmployerCollection);
            },
            schoolsPrefixComplete: function(prefix) {
                return _prefixComplete({keyword: prefix, filter: 'schools'}, SchoolCollection);
            },
            industriesPrefixComplete: function(prefix) {
                return _prefixComplete({keyword: prefix, filter: 'industries'}, IndustryCollection);
            },
            rolesPrefixComplete: function(prefix) {
                return _prefixComplete({keyword: prefix, filter: 'roles'}, RoleCollection);
            },
            languagePrefixComplete: function(prefix) {
                return _prefixComplete({keyword: prefix, filter: 'languages'}, LanguageCollection);
            },
            usersPrefixComplete: function(prefix) {
                return _prefixComplete({keyword: prefix, filter: 'users'}, UserCollection);
            },
            getTopLocations: function() {
                const deferred = $q.defer();

                $http.get('/registration/tips/locations.json').then(function(responseObj) {
                    deferred.resolve(responseObj.data.data.Locations);
                }, deferred.reject);

                return deferred.promise;
            },

            getTopEmployers: function() {
                const deferred = $q.defer();

                $http.get('/registration/tips/employers.json').then(function(responseObj) {
                    deferred.resolve(responseObj.data.data.Employers);
                }, deferred.reject);

                return deferred.promise;
            },

            getTopIndustries: function() {
                const deferred = $q.defer();

                $http.get('/registration/tips/industries.json').then(function(responseObj) {
                    deferred.resolve(responseObj.data.data.Industries);
                }, deferred.reject);

                return deferred.promise;
            },

            getTopRoles: function() {
                const deferred = $q.defer();

                $http.get('/registration/tips/roles.json').then(function(responseObj) {
                    deferred.resolve(responseObj.data.data.Roles);
                }, deferred.reject);

                return deferred.promise;
            },

            /**
             * THIS IS COPIED FROM profile.js WITH SLIGHT CHANGES -- WE SHOULD MERGE THEM ASAP
             * @param meeting
             * @param files
             */
            bookMeeting: function(meeting, files) {
                const consumer = meeting.get('Consumer');
                const deferred = $q.defer();

                const fd = new FormData();

                // if there are files, we need to add them
                if (Array.isArray(files) && files.length > 0) {
                    files.forEach(function(f) {
                        fd.append('files[]', f);
                    });
                }

                meeting.get('suggestedTimes').forEach(function(t) {
                    fd.append('times[]', t.getTime() / 1000);
                });

                fd.append('expertUserid', meeting.get('Expert').get('id'));
                fd.append('serviceid', meeting.get('Service').get('id'));
                fd.append('timezone', TimezonesService.getDeviceTimezone().locationCode);
                fd.append('comments', meeting.get('comments'));
                fd.append('userEmail', consumer.get('email'));
                fd.append('firstName', consumer.get('firstName'));
                fd.append('lastName', consumer.get('lastName'));
                fd.append('userPhone', consumer.get('phone').number);
                fd.append('userCountry', consumer.get('countryid'));

                $http({
                    method: 'POST',
                    url: '/consultations/book.json',
                    data: fd,
                    headers: {
                        'Content-Type': undefined, // @see
                        // http://www.uncorkedstudios.com/blog/multipartformdata-file-upload-with-angularjs/
                    },
                }).then(function({data: {data: {meetingid}}}) {
                    meeting.set('id', meetingid);
                    deferred.resolve(meeting);
                }, function(responseObj) {
                    deferred.reject(responseObj.data.error);
                });

                return deferred.promise;
            },

            registrationComplete: function() {
                let href = '/registration/complete';
                if ($rootScope.redirectURL) {
                    href += `?redirect=${$rootScope.redirectURL}`;
                }
                $window.location.href = href;
            },
        };
    },
];
