import AbstractHandler from "./AbstractHandler";

export default class MeetingAccessHandler extends AbstractHandler {
    run(exception, cause = null) {
        if (
            exception &&
            typeof exception === 'string' &&
            exception.indexOf('Possibly unhandled rejection: {') > -1
        ) {
            let responseText;
            let ex;

            try {
                ex = JSON.parse(exception.substr('Possibly unhandled rejection: '.length));
                responseText = JSON.parse(ex.responseText);
            } catch (exception2) {
                return false;
            }

            if (ex.status === 403 && responseText.error === 'You do not have access to this meeting') {
                console.log('no access to meeting');
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
}
