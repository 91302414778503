export const profileSyncItemSelector = {
    templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/components/profileSyncItemSelector.html',
    transclude: true,
    bindings: {
        value: '<',
        isRadio: '<',
        disabled: '<?',
        centeredOnSmall: '<?',
        onChange: '&',
    },
    controller: function() {
        var $ctrl = this;
        $ctrl.toggle = function() {
            if ($ctrl.disabled) {
                return;
            }
            if ($ctrl.isRadio && this.value) {
                return;
            }
            $ctrl.onChange({value: !$ctrl.value});
        };
    },
};
