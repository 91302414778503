export function chatInputArea() {
    return {
        restrict: 'E',
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/consultations/chat/inputArea.html',
        transclude: true,
        scope: {
            layerConversation: '=',
            conversation: '=',
        },
        controller: [
            '$scope', 'FileUploader', 'ChatService', 'VisitorService', '$http',
            function($scope, FileUploader, ChatService, VisitorService, $http) {
                // Initialize.
                $scope.messageText = '';
                $scope.loading = true;
                $scope.placeholder = 'Chat is still loading...';
                $scope.errorFiles = [];
                $scope.canSendMessage = true;

                VisitorService.getVisitor().then(function(visitor) {
                    if (visitor.isUser && visitor.user.consumerInOneToOneMatching) {
                        $scope.canSendMessage = expertUser.id === visitor.user.oneToOneMatchedExpertUserId;
                    }
                })

                $scope.inputVars = {
                    attach: false,
                };

                // Attachments stuff.
                $scope.fileIsTransferring = false;
                $scope.selectedFile = null;

                $scope.transferFile = function() {
                    if ($scope.uploader.getNotUploadedItems().length == 0) {
                        return;
                    }
                    $scope.fileIsTransferring = true;
                    $scope.errorFiles = [];
                    $scope.uploader.uploadAll();
                };

                $scope.markAllChatsRead = function() {
                    ChatService.markAllAsRead($scope.conversation);
                };

                // Wait for chat to be ready.
                $scope.$watch('conversation', function() {
                    if (!$scope.conversation) {
                        // do nothing
                        return;
                    }
                    $scope.uploader = new FileUploader({
                        url: '/api/chat/' + $scope.conversation.sid + '/attachFile.json',
                    });

                    $scope.uploader.onSuccessItem = function(item, response, status, headers) {
                        $scope.fileIsTransferring = false;
                        // if there are no errors, we hide the attach dialog
                        $scope.inputVars.attach = $scope.errorFiles.length > 0;
                    };
                    $scope.uploader.onErrorItem = function(item, response, status, header) {
                        $scope.fileIsTransferring = false;
                        $scope.errorFiles.push({
                            file: item.file.name,
                            message: response.data.message,
                        });

                        // if there are errors, we want to keep the "attach" dialog open
                        $scope.inputVars.attach = true;
                    };

                    $scope.loading = false;
                    $scope.placeholder = 'Type a message...';
                });

                // clean up the modal
                $scope.hideModal = function() {
                    $scope.uploader.clearQueue();
                    $scope.inputVars.attach = false;
                };

                // Clicked "Attach"
                $scope.attachFile = function($event) {
                    $scope.inputVars.attach = true;
                    $scope.selectedFile = null;
                    $scope.fileIsTransferring = false;
                };

                // Sending via <Enter> key
                $scope.handleMessageComposition = function($event) {
                    if ($event.key === 'Enter' && $event.ctrlKey) {
                        $event.preventDefault();
                        $scope.submitMessage();
                    }
                };

                // Send was clicked.
                $scope.submitMessage = function() {
                    if (!$scope.canSendMessage) {
                        return;
                    }

                    const messageText = $scope.messageText.trim();
                    if (messageText !== "") {
                        ChatService.sendMessage($scope.layerConversation, messageText);
                        $http.post(`/api/chat/${$scope.conversation.sid}/digest.json`).then();

                        $scope.messageText = "";
                    }
                };

                // handle removing files
                $scope.removeFile = function(key) {
                    $scope.uploader.removeFromQueue(key);
                };

                // we watch for when new files are attached
                $scope.countFilesToUpload = 0;
                $scope.$watch(function() {
                    return $scope.uploader ? $scope.uploader.getNotUploadedItems().length : undefined;
                }, function(newVal, oldVal) {
                    if (newVal === undefined) {
                        return;
                    }
                    $scope.filesToUpload = $scope.uploader.getNotUploadedItems();
                    $scope.countFilesToUpload = $scope.filesToUpload.length;
                });
            },
        ],
    };
}
