import {whitelabelling as Whitelabelling} from 'whitelabelling';
import {ResourcesSearchFilters as SearchFilterCollection} from '../collection/resourcesSearchFilters';
import {ResourcesSearchResults} from '../collection/resourcesSearchResults';
import _ from 'underscore';

export const ResourcesController = [
    '$scope', '$window', '$location', '$timeout', '$rootScope', 'RegistrationService', '$http', '$q',
    ($scope, $window, $location, $timeout, $rootScope, RegistrationService, $http, $q) => {
        $window.document.title = "Resources";
        $rootScope.pageClass = 'expert-search-container search-container';

        $scope.activeTab = 0;
        $scope.querying = false;
        $scope.loading = true;
        $scope.numberPerPage = 10;
        $scope.page = 0;
        $scope.queryCount = 0;
        $scope.showAdvancedFilters = false;
        $scope.whitelabelling = Whitelabelling;
        $scope.filters = new SearchFilterCollection();

        let startingQuery = $location.search();
        if (startingQuery['only-bookmarked']) {
            $scope.activeTab = 1;
        }

        $scope.results = new ResourcesSearchResults();

        $scope.performSearch = () => {
            $scope.results.reset();
            $scope.querying = true;
            $q.when($scope.results.search($scope.filters, $scope.numberPerPage, $scope.page)).then(() => {
                let data = $scope.filters.convertToQueryObject();
                $scope.querying = false;

                // we take this moment to update our query count on the advanced-toggle-icon
                let count = 0;
                _.each(data, d => {
                    count += Array.isArray(d) ? d.length : !!d;
                });
                $scope.queryCount = count;

                // rebuild our parameters
                // we call replace so that we don't add a new history event (so our back button still navigates away
                // from search)
                $location.replace();
                $location.search(data);
            }, () => {
                $scope.querying = false;
            });
        };

        $scope.filters.fetch().then(() => {
            $scope.filters.each(filter => {
                switch (filter.key) {
                    case 'employers':
                        filter.set('prefixHook', RegistrationService.employersPrefixComplete);
                        break;
                    case 'functions':
                        filter.set('prefixHook', RegistrationService.rolesPrefixComplete);
                        break;
                    case 'industries':
                        filter.set('prefixHook', RegistrationService.industriesPrefixComplete);
                        break;
                    case 'schools':
                        filter.set('prefixHook', RegistrationService.schoolsPrefixComplete);
                        break;
                    case 'languages':
                        filter.set('prefixHook', RegistrationService.languagePrefixComplete);
                        break;
                    case 'fields-of-study':
                        filter.set('prefixHook', RegistrationService.concentrationsPrefixComplete);
                        break;
                    case 'degrees':
                        filter.set('prefixHook', RegistrationService.degreesPrefixComplete);
                        break;
                    default:
                        // do nothing
                        break;
                }
            });

            $scope.networkFilter = $scope.filters.findWhere({key: 'network'});
            if ($scope.networkFilter) {
                $scope.networkFilter.set({
                    noSection: true,
                    ignoreInLoop: true,
                });
            }

            // add the available now field
            $scope.availableFilter = $scope.filters.findWhere({key: 'available'});
            if ($scope.availableFilter) {
                $scope.availableFilter.set({
                    noSection: true,
                    ignoreInLoop: true,
                });
            }

            // add the programs field
            $scope.programsFilter = $scope.filters.findWhere({key: 'programs'});
            if ($scope.programsFilter) {
                // if there's no options, it means they're only in one program, so we want to make it a checkbox like
                // the available now filter. Otherwise, we leave it as a group in the Filters section
                $scope.programsFilter.set({
                    noSection: true,
                    ignoreInLoop: true,
                });
            }

            // add the keyword field
            $scope.keywordFilter = $scope.filters.findWhere({key: 'keyword'});
            if ($scope.keywordFilter) {
                $scope.keywordFilter.set({
                    noSection: true,
                    inputPlaceholder: 'Search by keyword',
                    ignoreInLoop: true,
                });
            }

            // start off with our query params loaded into our filters
            $scope.filters.loadFromQueryObject(startingQuery);

            $scope.filters.on('change:value', () => {
                $scope.page = 0;
                $scope.performSearch();
            });

            // this will handle the initial search because this will always fire a change when the watcher is bound
            $scope.$watch('page', $scope.performSearch);

            // this is kind of a nice-to-have which makes sure our subfilters have the correct count
            // it needs to happen after some other stuff loads, so I put 2 second timeout on it
            $timeout(() => {
                $scope.filters.each(f => {
                    f.refreshValue(true);
                });
            }, 2000);
        }).always(() => {
            $scope.$emit('controllerLoaded', true);
            $scope.loading = false;
        });

        $scope.$watch('activeTab', newVal => {
            newVal *= 1;
            $scope.filters.resetFilters();
            $scope.page = 0;
            if (newVal === 1) {
                $scope.filters.setOnlyBookmarked()
            } else {
                $scope.filters.unsetOnlyBookmarked()
            }
        });
    }
]
