import {Backbone} from 'Vertebrae';
import {AffinityGroupForSearch} from '../model/affinityGroupForSearch';

export const AffinityGroupsForSearch = Backbone.Collection.extend({
    _name: 'AffinityGroups',
    model: AffinityGroupForSearch,
    programid: null,
    url: function() {
        if (this.programid) {
            return '/program-groups/' + this.programid + '/get.json';
        } else {
            return '/groups/get.json';
        }
    },
    filterToProgramid: function(programid) {
        this.programid = programid;
    },
});
