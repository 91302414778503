const CLASS_MAIN = 'diff-hl';
const CLASS_INS = 'diff-hl--ins';
const CLASS_DEL = 'diff-hl--del';

export function diffHl() {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            var classList = element[0].classList;
            classList.add(CLASS_MAIN);
            attrs.$observe('diffHl', function(value) {
                switch (scope.$eval(value)) {
                    case 'ins':
                        classList.add(CLASS_INS);
                        classList.remove(CLASS_DEL);
                        break;
                    case 'del':
                        classList.add(CLASS_DEL);
                        classList.remove(CLASS_INS);
                        break;
                    default:
                        classList.remove(CLASS_INS, CLASS_DEL);
                }
            });
        },
    };
}
