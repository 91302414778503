import {whitelabelling as Whitelabelling} from 'whitelabelling';

export function userInputGraduationDate() {
    return {
        restrict: 'E',
        scope: {
            data: '=',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel +
            'ng-template/partials/registration/directives/userInputs/userInputGraduationDate.tpl.html',
        controller: [
            '$scope', function($scope) {
                $scope.whitelabelling = Whitelabelling;

                $scope.graduationDateError = null;

                $scope.$on('showErrors', function() {
                    var errors = $scope.data.validateData();
                    if (errors && errors.dateGraduated) {
                        $scope.graduationDateError = 'Invalid date';
                    }
                });

                $scope.goToNextSubStep = function() {
                    $scope.$emit('goToNextSubStep');
                };
            },
        ],
    };
}

