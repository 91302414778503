import {whitelabelling} from "whitelabelling";

export function groupResource() {
    return {
        restrict: 'E',
        scope: {
            'resource': '=',
            'abridged': '@?',
        },
        templateUrl: '/asset/partials/user/directives/groupResource.html',
        replace: true,
        controller: ['$scope', '$routeParams', ($scope, $routeParams) => {
            $scope.resourceLink = function(resource) {
                if (!whitelabelling.hasWebinars || resource.source.indexOf('youtube.com') === -1) {
                    return resource.source;
                } else {
                    return `/resources/${resource.id}`
                }
            }
        }]
    };
}
