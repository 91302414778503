import {NgModel} from 'model/ngModel';
import {whitelabelling as Whitelabelling} from 'whitelabelling';
import * as moment from 'moment-timezone';
import * as _ from 'underscore';
import {capitalizeFirst, validateEmail} from 'configurations/utilities';

const COHORT_UNKNOWN = 'unknown',
    COHORT_PROSPECTIVE = 'prospective',
    COHORT_CURRENT = 'current',
    COHORT_YOUNG_ALUMNI = 'young-alumni',
    COHORT_ALUMNI = 'alumni';

const COHORT_UNKNOWN_LABEL = 'Unknown',
    COHORT_PROSPECTIVE_LABEL = 'Prospective',
    COHORT_CURRENT_LABEL = 'Current Student',
    COHORT_YOUNG_ALUMNI_LABEL = 'Young Alumni',
    COHORT_ALUMNI_LABEL = 'Alumni';

const cohortLabels = {
    [COHORT_UNKNOWN]: COHORT_UNKNOWN_LABEL,
    [COHORT_PROSPECTIVE]: COHORT_PROSPECTIVE_LABEL,
    [COHORT_CURRENT]: COHORT_CURRENT_LABEL,
    [COHORT_YOUNG_ALUMNI]: COHORT_YOUNG_ALUMNI_LABEL,
    [COHORT_ALUMNI]: COHORT_ALUMNI_LABEL,
};

export const User = NgModel.extend({
    _name: 'User',
    urlRoot: '/control/partner/users/[id]/get.json',
    fetch: function(options) {
        if (this.external === true) {
            this.urlRoot = '/control/partner/users/external/[id]/get.json';
        }

        return this._super('fetch', options);
    },
    customInputsMap: {}, // For mapping CSV headers to data.
    defaults: {
        firstName: '',
        lastName: '',
        email: '',
        externalid: '', // needed so that it binds for angular when used in the userCreate controller
        cohort: COHORT_UNKNOWN,
        cohortLabel: COHORT_UNKNOWN_LABEL,
        isSelected: false, // this property isn't sent by the server, but is used in our client side
        platformAffiliation: null,
        customInputs: {},
        consumerInOneToOneMatching: false,
    },
    validateData: function() {
        var errors = {};
        errors.firstName = !this.get('firstName').length;
        errors.lastName = !this.get('lastName').length;
        errors.email = !validateEmail(this.get('email'));

        this.set('errors', errors);
        return !this.hasErrors();
    },
    hasErrors: function() {
        return _.contains(this.get('errors'), true);
    },
    parse: function(data, options) {
        var dataFormatted = this._super('parse', data);

        dataFormatted.fullName = '';
        if (dataFormatted.firstName || dataFormatted.lastName) {
            if (dataFormatted.firstName) {
                dataFormatted.fullName += dataFormatted.firstName;
            }
            if (dataFormatted.lastName) {
                if (dataFormatted.fullName !== '') {
                    dataFormatted.fullName += ' ';
                }
                dataFormatted.fullName += dataFormatted.lastName;
            }
        }
        if (dataFormatted.fullName === '') {
            dataFormatted.fullName = dataFormatted.email;
        }

        if (data.dateCreated) {
            dataFormatted.dateCreated = this.getDate(data.dateCreated);
            dataFormatted.dateCreatedFormatted = moment.tz(data.dateCreated, 'UTC').format('MMM D, YYYY');
        }

        if (data.dateLastLogin) {
            dataFormatted.dateLastLogin = this.getDate(data.dateLastLogin);
            dataFormatted.dateLastLoginFormatted = moment.tz(data.dateLastLogin, 'UTC').format('MMM D, YYYY');
        }

        if (data.dateExpertProfileCompleted) {
            dataFormatted.dateExpertProfileCompleted = this.getDate(data.dateExpertProfileCompleted);
            dataFormatted.dateExpertProfileCompletedFormatted = moment.tz(data.dateExpertProfileCompleted, 'UTC').
                format('MMM D, YYYY');
        }

        if (data.dateConsumerProfileCompleted) {
            dataFormatted.dateConsumerProfileCompleted = this.getDate(data.dateConsumerProfileCompleted);
            dataFormatted.dateConsumerProfileCompletedFormatted = moment.tz(data.dateConsumerProfileCompleted, 'UTC').
                format('MMM D, YYYY');
        }

        if (data.dateUpdated) {
            dataFormatted.dateUpdated = this.getDate(data.dateUpdated);
            dataFormatted.dateUpdatedFormatted = moment.tz(data.dateUpdated, 'UTC').format('MMM D, YYYY');
        } else {
            dataFormatted.dateUpdatedFormatted = 'Never';
        }

        dataFormatted.cohortLabel = cohortLabels[dataFormatted.cohort];

        dataFormatted.relationship = capitalizeFirst(dataFormatted.relationship);
        dataFormatted.isRestrictedToGroups = parseInt(data.isRestrictedToGroups) ? 'Yes' : 'No';
        dataFormatted.isRestrictedToGroupsFormatted = parseInt(data.isRestrictedToGroups) ? 'Yes' : 'No';

        if (data.whitelabelGraduationDate) {
            dataFormatted.whitelabelGraduationDate = this.getDate(data.whitelabelGraduationDate);
            dataFormatted.whitelabelGraduationDateFormatted = parseInt(
                moment.tz(data.whitelabelGraduationDate, 'UTC').format('YYYY'));

            if (dataFormatted.whitelabelGraduationDateFormatted === 0) {
                // this year is bupkiss, remove it
                dataFormatted.whitelabelGraduationDate = dataFormatted.whitelabelGraduationDateFormatted = undefined;
            }
        }

        dataFormatted.oooFormatted = null;
        dataFormatted.oooStartFormatted = null;
        dataFormatted.isPenaltyBox = this.asBoolean(data.isPenaltyBox);
        if (dataFormatted.isPenaltyBox === true) {
            dataFormatted.oooFormatted = 'Indefinite';
        } else if (data.oooFinishTime) {
            dataFormatted.oooFinishTime = this.getDate(data.oooFinishTime);
            dataFormatted.oooFormatted = moment.tz(data.oooFinishTime, 'UTC').format('MMM D, YYYY');
        }
        if (data.oooStartTime) {
            dataFormatted.oooStartTime = this.getDate(data.oooStartTime);
            dataFormatted.oooStartFormatted = moment.tz(data.oooStartTime, 'UTC').format('MMM D, YYYY');
        }

        dataFormatted.averageRating = !_.isNull(data.averageRating) ? Math.round(data.averageRating) / 2 : null;
        dataFormatted.percentPositive = !_.isNull(data.percentPositive) ? parseInt(data.percentPositive) : null;
        dataFormatted.consultsTotal = parseInt(data.consultsTotal) || 0;
        dataFormatted.consultsGiven = parseInt(data.consultsGiven) || 0;
        dataFormatted.consultsReceived = parseInt(data.consultsReceived) || 0;
        dataFormatted.consultsTotal = dataFormatted.consultsReceived + dataFormatted.consultsGiven;

        dataFormatted.isPartner = !!parseInt(data.isPartner);
        dataFormatted.isManager = !!parseInt(data.isManager);

        dataFormatted.phoneNumber = (!_.isNull(data.phoneNumber) && data.phoneNumber !== '' && data.phoneNumber !== 'null') ? data.phoneNumber : null;

        if (options.selected) {
            dataFormatted.isSelected = true;
        }

        this.__bindManyForAngular(dataFormatted);

        return dataFormatted;
    },
    getDownloadHeaders: function() {
        var consumerNoun = capitalizeFirst(Whitelabelling.consumerNoun);
        var expertNoun = capitalizeFirst(Whitelabelling.expertNoun);
        const cols = [
            'Date Joined',
            'First Name',
            'Last Name',
            'Email',
            'Cohort',
            'Group(s)',
            'Last Login',
            'Graduation Year',
            'Total Consults',
            'Consults Given',
            'Consults Received',
            'Current Employer',
            'Current Location',
            'Current Industry',
            'Current Job Function',
            'Avg Rating',
            '% Positive Rating',
            'Is Restricted To Groups',
            'Notes',
            'Out of Office Until',
            // 'Programs',
            'Affiliation',
            'Firsthand ID',
            'External ID',
            'Field of Study',
            'Passcode',
            'Passcode School',
            'Schools',
            'Degrees',
            'PhoneNumber',
        ];

        if (!Whitelabelling.settings['hide:mentoringRelatedFeatures']) {
            cols.push(consumerNoun + ' Status');
            cols.push(expertNoun + ' Status');
            cols.push('Webinar Status');
            cols.push(consumerNoun + ' Profile Completed');
            cols.push(expertNoun + ' Profile Completed');
        }

        if (Whitelabelling.settings.hasFairs) {
            cols.push('Booth[s]');
            cols.push('Booth[s] Managed');
            // cols.push('Fairs Public Page Registration')
        }

        if (!Whitelabelling.settings['hideField:gender']) {
            cols.push('Gender');
        }

        if (!Whitelabelling.settings['hideField:race']) {
            cols.push('Race');
        }

        if (User.customInputsMap) {
            for (const label in User.customInputsMap) {
                cols.push(User.customInputsMap[label]);
            }
        }

        return cols;
    },
    getDownloadData: function() {
        const cols = [
            this.get('dateCreatedFormatted'),
            this.get('firstName'),
            this.get('lastName'),
            this.get('email'),
            this.get('cohortLabel'),
            this.get('groups'),
            this.get('dateLastLoginFormatted'),
            this.get('whitelabelGraduationDateFormatted'),
            this.get('consultsTotal'),
            this.get('consultsGiven'),
            this.get('consultsReceived'),
            this.get('currentEmployer') ? this.get('currentEmployer').replace(/"/gi, '\'') : null,
            this.get('currentLocation'),
            this.get('currentIndustry'),
            this.get('currentFunction'),
            this.get('averageRating'),
            this.get('percentPositive'),
            this.get('isRestrictedToGroups'),
            this.get('notes'),
            this.get('oooFormatted'),
            // this.get('programs'),
            this.get('platformAffiliation'),
            this.get('id'),
            this.get('externalid'),
            this.get('concentrationLabel'),
            this.get('passcodes'),
            this.get('passcodeSchools'),
            this.get('schools'),
            this.get('degrees'),
            this.get('phoneNumber'),
        ];

        if (!Whitelabelling.settings['hide:mentoringRelatedFeatures']) {
            cols.push(this.get('consumerStatus'));
            cols.push(this.get('expertStatus'));
            cols.push(this.get('webinarStatus'));
            cols.push(this.get('dateConsumerProfileCompletedFormatted'));
            cols.push(this.get('dateExpertProfileCompletedFormatted'));
        }

        if (Whitelabelling.settings.hasFairs) {
            cols.push(this.get('booths'));
            cols.push(this.get('boothsManaged'));
            // cols.push(this.get('registeredThroughFairsLandingPage'));
        }

        if (!Whitelabelling.settings['hideField:gender']) {
            cols.push(this.get('gender'));
        }

        if (!Whitelabelling.settings['hideField:race']) {
            cols.push(this.get('race'));
        }

        if (User.customInputsMap) {
            for (const dataKey in User.customInputsMap) {
                if (this.get(`${dataKey}:url`)) {
                    cols.push(this.get(`${dataKey}:url`));
                } else {
                    cols.push(this.get(dataKey));
                }
            }
        }

        return cols;
    },
}, {
    COHORT_UNKNOWN: COHORT_UNKNOWN,
    COHORT_PROSPECTIVE: COHORT_PROSPECTIVE,
    COHORT_YOUNG_ALUMNI: COHORT_YOUNG_ALUMNI,
    COHORT_CURRENT: COHORT_CURRENT,
    COHORT_ALUMNI: COHORT_ALUMNI,
    cohorts: [COHORT_PROSPECTIVE, COHORT_CURRENT, COHORT_YOUNG_ALUMNI, COHORT_ALUMNI],
    cohortLabels: cohortLabels,
    setCustomInputMap: (customInputMap) => {
        User.customInputsMap = customInputMap;
    },
});
