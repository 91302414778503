import {NgModel} from './ngModel';
import * as _ from 'underscore';

export const ExpertFeedback = NgModel.extend({
    _name: 'ExpertFeedback',
    urlRoot: '/consultations/[meetingid]/expertFeedback.json',
    idAttribute: 'meetingid',
    defaults: {
        'adviceQuality': null,
        'subjectExpertise': null,
        'responsiveness': null,
        'value': null,
        'comment': null,
        'evisorsExperience': null,
        'evisorsComment': null,
        'meetingid': null,
    },
    // this function ensures there's a truthey value for every property in defaults
    isComplete: function(requiredOnly) {
        return !this.missingFields(requiredOnly).length;
    },
    // this function returns a list of missing fields
    missingFields: function(requiredOnly) {
        var missingFields = [];
        var that = this;

        // we always skip the meetingid
        var skipFields = ['meetingid'];

        // if we only care about the required fields
        if (requiredOnly) {
            skipFields.push('evisorsExperience');
            skipFields.push('evisorsComment');
            skipFields.push('comment');
        }
        _.each(this.defaults, function(v, prop) {
            if (!!~skipFields.indexOf(prop)) {
                return;
            }
            if (!that.get(prop)) {
                missingFields.push(prop);
            }
        });
        return missingFields;
    },
});
