export function pluralize() {
    return function(str, number) {
        number = number ? parseInt(number) : 2;
        if (number === 1) {
            return str;
        }
        return str + 's';
    };
}

