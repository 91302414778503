export const MeetingState_Consumer_EmailVerification = [
    '$scope', '$http', function($scope, $http) {
        $scope.template = Evisors.config.cdnRootWhitelabel + 'asset/partials/consultations/meetingStates/consumer/emailVerification.html';

        // TODO: Create payment link to paypal
        $scope.resendVerificationEmail = function($event) {
            var _target = $($event.currentTarget);
            if (_target.hasClass('loading')) {
                return;
            }
            _target.addClass('loading');
            $http.post('/consultations/' + $scope.meeting.get('id') + '/resendVerification.json', {}).then(function() {
                $scope.showSuccessMessage = true;
                _target.removeClass('loading');
            }, function(response) {
                $scope.errorMessage = response.data.data.message;
                _target.removeClass('loading');
            });

            $scope.showCancel = false;
        };
    },
];
