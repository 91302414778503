import {NgModel} from './ngModel';
import {Employer} from './employer';
import {Location} from './location';
import {Industry} from './industry';
import {Role} from './role';
import {whitelabelling as Whitelabelling} from 'whitelabelling';
import {capitalizeFirst} from 'configurations/utilities';
import {User} from '../../control/partner/model/user';
import {validateURL} from 'configurations/utilities';

export const PlatformAgnosticPosition = 1;

export const JobOpeningBase = NgModel.extend({
    _name: 'JobOpening',
    associations: {
        Employer: Employer,
        Location: Location,
        Industries: Industry,
        Roles: Role,
        User: User,
        ContactUser: User,
    },
    defaults: {
        Employer: null,
        Location: null,
        Industries: null,
        Roles: null,
        EmploymentType: null,
        dateCreated: null,
        dateExpired: null,
        dateUpdated: null,
        isOpenToMeetings: null,
        isPlatformAgnostic: false,
        weight: 0,
        link: null,
        level: null,
        title: null,
        isDeleted: false,
        User: null,
        ContactUser: null,
        employerLogo: undefined,
    },
    constructor: function() {
        this.isPlatformAgnosticOptions = {
            0: {
                id: 0,
                label: capitalizeFirst(Whitelabelling.siteName) + ' Only',
            },
            1: {
                id: 1,
                label: 'Firsthand Global Network',
            },
        };
        NgModel.apply(this, arguments);
    },
    isPlatformAgnosticOptions: {},
    parse: function(data) {
        const dataFormatted = this._super('parse', data);

        if (dataFormatted.dateExpired) {
            dataFormatted.dateExpired = this.getDate(dataFormatted.dateExpired);

            if (dataFormatted.dateExpired.getTime() < Date.now()) {
                dataFormatted.isDeleted = true;
            }
        }

        if (dataFormatted.dateCreated) {
            dataFormatted.dateCreated = this.getDate(dataFormatted.dateCreated);
        }

        if (dataFormatted.dateUpdated) {
            dataFormatted.dateUpdated = this.getDate(dataFormatted.dateUpdated);
        }

        if (dataFormatted.employerLogo) {
            let logoPath = dataFormatted.employerLogo;
            if (logoPath.indexOf('/') === 0) {
                logoPath = logoPath.substring(1);
            }
            dataFormatted.cdnEmployerLogo = Whitelabelling.cdnRootGeneral + logoPath;
        }

        if (dataFormatted.Roles && dataFormatted.Roles.values && dataFormatted.Roles.values.length > 0) {
            dataFormatted.Roles = dataFormatted.Roles.values;
        }

        if (dataFormatted.Industries && dataFormatted.Industries.values && dataFormatted.Industries.values.length > 0) {
            dataFormatted.Industries = dataFormatted.Industries.values;
        }

        dataFormatted.isOpenToMeetings = this.asBoolean(dataFormatted.isOpenToMeetings);
        if (data.hasOwnProperty('isPlatformAgnostic')) {
            dataFormatted.isPlatformAgnostic = this.isPlatformAgnosticOptions[data.isPlatformAgnostic * 1];
        }
        dataFormatted.weight = parseInt(dataFormatted.weight);
        this.__bindForAngular(dataFormatted);

        return dataFormatted;
    },
    validateData: function() {
        const errors = {};

        if (!this.get('isDeleted')) {
            errors.title = !this.get('title');
            errors.Employer = !this.get('Employer') || !this.get('Employer').get('label');
            errors.Industry = false;
            errors.Role = false;
            errors.Location = false;
            errors.link = !this.get('link') || !validateURL(this.get('link'));
            errors.dateExpired = false;
        }

        this.set({'errors': errors}, {silent: true});
        return errors;
    },
    removeContactUser: function() {
        this.ContactUser = null;
    },
    getFullName: function(user) {
        return user.label ? user.label : `${user.firstName} ${user.lastName} (${user.email})`;
    }
}, {
    LEVELS: {
        '': '',
        'INTERNSHIP': "Internship",
        'ENTRY': "Entry level",
        'ASSOCIATE': "Associate",
        'MID-SENIOR': "Mid-Senior level",
        'DIRECTOR': "Director",
        'EXECUTIVE': "Executive",
        'NOTAPPLICABLE': "Not Applicable",
    },
    EMPLOYMENTTYPES: [{
        "id": 0,
        "label": ""
    }, {
        "id": 1,
        "label": "Full-time"
    }, {
        "id": 2,
        "label": "Part-time"
    }, {
        "id": 3,
        "label": "Contract"
    }, {
        "id": 4,
        "label": "Temporary"
    }, {
        "id": 5,
        "label": "Volunteer"
    }, {
        "id": 6,
        "label": "Internship"
    },
    ],
});
