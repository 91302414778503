export function profileExperienceEmployment() {
    return {
        restrict: 'E',
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/profileExperienceEmployment.html',
        replace: true,
        scope: {
            emp: '=employment',
        },
    };
}

