import {Meeting as MeetingModel} from 'model/meeting';

export const Meeting = [
    '$window', '$routeParams', '$http', '$q',
    function($window, $routeParams, $http, $q) {
        var lastUpdated = null;
        var meeting = null;
        var meetingPromise = null;
        var meetingid = null;

        /* Frequency that the meeting page will poll in milliseconds. */
        var POLL_FREQUENCY = 30000;

        /*
         NOTE: This service is a little old and poorly architected.
         It was originally built to exist on a single meeting page (and so it pulled the single meeting data
         from a meeting id stored in the pathname. However, we now build services to be reusable.
         So in order to shim this legacy logic into the better-practice, we kept the concept of a singleton meeting
         but only set it from the first call to .get() which expects a meeting id to be passed which is then persisted
         for the duration of the service's life.

         If we one day decide to make this service truly reusable, every individual function that depends on the global
         meeting or global meeting id variables will need to be refactored to take a meeting/meetingid respectively.
         */

        var retVal = {
            getLastUpdated: function() {
                return lastUpdated;
            }
            , getByRouteParams: function(skipCache) {
                if (!skipCache) {
                    skipCache = false;
                }

                if (!meetingid || $routeParams.meetingid !== meetingid) {
                    meetingPromise = null;
                    lastUpdated = null;

                    if (!$routeParams.meetingid) {
                        // throw 'Must initialize with a meeting id';
                        // temp disabled throw to prevent sentry errors
                        // using .log instead of .error so sentry doesn't catch it
                        console.log('No meeting id');
                        return $q.reject('noId');
                    }

                    // initialize / reset our global variables
                    meetingid = $routeParams.meetingid;
                    meeting = new MeetingModel({id: meetingid});
                }

                // if a request is already pending, just return that
                if (meetingPromise) {
                    return meetingPromise;
                }

                var deferred = $q.defer();
                // we update once every 1 minute
                if (skipCache || (!lastUpdated || Date.now() - lastUpdated > POLL_FREQUENCY)) {
                    meeting.fetch().then(() => {
                        meetingPromise = null;
                        lastUpdated = Date.now();
                        deferred.resolve(meeting);
                    }, (error) => {
                        meetingPromise = null;
                        deferred.reject(error);
                    });

                    meetingPromise = deferred.promise;
                } else {
                    deferred.resolve(meeting);
                }

                return deferred.promise;
            }
            , reschedule: function(m, rescheduleTimestamps) {
                var deferred = $q.defer();

                $http.post('/consultations/' + m.get('id') + '/reschedule.json', rescheduleTimestamps).then(() => {
                    // we re-pull this meeting
                    m.fetch().then(() => {
                        deferred.resolve(m);
                    }, deferred.reject);
                }, deferred.reject);

                return deferred.promise;
            }
            , cancel: function(m) {
                var deferred = $q.defer();

                $http.post('/consultations/' + m.get('id') + '/cancel.json', {}).then(function() {
                    // we re-pull this meeting
                    m.fetch().then(function() {
                        deferred.resolve(m);
                    }, deferred.reject);
                }, deferred.reject);

                return deferred.promise;
            }
            , selectTime: function(m, date) {
                var deferred = $q.defer();

                if (date instanceof Date) {
                    date = date.getTime();
                } else if (typeof date !== 'number') {
                    throw 'Selected date must be a timestamp or date object';
                }

                $http.post('/consultations/' + m.get('id') + '/selectTime.json', {
                    date: date,
                }).then(function() {
                    // we re-pull this meeting
                    m.fetch().then(function() {
                        deferred.resolve(m);
                    }, deferred.reject);
                }, deferred.reject);

                return deferred.promise;
            }
            , endConsultation: function(m) {
                return endCallGeneric('/endCall.json', m);
            }
            , endConsultationMarkComplete: function(m) {
                return endCallGeneric('/endCallMarkComplete.json', m);
            }
            , cancelRFP: function(rfp) {
                var deferred = $q.defer();

                var fd = new FormData();
                fd.append('rfpid', rfp.get('id'));

                $http({
                    method: 'POST',
                    url: '/consultations/rfp/cancel.json',
                    data: fd,
                    headers: {
                        'Content-Type': undefined, // @see
                        // http://www.uncorkedstudios.com/blog/multipartformdata-file-upload-with-angularjs/
                    },
                }).then(function() {
                    deferred.resolve(true);
                }, function(responseObj) {
                    deferred.reject(responseObj.data.error);
                });

                return deferred.promise;
            },
            getClientCapabilityToken: function(m) {
                var deferred = $q.defer();

                $http.get('/consultations/' + m.get('id') + '/capability-token.json').then(function(response) {
                    deferred.resolve(response.data.data.token);
                }, deferred.reject);

                return deferred.promise;
            },
            getVideoAccess: function(m) {
                // Returns a video access token plus metadata (room name).
                var deferred = $q.defer();

                $http.get('/consultations/' + m.get('id') + '/video-access.json').then(function(response) {
                    deferred.resolve({
                        token: response.data.data.token,
                        roomName: response.data.data.roomName,
                    });
                }, deferred.reject);

                return deferred.promise;
            },
            /**
             *
             * @param m: Meeting
             * @param trackId string - The unique identifier of the track
             * @param isConnected bool - Whether to notify the server that the user is connected or not.
             */
            updateVideoStatus: function(m, trackId, isConnected) {
                var deferred = $q.defer();

                var suffix = 'video-connected';
                if (isConnected === false) {
                    suffix = 'video-disconnected';
                }

                $http.post('/consultations/' + m.get('id') + '/' + trackId + '/' + suffix + '.json').
                    then(function(response) {
                        deferred.resolve(true);
                    }, deferred.reject);
            },
            /**
             *
             * @param m: Meeting
             */
            disconnectAllVideo: function(m) {
                var deferred = $q.defer();

                $http.post('/consultations/' + m.get('id') + '/video-disconnected.json').then(function(response) {
                    deferred.resolve(true);
                }, deferred.reject);
            },
        };

        function endCallGeneric(hook, m) {
            var deferred = $q.defer();

            $http.post('/consultations/' + m.get('id') + hook, {}).then(function() {
                // we re-pull this meeting
                m.fetch().then(function() {
                    deferred.resolve(m);
                }, deferred.reject);
            }, function() {
                // if it fails, it's possible it's because the meeting is no longer end-able (ie, complete)
                // so we want to update the meeting first, and then report the initial end request failed
                // if it's indeed un-endable, then this can/will be reflected in the view
                m.fetch().then(deferred.reject, deferred.reject);
            });

            return deferred.promise;
        }

        return retVal;
    },
];
