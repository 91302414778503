import {NgModel} from 'model/ngModel';

export const File = NgModel.extend({
    _name: 'File',
    parse: function(data) {
        var dataFormatted = this._super('parse', data);

        if (dataFormatted.dateUploaded) {
            dataFormatted.dateUploaded = this.getDate(dataFormatted.dateUploaded);
        }

        if (dataFormatted.chatFileid) {
            dataFormatted.url = '/asset/chat-file/' + dataFormatted.chatFileid;
        } else if (dataFormatted.meetingFileid) {
            dataFormatted.url = '/asset/meeting-file/' + dataFormatted.meetingFileid;
        }

        this.__bindManyForAngular(dataFormatted);
        return dataFormatted;
    },
});
