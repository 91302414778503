import {NgModel} from './ngModel';
import {ProfileUser} from 'model/profileUser';
import {MeetingState} from 'model/meetingState';
import {Rfp} from './rfp';
import {TimeSwath} from './timeSwath';
import {ExpertFeedback} from './expertFeedback';
import {ConsumerFeedback} from './consumerFeedback';
import {Service} from 'model/service';
import * as moment from 'moment-timezone';
import sortBy from 'lodash/sortBy';

export const Meeting = NgModel.extend({
    _name: 'Meeting',
    urlRoot: '/consultations/[id].json',
    associations: {
        MeetingState,
        Expert: ProfileUser,
        Consumer: ProfileUser,
        OtherUser: ProfileUser,
        RFP: Rfp,
        Service,
        ExpertFeedback,
        ConsumerFeedback,
    },
    defaults: {
        id: null,
        Service: null,
        selectedTime: null,
        suggestedTimes: [],
        consumerCode: undefined,
        expertCode: undefined,
    },
    parse: function(data) {
        const dataFormatted = this._super('parse', data);

        if (dataFormatted.Service) {
            dataFormatted.durationInSeconds = parseInt(dataFormatted.Service.get('durationInSeconds')) || 60;
        }

        dataFormatted.durationInSeconds = parseInt(dataFormatted.durationInSeconds);
        dataFormatted.durationInMinutes = dataFormatted.durationInSeconds / 60;
        dataFormatted.durationInHours = dataFormatted.durationInSeconds / 60 / 60;

        // here we convert the suggested times into date objects
        // NOTE: Dates are assumed UTC time
        if (dataFormatted.suggestedTimes) {
            dataFormatted.suggestedTimesFiltered = [];
            for (let i = 0; i < dataFormatted.suggestedTimes.length; i++) {
                if (dataFormatted.suggestedTimes[i] !== null)
                    dataFormatted.suggestedTimesFiltered.push(dataFormatted.suggestedTimes[i]);
            }

            dataFormatted.suggestedTimes = dataFormatted.suggestedTimesFiltered;
            const suggestedTimesFormatted = [];
            dataFormatted.suggestedTimeSwaths = [];
            for (let i = 0; i < dataFormatted.suggestedTimes.length; i++) {
                suggestedTimesFormatted.push(this.getDate(dataFormatted.suggestedTimes[i]));
                dataFormatted.suggestedTimeSwaths.push(
                    new TimeSwath(suggestedTimesFormatted[i], dataFormatted.durationInSeconds));
            }
            dataFormatted.suggestedTimes = suggestedTimesFormatted;
            dataFormatted.suggestedTimes = sortBy(dataFormatted.suggestedTimes, suggestedTime => (new Date(suggestedTime)));
        }

        if (data.selectedTime) {
            dataFormatted.selectedTime = this.getDate(data.selectedTime);
            dataFormatted.selectedTimeSwath = new TimeSwath(dataFormatted.selectedTime,
                dataFormatted.durationInSeconds);
            dataFormatted.selectedTimeFormatted = moment.tz(dataFormatted.selectedTime, moment.tz.guess()).
                format('dddd, MMM Do [at] h:mma (z)');
        }

        if (data.dateStateChanged) {
            dataFormatted.dateStateChanged = this.getDate(data.dateStateChanged);
        }

        if (data.consumerCode) {
            dataFormatted.consumerCodeLabel = this.formatConferenceCode(data.consumerCode);
        }

        if (data.expertCode) {
            dataFormatted.expertCodeLabel = this.formatConferenceCode(data.expertCode);
        }

        if (data.expiry) {
            dataFormatted.expiry = this.getDate(data.expiry);
            dataFormatted.expiryFormatted = moment.tz(dataFormatted.expiry, moment.tz.guess()).
                format('dddd, MMM Do [at] h:mma (z)');
        }

        if (data.consumerCost) {
            dataFormatted.consumerCost = data.consumerCost.toFixed(2);
        }
        if (data.expertCredit) {
            dataFormatted.expertCredit = data.expertCredit.toFixed(2);
        }

        dataFormatted.meetingStateid = parseInt(dataFormatted.meetingStateid);

        return dataFormatted;
    },
    formatConferenceCode: function(code) {
        return code.substring(0, 3) + '-' + code.substring(3, 5) + '-' + code.substring(5);
    },
    setSuggestedTimes: function(times) {
        let suggestedTimeSwaths = this.get('suggestedTimeSwaths');
        let suggestedTimes = [];
        times.forEach((slot) => {
            const date = slot.get('date');
            suggestedTimeSwaths.push(new TimeSwath(date, this.get('durationInSeconds')));
            suggestedTimes.push(date);
        })
        this.set('suggestedTimeSwaths', suggestedTimeSwaths);
        this.set('suggestedTimes', suggestedTimes);
    },
    // this function is used to determine what date we use to order it or group it on the dashboard
    getGroupDate: function() {
        if (this.cachedGroupDate) {
            return this.cachedGroupDate;
        }
        // Group Date will change based on the state of the meeting (request sent? selected time? first requested time?, etc.)
        switch (this.get('meetingStateid')) {
            case 4: // prepare
            case 5: // meet
            case 6: // complete
            case 19: // consumer now show
            case 20: // expert no show
            case 24: // both no show
                this.cachedGroupDate = this.get('selectedTime');
                break;

            case 0: // email verification
            case 3: // payment
            case 1: // expert accept
            case 7: // consumer reschedule
            case 9: // expert reschedule
                // we take the earliest time
                this.cachedGroupDate = new Date(Math.min(...(this.get('suggestedTimes'))));
                break;
            case -1: // expired
            case 11: // consumer cancelled
            case 12: // expert cancelled
            default:
                this.cachedGroupDate = this.get('dateStateChanged');
                break;
        }

        return this.cachedGroupDate;
    },
});
