export const FH_CUSTOM_QUESTIONS = [
    {
        id: 1,
        post: 'What advice do you have for someone who\'s new to networking?',
        description: 'Please share a couple pointers or resources that have helped you',
        enabled: false,
    },
    {
        id: 2,
        post: 'What was your first job and how did you get it?',
        description: 'Please share your story and any advice for a new grad',
        enabled: false,
    },
    {
        id: 3,
        post: 'What\'s the best career advice you\'ve ever received?',
        description: '',
        enabled: false,
    },
];

export function groupAdminForumSection() {
    return {
        scope: {
            group: '=',
            errors: '=',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/groupAdminForumSection.html',
        controller: [
            '$scope', function($scope) {
                $scope.seedQuestionsOptions = {
                    0: 'No seed questions',
                    1: 'Firsthand seed questions',
                    2: 'Write custom questions',
                };

                $scope.seedQuestionsCustomText = '';
                if ($scope.group) {
                    $scope.group.customQuestions = [];
                }

                $scope.changedSeedQuestionsData = () => {
                    switch ($scope.group.seedQuestionsType) {
                        case '0':
                            $scope.group.customQuestions = [];
                            break;
                        case '1':
                            $scope.group.customQuestions = $scope.firsthandCustomQuestions.filter(elem => elem.enabled);
                            break;
                    }
                };
                $scope.fhQuestionChange = () => {
                    $scope.group.customQuestions = $scope.firsthandCustomQuestions.filter(elem => elem.enabled);
                };
                $scope.$watch('group.seedQuestionsType', $scope.changedSeedQuestionsData);

                $scope.addCustomQuestion = function() {
                    $scope.group.customQuestions.push({
                        id: Date.now(),
                        post: this.seedQuestionsCustomText,
                    });
                    this.seedQuestionsCustomText = '';
                    $scope.changedSeedQuestionsData();
                };

                $scope.removeCustomQuestion = (id) => {
                    $scope.group.customQuestions = $scope.group.customQuestions.filter(elem => elem.id !== id);
                    $scope.changedSeedQuestionsData();
                };

                $scope.firsthandCustomQuestions = FH_CUSTOM_QUESTIONS;
            },
        ],
    };
}

