export function userInputAboutMe() {
    return {
        restrict: 'E',
        scope: {
            data: '=',
            displayType: '@?',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel +
            'ng-template/partials/angular-utilities/directives/userInputs/userInputAboutMe.tpl.html',
    };
}
