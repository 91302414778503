export function customInputText() {
    return {
        restrict: 'E',
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/customInputText.html',
        scope: {
            customInput: '=',
            data: '=',
        },
        require: 'ngModel',
        controller: [
            '$scope', function($scope) {
                $scope.input = {
                    val: $scope.data ? $scope.data : null,
                };

                $scope.$watch('input.val', () => {
                    $scope.data = $scope.input.val;
                    $scope.$emit('CustomInputChanged', $scope.customInput, $scope.data);
                });
            },
        ],
    };
}
