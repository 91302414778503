import {whitelabelling as Whitelabelling} from 'whitelabelling';
import {capitalizeFirst} from 'configurations/utilities';
import {SearchFilters} from '../collection/searchFilters';
import {DonationPrompt} from '../model/donationPrompt';

export const UserDashboardController = [
    '$scope',
    '$window',
    '$rootScope',
    '$location',
    '$q',
    'UserDashboardService',
    'VisitorService',
    '$http',
    '$sce',
    function(
        $scope, $window, $rootScope, $location, $q, UserDashboardService, VisitorService, $http, $sce) {
        $window.document.title = 'Dashboard';
        $rootScope.pageClass = 'user-dashboard-container';
        $scope.whitelabelling = Whitelabelling;
        $scope.selectedPeopleTab = 1;
        $scope.profileStrengthLabel = 'intermediate';
        $scope.resources = [];

        $scope.numberOfPeople = 8;
        $scope.isLoading = true;
        $scope.showDemoVideos = [];

        if (window.justRegistered && Whitelabelling.isAMP) {
            $scope.donationPrompt = new DonationPrompt({type: 'registration'});

            $q.when($scope.donationPrompt.fetch()).then(function() {
                if ($scope.donationPrompt.target) {
                    $scope.showDonationPrompt = true;
                }
                window.justRegistered = false;
            });
        }

        $scope.showVideoModal = window.justRegistered && window.showVideos;

        var expertNounPlural = capitalizeFirst(Whitelabelling.expertNounPlural);

        $scope.exploreIcons = [];

        if (Whitelabelling.hasWebinars) {
            $scope.exploreIcons.unshift({
                url: '/webinars',
                blank: true,
                label: 'Webinars',
                'class': 'webinars',
            });
        }

        // now we're going to emulate the verticals icons by grabbing the first service of each default vertical
        const searchFilters = new SearchFilters();
        $q.when(searchFilters.fetch()).then(function() {

            // if didn't add any custom vertical icons, we prepend the generic search icon
            $scope.exploreIcons.unshift({
                url: '#!/search', label: expertNounPlural, 'class': 'search',
            });

        });

        $scope.gotoConsultationsPage = function() {
            $location.path('/consultations');
        };

        VisitorService.getVisitor().then(function(visitor) {
            if (window.justRegistered) {
                let consumerVideo = Whitelabelling.settings['demoVideo:consumer:registration'];
                if (consumerVideo !== null && (visitor.get('isConsumer') || visitor.get('isWebinarUser'))) {
                    $scope.showDemoVideos.push($sce.trustAsResourceUrl(consumerVideo));
                    $scope.displayDemoVideos = true;
                }
                let expertVideo = Whitelabelling.settings['demoVideo:expert:registration'];
                if (expertVideo !== null && visitor.get('isExpert')) {
                    $scope.showDemoVideos.push($sce.trustAsResourceUrl(expertVideo));
                    $scope.displayDemoVideos = true;
                }
            }

            if (!visitor.get('isUser')) {
                window.location.href = '/login';
                return;
            }
            if (visitor.get('isUser') && visitor.get('user').consumerInOneToOneMatching) {
                window.location.href = '/app#!/syllabus';
                return;
            }
            if (visitor.get('isUser') && visitor.get('user').needsFairReceptionPage) {
                window.location.href = '/app#!/reception';
                return;
            }
            if (!visitor.get('isExpert') && !visitor.get('isConsumer') && !visitor.get('isManagerUser') && !visitor.get('isPartnerUser') && visitor.get('isWebinarUser')) {
                // No roles except Webinar
                $location.path('/resources');
            }
            markSomeDataLoaded();
            $scope.visitor = visitor;
            $scope.selectedPeopleTab = visitor.get('isExpert') ? 2 : 1;

            // load Consultation data
            UserDashboardService.getMeetingsWithFilter('upcoming').then(function(meetings) {
                markSomeDataLoaded();
                $scope.consults = meetings;
            });

            // load Mentor data
            UserDashboardService.getMentors($scope.numberOfPeople).then(function(mentors) {
                markSomeDataLoaded();
                $scope.myMentors = mentors;

                if ($scope.myMentors.length < $scope.numberOfPeople) {
                    // it's important we get a full list of people in case there are duplicates.
                    UserDashboardService.getMatchedMentors($scope.numberOfPeople).then(function(matches) {
                        // add all the matches that aren't mentors
                        $scope.myMentors.add(matches.filter(function(u) {
                            return !$scope.myMentors.contains(u);
                        }));
                    });
                }

                $scope.myMentors.comparator = UserDashboardService.peopleSort;
                $scope.myMentors.sort();
            });

            // load Mentee data
            UserDashboardService.getMentees($scope.numberOfPeople).then(function(mentees) {
                markSomeDataLoaded();
                $scope.myMentees = mentees;

                $scope.myMentees.comparator = UserDashboardService.peopleSort;
                $scope.myMentees.sort();
            });

            // load resources
            if (Whitelabelling.hasWebinars) {
                $http.get('/resources/suggestions?limit=3')
                    .then((res) => {
                        $scope.resources = res.data.data.Resources;
                    });
            }
        });

        function markSomeDataLoaded() {
            $scope.isLoading = false;
            $scope.$emit('controllerLoaded', true);
        }
    },
];
