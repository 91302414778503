import {AbstractProfileCollection} from './abstractProfileCollection';
import {Employment} from 'model/employment';

export const Employments = AbstractProfileCollection.extend({
    _name: 'Employments',
    model: Employment,
    urlRoot: '/employments/get.json',
    comparator: function(val1, val2) {
        if (val1.endDate && val2.endDate) {
            if (val1.endDate > val2.endDate) {
                return -1;
            } else if (val1.endDate < val2.endDate) {
                return 1;
            } else {
                return 0;
            }
        } else if (val1.endDate && !val2.endDate) {
            return 1;
        } else if (!val1.endDate && val2.endDate) {
            return -1;
        } else if (!val1.endDate && !val2.endDate) {
            // Go by Start Date.
            if (val1.startDate > val2.startDate) {
                return -1;
            } else if (val1.startDate < val2.startDate) {
                return 1;
            }
        }
        return 0;
    },
});
