import {whitelabelling as Whitelabelling} from 'whitelabelling';
import {Employment} from 'model/employment';

export function userInputEmploymentHistory() {
    return {
        restrict: 'E',
        scope: {
            data: '=', // a collection of employmentHistory entries
            displayType: '@?',
            hasLinkedin: '=?', // whether the user has synced with LinkedIn
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/registration/directives/userInputs/userInputEmploymentHistory.html',
        controller: [
            '$scope', 'RegistrationService', function($scope, RegistrationService) {
                $scope.now = new Date();
                $scope.whitelabelling = Whitelabelling;
                $scope.showTip = false;
                $scope.employmentDatesOptional = false;
                $scope.displayLinkedinText = !!$scope.hasLinkedin;
                $scope.canAddAnother = true;

                $scope.hideModal = function() {
                    $scope.showTip = false;
                };

                $scope.addEmployment = function() {
                    if ($scope.data) {
                        if ($scope.data.length === 0 || $scope.data.isComplete($scope.employmentDatesOptional)) {
                            $scope.data.add(new Employment({}, {parse: true}));
                        }
                    }
                };

                // they need at least one
                if ($scope.data && $scope.data.length === 0) {
                    $scope.addEmployment();
                }

                RegistrationService.getRegistrationSettings().then(function(d) {
                    $scope.employmentDatesOptional = d.WhitelabelSettings.employmentDatesOptional;

                    if ($scope.data) {
                        // Display different text if you have all the employment information.
                        if ($scope.displayLinkedinText && $scope.data.isComplete($scope.employmentDatesOptional)) {
                            $scope.displayLinkedinText = false;
                        }
                    }
                });

                $scope.$watch(function() {
                    let lastEmp = null;

                    if ($scope.data) {
                        lastEmp = $scope.data.last();
                    }

                    if (lastEmp) {
                        return lastEmp.isComplete($scope.employmentDatesOptional);
                    } else {
                        $scope.addEmployment();
                        return true;
                    }
                }, function (newVal) {
                    $scope.canAddAnother = newVal;
                });
            },
        ],
    };
}

