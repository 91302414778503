export const profileSyncSection = {
    templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/components/profileSyncSection.html',
    transclude: {
        title: 'sectionTitle',
        description: 'sectionDescription',
        body: 'sectionBody',
    },
    bindings: {
        type: '@?',
        isSynced: '<?',
        onApply: '&',
        onCancel: '&',
    },
};
