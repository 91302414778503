/**
 *
 * This filter takes a string and replaces the last space in it with a non-breaking space. It's used to make things
 * like names not look shitty in varying screen sizes. Examples:
 *
 *   "John Smith" => "John&nbsp;Smith"
 *   "Webster Emmanuel Lewis" => "Webster Emmanuel&nbsp;Lewis"
 *   "Daniela Eva Marin Puentes" => "Daniela Eva Marin&nbsp;Puentes"
 *
 * We can't just use <nobr /> on the whole string, because a long-ass name will likely go out of bounds. With this
 * method, you'll never get text that looks like:
 *
 *   Your advisee, John
 *   Smith
 *
 * It'll instead look like:
 *
 *   Your advisee,
 *   John Smith
 *
 */
export function betterNoBreak() {
    return function(input) {
        input = input || '';

        var inputParts = input.split(' ');

        if (inputParts.length < 3) {
            return inputParts.join('&nbsp;');
        } else {
            var first = inputParts.slice(0, inputParts.length - 2);
            var lastTwo = inputParts.slice(inputParts.length - 2, inputParts.length);
            return first.join(' ') + ' ' + lastTwo.join('&nbsp;');
        }
    };
}
