import {whitelabelling as Whitelabelling} from 'whitelabelling';
import * as _ from 'underscore';

export function userInputVerticalServices() {
    return {
        restrict: 'E',
        scope: {
            data: '=',
            opportunities: '@?',
            displayType: '@?',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/registration/directives/userInputs/userInputVerticalServices.html',
        controller: [
            '$scope', 'RegistrationService', function($scope, RegistrationService) {
                $scope.isOpportunities = !!$scope.opportunities;
                $scope.whitelabelling = Whitelabelling;
                var opportunityVertical = 5;

                $scope.obj = {
                    selectValue: null,
                    defaultSelectValue: 0,
                };

                $scope.$watch('obj.selectValue', function(verticalService) {
                    if (verticalService) {
                        $scope.selectType(verticalService);

                    }
                    $scope.obj.selectValue = $scope.obj.defaultSelectValue;
                });

                $scope.clearErrors = function() {
                    $scope.hasErrors = false;
                };
                $scope.checkIfInputIsOk = function() {
                    $scope.hasErrors = $scope.data.length === 0;
                };
                $scope.$on('showErrors', function() {
                    $scope.checkIfInputIsOk();
                });

                RegistrationService.getRegistrationSettings().then(function(d) {
                    $scope.services = d.VerticalServices;
                    $scope.verticals = d.Verticals;

                    // for opportunities, we want to use the registration text
                    if ($scope.isOpportunities) {
                        _.each($scope.services.filter({verticalid: opportunityVertical}), function(s) {
                            s.set('label', s.get('registrationText'));
                        });
                    }

                    // if we already selected consult types, it means we're returning to this step so we should start
                    // off inside the correct vertical
                    if ($scope.data.length > 0) {
                        $scope.data.each(function(vs) {
                            vs.set('isSelectedService', true);
                            $scope.services.get(vs.get('id')).set('isSelectedService', true);
                        });
                    }
                });

                $scope.selectType = function(verticalService) {
                    if (verticalService.get('isSelectedService') === true) {
                        verticalService.set('isSelectedService', false);
                        $scope.data.remove(verticalService);
                    } else {
                        verticalService.set('isSelectedService', true);
                        $scope.data.add(verticalService);
                    }
                    $scope.checkIfInputIsOk();
                };

                $scope.canShowVertical = function(v) {
                    return $scope.isOpportunities ? v.id == opportunityVertical : v.id != opportunityVertical;
                };

            },
        ],
    };
}
