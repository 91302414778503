import {AffinityGroupForSearch} from '../model/affinityGroupForSearch';
import * as _ from 'underscore';
import {JobOpenings} from "../../angular-utilities/collection/jobOpenings";
import {FAIR_TYPE_CAREER, FAIR_TYPE_ENROLLMENT} from "model/userFair"
import {DIMENSION_PROGRAMID, DIMENSION_GROUPID} from 'configurations/googleAnalyticsDimensions';

const getNewPostModel = () => {
    return {
        id: null,
        message: null,
        details: null,
        link: null,
        userid: null,
        linkId: null,
        description: null,
        baseUrl: null,
        image: null,
        title: null,
        questionid: null
    };
}

export class GroupBaseController {
    static $inject = ['$scope', '$rootScope', '$routeParams', '$q', '$window', '$http', 'VisitorService', '$analytics'];

    postModel = getNewPostModel();

    linkModel = {
        linkId: null,
        description: null,
        baseUrl: null,
        image: null,
        title: null,
    };

    btnPostingDisabled = false;
    showQuestionModal = false;
    showAnswerModal = false;

    constructor(
        $scope,
        $rootScope,
        $routeParams,
        $q,
        $window,
        $http,
        VisitorService,
        $analytics,
        limitNumberOfPeoples = null,
        limitNumberOfDiscussions = null,
        limitNumberOfEvents = null,
        limitNumberOfResources = null,
        limitNumberOfJobs = null,
    ) {
        this.scope = $scope;
        this.http = $http;
        this.groupid = $routeParams.groupid;
        this.window = $window;

        VisitorService.getVisitor().then(
            (data) => {
                this.activeUser = data;
            },
        );

        $scope.FAIR_TYPE_CAREER = FAIR_TYPE_CAREER;
        $scope.FAIR_TYPE_ENROLLMENT = FAIR_TYPE_ENROLLMENT;

        $scope.isLoading = true;
        $scope.showMessaging = false;
        $scope.selectedGroup = false;
        $scope.selectedGroupMembers = [];
        $scope.selectedGroupDiscussions = [];
        $scope.selectedGroupEvents = [];
        $scope.selectedGroupResources = [];
        $scope.selectedGroupJobs = [];
        $scope.numberPerPage = 10;
        $scope.descriptionTruncated = true;
        $scope.showMore = false;
        $scope.totalNumberOfMembers = 0;
        $scope.totalNumberOfDiscussions = 0;
        $scope.totalNumberOfEvents = 0;
        $scope.totalNumberOfResources = 0;
        $scope.totalNumberOfJobs = 0;
        $scope.filterRole = 'all';
        $scope.filterUser = '';
        $scope.filteredItems = '';
        $scope.isProgramGroup = false;
        $scope.programGroupids = [];
        $scope.groupid = $routeParams.groupid;
        $scope.isFair = false;
        $scope.programDistinctionLabel = 'Featured';

        $scope.TRUNCATE_LENGTH_GROUP_DESCRIPTION = 180;
        $scope.TRUNCATE_LENGTH_OTHER_TEXT = 150;

        $rootScope.pageClass = 'group-details';

        $scope.toggleDescriptionTruncated = () => {
            $scope.descriptionTruncated = !$scope.descriptionTruncated;
        };

        $scope.truncateString = (string, length) => {
            if (string && (string.length > length)) {
                let newString = string.substring(0, length - 1) + '...';
                if (newString.length !== string.length) {
                    $scope.showMore = true;
                }
                return newString;
            }
            return string;
        };

        let group = new AffinityGroupForSearch();
        group.setGroupId($routeParams.groupid);

        this.discussionsUrl = '/group/' + $routeParams.groupid + '/discussion/overview';
        this.membersUrl = '/group/' + $routeParams.groupid + '/members-profile/get.json';
        this.eventsUrl = '/group/' + $routeParams.groupid + '/events';
        this.resourcesUrl = '/group/' + $routeParams.groupid + '/resources';
        this.jobsUrl = '/group/' + $routeParams.groupid + '/jobs';

        if (limitNumberOfDiscussions) {
            this.discussionsUrl += '?limit=' + limitNumberOfDiscussions;
        }

        if (limitNumberOfEvents) {
            this.eventsUrl += `?limit=${limitNumberOfEvents}&ignoreFinishedEvents=true`;
        }

        if (limitNumberOfResources) {
            this.resourcesUrl += '?limit=' + limitNumberOfResources;
        }

        if (limitNumberOfJobs) {
            this.jobsUrl += '?limit=' + limitNumberOfJobs;
        }

        if (limitNumberOfPeoples) {
            this.membersUrl += '?limit=' + limitNumberOfPeoples;
        }

        let promises = [
            group.fetch(),
            $http.get(this.discussionsUrl),
            $http.get(this.membersUrl),
        ];

        $q.all(promises).then((data) => {
            $scope.selectedGroup = data[0].data.AffinityGroup;
            let discussions = data[1].data.data.Discussions;
            let members = data[2].data.data.Members;

            $scope.selectedGroupDiscussions = discussions.Questions;
            $scope.totalNumberOfDiscussions = discussions.Count;
            $scope.selectedGroupMembers = members.Profiles;
            $scope.totalNumberOfMembers = members.Count;

            if ($scope.selectedGroup.programDistinctionLabel) {
                $scope.programDistinctionLabel = $scope.selectedGroup.programDistinctionLabel;
            }

            $scope.selectedGroup.websiteLabel = $scope.selectedGroup.website;
            if ($scope.selectedGroup.website) {
                const regex = /^(?:https?:\/\/)?((?:[^@\n]+@)?(?:[^.]+\.)?([^:\/\n\?\=]+))/ig;
                const matches = [...$scope.selectedGroup.website.matchAll(regex)][0];
                if (matches && matches[1]) {
                    $scope.selectedGroup.websiteLabel = matches[1];
                }
            }

            $scope.selectedGroupCategory = members.Profiles[0] && members.Profiles[0].groupCategory;

            if ($scope.selectedGroup.programs && $scope.selectedGroup.programs.length > 0) {
                $scope.isProgramGroup = true;
                $scope.programGroupids = [];
                for (const programid of $scope.selectedGroup.programs) {
                    $scope.programGroupids.push(programid);
                }
            }

            $scope.isFair = $scope.selectedGroup.isBooth;
            $scope.fairType = $scope.selectedGroup.fairType;

            if ($scope.isFair) {
                // Tracking
                if ($scope.programGroupids && $scope.programGroupids.length === 1) {
                    const properties = {}
                    properties[DIMENSION_PROGRAMID] = $scope.programGroupids[0];
                    $analytics.setUserProperties(properties);
                }
                const userProperties = {};
                userProperties[DIMENSION_GROUPID] = this.groupid;
                $analytics.setUserProperties(userProperties);
                $analytics.eventTrack('Booth Shown', { 'category': 'Fair Interaction', 'label': this.groupid });

                let boothPromises = [
                    $http.get(this.eventsUrl),
                    $http.get(this.resourcesUrl),
                    $http.get(this.jobsUrl),
                ];
                $q.all(boothPromises).then((data) => {
                    let events = data[0].data.data.Events;
                    let resources = data[1].data.data.Resources;
                    let jobs = data[2].data.data.Jobs;
                    $scope.selectedGroupEvents = events.Data;
                    $scope.totalNumberOfEvents = events.Count;
                    $scope.selectedGroupResources = resources.Data;
                    $scope.totalNumberOfResources = resources.Count;
                    $scope.selectedGroupJobs = new JobOpenings(jobs.Data, {parse: true});
                    $scope.totalNumberOfJobs = jobs.Count;
                }, () => {
                    $scope.error = true;
                    $scope.errorMessage = 'Your platform does not support Affinity Groups.';
                });
            }
            $scope.isLoading = false;
            $scope.$emit('controllerLoaded', true);
        }, ({responseText}) => {
            $scope.error = true;
            responseText = JSON.parse(responseText);
            $scope.errorMessage = 'Your platform does not support Affinity Groups.';
            if (responseText.status === 401 && responseText.data.message === 'restricted') {
                $scope.errorMessage = 'Affinity Group is restricted. Please ask to join or contact your administrator.'
            }
            $scope.isLoading = false;
            $scope.$emit('controllerLoaded', true);
        });
    };

    getMetaData = (url) => {
        if (url && url.length > 0) {
            this.btnPostingDisabled = true;
            this.submitting = true;
            let scraperUrl = 'scraper';
            this.http.post(scraperUrl, {url}).then(
                ({data: {data: {meta}}}) => {
                    this.post = {...this.post, ...meta};
                },
                () => {
                    console.error(`Cannot get metadata from ${url}`);
                },
            ).finally(() => {
                this.btnPostingDisabled = false;
                this.submitting = false;
            });
        }
    };

    clearMeta = () => {
        this.submitting = false;
        this.post = {...this.post, ...this.linkModel};
    };

    openQuestionModal = (post) => {
        this.submitting = false;
        if (typeof (post) !== 'undefined') {
            this.post = _.clone(post);
        } else {
            this.post = this.postModel;
        }
        this.showQuestionModal = true;
    };

    openAnswerModal = (post, question) => {
        this.question = question;
        this.submitting = false;
        if (typeof (post) !== 'undefined') {
            this.post = _.clone(post);
        } else {
            this.post = this.postModel;
        }
        this.showAnswerModal = true;
    };

    submitQuestion = () => {
        this.submitting = true;
        this.btnPostingDisabled = true;
        this.post.userid = this.activeUser.user.id;
        let url = 'group/' + this.scope.groupid + '/discussion';
        this.http.post(url, this.post).then(() => {
            this.postModel = getNewPostModel();
            this.loadDiscussions();
        }, () => {
            console.error(`Cannot submit question. Object: ${this.post}`);
        }).finally(() => {
            this.submitting = false;
            this.hideQuestionModal();
        });
    };

    submitAnswer = () => {
        this.submitting = true;
        this.btnPostingDisabled = true;
        this.post.userid = this.activeUser.user.id;
        this.post.questionid = this.question.id;
        let url = '/group/' + this.groupid + '/discussion/' + this.question.id;
        this.http.post(url, this.post).then(() => {}, () => {
            console.error(`Cannot submit answer: Object: ${this.post}`);
        }).finally(() => {
            this.submitting = false;
            this.hideQuestionModal();
            this.window.location.reload();
        });
    };

    updateQuestion = () => {
        this.submitting = true;
        this.btnPostingDisabled = true;
        this.http.put('group/' + this.scope.groupid + '/discussion/' + this.post.id, this.post).then(() => {
            this.loadDiscussion(this.scope.groupid, this.post.id);
        }, () => {
            console.error(`Cannot submit question. Object: ${this.post}`);
        }).finally(() => {
            this.submitting = false;
            this.hideQuestionModal();
        });
    };

    updateAnswer = () => {
        this.submitting = true;
        this.btnPostingDisabled = true;
        let url = `/group/${this.groupid}/discussion/${this.question.id}/${this.post.id}`;
        this.http.put(url, this.post).then(() => {}, () => {
            console.error(`Cannot update answer: Object: ${this.post}`);
        }).finally(() => {
            this.window.location.reload();
        })
    }

    hideQuestionModal = () => {
        this.submitting = false;
        this.post = this.postModel;
        this.btnPostingDisabled = false;
        this.scope.$broadcast('hideModal');
    };

    hideAnswerModal = () => {
        this.submitting = false;
        this.post = this.postModel;
        this.btnPostingDisabled = false;
        this.scope.$broadcast('hideModal');
    };

    loadDiscussions = () => {
        this.http.get(this.discussionsUrl).then(({data: {data: {Discussions}}}) => {
            this.scope.selectedGroupDiscussions = Discussions.Questions;
            this.scope.totalNumberOfDiscussions = Discussions.Count;
        });
    };

    loadDiscussion = (groupid, discussionid) => {
        this.http.get('group/' + groupid + '/discussion/' + discussionid).then(({data: {data: {Question, Answers}}}) => {
            this.discussion = Question;
            this.answers = Answers;
            this.numberOfAnswers = this.answers.length;
        });
    };

}
