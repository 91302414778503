import {NgModel} from 'model/ngModel';
import {ProfileUser as User} from 'model/profileUser';
import {Meetings} from '../collection/meetings';
import {Files} from '../collection/files';

export const Conversation = NgModel.extend({
    _name: 'Conversation',
    associations: {
        OtherUser: User,
        Meetings: Meetings,
        Files: Files,
    },
    defaults: {
        user1id: null,
        user2id: null,
        sid: undefined,
        unreadMessageCount: undefined,
        dateLastMessageSent: undefined,
    },
    parse: function(data) {
        const dataFormatted = this._super('parse', data);

        if (dataFormatted.dateLastMessageSent) {
            dataFormatted.dateLastMessageSent = this.getDate(dataFormatted.dateLastMessageSent);
        }

        dataFormatted.unreadMessageCount = parseInt(dataFormatted.unreadMessageCount);

        this.__bindManyForAngular(dataFormatted);
        return dataFormatted;
    },
});

