import {whitelabelling as Whitelabelling} from 'whitelabelling';
import * as _ from 'underscore';
import {GeneralAvailabilities} from '../../collection/generalAvailabilities';
import {TimeSwath} from '../../model/timeSwath';

export const STEP_TIME = 'time';
export const STEP_DAY = 'day';
export const EVENT_STEP_CHANGE = 'UserInputAvailabilitySpecific:stepChange';

export function userInputAvailabilitySpecific() {
    return {
        restrict: 'E',
        scope: {
            data: '=',
        },
        replace: true,
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/registration/directives/userInputs/userInputAvailabilitySpecific.html',
        controller: [
            '$scope', function($scope) {
                $scope.whitelabelling = Whitelabelling;

                $scope.loading = false;
                $scope.STEP_TIME = STEP_TIME;
                $scope.STEP_DAY = STEP_DAY;
                $scope.step = STEP_DAY;

                // first we build an exhaustive list of every hour of every day
                $scope.allTimes = new GeneralAvailabilities();

                for (var day = 0; day <= 6; day++) {
                    for (var hour = 0; hour <= 23; hour++) {
                        $scope.allTimes.add(new $scope.allTimes.model({
                            hour: hour,
                            day: day,
                            isSelected: false,
                        }, {parse: true}));
                    }
                }

                $scope.daysOfTheWeek = [];
                // 0 = Monday, 6 = Sunday (this matches userInputAvailability directive)
                var days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
                // we now convert that memoizations object into an array with all days of the week (not just ones with
                // availabilities)
                days.forEach(function(label, index) {
                    var thisDay = {
                        label: label,
                        slots: $scope.allTimes.where({day: index}),
                        selectedTimeSwaths: [],
                    };
                    $scope.daysOfTheWeek.push(thisDay);
                });

                var dataWatch = $scope.$watch('data', function(newVal) {
                    if (newVal) {
                        // if the times are changed external to this directive, we need to reflect those changes
                        $scope.data.on('all', function() {
                            $scope.allTimes.each(function(representativeSwath) {
                                representativeSwath.set('isSelected', !!$scope.data.get(representativeSwath.get('id')));
                            });

                            // refresh all the days of the week
                            $scope.daysOfTheWeek.forEach(function(dayOfTheWeek) {
                                dayOfTheWeek.selectedTimeSwaths = combineSelectedSlots(dayOfTheWeek.slots);
                            });
                        });

                        // when we change the allTimes array, we reflect those changes in our preferred times collection
                        $scope.allTimes.on('change', function(model) {
                            if (model.get('isSelected')) {
                                $scope.data.push(model);
                            } else {
                                $scope.data.remove(model);
                            }
                            var dayOfTheWeek = $scope.daysOfTheWeek[model.get('day')];
                            // refresh our days of the week list
                            dayOfTheWeek.selectedTimeSwaths = combineSelectedSlots(dayOfTheWeek.slots);
                        });

                        // trigger change to load our allTimes settings properly
                        $scope.data.trigger('change');

                        dataWatch();
                    }
                });

                $scope.filterToSelectedDay = function(day) {
                    $scope.selectedDay = day;
                    $scope.step = STEP_TIME;
                    $scope.$emit('UserInputAvailabilitySpecific:stepChange', STEP_TIME);
                };

                $scope.returnToDayPicker = function() {
                    $scope.step = STEP_DAY;
                    $scope.$emit('UserInputAvailabilitySpecific:stepChange', STEP_DAY);
                };

                function combineSelectedSlots(availabilitiesArray) {
                    var retVal = [];
                    var selected = _.where(availabilitiesArray, {isSelected: true});
                    if (selected.length) {
                        if (selected.length === 1) {
                            // we don't have to care what the date is, we only need the date object for the hours
                            retVal.push(new TimeSwath(
                                new Date(2018, 1, (selected[0].day + 1), (selected[0].hour), 0, 0),
                                3600));
                        } else {
                            // we start at 1 because we want to constantly look back
                            // the case for just 1 is handled above
                            var starterBlock;
                            for (var i = 1; i < selected.length; i++) {
                                var block1 = new TimeSwath(
                                    new Date(2018, 1, (selected[i - 1].day + 1), (selected[i - 1].hour), 0, 0),
                                    3600);
                                var block2 = new TimeSwath(
                                    new Date(2018, 1, (selected[i].day + 1), (selected[i].hour), 0, 0),
                                    3600);

                                if (block1.overlaps(block2)) {
                                    // if starterBlock is empty, then we're starting a new block,
                                    if (!starterBlock) {
                                        starterBlock = block1;
                                        retVal.push(starterBlock);
                                    }
                                    starterBlock.combine(block2);
                                } else {
                                    // if we didn't have a starter block yet, then block 1 is an island (all alone
                                    // without neighbors) so we need to make sure it gets added. If it had neighbors,
                                    // it would have already been added in the if block above from a previous iteration
                                    // of this loop
                                    if (!starterBlock) {
                                        retVal.push(block1);
                                    }

                                    // if it's the last one and it didn't overlap with out previous block, then it's
                                    // an island and needs to be added
                                    if (i === selected.length - 1) {
                                        retVal.push(block2);
                                    }

                                    // the consecutive matches have stopped so we set starterBlock back to undefined
                                    starterBlock = undefined;

                                }
                            }
                        }

                    }
                    return retVal;
                }
            },
        ],
    };
}
