import {NgModel} from 'model/ngModel';

export const AffinityGroupForSearch = NgModel.extend({
    _name: 'AffinityGroups',
    REQUEST_TO_JOIN: 'Request to Join',
    PENDING: 'Pending',
    JOIN: 'Join',
    JOINED: 'Joined',
    EXPLORE: 'Explore',
    EXPLORED: 'Interested',

    url: function() {
        return '/group/' + this.groupid + '/get.json';
    },
    setGroupId: function(groupId) {
        this.groupid = groupId;
    },
    parse: function(data) {
        let dataFormatted = this._super('parse', data);

        if (data.isMember) {
            dataFormatted.joinStatus = data.programid ? this.EXPLORED : this.JOINED;
        }

        if (!data.isMember && data.isRestricted) {
            dataFormatted.joinStatus = data.isPending ? this.PENDING : this.REQUEST_TO_JOIN;
        } else {
            dataFormatted.joinStatus = data.programid ? this.EXPLORE : this.JOIN;
        }

        return dataFormatted;
    },
});
