export const DeviceLocation = [
    '$http', '$q',
    function($http, $q) {
        var promise = null;
        var deviceData = null;

        return {
            get: function() {
                // if a request is already pending, just return that
                if (promise) {
                    return promise;
                }

                var deferred = $q.defer();

                if (!deviceData) {
                    // if we haven't cached it yet, we query for it
                    promise = deferred.promise;

                    // we use this service (because it's easy and supports https)
                    // NOTE: must use https!
                    $http.get('https://api.teletext.io/api/v1/geo-ip').then(function(resp) {
                        promise = null;
                        deferred.resolve.call(this, {
                            country: resp.data.name,
                        });
                    }, deferred.reject);
                } else {
                    // return the cached data
                    deferred.resolve(deviceData);
                }

                return deferred.promise;
            },
        };
    },
];
