import {whitelabelling as Whitelabelling} from 'whitelabelling';
import * as AlgorithmCriteria from 'algorithmCriteria';

const criteriaLabels = {
    get [AlgorithmCriteria.CRITERIA_EXPERIENCED_EXPERT]() {
        return 'Experienced ' + Whitelabelling.expertNoun;
    }
};
criteriaLabels[AlgorithmCriteria.CRITERIA_CURRENT_LOCATION] = 'Current location';
criteriaLabels[AlgorithmCriteria.CRITERIA_GROUPS] = 'Groups';
criteriaLabels[AlgorithmCriteria.CRITERIA_DESIRED_WORK_LOCATION] = 'Desired work location';
criteriaLabels[AlgorithmCriteria.CRITERIA_DESIRED_EMPLOYER] = 'Desired employer';
criteriaLabels[AlgorithmCriteria.CRITERIA_DESIRED_ROLE] = 'Desired role';
criteriaLabels[AlgorithmCriteria.CRITERIA_DESIRED_INDUSTRY] = 'Desired industry';
criteriaLabels[AlgorithmCriteria.CRITERIA_DEGREE] = 'Degree';
criteriaLabels[AlgorithmCriteria.CRITERIA_CONCENTRATION] = 'Field of Study';
criteriaLabels[AlgorithmCriteria.CRITERIA_YEARS_SENIOR] = 'Seniority';
criteriaLabels[AlgorithmCriteria.CRITERIA_RESPONDS_QUICKLY] = 'Responsiveness';
criteriaLabels[AlgorithmCriteria.CRITERIA_HAS_PICTURE] = 'Has photo';
criteriaLabels[AlgorithmCriteria.CRITERIA_POSITIVELY_REVIEWED] = 'Average rating';
criteriaLabels[AlgorithmCriteria.CRITERIA_JOINED_RECENTLY] = 'Joined recently';
criteriaLabels[AlgorithmCriteria.CRITERIA_RECENTLY_LOGGED_IN] = 'Logged in recently';
criteriaLabels[AlgorithmCriteria.CRITERIA_NO_RECENT_BOOKINGS] = 'No recent bookings';
criteriaLabels[AlgorithmCriteria.CRITERIA_HIGHLY_AVAILABLE] = 'Availability';
criteriaLabels[AlgorithmCriteria.CRITERIA_LANGUAGE] = 'Language';
criteriaLabels[AlgorithmCriteria.CRITERIA_GENDER] = 'Gender';
criteriaLabels[AlgorithmCriteria.CRITERIA_ETHNICITY] = 'Minority Status';

export const AlgorithmHelper = {
    cachedCriteriaLabels: null,
    criteriaLabels: function() {
        if (AlgorithmHelper.cachedCriteriaLabels) {
            return AlgorithmHelper.cachedCriteriaLabels;
        }

        const labels = criteriaLabels;
        if (Whitelabelling.settings['hideField:race']) {
            delete labels[AlgorithmCriteria.CRITERIA_ETHNICITY];
        }
        if (Whitelabelling.settings['hideField:gender']) {
            delete labels[AlgorithmCriteria.CRITERIA_GENDER];
        }

        if (Whitelabelling.id == 426) {
            // @TODO Remove per FUTURE_MAC_HACK_REMOVAL
            labels[AlgorithmCriteria.CRITERIA_CURRENT_LOCATION] = 'Hometown';
            delete labels[AlgorithmCriteria.CRITERIA_DESIRED_WORK_LOCATION];
            delete labels[AlgorithmCriteria.CRITERIA_DESIRED_EMPLOYER];
            delete labels[AlgorithmCriteria.CRITERIA_DESIRED_ROLE];
            delete labels[AlgorithmCriteria.CRITERIA_DESIRED_INDUSTRY];
        }

        AlgorithmHelper.cachedCriteriaLabels = labels;
        return AlgorithmHelper.cachedCriteriaLabels;
    },
    addLabel: (key, value) => {
        AlgorithmHelper.cachedCriteriaLabels[key] = value;
    },
    injectCustomInputLabels: (customInputs) => {
        for (const customInput of customInputs) {
            AlgorithmHelper.addLabel('customInput_' + customInput.type + '_' + customInput.id, customInput.label);
        }
    },
    weightInjectCustomInputLabels: (algorithmWeightCollection, customInputs) => {
        // Walk across all the criteria missing labels, to map it over from CustomInputs.
        for (const model of algorithmWeightCollection.models) {
            if (model.criteriaLabel) {
                return;
            }

            const criteria = model.get('criteria');
            if (criteria.indexOf(AlgorithmCriteria.CRITERIA_CUSTOM_INPUT_PREFIX) === 0) {
                const split = criteria.split('_');
                const customInputType = split[1];
                const customInputid = parseInt(split[2]);
                const customInput = customInputs.find((customInput) => {
                    return customInput.type === customInputType && customInput.id === customInputid
                });
                if (customInput) {
                    model.set('criteriaFormatted', customInput.label);
                    AlgorithmHelper.addLabel('customInput_' + customInputType + '_' + customInputid, customInput.label);
                }
            }
        }
    },
};
