import {NgModel} from './ngModel';

export const Location = NgModel.extend({
    _name: 'Location',
    // these defaults come from Google's API
    defaults: {
        city: null,
        country: null,
        label: null,
        lat: null,
        lng: null,
        placeid: null,
        state: null,
    },
});
