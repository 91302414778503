export const Payments = [
    'PaypalForm', 'Meeting', '$http', '$q',
    function(PaypalForm, Meeting, $http, $q) {
        return {
            getPaypalForm: function() {
                // this is a helper function for one day when we have multiple payment options
                return PaypalForm.get();
            },
            getConsumerCancelRefund: function() {
                var deferred = $q.defer();

                Meeting.getByRouteParams().then(function(meeting) {
                    $http.get('/consultations/' + meeting.get('id') + '/policy/consumerCancelRefund.json', {}).
                        then(function(resp) {
                            deferred.resolve(resp.data.data);
                        }, deferred.reject);
                });

                return deferred.promise;
            },
            getExpertCancelPayout: function() {
                var deferred = $q.defer();

                Meeting.getByRouteParams().then(function(meeting) {
                    $http.get('/consultations/' + meeting.get('id') + '/policy/expertCancelPayout.json', {}).
                        then(function(resp) {
                            deferred.resolve(resp.data.data);
                        }, deferred.reject);
                });

                return deferred.promise;
            },
        };
    },
];
