import {NgModel} from './ngModel';
import * as _ from 'underscore';
import * as moment from 'moment-timezone';
import {getTimeUnits} from '../configurations/utilities';
import * as AlgorithmCriteria from 'algorithmCriteria';
import {whitelabelling as Whitelabelling} from 'whitelabelling';

export const AlgorithmUser = NgModel.extend({
    _name: 'AlgorithmUser',
    defaults: {
        selected: false,
    },
    urlRoot: '/control/partner/algorithm/users/[id]/get.json',
    parse: function(data) {
        const whitelabelling = Whitelabelling;
        var dataFormatted = this._super('parse', data);

        dataFormatted.groups = splitGroupedString(dataFormatted.groups);
        dataFormatted.desiredLocations = splitGroupedString(dataFormatted.desiredLocations);
        dataFormatted.desiredEmployers = splitGroupedString(dataFormatted.desiredEmployers);
        dataFormatted.desiredFunctions = splitGroupedString(dataFormatted.desiredFunctions);
        dataFormatted.desiredIndustries = splitGroupedString(dataFormatted.desiredIndustries);
        dataFormatted.locations = splitGroupedString(dataFormatted.locations);
        dataFormatted.employers = splitGroupedString(dataFormatted.employers);
        dataFormatted.industries = splitGroupedString(dataFormatted.industries);
        dataFormatted.functions = splitGroupedString(dataFormatted.functions);
        dataFormatted.degrees = splitGroupedString(dataFormatted.degrees);
        dataFormatted.concentrations = splitGroupedString(dataFormatted.concentrations);
        dataFormatted.languages = splitGroupedString(dataFormatted.languages);
        dataFormatted.verticalServiceids = splitGroupedString(dataFormatted.verticalServiceids);
        dataFormatted.verticalServiceids = _.map(dataFormatted.verticalServiceids, function(s) {
            return parseInt(s);
        });

        dataFormatted.positivelyReviewed = parseInt(dataFormatted.positivelyReviewed * 100);
        dataFormatted.monthlyAvailable = parseInt(data.monthlyAvailable);
        dataFormatted.noOfConsultations = parseInt(data.noOfConsultations);

        if (data.name) {
            dataFormatted.fullName = data.name;
        } else {
            dataFormatted.fullName = data.firstName + ' ' + data.lastName;
        }

        if (data.recentBookingDate) {
            dataFormatted.recentBookingDate = this.getDate(data.recentBookingDate);
            dataFormatted.recentBookingDateFormatted = moment.tz(dataFormatted.recentBookingDate, 'UTC').
                format('MMMM D, YYY');
        }

        if (data.graduationDate) {
            dataFormatted.graduationDate = this.getDate(data.graduationDate);
            dataFormatted.graduationDateFormatted = moment.tz(dataFormatted.graduationDate, 'UTC').format('\'YY');
        }

        if (data.expertGraduationDate) {
            dataFormatted.expertGraduationDate = this.getDate(data.expertGraduationDate);
            dataFormatted.expertGraduationDateFormatted = moment.tz(dataFormatted.expertGraduationDate, 'UTC').
                format('\'YY');

            if (!dataFormatted.graduationDate) {
                dataFormatted.graduationDate = dataFormatted.expertGraduationDate;
                dataFormatted.graduationDateFormatted = dataFormatted.expertGraduationDateFormatted;
            }
        }

        if (data.consumerGraduationDate) {
            dataFormatted.consumerGraduationDate = this.getDate(data.consumerGraduationDate);
            dataFormatted.consumerGraduationDateFormatted = moment.tz(dataFormatted.consumerGraduationDate, 'UTC').
                format('\'YY');

            // if we don't set it from the expert data, we overwrite it with this value
            if (!dataFormatted.graduationDate) {
                dataFormatted.graduationDate = dataFormatted.consumerGraduationDate;
                dataFormatted.graduationDateFormatted = dataFormatted.consumerGraduationDateFormatted;
            }
        }

        if (data.dateJoined) {
            dataFormatted.dateJoined = this.getDate(data.dateJoined);
            dataFormatted.dateJoinedFormatted = moment.tz(dataFormatted.dateJoined, 'UTC').format('\'YY');
        }

        if (data.lastOnline) {
            dataFormatted.lastOnline = this.getDate(data.lastOnline);
            dataFormatted.lastOnlineFormatted = moment.tz(dataFormatted.lastOnline, 'UTC').format('\'YY');
        }

        /** @TODO Remove per FUTURE_MAC_HACK_REMOVAL **/
        if (!dataFormatted.title && whitelabelling.id !== 426) {
            var now = new Date();
            dataFormatted.title = dataFormatted.graduationDate > now ? 'Student' : 'Alum';
        }

        if (dataFormatted.responseTime) {
            dataFormatted.responseTimeFormatted = getTimeUnits(dataFormatted.responseTime);
        }

        if (data.careerData) {
            var that = this;
            dataFormatted.careerData = [];
            _.each(data.careerData, function(d) {
                if (d.startDate) {
                    d.startDate = that.getDate(d.startDate);
                    d.endDate = d.endDate ? that.getDate(d.endDate) : new Date();
                } else {
                    delete d.startDate;
                    delete d.endDate;
                }

                dataFormatted.careerData.push(d);
            });
        }

        this.__bindManyForAngular(dataFormatted);
        return dataFormatted;
    },
    /**
     * @param {string} criteriaKey  -- the match criteria the label relates to
     * @param {string[]} labels   -- the labels to match / group by
     * @return {*}
     */
    getCareerData: function(criteriaKey, labels) {
        var careerData = this.get('careerData');

        // we need to find all the career data that has the passed label(s)
        var careerDataKey = getUserKeyFromCriteriaKey(criteriaKey, true);
        var matches = _.filter(careerData, function(d) {
            return _.contains(labels, d[careerDataKey]);
        });

        var retVal = null;
        // if there's at least 1 (should always be at least 1)
        if (matches.length) {
            retVal = _.clone(matches[0]);
            if (matches.length > 1) {
                // we need to group the years into a single response
                _.each(matches, function(m) {
                    if (m.startDate) {
                        retVal.duration = retVal.duration || 0;
                        // if the new startDate is less than our current, we take it
                        if (!retVal.startDate || m.startDate.getTime() < retVal.startDate.getTime()) {
                            retVal.startDate = m.startDate;
                        }

                        // if there's a startDate, there must be an endDate
                        // now do the same for endDate
                        if (!retVal.endDate || m.endDate.getTime() > retVal.endDate.getTime()) {
                            retVal.endDate = m.endDate;
                        }

                        // and this period's duration
                        retVal.duration += (m.endDate.getTime() - retVal.startDate.getTime()) / 1000;
                    }
                });

                // the last thing we do is make sure our combined duration does not exceed the presented dates
                // this scenario *could* occur if someone worked two jobs at once in the same industry, function,
                // employer as their tenure would be counted twice
                if (retVal.startDate) {
                    retVal.duration = Math.min(retVal.duration,
                        (retVal.endDate.getTime() - retVal.startDate.getTime()) / 1000);
                }

                return retVal;
            } else {
                retVal.duration = retVal.startDate
                    ? (retVal.endDate.getTime() - retVal.startDate.getTime()) / 1000
                    : undefined;
            }
        }
        return retVal;
    },
}, {
    /**
     *
     * This translates the AlgorithmWeighted criteria key into the AlgorithmUser's corresponding data attribute
     * @param key
     * @param isExpert
     * @return {*}
     */
    getUserKeyFromCriteriaKey: getUserKeyFromCriteriaKey,
});

/**
 * @param {string} string
 */
function splitGroupedString(string) {
    if (Array.isArray(string)) {
        return string;
    } else {
        return string ? string.split(';or;') : [];
    }
}

function getUserKeyFromCriteriaKey(key, isExpert) {
    var userKey;
    switch (key) {
        case AlgorithmCriteria.CRITERIA_CURRENT_LOCATION:
            userKey = 'currentLocation';
            break;
        case AlgorithmCriteria.CRITERIA_GROUPS:
            userKey = 'groups';
            break;
        case AlgorithmCriteria.CRITERIA_DESIRED_WORK_LOCATION :
            userKey = isExpert ? 'locations' : 'desiredLocations';
            break;
        case AlgorithmCriteria.CRITERIA_DESIRED_EMPLOYER :
            userKey = isExpert ? 'employers' : 'desiredEmployers';
            break;
        case AlgorithmCriteria.CRITERIA_DESIRED_ROLE :
            userKey = isExpert ? 'functions' : 'desiredFunctions';
            break;
        case AlgorithmCriteria.CRITERIA_DESIRED_INDUSTRY :
            userKey = isExpert ? 'industries' : 'desiredIndustries';
            break;
        case AlgorithmCriteria.CRITERIA_YEARS_SENIOR :
            userKey = 'graduationDate';
            break;
        case AlgorithmCriteria.CRITERIA_RESPONDS_QUICKLY :
            userKey = 'responseTime';
            break;
        case AlgorithmCriteria.CRITERIA_HAS_PICTURE :
            userKey = 'hasImage';
            break;
        case AlgorithmCriteria.CRITERIA_EXPERIENCED_EXPERT :
            userKey = 'noOfConsultations';
            break;
        case AlgorithmCriteria.CRITERIA_POSITIVELY_REVIEWED :
            userKey = 'positivelyReviewed';
            break;
        case AlgorithmCriteria.CRITERIA_JOINED_RECENTLY :
            userKey = 'dateJoined';
            break;
        case AlgorithmCriteria.CRITERIA_RECENTLY_LOGGED_IN :
            userKey = 'lastOnline';
            break;
        case AlgorithmCriteria.CRITERIA_NO_RECENT_BOOKINGS :
            userKey = 'recentBookingDate';
            break;
        case AlgorithmCriteria.CRITERIA_HIGHLY_AVAILABLE :
            userKey = 'monthlyAvailable';
            break;
        case AlgorithmCriteria.CRITERIA_DEGREE :
            userKey = 'degrees';
            break;
        case AlgorithmCriteria.CRITERIA_CONCENTRATION :
            userKey = 'concentrations';
            break;
        case AlgorithmCriteria.CRITERIA_LANGUAGE :
            userKey = 'languages';
            break;
        case AlgorithmCriteria.CRITERIA_ETHNICITY :
            userKey = 'race';
            break;
        case AlgorithmCriteria.CRITERIA_GENDER :
            userKey = 'gender';
            break;
        default:
            if (key.indexOf(AlgorithmCriteria.CRITERIA_CUSTOM_INPUT_PREFIX) === 0) {
                userKey = key;
                break;
            }

            throw 'Invalid key ' + key;
    }

    return userKey;
}
