import {NgModel} from 'model/ngModel';
import * as _ from 'underscore';

export const MeetingState = NgModel.extend({
    _name: 'MeetingState',
    idAttribute: 'meetingid',
    urlRoot: '', // we never actually fetch the state, this object is included in the Meeting fetch response
    expertHasBeenNotified: function() {
        var _STATE_PAYMENT = 3;
        var _STATE_EMAIL_VERIFICATION = 0;
        var _STATE_NEW = 25;

        var states = [_STATE_PAYMENT, _STATE_NEW, _STATE_EMAIL_VERIFICATION];

        // if the state is not in the invalid states array
        return (_.indexOf(states, this.get('status')) == -1);
    },
});
