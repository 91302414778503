export const nl2br = [
    '$sce', function($sce) {
        return function(msg, is_xhtml) {
            is_xhtml = angular.isUndefined(is_xhtml) ? true : is_xhtml;
            var breakTag = (is_xhtml) ? '<br />' : '<br>';
            msg = ('' + msg).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
            return $sce.trustAsHtml(msg);
        };
    },
];
