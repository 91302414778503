export function userInputPlatformAffiliationRelative() {
    return {
        restrict: 'E',
        scope: {
            data: '=',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/registration/directives/userInputs/userInputPlatformAffiliationRelative.html',
        controller: [
            '$scope', function($scope) {
                $scope.options = {
                    'parent': 'Parent',
                    'sibling': 'Sibling',
                    'grandparent': 'Grandparent',
                    'uncle': 'Uncle',
                    'aunt': 'Aunt',
                    'son': 'Son',
                    'daughter': 'Daughter',
                    'grandson': 'Grandson',
                    'granddaughter': 'Granddaughter',
                    'cousin': 'Cousin',
                    'niece': 'Niece',
                    'nephew': 'Nephew',
                };
            },
        ],
    };
}
