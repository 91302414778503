import {NgModel} from './ngModel';
import * as _ from 'underscore';

export const ConsumerFeedback = NgModel.extend({
    _name: 'ConsumerFeedback',
    urlRoot: '/consultations/[meetingid]/consumerFeedback.json',
    idAttribute: 'meetingid',
    defaults: {
        'consultationFeedbackText': null,
        'starRatingSkills': null,
        'starRatingMotivation': null,
        'starRatingCulture': null,
        'starRatingOverall': null,
        'assessmentText': null,
        'dateCreated': null,
        'isNotApplicable': false,
        'platformRating': null,
        'platformComment': null,
        'meetingid': null,
    },
    parse: function(data) {
        var dataFormatted = this._super('parse', data);

        if (data.dateCreated) {
            dataFormatted.dateCreated = new Date(data.dateCreated.date.replace(' ', 'T') + 'Z');
        }

        return dataFormatted;
    },
    // this function ensures there's a truthy value for every property in defaults
    isComplete: function(requiredOnly) {
        return !this.missingFields(requiredOnly).length;
    },
    // this function returns a list of missing fields
    missingFields: function(requiredOnly) {
        var missingFields = [];
        var that = this;

        // we always skip the meetingid, dateCreated, and isNotApplicable since they can/should be falsey
        var skipFields = [
            'meetingid',
            'dateCreated',
            'isNotApplicable',
        ];

        // if we only care about the required fields
        if (requiredOnly) {
            skipFields.push('platformRating');
            skipFields.push('platformComment');
        }

        _.each(this.defaults, function(v, prop) {
            if (!!~skipFields.indexOf(prop)) {
                return;
            }
            if (!that.get(prop)) {
                missingFields.push(prop);
            }
        });
        return missingFields;
    },
});
