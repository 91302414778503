/**
 * This directive creates the star rating interface
 * use like:
 * <textarea text-limit ng-model="..." length="1000"></textarea>
 */
export function textLimit() {
    return {
        restrict: 'A',
        replace: true,
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/textLimit.html',
        scope: {
            data: '=ngModel',
            length: '=?',
            min: '=?',
            ngDisabled: '=?',
        },
        controller: [
            '$scope', function($scope) {
                $scope.length = $scope.length || 0;
                $scope.min = $scope.min || 0;

                $scope.inputChange = function() {
                    if ($scope.data) {
                        if ($scope.length) {
                            $scope.data = $scope.data.substr(0, $scope.length);
                        }
                        $scope.amount = $scope.data.length;
                    } else {
                        $scope.amount = 0;
                    }
                };

                $scope.inputChange();

                $scope.$watch('data', $scope.inputChange);
            },
        ],
        link: function(scope, elem, attrs) {
            if (attrs.hasOwnProperty('msdElastic')) {
                scope.useElastic = true;
            }
        },
    };
}

