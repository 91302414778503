import * as moment from 'moment-timezone';

export const formatMoment = [
    'TimezonesService',
    function(TimezonesService) {
        return function(obj, formatString) {
            return moment(obj).tz(TimezonesService.getDeviceTimezone().locationCode).format(formatString);
        };
    },
];
