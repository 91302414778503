import * as _ from 'underscore';
import {guid} from 'configurations/utilities';

export function searchFilter() {
    return {
        restrict: 'E',
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/directives/search/searchFilter.html',
        replace: true,
        scope: {
            filter: '=',
        },
        controller: [
            '$scope', function($scope) {
                $scope.hasSubFilters = $scope.filter.get('hasSubFilters');

                $scope.UNIQUE_STRING = guid();
                $scope.count = 0;
                $scope.containerOpened = $scope.filter.get('startOpen') || !!$scope.filter.get('noSection');
                $scope.showAddMoreInput = false;

                if ($scope.hasSubFilters) {
                    // whenever any of the sub options change their value, we update the root items value
                    $scope.filter.get('options').on('change:value', function() {
                        var value = [];
                        $scope.filter.get('options').each(function(f) {
                            if (f.get('value')) {
                                value = value.concat(f.get('value'));
                            }
                        });
                        $scope.filter.set('value', value);
                    });
                } else {
                    $scope.customInput = {
                        text: undefined,
                        obj: undefined,
                        location: null,
                        pastLocations: false,
                        range: {
                            min: 0,
                            max: 400,
                        },
                    };

                    $scope.refreshOptionValues = function() {
                        $scope.filter.refreshValue();
                    };

                    $scope.handleCustomInputChanged = function() {
                        if ($scope.filter.get('type') === 'range') {
                            $scope.filter.set('value', $scope.customInput.range);
                            // we need to trigger change
                            $scope.filter.trigger('change:value');
                        } else {
                            $scope.filter.set('value', $scope.customInput.text);
                        }
                    };

                    $scope.sliderOptions = {
                        step: 5,
                        onChange: $scope.handleCustomInputChanged,
                    };

                    if ($scope.filter.key === 'price') {
                        $scope.sliderOptions.translate = function(value) {
                            return '$' + value;
                        };
                    }

                    $scope.$watch('customInput.obj', function(newVal) {
                        if (!newVal) {
                            return;
                        }
                        // We need to convert the response from the prefixComplete into a search option (value and
                        // label)
                        $scope.filter.addOption({
                            value: newVal.get('id'),
                            label: newVal.get('label'),
                        });
                    });

                    if ($scope.filter.type === 'location') {
                        const locationValue = $scope.filter.get('value');
                        $scope.customInput.location = locationValue;
                        $scope.customInput.pastLocations = locationValue && locationValue.pastLocations;

                        $scope.updateLocation = function() {
                            var value = $scope.filter.get('value');
                            if (value) {
                                value.pastLocations = $scope.customInput.pastLocations;
                                $scope.filter.set('value', value);
                                $scope.filter.trigger('change:value');
                            }
                        };

                        // on changes to the location input
                        $scope.$watch('customInput.location', function(newVal, oldVal) {
                            if (!oldVal && !newVal || angular.equals(newVal, oldVal)) {
                                // do nothing -- this stops an unneeded double search on page load
                                return;
                            }
                            // if they didn't pick a location, do nothing
                            if (!newVal || _.isEmpty(newVal)) {
                                // if they had the value set, we remove it
                                if ($scope.filter.get('value')) {
                                    $scope.filter.set('value', undefined);
                                }
                                return;
                            }

                            // We need to convert the response from the prefixComplete into a search option (value and
                            // label)
                            var optionObj = {
                                pastLocations: $scope.customInput.pastLocations,
                            };

                            if (newVal.get) {
                                optionObj.label = newVal.get('label');

                                if (newVal.get('city') && newVal.get('lat') && newVal.get('lng')) {
                                    optionObj.value = 'point||' + newVal.get('lat') + ',' + newVal.get('lng');
                                } else if (newVal.get('state')) {
                                    optionObj.value = 'state||' + newVal.get('state');
                                } else if (newVal.get('country')) {
                                    optionObj.value = 'country||' + newVal.get('country');
                                } else {
                                    optionObj.value = newVal.get('label');
                                }
                            } else {
                                optionObj.label = newVal.label || '';
                            }

                            $scope.filter.set('value', optionObj);
                        });
                    }
                }

                $scope.$watch('filter.value', function(newVal, oldVal) {
                    if ($scope.filter.type === 'range' && !newVal) {
                        $scope.customInput.range.min = 0;
                        $scope.customInput.range.max = 400;
                    }
                    if ($scope.filter.type === 'location') {
                        $scope.customInput.location = newVal;
                    }
                    $scope.count = Array.isArray(newVal) ? newVal.length : (!!newVal ? 1 : 0);
                });

                $scope.clearInput = function() {
                    $scope.customInput.text = '';
                    $scope.handleCustomInputChanged();
                };

            },
        ],
    };
}
