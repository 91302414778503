import {CustomInput} from 'model/customInput';

export const TYPE_RANGE = 'range';

export class CustomInputRange extends CustomInput {
    type = TYPE_RANGE;

    minValue = 0;
    maxValue = Number.MAX_SAFE_INTEGER
    step = 0;
    allowOther = false;
    otherValue = null;

    constructor(data) {
        super(data);
        Object.assign(this, data);
    }

    isValid(input) {
        if (!super.isValid(input)) {
            return false;
        } else if (this.allowOther && this.otherValue && !input) {
            return true;
        } else {
            if (this.minValue !== null && this.minValue > input.lowerValue) {
                this.error = `First value should be greater than ${this.minValue}`
                return false;
            }
            if (this.maxValue !== null && this.maxValue < input.upperValue) {
                this.error = `Last value should be lower than ${this.maxValue}`
                return false;
            }
            if ((input.upperValue !== null && input.lowerValue === null) || (input.lowerValue !== null && input.upperValue === null)) {
                this.error = null;
                return false;
            }
        }

        this.error = null;
        return true;
    }
}
