import {NgModel} from 'model/ngModel';
import moment from "moment"
import {Resource as ResourceModel} from '../../resource';
import {Event as EventModel} from '../../event';
import {Job as JobModel} from './job';

export const Booth = NgModel.extend({
    _name: 'Booth',
    defaultUrl: '/control/partner/booths/[id]',
    urlRoot: '/control/partner/booths/[id]',
    defaults: {
        label: "",
        description: "",
        resources: [],
        events: [],
        jobs: [],
        users: [],
        imageUrl: null,
        uploadedImageData: null,
        imageid: null,
        hasDistinction: false,
        website: null,
        email: null,
        phone: null,
        address: null,
        hiringInternship: false,
        hiringFulltime: false,
        hiringInternational: false,
        acceptUploads: false,
        hasForum: true,
        hasQuestionsBeenSeeded: false,
        isPubliclyVisible: true,
        seedQuestionsType: '0',
        customQuestions: [],
        extraInputs: [],
        statistics: {
            boothVisits: 0,
            eventClicks: 0,
            resourceClicks: 0,
            resumesUploads: 0
        }
    },
    fetch: function(options) {
        if (options && options.overview) {
            this.urlRoot = `${this.urlRoot}-overview`;
        }
        let fetch = this._super('fetch', options);
        this.urlRoot = this.defaultUrl;

        return fetch;
    },
    parse: function(data) {
        let dataFormatted = this._super('parse', data);

        dataFormatted.dateCreatedFormatted = moment(data.dateCreated).format("MMM Do YYYY");
        dataFormatted.hasDistinction = this.asBoolean(dataFormatted.hasDistinction);

        dataFormatted.hasQuestionsBeenSeeded = this.asBoolean(dataFormatted.hasQuestionsBeenSeeded);

        dataFormatted.resources = dataFormatted.resources.map(el => new ResourceModel(el, {parse: true}));
        dataFormatted.events = dataFormatted.events.map(el => new EventModel(el, {parse: true}));
        dataFormatted.jobs = dataFormatted.jobs.map(el => new JobModel(el, {parse: true}));

        return dataFormatted;
    },
    transform: function() {
        let website = this.get('website');

        if (website && (website.indexOf('http://') === - 1 && website.indexOf('https://') === -1)) {
            this.set('website', "https://" + website);
        }
    },
    getDownloadHeaders: function() {
        return [
            'Created',
            'Name',
            '# Members',
            '# Resources',
            '# Events',
            '# Resumes',
            'Badge',
        ];
    },
    getDownloadData: function() {
        return [
            this.get('dateCreatedFormatted'),
            this.get('label'),
            this.get('userCount'),
            this.get('resourceCount'),
            this.get('eventCount'),
            this.get('resumeCount'),
            this.get('hasDistinction'),
        ];
    },
});
