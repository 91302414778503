export const MeetingState_Both_Meet = [
    '$scope', '$timeout', 'MeetingConnectionService', 'Meeting', 'VoipConsultationService',
    function($scope, $timeout, MeetingConnectionService, Meeting, VoipConsultationService) {
        $scope.template = Evisors.config.cdnRootWhitelabel + 'asset/partials/consultations/meetingStates/both/meet.html';

        // the date table likes the date data as an array, so we construct one with just 1 element
        $scope.selectedDates = [$scope.meeting.get('selectedTimeSwath')];

        $scope.durationInSeconds = $scope.meeting.get('durationInSeconds');
        $scope.consultationType = $scope.meeting.get('consultationType');

        $scope.canEndCall = false;

        var oneSecond = 1000;
        var oneMinute = 60 * oneSecond;
        var loop = null;

        $scope.elapsedCallString = '00:00';
        $scope.elapsedCallStringColor = 'inherit';

        $scope.endCall = function() {
            if ($scope.canEndCall) {
                Meeting.endConsultation($scope.meeting).then(function() {
                    $timeout.cancel(loop);
                });
            }
        };

        $scope.endCallMarkComplete = function() {
            if ($scope.canEndCall) {
                Meeting.endConsultationMarkComplete($scope.meeting).then(function() {
                    $timeout.cancel(loop);
                });
            }
        };

        MeetingConnectionService.addScope($scope);
        $scope.connectedViaVoIP = (VoipConsultationService.status ===
            VoipConsultationService.statusEnum.STATUS_CONNECTED);
        $scope.$on('meeting-poll', function() {
            $scope.connectedViaVoIP = VoipConsultationService.status ===
                VoipConsultationService.statusEnum.STATUS_CONNECTED;

            // If user was connected with voip and then dialed in - we have to disconnect voip
            if ($scope.connectedViaVoIP && MeetingConnectionService.isCurrentUserConnectedAudioPhone()) {
                VoipConsultationService.disconnect();
            }
        });

        var update = function() {
            var now = new Date();
            var endDate = $scope.meeting.get('selectedTimeSwath').getEndDate();
            var startDate = $scope.meeting.get('selectedTimeSwath').getStartDate();
            var endsIn = endDate.getTime() - now.getTime();
            var elapsedTime = parseInt((now.getTime() - startDate.getTime()) / oneSecond);

            // elapsedTime cannot be negative
            elapsedTime = Math.max(elapsedTime, 0);

            if (endsIn <= 0) {
                // if the meeting time is over we set the color to red
                $scope.elapsedCallClass = 'alert';

                // and we ALWAYS show the endCallButton
                $scope.callIsRunningLong = true;
            } else if (endsIn <= (10 * oneMinute)) {
                // if it's 10 minutes till ending we set the color to yellow
                $scope.elapsedCallClass = 'warning';
            }

            // we get the dial status, note we call "get" instead of "update" so it only really requests every 5 seconds
            MeetingConnectionService.get().then(function() {
                // do nothing - we handle the data changes with $watch events below
            }, function() {
                // if we error, it's because this state is not Meet anymore, so we need to update our meeting reference
                // and stop dial status looping
                Meeting.getByRouteParams(true).then(function(meeting) {
                    if (meeting.get('MeetingState').get('status') !== 5) {
                        $timeout.cancel(loop);
                    }
                }, (error) => {
                    if (error === 'noId') {
                        $timeout.cancel(loop);
                    }
                });
            });

            $scope.connectedViaVoIP = VoipConsultationService.status ===
                VoipConsultationService.statusEnum.STATUS_CONNECTED;

            var elapsedMinutes = parseInt(elapsedTime / 60);
            var elapsedSeconds = elapsedTime % 60;
            $scope.elapsedCallString = (elapsedMinutes < 10 ? '0' : '') + elapsedMinutes + ':' +
                (elapsedSeconds < 10 ? '0' : '') + elapsedSeconds;

            // if 10 minutes have passed and the other user never connected, we let them mark no show
            if (elapsedMinutes >= 10) {
                $scope.canEndCall = true;
            }

            loop = $timeout(update, oneSecond);
        };
        update();

        var watchFunction = $scope.meeting.get('visitorIsConsumer')
            ? function() {
                return MeetingConnectionService.isConsumerPresent();
            }
            : function() {
                return MeetingConnectionService.isExpertPresent();
            };

        $scope.$watch(watchFunction, function(newVal, oldVal) {
            if (newVal === undefined || newVal === oldVal) {
                return;
            }
            if (newVal) {
                $scope.showEndCallButton = false;
            } else {
                // we only want to show the End-Call option if they CAN end the call (10 minutes have passed)
                $scope.showEndCallButton = $scope.canEndCall;

            }
        });

        $scope.showSettingsInModal = function() {
            $scope.$broadcast('toggle-multimedia-settings');
        };
        $scope.disconnectVoip = VoipConsultationService.disconnect;
    },
];
