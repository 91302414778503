export const SyncProfileController = [
    '$scope', '$q', '$window', '$rootScope', '$location', '$routeParams', 'ProfileLinkedinSyncService', 'RegistrationService', 'VisitorService',
    function($scope, $q, $window, $rootScope, $location, $routeParams, profileLinkedinSyncService, RegistrationService, VisitorService) {
        $window.document.title = 'Update Profile with LinkedIn';
        $rootScope.pageClass = 'sync-profile-container';

        $scope.SECTION_TYPES = {
            PERSONAL_INFO: 'personal_info',
            WORK_EXPERIENCE: 'work_experience',
            EDUCATION: 'education',
        };

        $scope.loadSection = function() {
            delete $scope.error;
            $location.search({error: undefined});
            $scope.loading = true;
            return profileLinkedinSyncService.loadSection($scope.section, $location.url()).then(function(response) {
                if (response.redirectTo) {
                    $window.location = response.redirectTo;
                } else {
                    $scope.profileSyncDiff = response;
                    if ($scope.profileSyncDiff.isSynced) {
                        $scope.showIsSyncedModal = true;
                    }
                    $scope.loading = false;
                }
            }).catch(function(error) {
                console.error(error);
                $scope.loading = false;
                $scope.error = {message: 'load_error'};
            });
        };

        $scope.applyPatch = function() {
            var patch = $scope.profileSyncDiff.buildPatch();
            var promise;
            if (patch.length) {
                $scope.processing = true;
                promise = profileLinkedinSyncService.updateSection($scope.section, patch).finally(function() {
                    RegistrationService.flushCache();
                    RegistrationService.flushCache($scope.userid);
                    delete $scope.processing;
                });
            } else {
                promise = $q.when();
            }
            promise.then(function() {
                $scope.goToEditProfileSection();
            });
        };

        $scope.goToEditProfileSection = function() {
            $location.path('/profile/edit/' + $scope.section);
        };

        $scope.hideIsSyncedModal = function() {
            delete $scope.showIsSyncedModal;
        };

        $scope.showError = function(error) {
            return $q.when(error).then(function(error) {
                $scope.loading = false;
                $scope.error = {message: '' + error};
            });
        };

        var knownSectionTypes = Object.keys($scope.SECTION_TYPES).map(function(key) { // IE11 has no Object.values()
            return $scope.SECTION_TYPES[key];
        });

        $scope.section = $routeParams.section;
        if (!~knownSectionTypes.indexOf($scope.section)) {
            return $location.path('/');
        }

        var error = $location.search().error;
        var promise;
        if (error) {
            promise = $scope.showError(error);
        } else {
            promise = $q.all([
                $scope.loadSection(),
                VisitorService.getVisitor().then(function(visitor) {
                    $scope.userid = visitor.user && visitor.user.id;
                    if (!$scope.userid) {
                        return $q.reject('access_denied');
                    }
                }),
            ]);
        }
        promise.then(function() {
            $scope.$emit('controllerLoaded', true);
        }).catch(function() {
            $location.path('/');
        });
    },
];
