import {PaypalForm as PaypalFormModel} from '../model/paypalForm';

export const PaypalForm = [
    'Meeting', '$q', function(Meeting, $q) {
        var promise = null;
        return {
            get: function() {
                if (promise) {
                    return promise;
                }

                var deferred = $q.defer();
                promise = deferred.promise;

                Meeting.getByRouteParams().then(function(meeting) {
                    var paypalForm = new PaypalFormModel({meetingid: meeting.get('id')});

                    paypalForm.fetch().then(function() {
                        promise = null;
                        deferred.resolve.call(this, paypalForm);
                    }, function() {
                        promise = null;
                        deferred.reject.apply(this, arguments);
                    });
                });
                return deferred.promise;
            },
        };
    },
];
