import * as Moment from 'moment-timezone';
import {OutOfOffices} from '../../collection/outOfOffices';
import {OutOfOffice} from '../../model/outOfOffice';

export function userInputOutOfOffice() {
    return {
        restrict: 'E',
        scope: {
            userid: '=',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/registration/directives/userInputs/userInputOutOfOffice.html',
        controller: [
            '$scope', '$q', function($scope, $q) {
                $scope.isSaving = false;

                $scope.periods = new OutOfOffices();
                $scope.periods.on('change reset add remove', function() {
                    $scope.initializeCurrentStatus();
                    var now = new Moment();
                    angular.forEach($scope.periods.models, function(period) {
                        if (period.endDate > now && period.startDate < now) {
                            $scope.currentStatus = 'Out of Office';
                        }
                    });
                });
                $scope.newOOO = null;

                $scope.currentStatus = null;

                $scope.initializeCurrentStatus = function() {
                    $scope.currentStatus = 'Visible';
                };

                $scope.initializeNewOOO = function() {
                    $scope.newOOO = new OutOfOffice({userid: $scope.userid});
                    $scope.newStartDate = null;
                    $scope.newEndDate = null;
                    $scope.errors = [];
                };

                $scope.$watch('userid', function() {
                    if ($scope.userid) {
                        $scope.periods.setUserid($scope.userid);
                        $scope.periods.fetch();
                        $scope.initializeNewOOO();
                        $scope.initializeCurrentStatus();

                        $scope.newStartDate = new Moment();
                    }
                });

                $scope.$watch('periods', function(change) {
                    $scope.initializeCurrentStatus();
                    var now = new Moment();
                    angular.forEach($scope.periods.models, function(period) {
                        console.log(period);
                        if (period.endDate > now && period.startDate < now) {
                            $scope.currentStatus = 'Out of Office';
                        }
                    });
                });

                $scope.cancelOOO = function(period) {
                    period.isBeingCancelled = true;
                    var data = {id: period.id};
                    var options = {data: data, wait: true};
                    $q.when(period.destroy(options)).then(function(result) {
                        // All deleted.
                    });
                };

                $scope.$watch('startDateError', function(newVal) {
                    if (newVal) {
                        $scope.errors.push('Please enter a valid start date.');
                    }
                });
                $scope.$watch('endDateError', function(newVal) {
                    if (newVal) {
                        $scope.errors.push('Please enter a valid end date.');
                    }
                });

                $scope.validateData = function() {
                    var errors = $scope.newOOO.validateData();

                    $scope.errors = [];

                    if (errors['startDate']) {
                        $scope.errors.push('A valid start date is required.');
                    }

                    if (errors['startDateInThePast']) {
                        $scope.errors.push('Start date must not be in the past.');
                    } else if (errors['endDate']) {
                        $scope.errors.push('A valid end date is required.');
                    }

                    if (errors['userid']) {
                        $scope.errors.push('Could not determine user. Try refreshing your browser?');
                    }

                    if (errors['negativeDifference']) {
                        $scope.errors.push('Start date must be before end date.');
                    }

                    return $scope.errors.length;
                };

                $scope.onChange = function() {
                    $scope.newOOO.startDate = $scope.newStartDate;
                    $scope.newOOO.endDate = $scope.newEndDate;

                    $scope.validateData();
                };

                $scope.canSave = function() {
                    return !$scope.isSaving && $scope.newOOO && !$scope.errors.length;
                };

                $scope.addOOO = function() {
                    if ($scope.canSave()) {
                        $scope.isSaving = true;
                        $q.when($scope.newOOO.save()).then(function(result) {
                            $scope.isSaving = false;
                            $scope.newOOO.set('userid', $scope.userid);
                            $scope.periods.push($scope.newOOO);
                            $scope.initializeNewOOO();
                        }, function(jqxhr) {
                            $scope.isSaving = false;
                            var response = JSON.parse(jqxhr.responseText);
                            if (response.error) {
                                $scope.errors.push(response.error);
                            } else {
                                $scope.errors.push('Could not save OOO period! Please contact us or try again.');
                            }
                        });
                    }
                };
            },
        ],
    };
}

