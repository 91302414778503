import {GroupBaseController} from './groupBase';

export class GroupEventsController extends GroupBaseController {
    static $inject = ['$scope', '$rootScope', '$routeParams', '$q', '$window', '$http', 'VisitorService', '$analytics'];

    constructor($scope, $rootScope, $routeParams, $q, $window, $http, VisitorService, $analytics) {
        super($scope, $rootScope, $routeParams, $q, $window, $http, VisitorService, $analytics);
        $window.document.title = 'Booth Events';
        $scope.position = 4;
    };
}
