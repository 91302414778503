import {AffinityGroupsForSearch} from '../collection/affinityGroupsForSearch';
import {AffinityGroupCategories as CategoriesCollection} from '../../angular-utilities/collection/affinityGroupCategories';
import sortGroupOrder from "../services/sortGroupOrder";
import {DIMENSION_PROGRAMID} from 'configurations/googleAnalyticsDimensions';

export const GroupsController = [
    '$scope', '$rootScope', '$routeParams', '$q', '$window', '$http', '$analytics',
    function ($scope, $rootScope, $routeParams, $q, $window, $http, $analytics) {
        $scope.loading = true;
        $scope.selectedGroup = {};

        $scope.programid = $routeParams.programid;
        $scope.publiclyVisibleGroups = new AffinityGroupsForSearch();
        if ($scope.programid) {
            $scope.publiclyVisibleGroups.filterToProgramid($scope.programid);
        }

        $scope.groupCategoriesCollection = new CategoriesCollection();
        $scope.groups = null;
        $scope.groupCategories = null;
        $scope.filterCategory = '';
        $scope.filterGroup = '';
        $scope.groupNounPlural = 'Groups';
        $scope.membershipStatus = $scope.ALL_GROUPS;
        $scope.filterMyGroup = {
            value: false,
        };
        $scope.isFair = false;
        $scope.sortBy = 'alphaAsc';

        $rootScope.pageClass = 'group-search-container search-container';

        $scope.truncateGroupDescription = function (description) {
            if (description && (description.length > 120)) {
                description = description.substring(0, 119) + '...';
                return description;
            }
            return description;
        };

        $scope.filterByCategory = function (cat) {
            if ($scope.filterCategory === cat) {
                $scope.filterCategory = '';
            } else {
                $scope.filterCategory = cat;
            }
        };

        $scope.filterCategories = function (group) {
            if ($scope.filterGroup) {
                return group.label.toLowerCase().includes($scope.filterGroup.toLowerCase());
            }
            if (!$scope.filterMyGroup.value && $scope.filterCategory === '') {
                return true;
            }
            if ($scope.filterMyGroup.value && $scope.filterCategory === '') {
                return group.isMember;
            }
            if ($scope.filterCategory === '') {
                return true;
            } else if ($scope.filterMyGroup.value && $scope.filterCategory !== '') {
                return group.isMember && group.groupCategoryLabel.toLowerCase().includes($scope.filterCategory.toLowerCase());
            } else {
                return group.groupCategoryLabel.toLowerCase().includes($scope.filterCategory.toLowerCase());
            }
        };

        $scope.showTruncatedDescription = function (index) {
            $scope.activeParentIndex = index;
        };

        $scope.showFullDescription = function (index) {
            return $scope.activeParentIndex === index;
        };

        $scope.hideModal = function () {
            $scope.showRequestSent = false;
        };

        $scope.groupJoinStatus = function (group) {
            if (group.isMember) {
                return $scope.programid ? $scope.EXPLORED : $scope.JOINED;
            }

            if (!group.isMember && group.isRestricted === '1') {
                return $scope.REQUEST_TO_JOIN;
            } else {
                return $scope.programid ? $scope.EXPLORE : $scope.JOIN;
            }
        };

        $scope.joinGroup = function (group) {
            group.isLoading = true;

            if (!group.isMember && group.isRestricted) {
                $scope.showRequestSent = true;
            }
            $http.post('/group/optin.json', {
                groupid: group.id,
            }).then(function (response) {
                group.isLoading = false;
                if (response.data.data.OptinGroup.hasJoined) {
                    group.isMember = true;
                    $scope.groupJoinStatus(group);
                } else {
                    group.isPending = true;
                }
            });
        };

        $scope.$watch('sortBy', function (newVal) {
            if (!$scope.groups || !$scope.groups.length) {
                return;
            }
            $scope.groups = sortGroupOrder(newVal, $scope.groups);
        })

        $q.all([
            $scope.publiclyVisibleGroups.fetch(),
            $scope.groupCategoriesCollection.fetch(),
        ]).then(function () {
            $scope.groups = sortGroupOrder($scope.sortBy, $scope.publiclyVisibleGroups.models);

            if ($scope.groups.length) {
                $scope.isFair = $scope.groups[0].isBooth;
            }
            // TODO Move Program metadata request so it's not pulling from an unrelated thing.
            if ($scope.groups[0] && $scope.groups[0].programs && $scope.groups[0].programs[0]) {
                if ($scope.groups[0].programs[0].customGroupLabelPlural) {
                    $scope.groupNounPlural = $scope.groups[0].programs[0].customGroupLabelPlural.toLowerCase();
                }
            }
            $window.document.title = $scope.isFair ? 'Booths' : 'Groups';

            // Tracking
            if ($scope.programid) {
                const userProperties = {};
                userProperties[DIMENSION_PROGRAMID] = $scope.programid;
                $analytics.setUserProperties(userProperties);
                $analytics.eventTrack('Booths List Shown', { 'category': 'Fair Interaction', 'label': $scope.programid });
            }

            $scope.groupCategories = $scope.groupCategoriesCollection.models;
            $scope.isLoading = false;
            $scope.$emit('controllerLoaded', true);
        }, function () {
            $scope.error = true;
            $scope.errorMessage = 'Your platform does not support Affinity Groups.';
            $scope.isLoading = false;
            $scope.$emit('controllerLoaded', true);
        });
    }
];
