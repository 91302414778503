import {NgModel} from 'model/ngModel';
import {visitor as Visitor} from 'visitor';
import moment from 'moment';

const ProgrammingEvent = NgModel.extend({
    _name: 'ProgrammingEvent',
    defaultUrl: '/control/partner/programming-events/[id]',
    urlRoot: '/control/partner/programming-events/[id]',
    defaults: {
        id: null,
        fairId: null,
        topic: null,
        meetingLink: null,
        startDate: null,
        stopDate: null,
        shortDescription: null,
        typeId: null,
        type: null,
        speakers: [],
        timezone: null
    },
    fetch: function(options) {
        let fetch = this._super('fetch', options);
        this.urlRoot = this.defaultUrl;

        return fetch;
    },
    initialize: function() {
        this.timezone = Visitor.user.timezone;
        this.timezoneAbbr = moment.tz(Visitor.user.timezone).zoneAbbr();
    },
    parse: function(data) {
        let dataFormatted = this._super('parse', data);

        dataFormatted.timezone = Visitor.user.timezone;
        dataFormatted.timezoneAbbr = moment.tz(Visitor.user.timezone).zoneAbbr();

        const dateFormatTime = 'hh:mm';
        const dateFormatOnlyMeridiem = 'A';

        let formattedStartDate = moment(`${dataFormatted.startDate}Z`).tz(dataFormatted.timezone);
        let formattedStopDate = moment(`${dataFormatted.stopDate}Z`).tz(dataFormatted.timezone);

        const startTimeMeridiem = formattedStartDate.format(dateFormatOnlyMeridiem);
        const stopTimeMeridiem = formattedStopDate.format(dateFormatOnlyMeridiem);
        const isSameMeridiem = startTimeMeridiem === stopTimeMeridiem;
        const isSameDay = formattedStartDate.format('D') === formattedStopDate.format('D');
        const startTime = formattedStartDate.format(dateFormatTime);
        const stopTime = formattedStopDate.format(dateFormatTime);
        dataFormatted.timing = formattedStartDate.format('MMM D')
            + ' '
            + startTime + (isSameMeridiem ? '' : (' ' + startTimeMeridiem))
            + ' - '
            + (isSameDay ? '' : (formattedStopDate.format('MMM D') + ' '))
            + stopTime + ' ' + stopTimeMeridiem;

        dataFormatted.speakerNames = dataFormatted.speakers.map(it => it.firstName + ' ' + it.lastName).join(', ')

        const now = moment();
        if (moment(dataFormatted.startDate) > now) {
            dataFormatted.timeSpan = 'upcoming';
        } else {
            if (moment(dataFormatted.startDate) <= now && moment(dataFormatted.stopDate) >= now) {
                dataFormatted.timeSpan = 'happening';
            } else {
                dataFormatted.timeSpan = 'past';
            }
        }

        return dataFormatted;
    }
});

export { ProgrammingEvent };
