import {whitelabelling as Whitelabelling} from 'whitelabelling';

export function userInputCalendarSync() {
    return {
        restrict: 'E',
        scope: {
            data: '=',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/userInputs/userInputCalendarSync.html',
        controller: [
            '$scope', '$window', '$http', function($scope, $window, $http) {
                $scope.whitelabelling = Whitelabelling;

                $scope.connectNewCalendar = function() {
                    $window.open('/calendar/connect', '_blank');
                };

                $scope.disconnectCalendar = function(calendar) {
                    $http.delete('/calendar/disconnect/' + calendar.get('profileid')).then(function() {
                        $scope.data.remove(calendar);
                    }, function() {
                        console.log('Something went wrong...');
                    });
                };
            },
        ],
    };
}
