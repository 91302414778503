export function alertSessionExpired() {
    return {
        restrict: 'E',
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/alertSessionExpired.html',
        controller: [
            '$scope',
            function ($scope) {
                $scope.showAlert = false;

                $scope.hideAlert = () => {
                    $scope.showAlert = false;
                };

                window.addEventListener('FirsthandSessionExpired', () => {
                    $scope.showAlert = true;
                })
            },
        ],
    };
}

