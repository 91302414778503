import {whitelabelling as Whitelabelling} from 'whitelabelling';

/**
 * This directive packages the assessment panel items
 * Use like:
 * <dashboard-consultation-card></dashboard-consultation-card>
 */
export function dashboardWebinarCard() {
    return {
        restrict: 'E',
        scope: {
            data: '=',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/directives/dashboard/dashboardWebinarCard.html',
        replace: true,
        controller: [
            '$scope', function($scope) {
                $scope.whitelabelling = Whitelabelling;

                $scope.MAX_CHARACTERS = 130;
            },
        ],
    };
}

