import {Backbone} from 'Vertebrae';
import {AlgorithmUser} from 'model/algorithmUser';

export const JobOpeningRelatedExperts = Backbone.Collection.extend({
    _name: 'JobOpeningRelatedExperts',
    model: AlgorithmUser,
    defaults: {
        jobid: null,
    },
    url: function() {
        return '/jobs/' + this.jobid + '/experts.json';
    },
    setUser: function(u) {
        this.userid = u.get('id');
    },
    setSelectedExpert: function(u) {
        var others = this.where({'selected': true});
        _.each(others, function(o) {
            o.set('selected', false);
        });
        u.set('selected', true);
    },
    setJob: function(jobId) {
        this.jobid = jobId;
    },
});
