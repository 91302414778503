import {whitelabelling as Whitelabelling} from 'whitelabelling';

export function editOfferings() {
    return {
        restrict: 'E',
        scope: {
            userid: '=?',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/editOfferings.html',
        link: function(scope, element, attrs) {
            // If the directive had a "userid" attribute, then we need to wait for that to load.
            // If not, then it's OK to load without a userID, and just load for "myself".
            scope.waitForUserId = 'userid' in attrs;
        },
        controller: [
            '$scope',
            'VisitorService',
            'RegistrationService',
            '$q',
            '$routeParams',
            function($scope, VisitorService, RegistrationService, $q, $routeParams) {
                $scope.isSavingData = false;
                $scope.error = null;
                $scope.whitelabelling = Whitelabelling;

                $scope.SECTION_ADVICE = 'advice';
                $scope.SECTION_OPPORTUNITIES = 'opportunities';
                $scope.SECTION_JOBS = 'jobs';

                if (Whitelabelling.settings.featureNewRegistrationEnabled) {
                    $scope.section = $scope.SECTION_JOBS;
                } else if ($routeParams.action) {
                    $scope.section = $routeParams.action;
                } else {
                    $scope.section = $scope.SECTION_ADVICE;
                }

                $scope.changeSection = function(newSection) {
                    if (newSection !== $scope.section) {
                        $scope.section = newSection;
                    }
                };

                $scope.$on('saveData', function() {
                    $scope.saveData();
                });

                $scope.saveData = function() {
                    $scope.isSavingData = true;
                    $q.when($scope.registrationData.saveOfferings()).then(function(responseObj) {
                        $scope.isSavingData = false;
                        bindChangeListeners();
                        if (responseObj.status === 401) {
                            $scope.error = 'Could not save!';
                        }
                    });
                };

                $scope.$watch('userid', function() {
                    if ($scope.waitForUserId && !$scope.userid) {
                        return;
                    }
                    $q.all([
                        RegistrationService.getRegistrationDataForOfferings($scope.userid),
                        RegistrationService.getRegistrationSettings(),
                    ]).then(function(data) {
                        $scope.registrationData = data[0];
                        $scope.registrationSettings = data[1];

                        if (!$scope.registrationData.hasRole('expert')) {
                            $scope.registrationData = {};
                            $scope.error = 'Sorry, you don\'t have permission to access this page.';
                        } else {
                            bindChangeListeners();
                        }

                        $scope.jobOpeningsEnabled = Whitelabelling.settings.jobOpeningsEnabled;
                        $scope.opportunitiesEnabled = !Whitelabelling.settings.featureNewRegistrationEnabled && $scope.registrationSettings.Verticals.has({'id': 5});
                        $scope.advicesEnabled = !Whitelabelling.settings.featureNewRegistrationEnabled;

                        // notify the app that we're ready
                        $scope.isLoaded = true;
                        $scope.$emit('controllerLoaded', true);
                    });
                });

                function bindChangeListeners() {
                    // watch for changes to the preferredTimes data set
                    $scope.preferredTimesChanged = false;
                    $scope.registrationData.GeneralAvailability.on('add remove', function() {
                        $scope.preferredTimesChanged = true;
                    });

                    $scope.verticalServicesChanged = false;
                    $scope.registrationData.VerticalServices.on('add remove', function() {
                        $scope.verticalServicesChanged = true;
                    });

                    $scope.registrationData.JobOpenings.on('add remove change', function() {
                        $scope.jobOpeningsChanged = true;
                    });
                    $scope.jobOpeningsChanged = false;
                }
            },
        ],
    };
}
