/**
 * This directive creates a list of 3 similar experts
 * use like:
 * <similar-experts expertid="..."></similar-experts>
 */
import {whitelabelling} from 'whitelabelling';

export function similarExperts() {
    return {
        restrict: 'E',
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/similarExperts.html',
        scope: {
            expertid: '=',
        },
        controller: [
            '$scope', 'ExpertsService', function($scope, ExpertsService) {
                $scope.whitelabelling = whitelabelling;
                $scope.experts = [];
                $scope.$watch('expertid', function(newVal) {
                    if (newVal) {
                        ExpertsService.getSimilar($scope.expertid, 3).then(function(collection) {
                            $scope.experts = collection.models;
                        });
                    }
                });
            },
        ],
    };
}
