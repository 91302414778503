export function profileCareerGoals() {
    return {
        restrict: 'E',
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/profileCareerGoals.html',
        replace: true,
        scope: {
            careerGoals: '=',
        },
        controller: [
            '$scope', function($scope) {

                var once = $scope.$watch('careerGoals', function(newVal) {
                    if (newVal) {
                        refreshGoals();
                        once();
                    }
                });

                function refreshGoals() {
                    if (!$scope.careerGoals) {
                        return;
                    }
                    $scope.functions = $scope.careerGoals.getFunctions();
                    $scope.employers = $scope.careerGoals.getEmployers();
                    $scope.locations = $scope.careerGoals.getLocations();
                    $scope.sectors = $scope.careerGoals.getSectors();
                }
            },
        ],
    };
}
