/**
 * This directive to pluralize noun
 * use like:
 * <pluralize count="..." singularNoun="..." />
 */

export function pluralize() {
    return {
        restrict: 'E',
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/pluralize.html',
        replace: true,
        scope: {
            count: '=',
            singularNoun: '=',
            withEs: '=?',
        },
        controller: [
            '$scope', function($scope) {
                $scope.suffix = $scope.withEs ? 'es' : 's';

                const makePluralNoun = () => {
                    $scope.pluralNoun = `${$scope.count} ${$scope.singularNoun}${$scope.count === 1 ? '' : $scope.suffix}`
                };
                makePluralNoun();
                $scope.$watch('count', makePluralNoun)
            },
        ],
    };
}
