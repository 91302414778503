import {Resource} from '../model/resource';

export const ResourceController = [
    '$scope',
    '$window',
    '$location',
    '$timeout',
    '$rootScope',
    '$routeParams',
    '$sce',
    '$http',
    function($scope, $window, $location, $timeout, $rootScope, $routeParams, $sce, $http) {
        $scope.suggestedResources = [];
        $scope.showMore = true;

        $scope.resource = new Resource({id: $routeParams.id});
        $scope.resource.fetch().then(() => {
            $scope.videoEmbedCode = $sce.trustAsHtml($scope.resource.videoEmbedCode);
            $window.document.title = $scope.resource.title;
            $scope.$emit('controllerLoaded', true);
        });

        $http.get('/resources/suggestions?ignore=' + $routeParams.id).then((res) => {
            $scope.suggestedResources = res.data.data.Resources;
        });

        $scope.toggleLike = function() {
            const url = '/resources/' + $scope.resource.id + '/likes';

            if ($scope.resource.isLiked) {
                $scope.resource.isLiked = false;
                $scope.resource.meta.likes--;
                $http.delete(url);
            } else {
                $scope.resource.isLiked = true;
                $scope.resource.meta.likes++;
                $http.post(url);
            }
        };

        $scope.toggleBookmark = function() {
            $http.post(`/resources/${$scope.resource.id}/bookmarks`);
            $scope.resource.bookmarked = !$scope.resource.bookmarked;
        };

        $scope.toggleShowMore = function() {
            $scope.showMore = !$scope.showMore;
        };
    },
];
