export function an() {
    return function(input, includeWord) {
        if (includeWord === undefined) {
            includeWord = true;
        }
        input = typeof input === 'string' ? input : '';
        var ret = 'a';
        if (['a', 'e', 'i', 'o', 'u'].indexOf(input.substr(0, 1).toLowerCase()) !== -1) {
            ret = 'an';
        }
        if (includeWord) {
            ret += ' ' + input;
        }
        return ret;
    };
}
