export const MeetingState_Both_SelfReschedule = [
    '$scope', function($scope) {
        $scope.template = Evisors.config.cdnRootWhitelabel + 'asset/partials/consultations/meetingStates/both/selfReschedule.html';
        $scope.meetingStartDates = $scope.meeting.get('suggestedTimeSwaths');
        $scope.durationInSeconds = $scope.meeting.get('durationInSeconds');
        $scope.expiryFormatted = $scope.meeting.get('expiryFormatted');

        // whether or not to show the cancel dialog
        $scope.showCancel = false;
    },
];
