export const MeetingStatesHelper = [
    '$scope', '$window', '$controller', 'Meeting', '$timeout', '$q',
    function($scope, $window, $controller, Meeting, $timeout, $q) {
        // These hashes map a meeting state (by status) to an angular Controller. They're used to determine which
        // view to load in the page
        var bothStateToControllerMapping = {
            '-1': 'MeetingState_Both_Expired',
            4: 'MeetingState_Both_Prepare',
            5: 'MeetingState_Both_Meet',
            24: 'MeetingState_Both_BothNoShow',
        };
        var consumerStateToControllerMapping = {
            3: 'MeetingState_Consumer_Payment',
            0: 'MeetingState_Consumer_EmailVerification',
            1: 'MeetingState_Consumer_ExpertAccept',
            6: 'MeetingState_Consumer_Complete',

            20: 'MeetingState_Both_OtherNoShow',
            19: 'MeetingState_Both_SelfNoShow',
            9: 'MeetingState_Both_OtherReschedule',
            7: 'MeetingState_Both_SelfReschedule',
            12: 'MeetingState_Both_OtherCancelled',
            11: 'MeetingState_Both_SelfCancelled',
        };

        var expertStateToControllerMapping = {
            3: 'MeetingState_Expert_Payment',
            0: 'MeetingState_Expert_EmailVerification',
            1: 'MeetingState_Expert_ExpertAccept',
            6: 'MeetingState_Expert_Complete',

            19: 'MeetingState_Both_OtherNoShow',
            20: 'MeetingState_Both_SelfNoShow',
            7: 'MeetingState_Both_OtherReschedule',
            9: 'MeetingState_Both_SelfReschedule',
            11: 'MeetingState_Both_OtherCancelled',
            12: 'MeetingState_Both_SelfCancelled',
        };

        function renderState() {
            // first we determine which via (via controller) we should be rendering
            var view;
            if ($scope.meeting.get('visitorIsConsumer')) {
                view = consumerStateToControllerMapping[$scope.meetingState.get('status')];
                $scope.visitorLabel = 'consumer'; // this is the class given the state container
            } else {
                view = expertStateToControllerMapping[$scope.meetingState.get('status')];
                $scope.visitorLabel = 'expert'; // this is the class given the state container
            }

            if (!view) {
                view = bothStateToControllerMapping['' + $scope.meetingState.get('status')];
            }

            if (!view) {
                throw 'Invalid state, ' + $scope.meetingState.get('status');
            }

            // convert state names from upper case and underscores to lower case and hyphens
            // to use as a class given to the state container
            // STATE_EXPERT_ACCEPT becomes state-expert-accept
            $scope.stateName = $scope.meetingState.get('name').toLowerCase().replace('_', '-');

            // load the state's controller with our scope
            $controller(view, {$scope: $scope});
        }

        Meeting.getByRouteParams().then(function(meeting) {
            // we seed our watching service by first loading the meeting
            $scope.meeting = meeting;
            $scope.meetingState = meeting.get('MeetingState');

            // render the first time
            renderState();

            $scope.$watch('meeting.MeetingState.status', function(newVal, oldVal) {
                if (newVal === undefined || oldVal === newVal) {
                    return;
                }

                // reload the meeting into the scope
                Meeting.getByRouteParams().then(function(meeting) {
                    $scope.meeting = meeting;
                    $scope.meetingState = meeting.get('MeetingState');

                    $scope.$broadcast('meeting-state-change');

                    // re-render the state section
                    renderState();
                });
            });
        });

        $scope.meetingServiceUpdateFunction = function() {
            return $q.when(Meeting.getByRouteParams(true));
        };
    },
];
