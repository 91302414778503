// noinspection JSIgnoredPromiseFromCall

import {MeetingTime} from 'model/meetingTime';
import {Availabilities} from 'collection/availabilities';
import {UnAvailabilities} from 'collection/unAvailabilities';
import {TimeSwath} from 'model/timeSwath';
import * as moment from 'moment-timezone';

export const AvailabilityService = [
    '$q', 'TimezonesService',
    function($q, TimezonesService) {
        /**
         * @param {Number} userid
         * @param {Number} weeksAhead
         * @param {Number} bookingHourBuffer
         * @param {Number} blockSizeInSeconds
         * @param {Array<MeetingTime>} compareToTimes
         * @param {Number} blockOverflowInSeconds
         * @param {Number} serviceid In case we need to filter to Fairs
         * @param {PromiseConstructor} deferred
         */
        function updateAvailability(
            userid, weeksAhead, bookingHourBuffer, blockSizeInSeconds, compareToTimes, blockOverflowInSeconds, serviceid,
            deferred) {
            if (!userid) {
                deferred.reject('Missing userid');
            }

            const availability = new Availabilities();
            const unAvailability = new UnAvailabilities();
            availability.userid = userid;
            unAvailability.userid = userid;

            const queryOptions = {
                data: {
                    weeksAhead: weeksAhead,
                    timezone: TimezonesService.getDeviceTimezone().locationCode,
                    serviceid: serviceid ? serviceid : null,
                },
            };
            $q.all([
                availability.fetch(queryOptions),
                unAvailability.fetch(queryOptions),
            ]).then((response) => {
                let thisWeekSunday = moment(new Date()).tz(queryOptions.data.timezone).day(7 * weeksAhead).toDate();
                thisWeekSunday.setHours(0, 0, 0, 0);

                let earliestBookableDate = moment().add(Math.floor(bookingHourBuffer), "hours").startOf('minute').toDate()

                // .setHours will automatically carry over correctly even if hours/minutes are greater than 23/59
                unAvailability.add(
                    new unAvailability.model(
                        {
                            startDate: {
                                date: moment(0).tz(queryOptions.data.timezone).format('YYYY-MM-DD HH:mm:ss'),
                                timezone: queryOptions.data.timezone,
                            },
                            endDate: {
                                date: moment(earliestBookableDate).tz(queryOptions.data.timezone).format('YYYY-MM-DD HH:mm:ss'),
                                timezone: queryOptions.data.timezone,
                            },
                        },
                        {
                            parse: true,
                        }));

                if (weeksAhead > 0) {
                    earliestBookableDate = new Date(Math.max(earliestBookableDate.getTime(), thisWeekSunday.getTime()));
                }

                // Hacky check because Backbone will still generate … weird … "models" if the result is null.
                if (response[0].data.Availabilities === null) {
                    // If availability is null, this week is legitimately unavailable.
                } else if (!availability.length) {
                    // if there is no availability, we assume full availability
                    let nextSunday = moment(new Date()).
                        tz(queryOptions.data.timezone).
                        day(7 * (weeksAhead + 1)).
                        toDate();
                    availability.add(new availability.model({
                        startDate: thisWeekSunday,
                        endDate: nextSunday,
                    }, {parse: true}));
                }

                // Here we're doing a Filter and a Map
                // Filter -- we only want times that are sufficiently in the future (accounting for the buffer)
                // Map -- we construct an array of TimeSwaths pulled from our collection of Availability models
                const futureAvailabilities = availability.filter(availability => availability.get('endDate') >= earliestBookableDate);
                let weeklyTimeSwaths = [];
                futureAvailabilities.forEach(timeSwathToCheck=>weeklyTimeSwaths.push(timeSwathToCheck.get('timeSwath')));

                // disjoin each unavailable time with the available times
                if (unAvailability.models.length) {
                    // disjoin each unAvailable time with the known weekly availability
                    unAvailability.each(function(ava) {
                        for (let i = 0; i < weeklyTimeSwaths.length; i++) {
                            let disjoin = weeklyTimeSwaths[i].disjoin(ava.get('timeSwath'));
                            // we replace this swath with the disjoin
                            weeklyTimeSwaths.splice.apply(weeklyTimeSwaths, [i, 1].concat(disjoin));

                            // we do 1-length because disjoin can be up to 2. So if it's 0, we want
                            // to nullify the for loops +1 (by subtracting 1). If it's 2, we don't need to check the ones
                            // we just inserted because they're already disjoined with this unavailability -- so we can
                            // "skip" one because we would have shifted the ones remaining to check down by 1
                            i -= 1 - disjoin.length;
                        }
                    });
                }

                deferred.resolve(
                    breakIntoBlocks(weeklyTimeSwaths, thisWeekSunday, earliestBookableDate, blockSizeInSeconds,
                        compareToTimes, blockOverflowInSeconds));
            }, deferred.reject);
        }

        /**
         * @param {Array<TimeSwath>} timeSwaths
         * @param {Date} thisWeekSunday
         * @param {Date} earliestDate
         * @param {Number} blockSizeInSeconds
         * @param {Array<MeetingTime>} compareToTimes
         * @param {Number} blockOverflowInSeconds how many seconds long each block should be
         * @return {Array}
         */
        // splits an array of availability swaths into blockSizeInSeconds long blocks
        function breakIntoBlocks(
            timeSwaths, thisWeekSunday, earliestDate, blockSizeInSeconds, compareToTimes, blockOverflowInSeconds) {
            const dateString = 'ddd[\n]MMM[ ]D';
            let column;

            // we make sure to use the timezone they selected
            const tz = TimezonesService.getDeviceTimezone().locationCode;
            const blockSizeInMinutes = blockSizeInSeconds / 60;
            const blockSizeWithOverflowInSeconds = blockSizeInSeconds + blockOverflowInSeconds;

            // we're going to break the availabilities into 1 hour long blocks listed as an array, keyed to the
            // memoizations object by their date string
            const memoizations = {};
            timeSwaths.forEach(function(ava) {
                const bigBlock = ava.clone();

                // we round up to the nearest BLOCK SIZE
                if (bigBlock.getStartDate().getMinutes() % blockSizeInMinutes) {
                    const minutes = bigBlock.getStartDate().getMinutes();
                    bigBlock.getStartDate().setMinutes(minutes + blockSizeInMinutes - (minutes % blockSizeInMinutes));
                    bigBlock.recalc();
                }

                // if this block is bigger than blockSizeWithOverflowInSeconds, we are going to break it into pieces
                if (bigBlock.getDurationInSeconds() >= blockSizeWithOverflowInSeconds) {
                    while (bigBlock.getDurationInSeconds() >= blockSizeWithOverflowInSeconds) {
                        column = moment(bigBlock.getStartDate()).tz(tz).format(dateString);
                        if (!memoizations[column]) {
                            memoizations[column] = [];
                        }

                        // blockSizeInSeconds long swaths
                        // NOTE: here we want the block to be the correct blockSize, not the blockSize with overflow
                        // buffer
                        const pieceBlock = new TimeSwath(bigBlock.getStartDate(), blockSizeInSeconds);

                        bigBlock.setStartDate(pieceBlock.getEndDate());

                        // only include the slot if it's in the future
                        if (pieceBlock.getStartDate() >= earliestDate) {
                            // add the TimeSwath to our memoized groups
                            memoizations[column].push(new MeetingTime({
                                date: pieceBlock.getStartDate(),
                                compareTo: compareToTimes,
                            }, {parse: true}));
                        }
                    }
                }
                //TODO: It looks like this piece of code is causing bugs. What it does is following - if the meeting durations bigger then the current available time slot, then it just checks whether proposal is starting after earliest possible date
                //Doesn't make so much sense after all
                /*else if (ava.getStartDate() >= earliestDate) {
                    column = moment.tz(ava.getStartDate(), tz).format(dateString);
                    if (!memoizations[column]) {
                        memoizations[column] = [];
                    }
                    memoizations[column].push(new MeetingTime({
                        date: ava.getStartDate(),
                        label: column,
                        compareTo: compareToTimes,
                    }, {parse: true}));
                }*/
            });
            column = undefined;

            // TODO: justBeforeNoon -- the divider that shows where 12:00 noon is

            // we now convert that memoizations object into an array with all days of the week (not just ones with
            // availabilities)
            const retVal = [];
            const thisWeek = TimezonesService.getWeek(thisWeekSunday);
            thisWeek.forEach(function(momentDate) {
                column = momentDate.format(dateString);
                retVal.push({
                    label: column,
                    moment: momentDate,
                    slots: memoizations[column] ? memoizations[column] : [],
                });
            });

            return retVal;
        }

        return {

            /**
             * @param {Number} userid
             * @param {Number} weeksAhead -- how many weeks ahead should we load availability for
             * @param {Array<MeetingTime>} compareToTimes -- compare to existing MeetingTimes to set isSelected (@see
             *     model/meetingTime.js)
             * @param {Number} bookingHourBuffer -- how many hours from now() in the future a block must be to be valid
             * @param {Number} blockSizeInSeconds -- how many seconds long each block should be
             * @param {Number} blockOverflowInSeconds -- how many seconds must be free after each block for it to be
             *     valid
             * @param {Number} serviceid -- In case it's a "Fair Consultation", we need to check against the service type.
             *
             * for example, we might let them pick 15-minute increments (blockSizeInSeconds),
             * but the meeting is an hour long and so there must be at least 45 minutes (blockOverflowInSeconds)
             * free at the end of each block to be included
             */
            getUserAvailabilityMerged: function(
                userid, weeksAhead, compareToTimes, bookingHourBuffer, blockSizeInSeconds, blockOverflowInSeconds, serviceid) {
                const deferred = $q.defer();
                updateAvailability(userid, weeksAhead, bookingHourBuffer || 0, blockSizeInSeconds || 3600,
                    compareToTimes, blockOverflowInSeconds || 0, serviceid, deferred);
                return deferred.promise;
            },
        };
    },
];
