import {NgModel} from './ngModel';

export const UserEngagement = NgModel.extend({
    _name: 'UserEngagement',
    urlRoot: '/user-engagements/[label].json',
    defaults: {
        userid: null,
        whitelabelid: null,
        label: null,
        value: null,
        dateCreated: null,
        lastModified: null,
    },
    parse: function(data) {
        var dataFormatted = this._super('parse', data);

        if (dataFormatted.dateCreated) {
            dataFormatted.dateCreated = this.getDate(dataFormatted.dateCreated);
        }

        if (dataFormatted.lastModified) {
            dataFormatted.lastModified = this.getDate(dataFormatted.lastModified);
        }

        this.__bindManyForAngular(dataFormatted);
        return dataFormatted;
    },
});
