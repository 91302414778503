import {whitelabelling as Whitelabelling} from 'whitelabelling';

export const PeopleController = [
    '$scope', '$window', '$rootScope', '$q', 'UserDashboardService', 'VisitorService', '$routeParams',
    function($scope, $window, $rootScope, $q, UserDashboardService, VisitorService, $routeParams) {
        $window.document.title = 'People';
        $rootScope.pageClass = 'people-container';
        $scope.TAB_MENTEES = 'mentees';
        $scope.TAB_MENTORS = 'mentores';
        $scope.whitelabelling = Whitelabelling;

        if ($routeParams.actorType === $scope.TAB_MENTEES) {
            $scope.currentTab = $scope.TAB_MENTEES;
        } else {
            $scope.currentTab = $scope.TAB_MENTORS;
        }

        $scope.pageCount = 8;
        $scope.mentorsPage = 0;
        $scope.menteesPage = 0;

        VisitorService.getVisitor().then(function(visitor) {
            if (!visitor.get('isUser')) {
                window.location.href = '/login';
                return;
            }
            $scope.visitor = visitor;

            // a sanity check to make sure a person without consumer access isn't finding themselves on the Mentors tab
            // and vice versa
            if (!$scope.visitor.get('isConsumer') && $scope.currentTab === $scope.TAB_MENTORS) {
                $scope.currentTab = $scope.TAB_MENTEES;
            } else if (!$scope.visitor.get('isExpert') && $scope.currentTab === $scope.TAB_MENTEES) {
                $scope.currentTab = $scope.TAB_MENTORS;
            }

            $q.all([
                UserDashboardService.getMentees($scope.pageCount, $scope.menteesPage),
                UserDashboardService.getMentors($scope.pageCount, $scope.mentorsPage),
            ]).then(function(data) {
                $scope.$emit('controllerLoaded', true);
                $scope.mentees = data[0];
                $scope.mentors = data[1];

                $scope.mentors.comparator = UserDashboardService.peopleSort;
                $scope.mentors.sort();

                $scope.mentees.comparator = UserDashboardService.peopleSort;
                $scope.mentees.sort();
            });
        });

        $scope.changePage = function(amt, userType) {
            if (
                ($scope[userType + 'Page'] === 0 && amt < 0)
                || ($scope[userType].length < $scope.pageCount && amt > 0)
            ) {
                // do nothing, no more pages
                return;
            }

            $scope[userType + 'Page'] += amt;
        };

        $scope.$watch('mentorsPage', function(newVal, oldVal) {
            if (newVal || oldVal) {
                $scope.loading = true;
                UserDashboardService.getMentors($scope.pageCount, $scope.mentorsPage).then(function(mentors) {
                    $scope.mentors = mentors;
                    $scope.loading = false;
                });
            }
        });

        $scope.$watch('menteesPage', function(newVal, oldVal) {
            if (newVal || oldVal) {
                $scope.loading = true;
                UserDashboardService.getMentees($scope.pageCount, $scope.menteesPage).then(function(mentees) {
                    $scope.mentees = mentees;
                    $scope.loading = false;
                });
            }
        });
    },
];
