export function objLimitTo() {
    return function(obj, limit, start) {
        if (typeof obj !== 'object') {
            return [];
        }

        var keys = Object.keys(obj);
        if (keys.length < 1) {
            return [];
        }

        var ret = {};
        var count = 0;
        keys.forEach(function(key, arrayIndex) {
            if (count < start || count >= (start + limit)) {
                return false;
            }
            ret[key] = obj[key];
            count++;
        });
        return ret;
    };
}
