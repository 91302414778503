import {NgModel} from 'model/ngModel';
import moment from "moment";

export const Event = NgModel.extend({
    _name: 'Event',
    defaults: {
        title: "",
        url: "",
        startDate: (new moment()).add(7, 'days').hour(10).minute(0).second(0).toDate(),
        endDate: (new moment()).add(10, 'days').hour(10).minute(0).second(0).toDate(),
        description: "",
        imageUrl: null,
        uploadedImageData: null,
        type: 'webinar',
    },
    parse: function(data) {
        let dataFormatted = this._super('parse', data);

        return dataFormatted
    }
});
