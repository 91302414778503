import {NgModel} from './ngModel';
import {whitelabelling} from 'whitelabelling';
import {validateEmail} from 'configurations/utilities';

export const ProfileUser = NgModel.extend({
    _name: 'User',
    urlRoot: '/profile/[id]/get.json',
    defaults: {
        id: null,
        expertid: null,
        firstName: null,
        lastName: null,
        avatarURL: null,
        avatarHash: null,
        email: null,
        gender: null,
        race: null,
        phone: {
            number: '',
            code: 1, // AMERICA
            canText: false,
        },
        phoneFormatted: null,
        currentLocation: {
            label: null,
            placeid: null,
        },
        timezone: null,
        roles: [],
        hasLinkedin: false,
        hasVerifiedEmail: false,
        creditsPerHour: null,
        restrictedToPrograms: null,
        outOfOffice: null,
        consultationsRemaining: 0
    },
    parse: function(data) {
        const dataFormatted = this._super('parse', data);

        if (data.graduationYear) {
            dataFormatted.graduationYearFormatted = ('' + data.graduationYear).substring(2);
        }

        if (data.consumerGraduationDate) {
            dataFormatted.consumerGraduationDate = this.getDate(data.consumerGraduationDate);
        }

        if (!dataFormatted.countryid) {
            dataFormatted.countryid = 222; // AMERICA
        } else {
            dataFormatted.countryid = parseInt(dataFormatted.countryid);
        }

        if (dataFormatted.phone.number && dataFormatted.phone.code && !dataFormatted.phoneFormatted) {
            dataFormatted.phoneFormatted = '+' + dataFormatted.phone.code + ' ' + dataFormatted.phone.number;
        }

        dataFormatted.hasLinkedin = !!data.hasLinkedin;

        dataFormatted.cdnAvatarUrl = 'asset/img/avatars/'
            + dataFormatted.id
            + ( dataFormatted.avatarHash ? `/${dataFormatted.avatarHash}` : '')
            + '/400.jpg';

        this.__bindManyForAngular(dataFormatted);

        // we bind fullName separately
        const that = this;
        Object.defineProperty(that, 'fullName', {
            get: function() {
                return that.get('firstName') + (that.get('lastName') ? (' ' + that.get('lastName')) : '');
            },
            set: function(value) {
                const names = value.split(' ');
                that.set('firstName', names[0]);
                that.set('lastName', names[1]);
            },
            enumerable: true,
            configurable: true,
        });

        return dataFormatted;
    },
    validateData: function() {
        const errors = {};
        errors.firstName = !this.get('firstName');
        errors.lastName = !this.get('lastName');
        errors.email = !validateEmail(this.get('email'));
        const location = this.get('currentLocation');
        errors.currentLocation = !(location && location.label && location.placeid);

        errors.gender = !this.get('gender');
        errors.race = !this.get('race');

        // never required
        if (whitelabelling.settings['registration:phoneRequired']) {
            errors.phone = !(this.phone.number && this.phone.code);
        } else {
            errors.phone = false;
        }

        errors.avatarURL = !this.get('avatarURL');
        this.set('errors', errors);
        return errors;
    },
    hasRole: function(roleKey) {
        return this.get('roles') && this.get('roles').hasOwnProperty(roleKey);
    },
});
