export function rescheduleMeeting() {
    return {
        restrict: 'A',
        scope: {
            userid: '<',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/consultations/rescheduleMeeting.html',
        controller: [
            '$scope', 'Meeting', ($scope, Meeting) => {
                $scope.selectedTimes = [];

                Meeting.getByRouteParams().then(data => {
                    $scope.meetingDuration = data.durationInSeconds;
                });

                $scope.submitNewTimes = () => {
                    $scope.isLoading = true;
                    $scope.errorMessage = false;

                    let rescheduleTimestamps = {};
                    for (const [i, selectedTime] of $scope.selectedTimes.entries()) {
                        rescheduleTimestamps['data' + (i + 1)] = selectedTime.get('date').getTime() / 1000;
                    }

                    Meeting.getByRouteParams().then(m => {
                        Meeting.reschedule(m, rescheduleTimestamps).then(() => {
                            $scope.isLoading = false;
                        }, response => {
                            $scope.isLoading = false;
                            $scope.errorMessage = response.data.data.message;
                        });
                    });
                };

                $scope.$watchCollection(() => $scope.selectedTimes.length, (newVal, oldVal) => {
                    $scope.showButton = (newVal > 0);
                });

                $scope.hideModal = () => {
                    $scope.$emit('hideModal');
                };
            },
        ],
    };
}

