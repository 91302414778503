import {CustomInput} from 'model/customInput';

export const TYPE_TEXT = 'text';

export class CustomInputText extends CustomInput {
    type = TYPE_TEXT;

    placeholder = null;
    minLength = null;
    maxLength = null;
    multiline = false;

    constructor(data) {
        super(data);

        this.placeholder = data.placeholder;
        this.minLength = data.minLength;
        this.maxLength = data.maxLength;
        this.multiline = data.multiline;
    };

    isValid(inputData) {
        if (!super.isValid(inputData)) {
            return false;
        } else {
            if (this.minLength && inputData.length < this.minLength) {
                this.error = 'This field is too short.';
                return false;
            }
            if (this.maxLength && inputData.length > this.maxLength) {
                this.error = 'This field is too long.';
                return false;
            }
        }

        this.error = null;
        return true;
    }
}
