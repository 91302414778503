export const MeetingState_Consumer_ExpertAccept = [
    '$scope', 'Meeting', function($scope, Meeting) {
        $scope.template = Evisors.config.cdnRootWhitelabel + 'asset/partials/consultations/meetingStates/consumer/expertAccept.html';
        $scope.meetingStartDates = $scope.meeting.get('suggestedTimeSwaths');
        $scope.durationInSeconds = $scope.meeting.get('durationInSeconds');
        $scope.expiryFormatted = $scope.meeting.get('expiryFormatted');

        // whether or not to show the reschedule / cancel dialogs
        $scope.showCancel = false;

        $scope.showSimilarAdvisorsTip = false;

        $scope.hideTip = function() {
            $scope.showSimilarAdvisorsTip = false;
            $scope.$broadcast('hideModal');
        };

        $scope.cancelRfp = function() {
            Meeting.cancelRFP($scope.meeting.RFP).then(function() {
                // do nothing -- great success
            }, function(error) {
                console.log(error);
            });

            $scope.meeting.set('RFP', false);
        };
    },
];
