import {NgModel} from 'model/ngModel';
import moment from "moment"

export const FAIR_TYPE_CAREER = 'career';
export const FAIR_TYPE_ENROLLMENT = 'enrollment';

export const UserFair = NgModel.extend({
    _name: 'Fair',
    defaults: {
        label: '',
        type: FAIR_TYPE_CAREER,
        isGlobal: true,
        eventDateStart: (new moment()).add(7, 'days').hour(10).minute(0).second(0).toDate(),
        eventDateEnd: (new moment()).add(10, 'days').hour(10).minute(0).second(0).toDate(),
        daysBefore: '7',
        daysAfter: '7',
        audienceProgramid: null
    },
    urlRoot: '/fairs/[id]',
    parse: function(data) {
        const DATE_FORMAT = "MMM Do YYYY";
        const NOW = new Date();
        let dataFormatted = this._super('parse', data);

        const eventDateStart = new moment(data.eventDateStart);
        const eventDateEnd = new moment(data.eventDateEnd);
        const accessDateStart = new moment(data.accessDateStart);
        const accessDateEnd = new moment(data.accessDateEnd);

        dataFormatted.eventDateStart = eventDateStart.toDate();
        dataFormatted.eventDateEnd = eventDateEnd.toDate();
        dataFormatted.accessDateStart = accessDateStart.toDate();
        dataFormatted.accessDateEnd = accessDateEnd.toDate();

        dataFormatted.eventDateStartFormatted = eventDateStart.format(DATE_FORMAT);
        dataFormatted.eventDateEndFormatted = eventDateEnd.format(DATE_FORMAT);
        dataFormatted.accessDateStartFormatted = accessDateStart.format(DATE_FORMAT);
        dataFormatted.accessDateEndFormatted = accessDateEnd.format(DATE_FORMAT);

        dataFormatted.dateCreated = this.getDate(dataFormatted.dateCreated);

        dataFormatted.visibleDates = dataFormatted.accessDateStartFormatted + " - " + dataFormatted.accessDateEndFormatted;
        dataFormatted.eventDates = dataFormatted.eventDateStartFormatted + " - " + dataFormatted.eventDateEndFormatted;

        if (data.dateCanceled) {
            dataFormatted.visible = "CANCELED"
        } else {
            dataFormatted.visible = dataFormatted.accessDateStart < NOW && dataFormatted.accessDateEnd > NOW ? "Yes" : "No";
        }

        dataFormatted.isGlobal = this.asBoolean(dataFormatted.isGlobal);

        this.__bindManyForAngular(dataFormatted);
        return dataFormatted;
    },
});
