import {NgModel} from './ngModel';
import {ProfileUser} from 'model/profileUser';
import {Meeting} from './meeting';
import * as moment from 'moment-timezone';

export const Review = NgModel.extend({
    _name: 'Review',
    associations: {
        User: ProfileUser,
        Meeting,
        comment: undefined
    },
    parse: function(data) {
        const dataFormatted = this._super('parse', data);

        if (data.dateCreated) {
            dataFormatted.dateCreated = this.getDate(data.dateCreated);
            const momentDate = moment.tz(data.dateCreated, moment.tz.guess());
            dataFormatted.dateCreatedFormatted = momentDate.format('ddd M[/]D');

            dataFormatted.timeSinceFormatted = momentDate.fromNow(true) + ' ago';
        }

        if (dataFormatted.User) {
            dataFormatted.cdnPicture = 'asset/img/avatars/' + dataFormatted.User.userid + '/75.jpg';
        }

        dataFormatted.overall = parseInt(dataFormatted.overall);

        this.__bindManyForAngular(dataFormatted);

        return dataFormatted;
    },
});
