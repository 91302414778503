import {CustomInputs} from 'collection/customInputs';
import {TYPE_TEXT} from 'model/customInputText.js';
import {TYPE_FILE} from 'model/customInputFile.js';
import {TYPE_MULTIPLE_CHOICE} from 'model/customInputMultipleChoice.js';
import {CUSTOM_INPUT_FILE_CLEAR} from 'directives/customInputFile';

const EDIT_PROFILE_CUSTOM_SECTION = 'edit_profile_custom';

export const CustomInputService = [
    '$http', '$q', '$timeout', '$rootScope',
    function($http, $q, $timeout, $rootScope) {
        return {
            customInputs: new CustomInputs(),
            customInputPromise: null,
            visitorPromise: null,

            uploadCustomInputResponseFiles(filesByCustomInputId, filesMap) {
                const deferred = $q.defer();

                if (angular.equals(filesByCustomInputId, {})) {
                    $timeout(() => deferred.resolve());
                    return deferred.promise;
                }

                const formData = new FormData();
                Object
                    .keys(filesByCustomInputId)
                    .forEach((customInputId) => {
                        filesByCustomInputId[customInputId].forEach((f, i) =>
                            formData.append(`${customInputId}_${i}`, f)
                        )
                    });
                const isFormDataEmpty = formData.entries().next().done;
                if (isFormDataEmpty) {
                    $timeout(() => deferred.resolve());
                    return deferred.promise;
                }

                $http
                    .post('/api/custom-inputs/file', formData, {
                        headers: {
                            'Content-Type': undefined // important to upload file
                        }
                    })
                    .then(({data}) => {
                        const {UserFilesByCustomInputId} = data.data;
                        Object.keys(UserFilesByCustomInputId).forEach(customInputFileId => {
                            UserFilesByCustomInputId[customInputFileId].forEach(uf => {
                                if (!filesMap[customInputFileId]) {
                                    filesMap[customInputFileId] = [];
                                }
                                filesMap[customInputFileId].push({
                                    userFileId: uf.id,
                                    value: uf.fileName,
                                    customInputFileId: customInputFileId,
                                    url: uf.url
                                })
                            })
                        });

                        $rootScope.$broadcast(CUSTOM_INPUT_FILE_CLEAR);

                        deferred.resolve();
                    })
                    .catch((err) => {
                        deferred.reject(err);
                    });

                return deferred.promise;
            },
            fetchVisitorResponses() {
                if (this.visitorPromise) {
                    return this.visitorPromise;
                }

                const deferred = $q.defer();
                $http.get('/api/custom-inputs-for-visitor.json').then((response) => {
                    deferred.resolve(response.data.data.CustomInputs);
                }, function(err) {
                    deferred.reject(err);
                });

                this.visitorPromise = deferred.promise;
                return this.visitorPromise;
            },
            getCustomInputs() {
                if (this.customInputPromise) {
                    return this.customInputPromise;
                }

                const deferred = $q.defer();
                $http.get('/api/custom-inputs.json').then((response) => {
                    this.customInputs = new CustomInputs(response.data.data.CustomInputs);
                    deferred.resolve(this.customInputs);
                }, function(err) {
                    deferred.reject(err);
                });

                this.customInputPromise = deferred.promise;
                return this.customInputPromise;
            },
            getCustomInputsForAlgorithm() {
                // Raw data for algorithm. (E.g. not a collection.)
                const deferred = $q.defer();
                $http.get('/api/custom-inputs-for-algorithm.json').then((response) => {
                    deferred.resolve(response.data.data.CustomInputs);
                }, function(err) {
                    deferred.reject(err);
                });
                return deferred.promise;
            },
            hasEditProfileCustomSection(customInputs) {
                if (this.customInputs) {
                    for (const section in this.customInputs.sections) {
                        if (section === EDIT_PROFILE_CUSTOM_SECTION) {
                            return true;
                        }
                    }
                }
                return false;
            },
            mergeCustomInputPlaceholdersIntoRegistrationData(registrationData) {
                if (!registrationData.CustomInputs) {
                    registrationData.CustomInputs = {};
                }

                if (this.customInputs) {
                    for (const section in this.customInputs.sections) {
                        for (const input of this.customInputs.sections[section].inputs) {
                            if (!registrationData.CustomInputs[input.type]) {
                                registrationData.CustomInputs[input.type] = {};
                            }
                            if (!registrationData.CustomInputs[input.type][input.id]) {
                                registrationData.CustomInputs[input.type][input.id] = null;
                            }
                        }
                    }
                }

                return registrationData;
            },
            /**
             * Take objects whose keys are customInput IDs and values are objects with a "responses" child element.
             *
             * Custom Inputs will restrict booking if an Expert has one or more CustomInputs with "matchRequiredForBooking" === true and the consumer does not match
             * a response on any of them. (That is, a single match on any custom input grants access.)
             *
             * Returns null if nothing is blocking booking, or a list of CustomInput labels that are blocking booking.
             *
             * @param consumerProfileCustomInputs
             * @param expertProfileCustomInputs
             * @returns array
             */
            customInputsRestrictingBooking(consumerProfileCustomInputs, expertProfileCustomInputs) {
                let requiresMatch = false;

                let customInputsRequiringMatch = [];

                // Loop through the Expert's custom inputs to see if any require matches.
                for (const customInputId in expertProfileCustomInputs) {
                    const expertCustomInput = expertProfileCustomInputs[customInputId];
                    if (!expertCustomInput.matchRequiredForBooking) {
                        // This one doesn't.
                        continue;
                    }

                    // Found one that does. First see if the consumer has no response set at all.
                    requiresMatch = true;
                    customInputsRequiringMatch.push(expertCustomInput.label);
                    if (!consumerProfileCustomInputs || !consumerProfileCustomInputs[customInputId]) {
                        // No match. Look for a different expert custom input that might grant access.
                        continue;
                    }

                    // Add all the lookup values to an array to be matched after.
                    const expertResponseValues = this.extractResponsesFromProfileCustomInput(expertCustomInput, expertCustomInput.type);
                    const consumerResponseValues = this.extractResponsesFromProfileCustomInput(consumerProfileCustomInputs[customInputId], expertCustomInput.type);

                    if (expertResponseValues.filter(responseValue => consumerResponseValues.includes(responseValue)).length > 0) {
                        // There is a match! Booking is definitively NOT restricted by customInput.
                        return null;
                    }
                }

                // At this point, (a) no match requirement existed, (b) we found a match and already returned true, or (c) a match was required but we didn't find one.
                if (requiresMatch === true) {
                    return customInputsRequiringMatch;
                } else {
                    return null;
                }
            },
            extractResponsesFromProfileCustomInput(profileCustomInput, type)
            {
                let responseValues = [];

                for (const response of profileCustomInput.responses) {
                    switch (type) {
                        case TYPE_FILE:
                            responseValues.push(response.label);
                            break;
                        case TYPE_TEXT:
                            responseValues.push(response.label);
                            break;
                        case TYPE_MULTIPLE_CHOICE:
                            responseValues.push(response.customInputMultipleChoiceOptionid);
                            break;
                    }
                }

                return responseValues;
            },
        };
    },
];
