export function paypalForm() {
    return {
        restrict: 'A',
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/consultations/paypalForm.html',
        controller: [
            '$scope', 'Meeting', 'PaypalForm', '$sce', function($scope, Meeting, PaypalForm, $sce) {
                Meeting.getByRouteParams().then(function(meeting) {
                    if (meeting.get('consumerCost') > 0) {
                        PaypalForm.get().then(function(paypalForm) {
                            $scope.paypalForm = paypalForm;
                            $scope.actionUrl = $sce.trustAsResourceUrl(paypalForm.get('action'));
                        });
                    }
                });
            },
        ],
    };
}

