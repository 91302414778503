import * as moment from 'moment-timezone';
import {Timezone as TimezoneModel} from '../model/timezone';
import {timezones as Timezones} from 'timezones';
import * as _ from 'underscore';
import {getGoogleMaps} from 'googleMaps';

export const TimezonesService = [
    '$http',
    function($http) {
        // we use this to cache the device's timezone so that other application can know if they've changed the timezone
        var deviceTimezone;
        var timezones;

        function getTimezoneStringOrDefault(timezone, useLocalDefault) {
            var timezoneString;
            if (!timezone) {
                timezoneString = useLocalDefault
                    ? retVal.getLocalTimezone().locationCode
                    : retVal.getDeviceTimezone().locationCode;
            } else if (timezone instanceof TimezoneModel) {
                timezoneString = timezone.locationCode;
            } else { // assume it's a string of the locationCode
                timezoneString = timezone;
            }
            return timezoneString;
        }

        var retVal = {
            /**
             * This function will return a list of all timezones formatted to fit nicely into a select element
             * @returns {Array}
             */
            getTimezones: function() {
                if (timezones) {
                    return timezones;
                }
                timezones = [];
                _.each(Timezones, function(ts, group) {
                    if (group == -1) {
                        _.each(ts, function(t) {
                            t.continent = 'GMT';
                        });
                    }
                    _.each(ts, function(t, key) {
                        t.label = (t.isLocation ? t.city : t.label) + ' (' + t.abbreviation + ')';
                        ts[key] = new TimezoneModel(t);
                    });
                    timezones = _.union(timezones, ts);
                });

                return timezones;
            },

            getLocationObjectFromCode: function(code) {
                var timezones = retVal.getTimezones();
                var tzObject;
                _.every(timezones, function(t) {
                    if (t.locationCode === code) {
                        tzObject = t;
                        // return false to break
                        return false;
                    }
                    if (t.locationCode === 'Etc/GMT') {
                        // we can set the deviceTZ (return value) here because we break when we find it
                        // which means we haven't found it yet, and if we do find it after we'll overwrite it.
                        tzObject = t;
                    }
                    return true;
                });

                if (tzObject.locationCode === 'Etc/GMT' && code !== 'GMT' && code !== 'Etc/GMT') {
                    // We didn't find it right; let's try again with a Continent/City lookup.
                    var split = code.split('/');
                    var continent = split[0];
                    var city = split[split.length - 1];
                    _.every(timezones, function(t) {
                        if (t.continent === continent && t.city === city) {
                            tzObject = t;
                            // return false to break
                            return false;
                        }
                        return true;
                    });
                }

                return tzObject;
            },

            getLocationObjectFromAbbreviation: function(abbreviation) {
                var timezones = retVal.getTimezones();
                var tzObject;
                _.every(timezones, function(t) {
                    if (t.abbreviation === abbreviation) {
                        tzObject = t;
                        // return false to break
                        return false;
                    }
                    if (t.abbreviation === 'GMT') {
                        // we can set the deviceTZ (return value) here because we break when we find it
                        // which means we haven't found it yet, and if we do find it after we'll overwrite it.
                        tzObject = t;
                    }
                    return true;
                });

                return tzObject;
            },

            getLocationObjectFromMomentGuess: function(deviceTimezoneLabel) {
                if (deviceTimezoneLabel !== 'GMT' && deviceTimezoneLabel !== 'Etc/GMT') {
                    var locationObject = retVal.getLocationObjectFromCode(deviceTimezoneLabel);
                    if (locationObject.locationCode === 'Etc/GMT') {
                        // Something went wrong mapping to our database. Moment thinks we're not-GMT, but our database
                        // only found GMT... Let's look up a similar abbreviation at least.
                        var momentAbbr = moment.tz(deviceTimezoneLabel).zoneAbbr();
                        locationObject = retVal.getLocationObjectFromAbbreviation(momentAbbr);
                    }
                    return locationObject;
                } else {
                    return retVal.getLocationObjectFromCode(deviceTimezoneLabel);
                }
            },

            getLocalTimezone: function() {
                var deviceTimezoneLabel = moment.tz.guess();
                return retVal.getLocationObjectFromMomentGuess(deviceTimezoneLabel);
            },

            getCoordsTimezone: function(lat, lng) {
                let apiKey;
                if (!window.firsthand || !window.firsthand.googleMapsApiKey || !getGoogleMaps()) {
                    // No API key, or Google unreachable.
                    return null;
                } else {
                    apiKey = window.firsthand.googleMapsApiKey;
                }
                var params = {
                    location: lat + ',' + lng,
                    key: apiKey,
                    timestamp: Math.floor(((new Date()).getTime() / 1000)),
                };
                var options = {
                    url: 'https://maps.googleapis.com/maps/api/timezone/json',
                    method: 'GET',
                    params: params,
                };
                return $http(options);
            },

            getProxiedGooglePlaceTimezone: function(googlePlace) {
                var params = {
                    placeid: googlePlace.placeid,
                    label: googlePlace.label,
                };
                var options = {
                    url: '/api/google-proxy-timezone.json',
                    method: 'GET',
                    params: params,
                };
                return $http(options);
            },

            /**
             * This function will return a timezone object corresponding to this device's preferred location
             * NOTE: At first, it will return the device geographic location, but if changed using setDeviceTimezone,
             * it will return that preferred timezone
             * @returns {*}
             */
            getDeviceTimezone: function() {
                if (deviceTimezone) {
                    return deviceTimezone;
                }

                return retVal.getLocalTimezone();
            },

            /**
             * Set the preferred Timezone for this device
             * @param tz
             */
            setDeviceTimezone: function(tz) {
                deviceTimezone = tz;
            },

            /**
             * Reset the preferred timezone back to the default (according to current geographic location)
             * @returns {*}
             */
            refreshTimezone: function() {
                retVal.setDeviceTimezone(null);
                return retVal.getDeviceTimezone();
            },

            /**
             * Returns a date object given a date object and a timezone.
             * This will remove a timezone offset from the Device's Local Time onto the passed date object
             * ie, Dec 14th, 2016 00:00:00 EST, and "PST" will return a date object with datetime representing Dec
             * 14th, 2016 00:00:00 PST
             * @param {Date} date
             * @param {*|string=} toTimezone If omitted, we use the current device timezone
             * @param {*|string=} startTimezone If omitted, we assume the locale timezone
             * @returns {Date}
             */
            getDateAndTimeWithOtherTimezone: function(date, toTimezone, startTimezone) {
                if (!date) {
                    return date;
                }
                var startTimezoneString = getTimezoneStringOrDefault(startTimezone, true);
                var toTimezoneString = getTimezoneStringOrDefault(toTimezone);

                var dateMoment = moment(date);
                var another = dateMoment.clone();

                dateMoment.tz(startTimezoneString);
                another.tz(toTimezoneString);
                another.add(dateMoment.utcOffset() - another.utcOffset(), 'minutes');

                return another.toDate();
            },

            /**
             * Returns a date object given a date object and a timezone.
             * This will apply a timezone offset from the Device's Local Time onto the passed date object
             * ie, Dec 14th, 2016 00:00:00 EST, and "PST" will return a date object with datetime representing Dec
             * 13th, 2016 21:00:00 EST
             * @param {Date} date
             * @param {*|string=} toTimezone If omitted, we use the current device timezone
             * @param {*|string=} startTimezone If omitted, we assume the locale timezone
             * @returns {Date}
             */
            getDateAndTimeInOtherTimezone: function(date, toTimezone, startTimezone) {
                if (!date) {
                    return date;
                }

                var startTimezoneString = getTimezoneStringOrDefault(startTimezone, true);
                var toTimezoneString = getTimezoneStringOrDefault(toTimezone);
                var dateMoment = moment(date);
                var another = dateMoment.clone();

                dateMoment.tz(startTimezoneString);
                another.tz(toTimezoneString);
                another.subtract(dateMoment.utcOffset() - another.utcOffset(), 'minutes');

                return another.toDate();
            },

            /**
             * @param {Date?} d
             * @returns {Date}
             */
            getSunday: function(d) {
                d = new Date(d);
                var diff = d.getDate() - d.getDay();
                d.setHours(0, 0, 0, 0);
                return retVal.getDateAndTimeWithOtherTimezone(new Date(d.setDate(diff)));
            },

            /**
             * @param {Date} d
             * @returns {moment[]}
             */
            getWeek: function(d) {
                const sunday = retVal.getSunday(d);
                const tz = retVal.getDeviceTimezone().locationCode;

                const week = [
                    moment(new Date(sunday)).tz(tz),
                ];

                // we load 6 more days, each 1 day after the last
                for (let i = 0; i < 6; i++) {
                    week.push(moment(new Date(sunday)).add(i + 1, 'days').tz(tz));
                }

                return week;
            },
        };

        // we start off by instantiating the cached timezone variable to the device's current location
        retVal.setDeviceTimezone(retVal.getDeviceTimezone());

        return retVal;
    },
];
