import {whitelabelling as Whitelabelling} from 'whitelabelling';

/**
 * This directive packages the job openings panel items
 * Use like:
 * <job-opening job-opening="..."></job-opening>
 */
export function jobOpening() {
    return {
        restrict: 'E',
        scope: {
            data: '=jobOpening',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/directives/jobOpening.html',
        replace: true,
        controller: [
            '$scope', '$location', function($scope, $location) {
                $scope.whitelabelling = Whitelabelling;
                $scope.requestMeeting = function() {
                    // ---------------------------------------------------------
                    // I'm sorry, but we need to get this out and I'm not sure an easier way to pass this to the
                    // booking controller  ^ ...Isaac!! What the fuck!? ^ -Craig
                    window.HACKY_JOB_LABEL = $scope.data.title;
                    const userID = $scope.data.contactUserID ? $scope.data.contactUserID : $scope.data.userid;
                    // ---------------------------------------------------------
                    $location.path(`/profile/${userID}/book/service/55`);
                };
            },
        ],
    };
}
