import moment from "moment";

export function groupEvent() {
    return {
        restrict: 'E',
        scope: {
            'event': '=',
        },
        templateUrl: '/asset/partials/user/directives/groupEvent.html',
        replace: true,
        controller: ['$scope', '$routeParams', ($scope, $routeParams) => {
            let startDate = moment($scope.event.startDate);
            let endDate = moment($scope.event.endDate);

            if (startDate.format('YYYY-MM-DD') === endDate.format('YYYY-MM-DD')) {
                $scope.event.dateLabel = startDate.format('MMMM Do YYYY | hh:mm A')
                    + " - " + endDate.format("hh:mm A");
            } else {
                $scope.event.dateLabel = startDate.format('MMMM Do YYYY hh:mm A')
                    + " - " + endDate.format("MMMM Do YYYY hh:mm A");
            }
        }]
    };
}
