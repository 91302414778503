export function forwardClick() {
    return {
        restrict: 'A',
        scope: {
            targetSelector: '@',
        },
        link: function(scope, element) {
            element.on('click', function(e) {
                angular.element(e.target).siblings(scope.targetSelector).trigger('click');
            });
        },
    };
}
