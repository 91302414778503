import {AbstractProfileCollection} from './abstractProfileCollection';
import {JobOpening} from '../model/jobOpening';

export const JobOpenings = AbstractProfileCollection.extend({
    _name: 'JobOpenings',
    model: JobOpening,
    urlRoot: '/job-openings/get.json',
    isComplete: function(datesOptional) {
        if (this.length === 0) {
            return false;
        }

        var retVal = true;
        this.every(function(e) {
            retVal = e.isComplete(datesOptional);
            return retVal;
        });

        return retVal;
    },
});
