/**
 * This directive will float as a fixed header to the bottom of the page up until it would overlap with the site header
 * in which case it sticks to the header
 * <div sticky-header></div>
 */
const FLOATING_CLASS = 'sticky-window';
const STUCK_CLASS = 'sticky-page';

export function stickyHeader() {
    return {
        restrict: 'A',
        scope: {},
        controller: [
            '$scope', '$window', '$timeout', '$document', function($scope, $window, $timeout, $document) {
                // this stores the breakpoint (whereby the header changes styles) according to the actor values
                var breakPoint = false;
                var overFlowPoint = 0;
                var viewHeight = 0;
                var elemHeight = 0;
                var containerHeight = 0;
                var isStuck = false;

                // this function determine the primary actor heights
                function recalculate() {
                    if (!$scope.$elem) {
                        return;
                    }
                    elemHeight = $scope.$elem.outerHeight();
                    viewHeight = $scope.$scrollContainer.innerHeight();
                    containerHeight = $scope.$contentContainer.height();

                    // recalculate the breakpoint
                    var offset = $scope.$elem.position();
                    // 15 px is a margin-top
                    breakPoint = offset.top - 15;

                    // the overflow amount is the basically just the amount the sticky header hangs off the bottom of
                    // the window frame we will use this value to determine when to stop sticking the header (so we can
                    // see the bottom)
                    overFlowPoint = containerHeight - elemHeight - breakPoint;

                    // call scroll so we can re-determine isStuck or not
                    // NOTE: This may not be needed if resize always fires a scroll event (by virtue of the frame
                    // dimensions changing) I can't be sure, so best to just fire it and be safe
                    updatePosition();
                }

                function updatePosition() {
                    // don't do anything until we know the breakpoint
                    if (breakPoint === false) {
                        recalculate();
                        return;
                    }
                    var scrollAmount = $scope.$scrollContainer.scrollTop();

                    // if we've scrolled far enough up
                    if (scrollAmount < breakPoint) {
                        // and we're not yet stuck to the page,
                        if (!isStuck) {
                            // stick to the page
                            $scope.$elem.css({transform: 'translateY(0)'});
                            $scope.$elem.removeClass(FLOATING_CLASS).addClass(STUCK_CLASS);
                            isStuck = true;
                        }
                    } else {
                        var topAmount = Math.min(overFlowPoint, (scrollAmount - breakPoint));
                        // stick to the window
                        $scope.$elem.css({transform: 'translateY(' + topAmount + 'px)'});
                        if (isStuck) {
                            $scope.$elem.removeClass(STUCK_CLASS).addClass(FLOATING_CLASS);
                            isStuck = false;
                        }
                    }
                }

                // we call recalculate once to ensure we have a starting point
                recalculate();

                // if the window dimensions ever change, we need to recalculate
                $($window).resize(recalculate);

                // if the document height ever changes, we need to recalculate
                $scope.$watch(function() {
                    return $document.height();
                }, recalculate);

                // TODO: THis is an effort to combat changes in the page size (ie, from the nav panel expanding)
                // but it's not perfect. Need to think of a better way to handle that
                $scope.$watch(function() {
                    return $scope.$elem ? $scope.$elem.width() : 0;
                }, function(newVal, oldVal) {
                    $timeout(recalculate, 500);
                });

                // we can also force a refresh
                $scope.$on('recalculateHeader', function() {
                    $timeout(recalculate, 500);
                });

                var once = $scope.$watch('$scrollContainer', function() {
                    // when the container scrolls, we determine where the stickyHeader should be
                    $scope.$scrollContainer.on('scroll', updatePosition);

                    once();
                });

            },
        ],
        link: function(scope, element, attrs, controller) {
            scope.$elem = $(element);

            // TODO: we might want to pass this via an attribute if it's ever not going to be the page-content
            scope.$scrollContainer = $('#page-content');
            scope.$contentContainer = $('#page-content-inner');
        },
    };
}

