import * as angular from 'angular';

// -------------------------------------------------------------------------------------
// show-slide
// use like ng-show --- <div show-slide="isOpen">...</div>
// This combines Angular ngShow with the jQuery slideUp/Down functionality, but also works with auto height elements*
// NOTE: an element with this directive should wrap a single child element
// NOTE: the child element CANNOT be a directive that replaces its placeholder -> if that's the case, consider wrapping it once in an outer div
// -------------------------------------------------------------------------------------
export const setupFirsthandAnimate = () => {
    angular.module('firsthandAnimate', []).directive('showSlide', function() {
        // 200 millisecond transition speed
        var DEFAULT_ANIMATE_SPEED = 200;

        return {
            restrict: "A",
            link: function(scope, element, attrs) {
                var animationSpeed = attrs.speed || DEFAULT_ANIMATE_SPEED;

                // in the case where we decided to open / close before the child was loaded, we store the most recent case in this variable
                // this variable is also used to track our state for if the child height changes while open
                // true => should be open, false => should be closed, undefined => do nothing, we didn't change states yet
                var shouldOpen = undefined;

                // we don't want to be animate if we're already in the process of animating -- this should prevent multiple calls to digest
                var isAnimating = false;

                // we need to check the height change at the end of a digest cycle (in case that digest made any new elements show/hide inside
                var childHeight = 0;

                // store a reference to our element.
                scope.$elem = $(element);

                // we need to get the child element here, but it's probably not loaded so we need to wait for it to be loaded
                var waitingForChild = scope.$watch(function() {
                    return scope.$elem.children().length;
                }, function(newVal) {
                    // if we now have a child
                    if (parseInt(newVal) === 1) {
                        // we store the child
                        scope.$child = scope.$elem.children().first();

                        // seed our childHeight variable
                        childHeight = parseInt(scope.$child.outerHeight());

                        // we stop watching for children
                        waitingForChild();

                        // update
                        update();

                        // watch for changes to the child's height...
                        // this hasTriggered variable will ensure we don't double count the child's height change
                        // in a single digest
                        /** @see https://github.com/angular/angular.js/issues/5828#issuecomment-33020323 */
                        var hasTriggered = false;
                        scope.$watch(function() {
                            if (hasTriggered) {
                                return;
                            }
                            hasTriggered = true;

                            scope.$$postDigest(function() {
                                setTimeout(function() {
                                    hasTriggered = false;
                                    refreshHeight();

                                    // we don't need this call to $apply.
                                    // If the value changed, update is called and will handle calling apply in its step callback function
                                    // if it didn't change, then we have nothing to apply, still no reason to call it
                                    // I'm keeping it here so we don't think it was forgotten
                                    // scope.$apply();
                                }, 50);
                            });
                        });
                    } else {
                        throw "showSlide directive requires exactly 1 child, " + newVal + " found";
                    }
                });

                // watch for changes to our slide condition
                scope.$watch(attrs.showSlide, function(newVal) {
                    shouldOpen = newVal;
                    update();
                });

                // it gets funky when you have show-slide directives inside other show-slide directives
                // so this just makes sure everything ends in the right state
                scope.$on('slide-opened', function(event, emitter) {
                    if (emitter === scope) {
                        return false;
                    }
                    update();
                });
                scope.$on('slide-closed', function(event, emitter) {
                    if (emitter === scope) {
                        return false;
                    }
                    update();
                });

                // determines whether to open or close based on the state of the showSlide variable
                function update() {
                    if (isAnimating) {
                        return;
                    }
                    if (shouldOpen) {
                        open();
                    } else if (shouldOpen !== undefined) {
                        close();
                    }
                }

                function open() {
                    // if we don't have a child yet
                    if (!scope.$child) {
                        // we mark what state we should be in once we get one
                        shouldOpen = true;
                        return;
                    }

                    scope.$emit('slide-opening', scope);
                    isAnimating = true;

                    // set the height to the child's height
                    scope.$elem.animate({height: childHeight + 'px'}, {
                        duration: animationSpeed,
                        step: function() {
                            // we call apply each step in case we need to update heights of our parents
                            scope.$applyAsync();
                        },
                        complete: function() {
                            isAnimating = false;
                            // only when we're totally open, do we remove the overflow hidden style
                            scope.$elem.css({overflow: ''});
                            scope.$emit('slide-opened', scope);
                        }
                    });
                }

                function close() {
                    // if we don't have a child yet
                    // technically we don't need this check since the child's height is not used to calculate the closed height,
                    // but I have it here for consistency with open + one day we might?
                    if (!scope.$child) {
                        // we mark what state we should be in once we get one
                        shouldOpen = false;
                        return;
                    }

                    // as we're closing, we set overflow hidden so that their containing children's content does not show
                    scope.$elem.css({overflow: 'hidden'});

                    scope.$emit('slide-closing', scope);
                    isAnimating = true;

                    // set the height to 0
                    scope.$elem.animate({height: '0px'}, {
                        duration: animationSpeed,
                        step: function() {
                            // we call apply each step in case we need to update heights of our parents
                            scope.$applyAsync();
                        },
                        complete: function() {
                            isAnimating = false;
                            scope.$emit('slide-closed', scope);
                        }
                    });
                }

                function refreshHeight() {
                    var newHeight = parseInt(scope.$child.outerHeight(true));
                    if (newHeight !== childHeight) {
                        childHeight = newHeight;
                        if (shouldOpen) {
                            open(newHeight);
                        }
                    }
                }
            }
        };
    });
};
