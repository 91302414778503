import {Backbone} from 'Vertebrae';
import {Meeting} from 'model/meeting';

export const Meetings = Backbone.Collection.extend({
    _name: 'Meetings',
    model: Meeting,
    url: '/consultations.json',
    comparator: function(m) {
        if (m.getGroupDate()) {
            return m.getGroupDate().getTime();
        }
        return 0;
    },
});
