import {whitelabelling as Whitelabelling} from 'whitelabelling';

export function userInputAffinityGroupsEdit() {
    return {
        restrict: 'E',
        scope: {
            data: '=',
            category: '=',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/userInputs/userInputAffinityGroupsEdit.html',
        controller: [
            '$scope', '$rootScope', function($scope, $rootScope) {
                $scope.whitelabelling = Whitelabelling;
                $scope.filteredGroups = [];
                $scope.pickedGroups = {
                    value: [],
                };
                $scope.modalRemovePendingGroup = null;
                $scope.modalRemoveGroup = null;

                // we only want this to run once after the data collection is defined
                var dataWatch = $scope.$watch('data', function(newVal) {
                    if (!newVal) {
                        return;
                    }

                    // listen for removals to our groups array and show
                    $scope.data.on('update', function() {
                        if (!$scope.category) {
                            return;
                        }
                        $scope.pickedGroups.value = $scope.data.where(
                            {'affinityGroupCategoryid': parseInt($scope.category.get('id'))});
                    });

                    // Initial trigger.
                    $scope.data.trigger('update');

                    dataWatch();
                });

                // watch for additions via the select
                $scope.$watch('pickedGroups.value', function(newVal, oldVal) {
                    if ($scope.data) {
                        // make sure we've added a new group, all other times we ignore it
                        if (newVal && oldVal && newVal.length > oldVal.length) {
                            $scope.data.add(newVal);
                            $scope.groupsErrors = false;
                        }
                    } else {
                        console.log('no data');
                    }
                });

                $scope.removeGroup = function(g, force) {
                    if ($scope.data) {
                        if (!force && g.isRestricted) {
                            if ($scope.isPending(g)) {
                                $scope.modalRemovePendingGroup = g;
                                return;
                            } else if ($scope.isAccepted(g)) {
                                $scope.modalRemoveGroup = g;
                                return;
                            }
                        }
                        $scope.modalRemoveGroup = null;
                        $scope.modalRemovePendingGroup = null;
                        $scope.data.remove(g);
                    }
                };

                $scope.selectItem = function($item) {
                    if (!$item.isRestricted) {
                        $item.isAccepted = true;
                    } else {
                        $item.isPending = true;
                    }
                };

                $scope.isAccepted = function(g) {
                    return g.affinityGroupCategoryid === parseInt($scope.category.get('id')) && g.isAccepted;
                };
                $scope.isPending = function(g) {
                    return g.affinityGroupCategoryid === parseInt($scope.category.get('id')) && g.isPending;
                };
                $scope.isRejected = function(g) {
                    return g.affinityGroupCategoryid === parseInt($scope.category.get('id')) && g.isRejected;
                };
            },
        ],
    };
}

