import {pickBestReverseGeocode} from 'configurations/utilities';
import {getGoogleMaps} from 'googleMaps';

export function userInputCurrentLocation() {
    return {
        restrict: 'E',
        scope: {
            data: '=',
            displayType: '@?',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/registration/directives/userInputs/userInputCurrentLocation.html',
        controller: [
            '$scope', '$geolocation', 'TimezonesService', function($scope, $geolocation, TimezonesService) {
                // Returns null if Google.
                const googleMaps = getGoogleMaps();

                $scope.tryingToGuess = false;

                $scope.$watch('data', function() {
                    if ($scope.data) {
                        if (googleMaps && (!$scope.data.currentLocation ||
                            (!$scope.data.currentLocation.placeid && !$scope.data.currentLocation.label))) {
                            $scope.tryingToGuess = true;
                            $geolocation.getCurrentPosition({timeout: 300000}).then(function(position) {
                                $scope.tryingToGuess = false;

                                if (position) {
                                    $scope.setLocationFromLatLng(position.coords.latitude, position.coords.longitude);
                                }
                            }, function() {
                                // The request to guess the user's location expired. No worries.
                                $scope.tryingToGuess = false;
                            });
                        }
                    }
                });

                $scope.$watch('data.currentLocation', function() {
                    if ($scope.data && $scope.data.currentLocation) {
                        if (googleMaps && $scope.data.currentLocation.lat && $scope.data.currentLocation.lng) {
                            // Lookup Timezone from Google Maps.
                            TimezonesService.getCoordsTimezone($scope.data.currentLocation.lat, $scope.data.currentLocation.lng).then(function({data: {status, timeZoneId, errorMessage}}) {
                                // Success.
                                if (status === 'OK') {
                                    $scope.data.timezone = timeZoneId;
                                } else {
                                    console.error(errorMessage);
                                }
                            }, function(error) {
                                // Error.
                                console.error(error);
                            })
                        } else if ($scope.data.currentLocation && $scope.data.currentLocation.placeid && $scope.data.currentLocation.label) {
                            // Lookup by Proxy.
                            TimezonesService.getProxiedGooglePlaceTimezone($scope.data.currentLocation).then(
                                function({data: {timeZoneId}}) {
                                    if (timeZoneId) {
                                        $scope.data.timezone = timeZoneId;
                                    } else {
                                        console.error('Could not look up timezone by proxy.');
                                    }
                                });
                        }
                    }
                });

                $scope.setLocationFromLatLng = function(lat, lng) {
                    const latLng = new googleMaps.LatLng(lat, lng);
                    const geocoder = new googleMaps.Geocoder();
                    geocoder.geocode({'location': latLng}, function(results, status) {
                        if ($scope.data.currentLocation && $scope.data.currentLocation.label) {
                            // If the user entered a location in the meanwhile, let's just... pretend like we
                            // never tried to guess it...
                            return;
                        }

                        if (status === 'OK') {
                            if (results[1]) {
                                $scope.$applyAsync(function() {
                                    $scope.googlePlace = pickBestReverseGeocode(results);
                                });
                            } else {
                                // No results.
                            }
                        } else {
                            console.error('Geocoder failed due to: ' + status);
                        }
                    });
                };
            },
        ],
    };
}

