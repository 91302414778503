/**
 * This directive lets you assign a directive to your scope object as the propertyname indicated as the attribute value
 * Use like:
 * <div ... scope-element="SCOPE_VARIABLE_NAME" ...></div>
 * -- access with $scope["SCOPE_VARIABLE_NAME"]
 *
 * @see http://stackoverflow.com/questions/15881453/angularjs-accessing-dom-elements-inside-directive-template
 */
export function scopeElement() {
    return {
        restrict: 'A',
        compile: function compile(tElement, tAttrs, transclude) {
            return {
                pre: function preLink(scope, iElement, iAttrs, controller) {
                    scope.$parent[iAttrs.scopeElement] = iElement;
                },
            };
        },
    };
}

