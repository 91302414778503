import {NgModel} from './ngModel';

const UNLIMITED_CUTOFF = 10000;
const unlimitedCap = value => value > UNLIMITED_CUTOFF ? UNLIMITED_CUTOFF : value;

export const Exposure = NgModel.extend({
    _name: 'Exposure',
    UNLIMITED_CUTOFF: UNLIMITED_CUTOFF,
    defaults: {
        limit_Network: null, // let the server decide the defaults based on whitelabel settings
        limit_Contract_0: null,
        limit_Contract_20: null,
        limit_Contract_40: null,
        limit_Public: null,
        publicRate: null,
        useSmartRate: true,
        paymentIsPaypal: true,
        paymentPaypalEmail: null,
        paymentAddress: null,
    },
    parse: function(data) {
        var dataFormatted = this._super('parse', data);

        dataFormatted.limit_Network = unlimitedCap(data.limit_Network);
        dataFormatted.limit_Contract_0 = unlimitedCap(data.limit_Contract_0);
        dataFormatted.limit_Contract_20 = unlimitedCap(data.limit_Contract_20);
        dataFormatted.limit_Contract_40 = unlimitedCap(data.limit_Contract_40);
        dataFormatted.limit_Public = unlimitedCap(data.limit_Public);

        this.__bindManyForAngular(dataFormatted);
        return dataFormatted;
    },
    isComplete: function() {
        return this.limit_Network > 0 || this.limit_Contract_0 > 0
            || this.limit_Contract_20 > 0 || this.limit_Contract_40 > 0
            || this.limit_Public > 0;
    },
    validateData: function() {
        var errors = {};
        this.set('errors', errors);
        return errors;
    },
});
