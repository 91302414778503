import {NgModel} from './ngModel';
import {Navigations} from '../collection/navigations';

export const Navigation = NgModel.extend({
    _name: 'Navigation',
    associations: {
        Navigations,
    },
    defaults: {
        isSelected: false,
        isOpen: false,
        url: null,
        label: null,
        'class': null,
        isTopLevel: false,
        isNewDropDownSection: false,
    },
});
