import {whitelabelling as Whitelabelling} from 'whitelabelling';

export function userInputProgramRestriction() {
    return {
        restrict: 'E',
        scope: {
            data: '=',
            displayType: '@?',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/userInputs/userInputProgramRestriction.html',
        controller: [
            '$scope', function($scope) {
                $scope.whitelabelling = Whitelabelling;

                $scope.onlyFairs = false;
                $scope.noun = 'program';
                if ($scope.data.Programs.where({isFair: false}).length === 0) {
                    // Contains non-fair programs.
                    $scope.onlyFairs = true;
                    $scope.noun = 'fair';
                }

                // Initialize checkbox.
                $scope.willConsultOutsideOfProgram = !$scope.data.restrictToPrograms;

                $scope.$watch('willConsultOutsideOfProgram', function(newVal) {
                    $scope.data.restrictToPrograms = !newVal;
                });
            },
        ],
    };
}

