export function resourceCard() {
    return {
        restrict: 'E',
        scope: {
            resource: '=',
            hideTags: '=?',
            forceShowParticipantId: '=?',
            imageWidth: '=?'
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/directives/resourceCard.html',
        controller: [
            '$scope', '$http', function ($scope, $http) {
                $scope.hideTags = $scope.hideTags === true;
                $scope.imageWidth = !$scope.imageWidth ? 250 : $scope.imageWidth;

                if ($scope.forceShowParticipantId && $scope.resource.participantUserIds.length > 1) {
                    // force the participant to be shown in the first place
                    let userIdIndex = -1;
                    $scope.resource.participantUserIds.forEach((el, i) => {
                        if (parseInt(el) === parseInt($scope.forceShowParticipantId)) {
                            userIdIndex = i;
                        }
                    })

                    const fields = ['participantUserIds', 'participantUserLabels', 'participantUserAvatars'];
                    fields.forEach((field) => {
                        let swap = $scope.resource[field].splice(userIdIndex, 1)[0]
                        $scope.resource[field].unshift(swap);
                    })
                }

                $scope.toggleBookmark = function (e) {
                    e.preventDefault();
                    e.stopPropagation();

                    $http.post('/resources/' + $scope.resource.id + '/bookmarks');
                    $scope.resource.bookmarked = !$scope.resource.bookmarked;
                }
            }
        ]
    };
}

