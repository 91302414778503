import {NgModel} from './ngModel';

export const GeneralAvailability = NgModel.extend({
    _name: 'GeneralAvailability',
    defaults: {
        hour: null,
        day: null,
        expertid: null,
    },
    parse: function(data) {
        var dataFormatted = this._super('parse', data);

        // the id is always a con
        dataFormatted.id = this.getIdFromHourAndDay(dataFormatted.day, dataFormatted.hour);

        this.__bindManyForAngular(dataFormatted);
        return dataFormatted;
    },
    getIdFromHourAndDay: function(day, hour) {
        return '' + day + '-' + hour;
    },
});
