import {whitelabelling as Whitelabelling} from 'whitelabelling';
import * as _ from 'underscore';
import {SearchResults as SearchResultCollection} from '../collection/searchResults';
import {SearchFilters as SearchFilterCollection} from '../collection/searchFilters';
import {capitalizeFirst} from 'configurations/utilities';

export const SearchController = [
    '$scope', '$window', '$rootScope', '$location', '$q', '$timeout', 'RegistrationService', '$http', '$analytics',
    function($scope, $window, $rootScope, $location, $q, $timeout, RegistrationService, $http, $analytics) {
        const startingQuery = $location.search();
        $window.document.title = capitalizeFirst(Whitelabelling.expertNounPlural);
        $scope.querying = false;
        $scope.loading = true;
        $scope.numberPerPage = 10;
        $scope.page = 0;
        $scope.queryCount = 0;
        $scope.showAdvancedFilters = false;

        $rootScope.pageClass = 'expert-search-container search-container';
        $scope.whitelabelling = Whitelabelling;
        $scope.showAllCount = false;
        $scope.totalResultsCount = null;
        if (Whitelabelling.settings['searchShowAllCount']) {
            $scope.showAllCount = true;
        }

        $scope.filters = new SearchFilterCollection();
        $scope.results = new SearchResultCollection();
        $scope.results.alumniPlatformid = $scope.whitelabelling.amp ? $scope.whitelabelling.amp.id * 1 : null;
        $scope.searchRfp = {
            message: '',
            email: '',
            sending: false,
            sent: false,
        };

        $scope.orderByKeys = () => {
            const orderedKeys = Whitelabelling.settings['customized:searchFilterOrderList'];
            return item => {
                if (orderedKeys) {
                    const pos = orderedKeys.indexOf(item.key);
                    if (pos >= 0) {
                        return pos - orderedKeys.length;
                    }
                }
                return item;
            };
        };

        $scope.sendSearchRfp = () => {
            $scope.searchRfp.sending = true;
            $http.post('/api/search/rfp.json', {
                email: $scope.searchRfp.email,
                message: $scope.searchRfp.message,
                search: $location.search(),
            }).then(() => {
                $scope.searchRfp.sent = true;
                $scope.searchRfp.sending = false;
            }, responseOjb => {
                $scope.searchRfp.sending = false;
                $scope.searchRfp.error = responseOjb.data.error;
            });
        };

        $scope.performSearchOnValueChange = () => {
            $scope.performSearch(true);
        };

        $scope.performSearchOnPageChange = () => {
            $scope.performSearch(false);
        };

        $scope.performSearch = reset => {
            $scope.searchRfp.sent = false;
            $scope.results.reset();
            $scope.querying = true;

            if (reset) {
                $scope.page = 0;
            }

            $q.when($scope.results.search($scope.filters, $scope.numberPerPage, $scope.page)).then(res => {
                $scope.totalResultsCount = res.data.totalResultsCount;
                $analytics.eventTrack('User Search', { 'category': 'Platform Search', 'label': $scope.filters['keyword'] });
                $scope.alumniPlatformid = $scope.results.alumniPlatform;
                const data = $scope.filters.convertToQueryObject();
                $scope.querying = false;

                // we take this moment to update our query count on the advanced-toggle-icon
                let count = 0;
                _.each(data, d => {
                    count += Array.isArray(d) ? d.length : !!d;
                });
                $scope.queryCount = count;

                // rebuild our parameters
                // we call replace so that we don't add a new history event (so our back button still navigates away
                // from search)
                $location.replace();
                if (data.price) {
                    data.price_min = data.price.min;
                    data.price_max = data.price.max;
                    delete data.price;
                }
                $location.search(data);
            }, () => {
                $scope.querying = false;
            });
        };

        $scope.filters.fetch().then(() => {
            $scope.filters.each(filter => {
                if (filter.key === 'employers') {
                    filter.set('prefixHook', RegistrationService.employersPrefixComplete);
                } else if (filter.key === 'functions') {
                    filter.set('prefixHook', RegistrationService.rolesPrefixComplete);
                } else if (filter.key === 'industries') {
                    filter.set('prefixHook', RegistrationService.industriesPrefixComplete);
                } else if (filter.key === 'schools') {
                    filter.set('prefixHook', RegistrationService.schoolsPrefixComplete);
                } else if (filter.key === 'languages') {
                    filter.set('prefixHook', RegistrationService.languagePrefixComplete);
                } else if (filter.key === 'fields-of-study') {
                    filter.set('prefixHook', RegistrationService.concentrationsPrefixComplete);
                } else if (filter.key === 'degrees') {
                    filter.set('prefixHook', RegistrationService.degreesPrefixComplete);
                } else {// do nothing
                }
            });

            $scope.networkFilter = $scope.filters.findWhere({key: 'network'});
            if ($scope.networkFilter) {
                $scope.networkFilter.set({
                    noSection: true,
                    ignoreInLoop: true,
                });
            }

            // add the available now field
            $scope.availableFilter = $scope.filters.findWhere({key: 'available'});
            if ($scope.availableFilter) {
                $scope.availableFilter.set({
                    noSection: true,
                    ignoreInLoop: true,
                });
            }

            // add the programs field
            $scope.programsFilter = $scope.filters.findWhere({key: 'programs'});
            if ($scope.programsFilter) {
                // if there's no options, it means they're only in one program, so we want to make it a checkbox like
                // the available now filter. Otherwise, we leave it as a group in the Filters section
                $scope.programsFilter.set({
                    noSection: true,
                    ignoreInLoop: true,
                });
            }

            // add the keyword field
            $scope.keywordFilter = $scope.filters.findWhere({key: 'keyword'});
            if ($scope.keywordFilter) {
                $scope.keywordFilter.set({
                    noSection: true,
                    inputPlaceholder: 'Search by keyword',
                    ignoreInLoop: true,
                });
            }

            // start off with our query params loaded into our filters
            $scope.filters.loadFromQueryObject(startingQuery);

            $scope.filters.on('change:value', $scope.performSearchOnValueChange);

            // this will handle the initial search because this will always fire a change when the watcher is bound
            $scope.$watch('page', $scope.performSearchOnPageChange);

            // this is kind of a nice-to-have which makes sure our sub-filters have the correct count
            // it needs to happen after some other stuff loads, so I put 2 second timeout on it
            $timeout(() => {
                $scope.filters.each(f => {
                    f.refreshValue(true);
                });
            }, 2000);
        }).always(() => {
            $scope.$emit('controllerLoaded', true);
            $scope.loading = false;
        });
    },
];
