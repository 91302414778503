import {GroupBaseController} from './groupBase';

export class GroupMembersController extends GroupBaseController {
    static $inject = ['$scope', '$rootScope', '$routeParams', '$q', '$window', '$http', 'VisitorService', '$analytics'];

    filterUsersByName = null;

    constructor($scope, $rootScope, $routeParams, $q, $window, $http, VisitorService, $analytics) {
        super($scope, $rootScope, $routeParams, $q, $window, $http, VisitorService, $analytics);
        $scope.position = 3;

        $scope.$on('controllerLoaded', () => {
            $window.document.title = $scope.isFair ? 'Booth Members' : 'Group Members';
        })
    }

    filterUsers = member => {
        if (this.filterUsersByName) {
            if (!member.fullName) {
                return false;
            } else {
                return member.fullName.toLowerCase().includes(this.filterUsersByName.toLowerCase());
            }
        }
        if (this.scope.filterRole === 'all') {
            return true;
        }
        if (this.scope.filterRole === 'consumer') {
            return member.isConsumer;
        }
        if (this.scope.filterRole === 'expert') {
            return member.isExpert;
        }
    }
}
