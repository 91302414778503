import {Backbone} from 'Vertebrae';
import * as _ from 'underscore';

const promises = {};

export const NgModel = Backbone.Model.extend({
    initialize: function(opt) {
        if (this.defaults) {
            opt = _.extend(_.extend({}, this.defaults), opt);
        }
        _.each(opt, (value, key) => {
            this.__bindForAngular(key, value);
        });
    },
    set: function(key, value) {
        // if they pass an object (as backbone allows), we bind each key:value pair to angular
        if (typeof key === 'object') {
            this.__bindManyForAngular(key);
        } else {
            this.__bindForAngular(key, value);
        }
        return this._super('set', key, value);
    },
    parse: function(data) {
        data = this._super('parse', data);
        this.__bindManyForAngular.call(this, data);
        return data;
    },
    __bindManyForAngular: function(data) {
        _.each(data, (value, key) => {
            this.__bindForAngular(key, value);
        });
    },
    __bindForAngular: function(key, value) {
        if (typeof key !== 'string' || _.has(this, key)) {
            return;
        }
        this[key] = value;
        const that = this;
        Object.defineProperty(that, key, {
            get: function() {
                return that.get(key);
            },
            set: function(value) {
                that.set(key, value);
            },
            enumerable: true,
            configurable: true,
        });
    },
    // this is a convenience function that converts dates (as sent by the server) into JavaScript Date objects
    getDate: function(date) {
        let string;
        if (!date) {
            return null;
        } else if (date instanceof Date) {
            string = date.toISOString();
        } else if (typeof date === 'object') {
            string = date.date;
        } else if (typeof date === 'number') {
            string = date.toString();
        } else {
            string = date;
        }

        if (!string) {
            return date;
        }

        // if it's an ISO date, we strip the milliseconds
        const plusLocation = string.indexOf('+');
        if (!!~plusLocation) {
            string = string.substring(0, plusLocation);
        }

        if (string.indexOf(' ') !== -1 || string.indexOf('T') !== -1) {
            // This is probably a datetime string.
            return new Date(string.replace(' ', 'T').replace('Z', '') + 'Z');
        } else {
            // Otherwise just assume Date() can handle it.
            return new Date(string);
        }
    },

    cleanForCsvExport: function(attr) {
        if (typeof this.get(attr) === 'string') {
            return this.get(attr).replace(/\s+/g, ' ').replace(/"/g, '\'').trim();
        } else if (typeof this.get(attr) === 'number') {
            return this.get(attr).toString();
        } else {
            this.get(attr);
        }
    },

    asBoolean: function(data) {
        const dataType = typeof data;
        if (dataType === 'boolean') {
            return data;
        } else if (dataType === 'string') {
            const intVal = parseInt(data);
            // this handles our server passing string false (which might happen because... ya know... fml)
            if (isNaN(intVal)) {
                return data && data.toLowerCase() !== 'false';
            } else {
                return !!intVal;
            }
        } else {
            return !!data;
        }
    },

    /*
     * These 2 functions are used to convert this object properties into strings for use in a CSV
     * use like MODEL.getDownloadData().join([delimeter])
     * NOTE: the headers array should always match the data array
     */
    getDownloadHeaders: function() {
        return [];
    },
    getDownloadData: function() {
        return [];
    },
    /**
     * This fetch override attempts to prevent making the same request multiple times by checking
     * if a fetch for that model is currently outstanding. Not quite a cache, but similar.
     * @param options
     * @return {*}
     */
    fetch: function(options) {
        // if there are options, we don't even begin to decide if the same fetch already exists,
        // best to just ignore this case for now
        if (options) {
            return this._super('fetch', options);
        }

        // otherwise, we identify a model fetch by its name and ID
        const promiseKey = this._name + this.get('id');
        if (promises[promiseKey]) {
            return promises[promiseKey];
        }

        promises[promiseKey] = this._super('fetch');
        promises[promiseKey].then(function() {
            delete promises[promiseKey];
        }, function() {
            delete promises[promiseKey];
        });

        return promises[promiseKey];
    },
});
