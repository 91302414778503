import {NgModel} from './ngModel';
import * as _ from 'underscore';

export const MeetingTime = NgModel.extend({
    _name: 'MeetingTime',
    defaults: {
        isSelected: false,
        justBeforeNoon: false,
        isDisabled: false,
        colSpan: 1,
    },
    parse: function(data) {
        const dataFormatted = this._super('parse', data);

        if (dataFormatted.compareTo) {
            _.every(dataFormatted.compareTo, function(selectedMeetingTime) {
                dataFormatted.isSelected = selectedMeetingTime.get('date').getTime() === dataFormatted.date.getTime();

                return !dataFormatted.isSelected;
            });
            dataFormatted.compareTo = undefined;
        }

        dataFormatted.isAM = !(dataFormatted.date.getHours() >= 12);
        dataFormatted.isPM = !dataFormatted.isAM;

        this.__bindManyForAngular(dataFormatted);
        return dataFormatted;
    },
});
