export function TimeSwath(startDate, duration) {
    var endDate;

    function clone(obj) {
        return new TimeSwath(obj.getStartDate(), obj.getEndDate());
    }

    function recalc() {
        duration = (endDate.getTime() - startDate.getTime()) / 1000;
    }

    startDate = new Date(startDate);
    if (duration instanceof Date) {
        endDate = new Date(duration);
    } else {
        duration = duration ? duration : 0;
        endDate = new Date(startDate.getTime() + (duration * 1000));
    }
    recalc();

    // SETTERS ------------------------------------------------------------------------
    this.setStartDate = function(d) {
        startDate = new Date(d);
        recalc();
    };

    this.setEndDate = function(d) {
        endDate = new Date(d);
        recalc();
    };

    // GETTERS ------------------------------------------------------------------------
    this.clone = function() {
        return clone(this);
    };

    this.getStartDate = function() {
        return startDate;
    };

    this.getEndDate = function() {
        return endDate;
    };

    this.getDurationInMinutes = function() {
        return this.getDurationInSeconds() / 60;
    };

    this.getDurationInSeconds = function() {
        return duration;
    };

    this.getDurationInHours = function() {
        return this.getDurationInSeconds() / 3600;
    };

    this.getDurationInMilliseconds = function() {
        return this.getDurationInSeconds() * 1000;
    };

    this.recalc = recalc;

    // TIME SWATH LOGIC FUNCTION ------------------------------------------------------
    /**
     * @param {TimeSwath} t
     * @returns {boolean}
     */
    this.contains = function(t) {
        return (this.getStartDate() <= t.getStartDate() && this.getEndDate() >= t.getEndDate());
    };

    this.combine = function(t) {
        if (!this.overlaps(t)) {
            throw 'Cannot combine TimeSwaths';
        }

        // we extend this range to include the aggregate range of this and t
        this.setEndDate(Math.max(this.getEndDate(), t.getEndDate()));
        this.setStartDate(Math.min(this.getStartDate(), t.getStartDate()));

        return this;
    };

    /**
     * @param {TimeSwath} t
     * @param {boolean?} exclusive
     * @returns {boolean}
     */
    this.overlaps = function(t, exclusive) {
        if (!t instanceof TimeSwath) {
            throw 'Parameter object must be time swath';
        }

        if (exclusive === true) {
            // if this partially overlaps t
            return (this.getStartDate() > t.getStartDate() && this.getStartDate() < t.getEndDate())
                // or if t partially overlaps this
                || (t.getStartDate() > this.getStartDate() && t.getStartDate() < this.getEndDate());
        }

        // if this partially overlaps t
        return (this.getStartDate() >= t.getStartDate() && this.getStartDate() <= t.getEndDate())
            // or if t partially overlaps this
            || (t.getStartDate() >= this.getStartDate() && t.getStartDate() <= this.getEndDate());
    };

    /**
     * @param {TimeSwath} t
     * @returns {TimeSwath[]}
     */
    this.disjoin = function(t) {
        var retVal = [];
        var thisClone = clone(this);

        if (t.contains(this)) {
            // do nothing, we want retVal empty
        } else if (!this.overlaps(t)) {
            // we return this timeswath untouched
            retVal.push(thisClone);
        } else {
            // if this start is greater than the other, we shrink the box so the new start is the last one's end
            if (this.getStartDate() >= t.getStartDate()) {
                thisClone.setStartDate(Math.max(this.getStartDate(), t.getEndDate()));
                retVal.push(thisClone);
                // if this end is less than the other, we shrink the box the other way
            } else if (this.getEndDate() <= t.getEndDate()) {
                thisClone.setEndDate(Math.min(this.getEndDate(), t.getStartDate()));
                retVal.push(thisClone);
            } else {
                // we clone this block [again]
                var newBlock = clone(this);

                // we set this block's range from original start to $t's start
                thisClone.setEndDate(t.getStartDate());
                // and we set the new block's range from $t's end to the original end
                newBlock.setStartDate(t.getEndDate());

                // we add them both the return array
                retVal.push(thisClone);
                retVal.push(newBlock);
            }
        }
        return retVal;
    };

    /**
     * @param {TimeSwath} t
     * @returns {TimeSwath}
     */
    this.intersect = function(t) {
        if (!this.overlaps(t)) {
            return null;
        }

        var start = new Date(Math.max(t.getStartDate().getTime(), this.getStartDate().getTime()));
        var end = new Date(Math.min(t.getEndDate(), this.getEndDate().getTime()));

        return new TimeSwath(start, end);
    };
}
