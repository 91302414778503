export const profileSyncSectionRow = {
    templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/components/profileSyncSectionRow.html',
    transclude: {
        our: 'our',
        their: 'their',
    },
    bindings: {
        row: '<',
    },
};
