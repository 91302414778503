import {whitelabelling as Whitelabelling} from 'whitelabelling';

export function userInputCareerGoalsLocationsEdit() {
    return {
        restrict: 'E',
        scope: {
            data: '=',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/userInputs/userInputCareerGoalsLocationsEdit.html',
        controller: [
            '$scope', function($scope) {
                $scope.whitelabelling = Whitelabelling;
                $scope.MAX_TO_ADD = 10;

                $scope.locationsSelector = {
                    value: null,
                };

                $scope.removeLocation = function(l) {
                    $scope.data.remove(l);
                };

                $scope.$on('showErrors', function() {
                    $scope.noInputs = true;
                });

                // because we can add multiple concentrations, we have the intermediate value to store the
                // currently selecting one
                $scope.$watch('locationsSelector.value', function(newVal) {
                    if (newVal && newVal.placeid) {
                        $scope.noInputs = false;
                        $scope.data.add(newVal);
                        $scope.locationsSelector.value = '';
                    }
                });
            },
        ],
    };
}

