import {DirectivesModule} from 'directives/module';
import {profileLinkedinSyncButton} from './profileLinkedinSyncButton';
import {profileSyncAvatar} from './profileSyncAvatar';
import {profileSyncEducation} from './profileSyncEducation';
import {profileSyncEducationItem} from './profileSyncEducationItem';
import {profileSyncItem} from './profileSyncItem';
import {profileSyncItemPseudoRadio} from './profileSyncItemPseudoRadio';
import {profileSyncItemPseudoRadios} from './profileSyncItemPseudoRadios';
import {profileSyncItemSelector} from './profileSyncItemSelector';
import {profileSyncLanguages} from './profileSyncLanguages';
import {profileSyncPersonalInfo} from './profileSyncPersonalInfo';
import {profileSyncSection} from './profileSyncSection';
import {profileSyncSectionRow} from './profileSyncSectionRow';
import {profileSyncValuesPair} from './profileSyncValuesPair';
import {profileSyncWorkExperience} from './profileSyncWorkExperience';
import {profileSyncWorkExperienceItem} from './profileSyncWorkExperienceItem';

export default DirectivesModule.
    component('profileLinkedinSyncButton', profileLinkedinSyncButton).
    component('profileSyncAvatar', profileSyncAvatar).
    component('profileSyncEducation', profileSyncEducation).
    component('profileSyncEducationItem', profileSyncEducationItem).
    component('profileSyncItem', profileSyncItem).
    component('profileSyncItemPseudoRadio', profileSyncItemPseudoRadio).
    component('profileSyncItemPseudoRadios', profileSyncItemPseudoRadios).
    component('profileSyncItemSelector', profileSyncItemSelector).
    component('profileSyncLanguages', profileSyncLanguages).
    component('profileSyncPersonalInfo', profileSyncPersonalInfo).
    component('profileSyncSection', profileSyncSection).
    component('profileSyncSectionRow', profileSyncSectionRow).
    component('profileSyncValuesPair', profileSyncValuesPair).
    component('profileSyncWorkExperience', profileSyncWorkExperience).
    component('profileSyncWorkExperienceItem', profileSyncWorkExperienceItem);
