import {AffinityGroupForSearch} from '../model/affinityGroupForSearch';
import {GroupBaseController} from './groupBase';

export class DiscussionDetailController extends GroupBaseController {
    static $inject = ['$scope', '$rootScope', '$routeParams', '$q', '$window', '$http', 'VisitorService'];

    answer = {
        message: null,
        link: null,
    };

    constructor($scope, $rootScope, $routeParams, $q, $window, $http, VisitorService) {
        super($scope, $rootScope, $routeParams, $q, $window, $http, VisitorService);
        this.scope = $scope;
        this.groupid = $routeParams.groupid;
        this.discussionid = $routeParams.discussionid;
        this.http = $http;
        this.q = $q;
        $window.document.title = 'Post';
        $scope.isLoading = true;
        $scope.groupid = this.groupid;

        $scope.descriptionTruncated = true;
        $scope.showMore = false;
        $scope.TRUNCATE_LENGTH_GROUP_DESCRIPTION = 180;
        $scope.TRUNCATE_LENGTH_OTHER_TEXT = 150;

        let group = new AffinityGroupForSearch();
        group.setGroupId(this.groupid);

        this.discussionsUrl = '/group/' + $routeParams.groupid + '/discussion/overview?limit=0';
        this.membersUrl = '/group/' + $routeParams.groupid + '/members-profile/get.json?limit=0';

        $q.all([
            group.fetch(),
            VisitorService.getVisitor(),
            this.http.get('group/' + this.groupid + '/discussion/' + this.discussionid),
            this.http.get(this.discussionsUrl),
            this.http.get(this.membersUrl),
        ]).then(([{data: {AffinityGroup}}, activeUser, {data: {data: {Question, Answers}}}, {data: {data: {Discussions}}}, {data: {data: {Members}}}]) => {
            this.scope.selectedGroup = AffinityGroup;
            this.scope.totalNumberOfDiscussions = Discussions.Count;
            this.scope.totalNumberOfMembers = Members.Count;
            this.activeUser = activeUser;
            this.discussion = Question;

            if (this.discussion === 'Deleted') {
                this.error = true;
            } else {
                this.error = false;
                this.answers = Answers;
                this.numberOfAnswers = this.answers.length;
                this.scope.isLoading = false;
            }
            this.scope.$emit('controllerLoaded', true);
        });
    }
}
