import {whitelabelling as Whitelabelling} from 'whitelabelling';

export function userInputPlatformAffiliation() {
    return {
        restrict: 'E',
        scope: {
            data: '=',
            primaryRole: '<?',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/registration/directives/userInputs/userInputPlatformAffiliation.html',
        controller: [
            '$scope', $scope => {
                $scope.goToNextSubStep = () => {
                    $scope.$emit('goToNextSubStep');
                };

                const getAffiliations = () => Whitelabelling.settings[`customized:affiliation:${$scope.primaryRole}`] !== ''
                    ? Whitelabelling.settings[`customized:affiliation:${$scope.primaryRole}`]
                    : Whitelabelling.settings['customized:affiliation:both'];

                $scope.affiliations = getAffiliations();

                $scope.setAffiliation = (affiliation) => {
                    if (affiliation.value === 'none') {
                        $scope.goToNextSubStep();
                    } else {
                        $scope.data.platformAffiliation = affiliation.value;
                    }
                };
            },
        ],
    };
}

