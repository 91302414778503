import {FiltersModule} from 'filters/module';
import {betterNoBreak} from 'filters/betterNoBreak';
import {capitalizeFirst} from 'filters/capitalizeFirst';
import {collectionWhere} from 'filters/collectionWhere';
import {formatMoment} from 'filters/formatMoment';
import {an} from 'filters/indefiniteArticle';
import {nl2br} from 'filters/nl2br';
import {objLimitTo} from 'filters/objLimitTo';
import {pluralize} from 'filters/pluralize';
import {swathHourlyRange} from 'filters/swathHourlyRange';

export default FiltersModule.
    filter('objLimitTo', objLimitTo).
    filter('nl2br', nl2br).
    filter('formatMoment', formatMoment).
    filter('collectionWhere', collectionWhere).
    filter('pluralize', pluralize).
    filter('an', an).
    filter('betterNoBreak', betterNoBreak).
    filter('capitalizeFirst', capitalizeFirst).
    filter('swathHourlyRange', swathHourlyRange);
