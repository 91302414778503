export const MeetingState_Both_Prepare = [
    '$scope', '$timeout', function($scope, $timeout) {
        var oneMinute = 60 * 1000;
        var tenMinutes = 10 * oneMinute;
        var _PREPARE_STATE = 4;
        var loop = null;

        var update = function() {
            var now = new Date();
            var startDate = $scope.meeting.get('selectedTimeSwath').getStartDate();
            var startsIn = startDate.getTime() - now.getTime();

            // if we're 10 or fewer minutes away from the meeting start time, we show the prepare_late view
            if (startsIn < tenMinutes) {
                // we round up because 0:30 should read as 1:00 minute
                $scope.minutesTillStart = Math.ceil(startsIn / oneMinute);
                $scope.template = Evisors.config.cdnRootWhitelabel + 'asset/partials/consultations/meetingStates/both/prepare_late.html';
                if (startsIn <= 0) {
                    // if it's supposed to have started, we need to update the meeting so it can load the correct view
                    $scope.meeting.fetch().then(function() {
                        // it's "possible" that our JS clock ran faster, so we make sure the state actually did change
                        // before cancelling the polling.
                        // The actual transition will be handled by the MeetingState Controller
                        if ($scope.meeting.get('MeetingState').get('status') !== _PREPARE_STATE) {
                            $timeout.cancel(loop);
                        }
                    });
                    $scope.minutesTillStart = 0;
                }

                // check again in one second
                loop = $timeout(update, 1000); //one second
            } else {
                // we show the regular prepare view
                $scope.template = Evisors.config.cdnRootWhitelabel + 'asset/partials/consultations/meetingStates/both/prepare.html';

                // check again in one minute
                loop = $timeout(update, oneMinute);
            }
        };
        update();

        // the date table likes the date data as an array, so we construct one with just 1 element
        $scope.selectedDates = [$scope.meeting.get('selectedTimeSwath')];

        $scope.durationInSeconds = $scope.meeting.get('durationInSeconds');
        $scope.consultationType = $scope.meeting.get('consultationType');

        // whether or not to show the reschedule / cancel dialogs
        $scope.showReschedule = false;
        $scope.showCancel = false;
    },
];
