export default class AbstractHandler {
    /**
     * Your implementation should return true if the your handler caught and processed the error
     * otherwise return false
     *
     * @return boolean
     */
    run(exception, cause = null) {
        throw new Error('You have to implement this method in your subclass!');
    }
}
