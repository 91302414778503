/**
 * This directive lets you assign a directive to your scope object as the propertyname indicated as the attribute value
 * Use like:
 * <loading-block></loading-block>
 */
export function loadingDots() {
    return {
        restrict: 'E',
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/loadingDots.html',
        scope: {
            size: '@size',
            color: '@color',
            isLoading: '=?',
        },
        controller: [
            '$scope', function($scope) {
                // check if the loading animation flag was passed
                $scope.passedLoading = $scope.isLoading !== undefined;

                // if it's not passed, it's true
                $scope.isLoading = $scope.passedLoading ? $scope.isLoading : true;
            },
        ],
        compile: function() {
            return {
                pre: function(scope) {
                    if (!scope.size) {
                        scope.size = 1;
                    }

                    if (!scope.color) {
                        scope.color = '#333';
                    }
                },
            };
        },
    };
}
