import {NgModel} from "../../angular-utilities/model/ngModel";

export const ResourceSearchResult = NgModel.extend({
    _name: 'Resource',
    parse: function(data) {
        let dataFormatted = this._super('parse', data);

        if (data.description.indexOf('<span class="highlight">') > -1) {
            dataFormatted.descPreview = data.description;
        } else {
            dataFormatted.descPreview = data.description.length > 150 ?
                data.description.substr(0, 150).trim() + "..." :
                data.description;
        }

        return dataFormatted;
    }
});
