import {Conversation} from '../model/conversation';
import {Client as TwilioConversationClient} from '@twilio/conversations';

export class ChatService {
    static $inject = ['$q', '$rootScope', 'VisitorService', '$http'];
    // the keys to this object are user id's and the values are cached conversation between that user and the current user
    conversations = {};
    client = null;

    pendingMessages = [];
    myUserId = null;

    constructor($q, $rootScope, VisitorService, $http) {
        this.q = $q;
        this.rootScope = $rootScope;
        this.VisitorService = VisitorService;
        this.http = $http;
    }

    async getMyUserid() {
        const deferred = this.q.defer();

        if (this.myUserId) {
            deferred.resolve(this.myUserId);
        } else {
            deferred.reject(null);
        }

        return deferred.promise;
    };

    getConversationWithUser(otherUserid) {
        const that = this;
        if (this.conversations[otherUserid]) {
            return this.q.when(this.conversations[otherUserid]);
        } else {
            return this.http.get('/api/chat/conversations/get_or_create.json', {params: {otherUserid: otherUserid}}).then(response => {
                const newConversation = new Conversation(response.data.data.Conversation, {parse: true});
                that.conversations[otherUserid] = newConversation;
                if (otherUserid.toString() === newConversation.user2id.toString()) {
                    that.myUserId = newConversation.user1id.toString();
                } else {
                    that.myUserId = newConversation.user2id.toString();
                }
                return that.conversations[otherUserid];
            }, error => {
                const message = 'Failed to get Conversation data: ' + error;
                console.error(message);
                return $q.reject(message);
            });
        }
    };

    async getConversation(conversationSid) {
        const client = await this.getClient();
        try {
            return await client.getConversationBySid(conversationSid);
        }
        catch (e) {
            console.error(e);
            throw e;
        }
    };

    /**
     * @param {Conversation} conversation
     * @param {string} messageBody
     */
    async sendMessage(conversation, messageBody) {
        const messageHash = this.messageHash(messageBody);

        this.pendingMessages.push(messageHash);

        this.rootScope.$broadcast('chatMessageSent', conversation.sid, messageBody, messageHash);
        const messageIndex = await conversation.sendMessage(messageBody);
        this.rootScope.$evalAsync(() => {
            this.rootScope.$broadcast('chatMessageDelivered', conversation.sid, messageHash, messageIndex);
        });
    }

    checkIfSuccessfulDelivery(newMessageFromTwilio) {
        if (newMessageFromTwilio.state.author !== this.myUserId) {
            // It's not our message.
            return;
        }
        if (this.pendingMessages.length > 0) {
            const receivedMessageHash = this.messageHash(newMessageFromTwilio.state.body);
            if (this.pendingMessages.includes(receivedMessageHash)) {
                // Remove this message's hash from our list of pending messages.
                this.pendingMessages = this.pendingMessages.filter(item => item !== receivedMessageHash);

                // Return the current unique ID of this pending message.
                return receivedMessageHash;
            }
        }
    };

    async getClient() {
        const that = this;
        if (this.client) {
            return this.client;
        }

        const token = await this.getToken();
        this.client = await new TwilioConversationClient(token);

        this.client.on('connectionStateChanged', newState => {
            that.rootScope.$evalAsync(() => {
                that.rootScope.$broadcast('chatConnectionStateChanged', newState);
            });
        });

        this.client.on('tokenAboutToExpire', () => {
            that.getToken().then(token => {
                that.client.updateToken(token);
            });
        });

        this.client.on('messageAdded', message => {
            if (message.attributes.type === 'meeting') {
                return;
            }
            that.rootScope.$evalAsync(() => {
                that.rootScope.$broadcast('chatMessageAdded', message);
                that.rootScope.$broadcast('refreshNavBadges');
            });
        });

        // Used for when someone's read-state changes.
        this.client.on('participantUpdated', memberUpdate => {
            const {participant: {identity, conversation: {sid}, lastReadMessageIndex}, updateReasons} = memberUpdate;
            if (updateReasons.includes('lastReadMessageIndex')) {
                that.rootScope.$evalAsync(() => {
                    that.rootScope.$broadcast('messageReadIndexChange', sid, identity, lastReadMessageIndex);
                });
            }
        });

        return this.client;
    };

    async getToken() {
        try {
            const {data: {identityToken}} = await this.http.get('/api/chat/token.json');
            return identityToken;
        }
        catch (e) {
            let message = 'Failed to get challenge identityToken: ' + e;
            console.error(message);
            throw e;
        }
    };

    markAllAsRead(conversationObj) {
        const that = this;
        this.rootScope.$broadcast('markAllChatsRead');

        this.getConversation(conversationObj.sid).then(twilioConversation => {
            twilioConversation.setAllMessagesRead().then();
        });

        that.rootScope.$broadcast('refreshNavBadges');
    };

    messageHash(message) {
        let hash = 5381;
        const str = this.myUserId + ':' + message;
        let i = str.length;
        while (i) {
            hash = (hash * 33) ^ str.charCodeAt(--i);
        }
        return hash >>> 0;
    };
}
