/**
 * This directive lets you assign a directive to your scope object as the propertyname indicated as the attribute value
 * Use like:
 * <loading-block></loading-block>
 */
export function loadingBlock() {
    return {
        restrict: 'AE',
        scope: true,
        transclude: true,
        replace: true,
        compile: function() {
            return {
                pre: function(scope) {
                    if (!scope.size) {
                        scope.size = 1;
                    }
                },
            };
        },
        template: function(tElem, tAttrs) {
            const size = tAttrs['size'] ?? 1;

            const inner = '<div class="loading-block-ui"><span class="loading-block-ui-spinner fa fa-circle-o-notch fa-spin" ng-style="{ \'font-size\': \'' +
                size + 'em\'}"></span></div>';
            if (tAttrs.loadingBlock) {
                return '<div class="loading-block-enabled"><div ng-transclude></div><div ng-show="' +
                    tAttrs.loadingBlock + '" class="loading-block-container">' + inner + '</div></div>';
            } else {
                return '<div class="loading-block-container">' + inner + '</div>';
            }
        },
    };
}
