import {NgModel} from 'model/ngModel';

const PRESENCE_TIME = 15000;

export const MeetingConnectionStatus = NgModel.extend({
    _name: 'MeetingConnectionStatus',
    urlRoot: '/consultations/[meetingid]/connectionStatus.json',
    idAttribute: 'meetingid',
    defaults: {
        meetingid: null,
        consumerIsPresent: false,
        consumerConnectedAudio: false,
        consumerConnectedAudioVoip: false,
        consumerConnectedAudioPhone: false,
        consumerConnectedVideo: false,
        consumerEarlier: false,
        expertIsPresent: false,
        expertConnectedAudio: false,
        expertConnectedAudioVoip: false,
        expertConnectedAudioPhone: false,
        expertConnectedVideo: false,
        expertEarlier: false,
        retrieved: null,
    },
    parse: function(data) {
        var dataFormatted = this._super('parse', data);
        if (dataFormatted.retrieved) {
            dataFormatted.retrieved = new Date(dataFormatted.retrieved * 1000);
        }

        // Set formatted dates and calculate, if it's been <= PRESENCE_TIME milliseconds since they last pinged, that
        // they are "present".
        if (dataFormatted.consumerLastPing) {
            dataFormatted.consumerLastPing = this.getDate(dataFormatted.consumerLastPing);
            if ((dataFormatted.retrieved - dataFormatted.consumerLastPing) <= PRESENCE_TIME) {
                dataFormatted.consumerIsPresent = true;
            }
        }
        if (dataFormatted.expertLastPing) {
            dataFormatted.expertLastPing = this.getDate(dataFormatted.expertLastPing);
            if ((dataFormatted.retrieved - dataFormatted.expertLastPing) <= PRESENCE_TIME) {
                dataFormatted.expertIsPresent = true;
            }
        }

        return dataFormatted;
    },
});
