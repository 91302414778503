import {CUSTOM_INPUT_FILE_MAX_SIZE} from 'directives/customInputFile';

/**
 * Container that switches to provide Custom Inputs for a given section.
 */
export function customInputSection() {
    return {
        restrict: 'E',
        templateUrl: Evisors.config.cdnRootWhitelabel + 'ng-template/partials/angular-utilities/directives/customInputSection.tpl.html',
        scope: {
            section: '=',
            data: '=',
            filterToDelta: '@?',   // Only show one of the inputs in this section, identified by delta (0, 1, 2...)
            filterToKey: '=?',     // Only show one of the inputs in this section, identified by key ("multipleChoice_2")
            displayType: '@?',
            userPrimaryRole: '=?',
        },
        require: 'ngModel',
        controller: [
            '$scope', '$sce', function($scope, $sce) {
                $scope.fileMaxSize = CUSTOM_INPUT_FILE_MAX_SIZE;
                const updateInputs = () => {
                    if ($scope.section) {
                        if ($scope.filterToDelta) {
                            // Use only a single input.
                            if ($scope.section.inputs[$scope.filterToDelta]) {
                                $scope.inputs = [$scope.section.inputs[$scope.filterToDelta]];
                            }
                        } else if ($scope.filterToKey) {
                            $scope.inputs = $scope.section.inputs.filter((inputItem) => {
                                return inputItem.key === $scope.filterToKey;
                            });
                        } else {
                            // Use all inputs.
                            $scope.inputs = $scope.section.inputs;
                        }
                    }
                };

                $scope.$watch('section', () => {
                    if (!$scope.section) {
                        return;
                    }
                    if (!$scope.displayType) {
                        $scope.displayType = 'edit';
                    }

                    updateInputs();

                    if ($scope.inputs && $scope.inputs.length) {
                        $scope.inputs.map((input) => {
                            switch ($scope.userPrimaryRole) {
                                case 'consumer':
                                    input.descriptionTrusted = $sce.trustAsHtml(input.descriptionForConsumer ? input.descriptionForConsumer : input.description);
                                    break;
                                case 'expert':
                                    input.descriptionTrusted = $sce.trustAsHtml(input.descriptionForExpert ? input.descriptionForExpert : input.description);
                                    break;
                                default:
                                    input.descriptionTrusted = $sce.trustAsHtml(input.description);
                                    break;
                            }

                            return input;
                        });
                    }
                });

                $scope.$watch('filterToKey', () => {
                     updateInputs();
                });
            },
        ],
    };
}
