import {Resource} from '../../model/resource';

export function boothResources() {
    return {
        restrict: 'E',
        scope: {
            'resources': '=',
            'abridged': '='
        },
        templateUrl: '/asset/partials/partner/directives/fairs/boothResources.html',
        replace: true,
        controller: [
            '$scope',
            function ($scope) {
                $scope.dragStart = function() {
                    $scope.dragging = true;
                }

                $scope.dragEnd = function() {
                    delete $scope.dragging;
                }

                $scope.addResource = function() {
                    $scope.resources.push(new Resource({resourceType: 'video'}));
                };

                $scope.removeResource = function(i) {
                    $scope.resources.splice(i, 1);
                };
            }
        ]
    }
}
