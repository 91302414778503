import {makeLoader} from 'makeLoader';

let whitelabelling = {
    id: undefined,
    settings: {
        allowRegistrationConsumer: false,
        featureNewRegistrationEnabled: false,
        jobOpeningsEnabled: false,
        passcodeProvisioning: undefined,
        featureNewMeetingsEnabled: undefined,
        isMeetingsV2AppEnabled: undefined,
        featureNewMentorSearchEnabled: undefined,
        pendingExpertsBookable: false,
        concurrentMeetingLimit: 1,
        bookingHourBuffer: 0,
        allowJobsBoard: false,
    },
    contractType: undefined,
    isAMP: undefined,
    isCAP: undefined,
    isHybrid: undefined,
    siteName: undefined,
    expertNounPlural: undefined,
    amp: {
        id: undefined,
    },
    showApi: undefined,
    showHandshake: undefined,
    showJobadder: undefined,
    expertNoun: '',
    consumerNoun: '',
    consumerNounPlural: '',
    affiliatedSchool: undefined,
    hasWebinars: true,
    cdnRootWhitelabel: undefined,
};

whitelabelling = makeLoader('/whitelabelling/get.json', 'whitelabelling', whitelabelling);
whitelabelling.isWhitelabel = () => !!whitelabelling.id;

export {whitelabelling};

