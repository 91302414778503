import {Backbone} from 'Vertebrae';
import {SearchResult} from '../model/searchResult';

export const SearchResults = Backbone.Collection.extend({
    _name: 'results',
    model: SearchResult,
    url: '/api/search/search.json',
    alumniPlatformid: null,
    search: function(searchFilters, perPage, page) {
        const self = this;
        const data = searchFilters.convertToQueryObject();
        return this.fetch({
            data: {
                'filters': data,
                'perPage': perPage || 10,
                'page': page || 0,
            },
            alumniPlatformid: self.alumniPlatformid ? self.alumniPlatformid * 1 : null,
        });
    },
});
