import {NgModel} from './ngModel';
import * as Moment from 'moment-timezone';

export const OutOfOffice = NgModel.extend({
    _name: 'OutOfOffice',
    url: function() {
        var base = '/api/profile/' + this.get('userid') + '/ooo';
        if (this.get('id')) {
            return base + '/' + this.get('id');
        } else {
            return base;
        }
    },
    defaults: {
        userid: null,
        startDate: null,
        endDate: null,
        timezone: null,
        isBeingCancelled: false,
    },
    parse: function(data) {
        var dataFormatted = this._super('parse', data);

        if (!dataFormatted.timezone) {
            dataFormatted.timezone = Moment.tz.guess();
        }

        dataFormatted.startDate = this.getDate(dataFormatted.startTime);
        dataFormatted.startDateFormatted = Moment.tz(dataFormatted.startDate, dataFormatted.timezone).
            format('YYYY-MM-DD');

        dataFormatted.endDate = this.getDate(dataFormatted.finishTime);
        dataFormatted.endDateFormatted = Moment.tz(dataFormatted.endDate, dataFormatted.timezone).format('YYYY-MM-DD');

        this.__bindManyForAngular(dataFormatted);

        return dataFormatted;
    },
    isComplete: function() {
        return this.get('userid') && this.get('startDate') && this.get('endDate');
    },
    validateData: function() {
        var errors = {};

        // Dates not set
        errors.startDate = !this.get('startDate');
        errors.endDate = !this.get('endDate');

        // If start date is set...
        if (!errors.startDate) {
            // Must not be in the past.
            var nowMidnight = this.getDate(new Date());
            var startDateMidnight = new Date(this.get('startDate'));

            nowMidnight.setHours(0, 0, 0, 0);
            startDateMidnight.setHours(0, 0, 0, 0);

            errors.startDateInThePast = startDateMidnight < nowMidnight;

            // If end date is set...
            if (!errors.endDate) {
                var endDateMidnight = this.getDate(this.get('endDate'));
                endDateMidnight.setHours(23, 59, 59, 99);
                // Must be after start date.
                errors.negativeDifference = endDateMidnight <= startDateMidnight;
            }
        }
        errors.userid = !this.get('userid');

        this.set('errors', errors);
        return errors;
    },
});
