import * as moment from 'moment-timezone';

export function upcomingConsultationsTimeline() {
    return {
        restrict: 'E',
        scope: {
            meetings: '=',
            continueAction: '=',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/directives/upcomingConsultationsTimeline.html',
        replace: true,
        controller: [
            '$scope', 'TimezonesService', function($scope, TimezonesService) {
                $scope.offset = 0;
                $scope.columnCount = 3;
                $scope.showNextButton = false;

                if (!$scope.continueAction) {
                    $scope.continueAction = () => {
                        $scope.offset += $scope.columnCount;
                        $scope.showNextButton = ($scope.offset + $scope.columnCount) < $scope.daysWithConsults.length;
                    };
                }

                $scope.$watch('meetings', () => {
                    // group our consultations into days
                    if ($scope.meetings && $scope.meetings.length) {
                        $scope.daysWithConsults = {};
                        $scope.meetings.each(function(m) {
                            const date = moment(m.getGroupDate()).
                                tz(TimezonesService.getDeviceTimezone().locationCode).
                                format('ddd, MMM D');
                            if (!$scope.daysWithConsults[date]) {
                                $scope.daysWithConsults[date] = [];
                            }
                            $scope.daysWithConsults[date].push(m);
                        });

                        $scope.showNextButton = ($scope.offset + $scope.columnCount) < $scope.daysWithConsults.length;
                    } else {
                        $scope.daysWithConsults = false;
                    }
                });
            },
        ],
    };
}

