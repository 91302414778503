import {NgModel} from 'model/ngModel';

export const DonationPrompt = NgModel.extend({
    _name: 'DonationPrompt',
    urlRoot: '/donations/prompt/[type].json',
    defaults: {
        headline: null,
        text: null,
        target: null,
        cta: null,
        type: null,
    },
});
