/**
 * Usage:
 * type: 'question'|'answer'
 * post: message to display in the question template
 * options:
 *  detail:         used to show answers after post in group detail page. Developer should provide answers
 *  leftIdent:      used to ident answers to look fancier
 *  last:           decide if you want to put <hr> element at the end of the question template
 *  activeUser:     used to check whether to show EDIT button when active user is same to the post user
 *  question:       if used for answers, provide question so we can show it in respond modal
 * @returns {{controller: [string, string, function(*, *): void], scope: {answerModal: string, post: string, editModal: string, options: string, type: string}, replace: boolean, restrict: string, templateUrl: string}}
 */
export function question() {
    return {
        restrict: 'E',
        scope: {
            'type': '<',
            'post': '<',
            'options': '<?',
            'answerModal': '&?',
            'editModal': '&?',
        },
        templateUrl: '/asset/partials/user/directives/question.html',
        replace: true,
        controller: ['$scope', '$routeParams', '$http', ($scope, $routeParams, $http) => {
            $scope.isLoading = false;
            let defaultOptions = {
                detail: false,
                leftIdent: false,
                last: false,
            }

            $scope.options = {...defaultOptions, ...$scope.options};
            $scope.groupid = $routeParams.groupid;

            $scope.togglePin = () => {
                $scope.isLoading = true;
                $http.post('/group/' + $scope.post.groupid + '/discussion/' + $scope.post.id + '/toggle-pin').then((res) => {
                    $scope.post.isPinned = res.data.data.Discussion.isPinned ? '1' : '0';
                }).finally(() => {
                    $scope.isLoading = false;
                });
            };
        }]
    };
}
