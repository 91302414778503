import {Event} from "../../model/event";

export function boothEvents() {
    return {
        restrict: 'E',
        scope: {
            'events': '=',
            'abridged': '=',
        },
        templateUrl: '/asset/partials/partner/directives/fairs/boothEvents.html',
        replace: true,
        controller: [
            '$scope',
            function ($scope) {
                $scope.dragStart = function() {
                    $scope.dragging = true;
                }

                $scope.dragEnd = function() {
                    delete $scope.dragging;
                }

                $scope.addEvent = function () {
                    $scope.events.push(new Event({type: 'webinar'}));
                }

                $scope.removeEvent = function (i) {
                    $scope.events.splice(i, 1);
                }
            }
        ]
    }
}
