export const CUSTOM_INPUT_FILE_ADDED = 'CustomInputFileAdded';
export const CUSTOM_INPUT_FILE_CLEAR = 'CustomInputFileClear';
export const CUSTOM_INPUT_FILE_MAX_SIZE = 2 * 1024 * 1024;
export const CUSTOM_INPUT_FILE_MAX_QUEUE = 2;

export function customInputFile() {
    return {
        restrict: 'E',
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/customInputFile.html',
        scope: {
            customInput: '=',
            data: '=',
            multiple: '=?'
        },
        require: 'ngModel',
        controller: [
            '$scope', 'FileUploader', function($scope, FileUploader) {
                $scope.pristine = true;
                $scope.data = $scope.data || [];
                $scope.multiple = $scope.multiple || true;
                if (!$scope.multiple) {
                    if ($scope.data.length > 0) {
                        $scope.data = [$scope.data.shift()];
                    }
                }

                $scope.availableFileFormats = '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,image/png,image/jpg';
                $scope.fileUploader = new FileUploader({queueLimit: CUSTOM_INPUT_FILE_MAX_QUEUE});
                $scope.fileUploader.filters.push(
                    {
                        name: 'fileSizeLimit',
                        fn: function(item) {
                            const maxFileSizeLimitation = item.size > CUSTOM_INPUT_FILE_MAX_SIZE;
                            if (maxFileSizeLimitation) {
                                $scope.customInput.error = `${item.name} is bigger than ${CUSTOM_INPUT_FILE_MAX_SIZE} MB`;
                            } else {
                                delete $scope.customInput.error;
                            }
                            return !maxFileSizeLimitation;
                        },
                    });

                $scope.fileUploader.onWhenAddingFileFailed = (item, filter, options) => {
                    if (filter.name === 'queueLimit') {
                        $scope.customInput.error = `You can add up to ${CUSTOM_INPUT_FILE_MAX_QUEUE} files`;
                    } else {
                        delete $scope.customInput.error
                    }
                    console.info(item, filter, options);
                }

                $scope.removeFile = (file) => {
                    $scope.data = $scope.multiple ? $scope.data.filter(f => f !== file) : [];
                };

                $scope.unAttachFile = (file) => {
                    $scope.fileUploader.queue = $scope.fileUploader.queue.filter(f => f !== file);
                };

                $scope.$watch('fileUploader.queue.length', ( newQueueLength, oldQueueLength) => {
                    if (newQueueLength > 0) {
                        $scope.pristine = false;
                    }
                    let files = [];
                    if ($scope.multiple) {
                        files = $scope.fileUploader.queue.map(qf => qf._file);
                    } else {
                        if (newQueueLength < oldQueueLength) {
                            const {length} = $scope.fileUploader.queue;
                            const fileAdded = $scope.fileUploader.queue[length - 1];
                            files = [fileAdded._file];
                        }
                    }

                    $scope.$emit(
                        CUSTOM_INPUT_FILE_ADDED,
                        $scope.customInput.id,
                        files
                    )

                    if (!$scope.pristine) {
                        $scope.$emit('CustomInputChanged', $scope.customInput, files);
                    }
                });

                $scope.$on(CUSTOM_INPUT_FILE_CLEAR, () => {
                    $scope.fileUploader.cancelAll();
                    $scope.fileUploader.clearQueue();
                });
            }
        ]
    };
}
