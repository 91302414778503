/// @abstract
class ProfileSyncDiffItem {

    /// @abstract
    getChange() {
        throw new Error('Abstract');
    }

}

/// @abstract
class ProfileSyncDiffPairItem extends ProfileSyncDiffItem {

    toggleOur() {
        this.toggle('our');
    }

    toggleTheir() {
        this.toggle('their');
    }

    selectOur() {
        this._select('our', true);
    }

    selectTheir() {
        this._select('their', true);
    }

    toggle(which) {
        this._select(which, null);
    }

    _select(which, value) {
        if (which in this.selection && null !== this.selection[which]) {
            this.selection[which] = value === null ? !this.selection[which] : value;
            if (this.mutualExclusive) {
                const invertedWhich = {our: 'their', their: 'our'}[which];
                if (this.selection[invertedWhich] !== null) {
                    this.selection[invertedWhich] = !this.selection[which];
                }
            }
        }
    }

}

/// @abstract
class ProfileSyncDiff {

    buildPatch() {
        return this.getItems().reduce((patch, item) => {
            let change = item.getChange();
            change && patch.push(change);
            return patch;
        }, []);
    }

    /// @abstract
    getItems() {
        throw new Error('Abstract');
    }

}

class ProfileSyncCollectionDiffItem extends ProfileSyncDiffItem {

    static RADIOS = {
        'our': {
            our: ['keep', 'remove'],
        },
        'their': {
            their: ['add', 'ignore'],
        },
        'neq': {
            our: ['keep'],
            their: ['merge', 'addAsNew'],
        },
        'sim': {
            our: ['keep'],
            their: ['merge', 'addAsNew'],
        },
        'same': {},
    };

    static SELECTIONS = {
        // selection choice -> type -> selection
        keep: {
            'our': {
                our: 'keep',
                their: null,
                selectedOur: true,
            },
            'neq': {
                our: 'keep',
                their: 'ignore',
                selectedOur: true,
                selectedTheir: false,
            },
            'sim': {
                our: 'keep',
                their: 'ignore',
                selectedOur: true,
                selectedTheir: false,
            },
        },
        remove: {
            'our': {
                our: 'remove',
                their: null,
            },
        },
        add: {
            'their': {
                our: null,
                their: 'add',
                selectedTheir: true,
            },
            'neq': {
                our: 'keep',
                their: 'add',
                selectedOur: true,
                selectedTheir: true,
            },
            'sim': {
                our: 'keep',
                their: 'add',
                selectedOur: true,
                selectedTheir: true,
            },
        },
        merge: {
            'neq': {
                our: 'update',
                their: 'merge',
                selectedOur: false,
                selectedTheir: true,
            },
            'sim': {
                our: 'update',
                their: 'merge',
                selectedOur: false,
                selectedTheir: true,
            },
        },
        ignore: {
            'their': {
                our: null,
                their: 'ignore',
                selectedTheir: false,
            },
        },
    };

    static DEFAULT_SELECTION_BY_TYPE = {
        our: 'keep',
        their: 'add',
        neq: 'merge',
        sim: 'merge',
        same: null,
    };

    constructor(diffItem) {
        super();
        this.type = diffItem.type;
        this.our = diffItem.our;
        this.their = diffItem.their;
        const defaultSelectionByType = ProfileSyncCollectionDiffItem.DEFAULT_SELECTION_BY_TYPE[this.type];
        this.selection = defaultSelectionByType &&
            angular.copy(ProfileSyncCollectionDiffItem.SELECTIONS[defaultSelectionByType][this.type]);
        this.radios = ProfileSyncCollectionDiffItem.RADIOS[this.type];
        this.diffHl = (diffItem.diff || []).reduce((acc, item) => {
            acc.our[item] = 'del';
            acc.their[item] = 'ins';
            return acc;
        }, {our: {}, their: {}});
    }

    select(which) {
        try {
            this.selection = angular.copy(ProfileSyncCollectionDiffItem.SELECTIONS[which][this.type]);
        }
        catch (e) {
            throw new Error('Invalid selection');
        }
    }

    getChange() {
        if (!this.selection) {
            return null;
        }

        const hasOur = this.type !== 'their';
        const hasTheir = this.type !== 'our';

        if ((this.selection.our && !hasOur) || (this.selection.their && !hasTheir)) {
            throw new Error('Invalid selection');
        }

        if (hasTheir) {
            switch (this.selection.their) {
                case 'merge':
                    if (!hasOur) {
                        throw new Error('Invalid selection');
                    }
                    return this._getMergeChange();
                case 'add':
                    return this._getInsertChange();
                case 'ignore':
                    break;
                default:
                    throw new Error('Invalid selection');
            }
        }

        if (hasOur) {
            switch (this.selection.our) {
                case 'remove':
                    return this._getDeleteChange();
                case 'keep':
                case 'update':
                    break;
                default:
                    throw new Error('Invalid selection');
            }
        }

        return null;
    }

    _getInsertChange() {
        return [null, this.their.entity];
    }

    _getMergeChange() {
        return [this.our.id, this.their.entity];
    }

    _getDeleteChange() {
        return [this.our.id, null];
    }

}

class ProfileSyncCollectionDiff extends ProfileSyncDiff {

    constructor(diff, isSynced) {
        super();
        this.isSynced = isSynced;
        this.rows = diff.map(diffItem => new ProfileSyncCollectionDiffItem(diffItem));
    }

    getItems() {
        return this.rows;
    }

}

class ProfileSyncValuesDiffLanguagesItem extends ProfileSyncDiffItem {

    constructor(diffItem) {
        super();
        this.key = diffItem.key;
        this.languages = diffItem.languages.map(diffItemLang =>
            angular.extend({selected: diffItemLang.our}, diffItemLang));
    }

    toggle(idx) {
        if (this.languages[idx]) {
            this.languages[idx].selected = !this.languages[idx].selected;
        }
    }

    getChange() {
        const change = this.languages.reduce((carry, language) => {
            if (language.our && !language.selected) {
                carry.push([language.id, null]); // delete
            } else if (!language.our && language.their && language.selected) {
                carry.push([null, {id: language.id, label: language.label}]); // insert
            }
            return carry;
        }, []);
        return change.length ? {key: 'languages', value: change} : null;
    }

}

class ProfileSyncValuesDiffPairItem extends ProfileSyncDiffPairItem {

    constructor(diffItem) {
        super();
        this.key = diffItem.key;
        this.our = diffItem.our;
        this.their = diffItem.their;
        this.selection = {our: false, their: true};
        this.mutualExclusive = true;
    }

    getChange() {
        if (this.selection.their) {
            return {key: this.key, value: this.their.value};
        }
        return null;
    }

}

class ProfileSyncValuesDiff extends ProfileSyncDiff {

    constructor(diff, isSynced) {
        super();
        this.isSynced = isSynced;
        this.rows = diff.map((diffItem) => {
            let ctor;
            if (diffItem.key === 'languages') {
                ctor = ProfileSyncValuesDiffLanguagesItem;
            } else {
                ctor = ProfileSyncValuesDiffPairItem;
            }
            return new ctor(diffItem);
        });
        this.itemsMap = this.rows.reduce((carry, item) => {
            carry[item.key] = item;
            return carry;
        }, {});
    }

    getItems() {
        return this.rows;
    }

}

export class ProfileLinkedinSyncService {

    static SYNC_API_URL = '/api/profile/sync/';

    static SECTIONS = {
        work_experience: ProfileSyncCollectionDiff,
        education: ProfileSyncCollectionDiff,
        personal_info: ProfileSyncValuesDiff,
    };

    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    loadSection(section, retUrl) {
        if (!(section in ProfileLinkedinSyncService.SECTIONS)) {
            throw new Error('Invalid section: ' + section);
        }
        const apiUrl = this._getApiUrl(section) + '?retUrl=' + encodeURIComponent(retUrl);
        return this.$http.get(apiUrl).then(response => {
            response = response.data.data;
            if (response.redirectTo) {
                return {redirectTo: response.redirectTo};
            }
            return new (ProfileLinkedinSyncService.SECTIONS[section])(response.diff, response.isSynced);
        });
    }

    updateSection(section, patch) {
        return this.$http.post(this._getApiUrl(section), patch);
    }

    _getApiUrl(section) {
        return ProfileLinkedinSyncService.SYNC_API_URL + section;
    }
}

