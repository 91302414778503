import {CustomInputText, TYPE_TEXT} from 'model/customInputText';
import {CustomInputFile, TYPE_FILE} from 'model/customInputFile';
import {CustomInputMultipleChoice, TYPE_MULTIPLE_CHOICE} from 'model/customInputMultipleChoice';
import {CustomInputRange, TYPE_RANGE} from 'model/customInputRange';

export class CustomInputs {

    // Section data, keyed to name.
    sections = {};

    // Numerical ID to section name.
    sectionIdMap = {};

    constructor(sections) {
        this.sections = sections;

        for (const sectionName in this.sections) {
            const section = this.sections[sectionName];
            this.sectionIdMap[section.id] = sectionName;

            let inputs = section.inputs;
            let inputModels = [];
            for (const input of inputs) {
                let newCustomInput;
                switch (input.type) {
                    case TYPE_FILE:
                        newCustomInput = new CustomInputFile(input);
                        break;
                    case TYPE_TEXT:
                        newCustomInput = new CustomInputText(input);
                        break;
                    case TYPE_MULTIPLE_CHOICE:
                        newCustomInput = new CustomInputMultipleChoice(input);
                        break;
                    case TYPE_RANGE:
                        newCustomInput = new CustomInputRange(input);
                        break;
                }
                inputModels.push(newCustomInput);
            }

            section.inputs = inputModels;
            section.errors = {};
        }
    };

    requiresInput(sectionName, currentData, inputDelta) {
        if (!this.sections[sectionName]) {
            return false;
        }
        const section = this.sections[sectionName];

        if (inputDelta !== undefined) {
            // Only pay attention to one of the inputs.
            const input = section.inputs[inputDelta];

            if (input.requiresInput(currentData[input.type][input.id])) {
                return true;
            }
        } else {
            // Loop through all inputs.
            for (const input of section.inputs) {
                if (input.requiresInput(currentData[input.type][input.id])) {
                    return true;
                }
            }
        }

        return false;
    };

    /**
     *
     * @param {CustomInput} customInputModel
     * @param {any} userInputData
     */
    validate(customInputModel, userInputData) {
        if (!this.sectionIdMap[customInputModel.customInputSectionid]) {
            // No mapping for this input's section.
            return;
        }

        const sectionName = this.sectionIdMap[customInputModel.customInputSectionid];
        if (!this.sections[sectionName]) {
            // Don't have this section.
            return;
        } else {
            const section = this.sections[sectionName];
            if (!customInputModel.isValid(userInputData)) {
                section.errors[customInputModel.id] = customInputModel.error;
            } else {
                delete section.errors[customInputModel.id];
            }
        }
    };

    /**
     * @return {boolean}
     */
    isValid(sectionName) {
        if (!this.sections[sectionName]) {
            // Don't have this section.
            return true;
        }

        const section = this.sections[sectionName];
        return Object.keys(section.errors).length === 0;
    };
};
