import {NgModel} from 'model/ngModel';
import * as moment from "moment-timezone";

export const Resource = NgModel.extend({
    _name: 'Resource',
    defaults: {
        id: null,
        title: "",
        description: "",
        source: "",
        imageUrl: null,
        uploadedImageData: null,
        tags: [],
        audience: [],
        participants: [],
        meta: [],
        isLiked: false,
        resourceType: 'video',
    },
    urlRoot: '/resources/[id]',
    parse: function(data) {
        let dataFormatted = this._super('parse', data);

        dataFormatted.dateCreated = this.getDate(data.dateCreated);
        dataFormatted.dateCreatedFormatted = moment.tz(data.dateCreated, moment.tz.guess()).format('MMM D, YYYY');

        dataFormatted.dateUpdated = this.getDate(data.dateUpdated);
        dataFormatted.dateUpdatedFormatted = moment.tz(data.dateUpdated, moment.tz.guess()).format('MMM D, YYYY');

        if (data.source) {
            const videoId = this.getYotubeId(data.source);
            dataFormatted.videoEmbedCode = '<iframe style="width: 100%; min-height: 400px" src="//www.youtube.com/embed/'
                + videoId + '" frameborder="0" allowfullscreen></iframe>';
        } else {
            dataFormatted.videoEmbedCode = null;
        }

        if (data.description.length > 150) {
            dataFormatted.descriptionShort = data.description.substr(0, 150) + "...";
        } else {
            dataFormatted.descriptionShort = null;
        }

        this.__bindManyForAngular(dataFormatted);
        return dataFormatted
    },
    getYotubeId: function(url) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);

        return (match && match[2].length === 11)
            ? match[2]
            : null;
    }
});
