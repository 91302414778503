import {GroupBaseController} from './groupBase';

export class GroupDiscussionsController extends GroupBaseController {
    static $inject = ['$scope', '$rootScope', '$routeParams', '$q', '$window', '$http', 'VisitorService', '$analytics'];

    constructor($scope, $rootScope, $routeParams, $q, $window, $http, VisitorService, $analytics) {
        super($scope, $rootScope, $routeParams, $q, $window, $http, VisitorService, $analytics);
        $scope.$on('controllerLoaded', () => {
            $window.document.title = $scope.isFair ? 'Booth Forums' : 'Group Forums';
        })
        $scope.position = 2;
    };
}
