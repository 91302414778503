import {AbstractProfileCollection} from './abstractProfileCollection';
import {CareerGoals} from '../model/careerGoals';

const EMPLOYERS = 'employers_id';
const FUNCTIONS = 'categories_id';
const SECTORS = 'industries_id';
const LOCATIONS = 'locations_id';

export const ProfileCareerGoals = AbstractProfileCollection.extend({
    _name: 'CareerGoals',
    model: CareerGoals,
    urlRoot: '/careergoals/get.json',

    comparator: function(val1, val2) {
        return val1.rank > val2.rank;
    },

    getFunctions: function() {
        if (!this._functions) {
            this._functions = this.where({'field': FUNCTIONS});
        }
        return this._functions;
    },

    getEmployers: function() {
        if (!this._employers) {
            this._employers = this.where({'field': EMPLOYERS});
        }
        return this._employers;
    },

    getLocations: function() {
        if (!this._locations) {
            this._locations = this.where({'field': LOCATIONS});
        }
        return this._locations;
    },

    getSectors: function() {
        if (!this._sectors) {
            this._sectors = this.where({'field': SECTORS});
        }
        return this._sectors;
    },
});

