import * as moment from 'moment-timezone';

export function swathHourlyRange() {
    return function(timeSwath) {
        var date1 = new moment(timeSwath.getStartDate());
        var date2 = new moment(timeSwath.getEndDate());

        return date1.format('h a') + ' - ' + date2.format('h a');
    }
}
