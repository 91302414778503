/**
 * This directive will perform some action on an enter keypress
 *
 * <div on-key-enter="someFunction"></div>
 */
export function onKeyEnter() {
    return {
        restrict: 'A',
        scope: {
            onKeyEnter: '=',
        },
        link: function(scope, element) {
            element.on('keydown', function(ev) {
                if (ev.which === 13) {
                    scope.onKeyEnter(ev);
                }
            });
        },
    };
}
