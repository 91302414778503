import {TwilioMessage} from '../../model/twilioMessage';
import { saveAs } from 'file-saver';

export function chatSection() {
    return {
        restrict: 'E',
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/consultations/chat/chatSection.html',
        transclude: true,
        scope: {
            conversation: '=',
            layerConversation: '=',
        },
        controller: [
            '$rootScope', '$scope', '$interval', '$http', '$timeout', 'ChatService', '$anchorScroll',
            ($rootScope, $scope, $interval, $http, $timeout, ChatService, $anchorScroll) => {
                const scrollTo = messageId => {
                    $scope.isGlued = false;

                    // timeout so the page has time to add the new message before we scroll
                    $timeout(() => {
                        const newElementId = 'chatMessage-' + messageId;
                        $anchorScroll(newElementId);
                    }, 200);
                };

                $scope.connectionState = null;
                $scope.chatsLoading = true;

                $scope.$on('chatConnectionStateChanged', (evt, newState) => {
                    $scope.connectionState = newState;
                });

                $scope.$watch('conversation', newVal => {
                    if (!newVal) {
                        // do nothing
                        return;
                    }
                    $scope.chatsLoading = true;
                    $scope.isGlued = true;

                    $scope.chatMessages = [];

                    $scope.myUserid = null;
                    $scope.otherUserid = $scope.conversation.OtherUser.id.toString();
                    $scope.otherUserFirstName = $scope.conversation.OtherUser.firstName;
                    ChatService.getMyUserid().then(myUserid => {
                        $scope.myUserid = myUserid;
                    });

                    // Initialize read-index.
                    $scope.readIndex = {};
                    $scope.readIndex[$scope.conversation.user1id.toString()] = 0;
                    $scope.readIndex[$scope.conversation.user2id.toString()] = 0;

                    // The element that should be scrolled to on next Update.
                    $scope.currentOldest = null;

                    // Set up read index (aka "message consumption horizon")
                    $scope.layerConversation.getParticipants().then(members => {
                        $scope.$evalAsync(() => {
                            members.forEach(member => {
                                $scope.readIndex[member.identity] = member.lastReadMessageIndex;
                            });
                        });
                    });

                    // Handler for messages loaded from Chat Client
                    $scope.handleLoadedMessages = messagePaginator => {
                        $scope.messageClientPaginator = messagePaginator;
                        const messages = messagePaginator.items.reverse();

                        $scope.$evalAsync(() => {
                            messages.filter(message => message.attributes.type !== 'meeting' && message.body !== '').
                                map(message => {
                                    const newTwilioMessage = new TwilioMessage();
                                    newTwilioMessage.parseTwilioMessageData(message, $scope.conversation);
                                    $scope.chatMessages.unshift(newTwilioMessage);
                                });

                            $scope.chatsLoading = false;
                        });
                    };

                    // Get initial messages
                    $scope.layerConversation.getMessages(15).then(messagePaginator => {
                        $scope.handleLoadedMessages(messagePaginator, true);
                    });

                    $scope.scrollBack = () => {
                        if ($scope.chatMessages.length === 0) {
                            return;
                        }

                        if ($scope.messageClientPaginator && $scope.messageClientPaginator.hasPrevPage) {
                            scrollTo($scope.chatMessages[0].id);
                            $scope.messageClientPaginator.prevPage().then($scope.handleLoadedMessages);
                        }
                    };
                });

                $scope.isBot = userId => (userId === 'FirsthandBot');

                $scope.loadPrevious = ((first, inview)=> {
                    if (first && inview) {
                        $scope.scrollBack();
                    }
                })

                $scope.isUnread = message => {
                    if (message.senderId === $scope.myUserid) {
                        // You've read all your own messages.
                        return false;
                    } else {
                        return message.index > $scope.readIndex[$scope.myUserid];
                    }
                };

                $scope.$on('chatMessageSent', (evt, conversationid, messageBody, temporaryId) => {
                    if ($scope.layerConversation && conversationid === $scope.layerConversation.sid) {
                        const newMessage = new TwilioMessage();
                        newMessage.parseNewMessage(messageBody, $scope.conversation, $scope.layerConversation.sid,
                            temporaryId, $scope.myUserid);
                        $scope.chatMessages.push(newMessage);
                    }
                });

                $scope.$on('markAllChatsRead', () => {
                    $scope.$emit('unreadChatCount', 0);
                });

                $scope.$on('messageReadIndexChange', (evt, channelSid, userid, lastConsumedMessageIndex) => {
                    if (channelSid !== $scope.layerConversation.sid) {
                        // A different channel.
                        return;
                    }

                    $scope.readIndex[userid] = lastConsumedMessageIndex;
                });

                $scope.downloadNewFile = (async message => {
                    const url = await message.attachedMedia.getContentTemporaryUrl();
                    saveAs(url, message.attachedMedia.filename);
                });

                $scope.$on('chatMessageAdded', (evt, message) => {
                    if (message.conversation.sid !== $scope.layerConversation.sid) {
                        // A different channel.
                        return;
                    }
                    if (message.author === $scope.myUserid) {
                        // Mark that I read my own message.
                        ChatService.markAllAsRead($scope.conversation);
                    }

                    // Ensure not in message list.
                    const foundTemporaryId = ChatService.checkIfSuccessfulDelivery(message);

                    if (foundTemporaryId) {
                        // Update pending message.
                        $scope.chatMessages.forEach(chatMessage => {
                            if (chatMessage.pending && chatMessage.id === foundTemporaryId) {
                                chatMessage.parseTwilioMessageData(message, $scope.conversation);
                            }
                        });
                    } else {
                        // Add legitimate new message.
                        const newTwilioMessage = new TwilioMessage();
                        newTwilioMessage.parseTwilioMessageData(message, $scope.conversation);
                        $scope.chatMessages.push(newTwilioMessage);
                    }
                    scrollTo(message.sid);
                });
            },
        ],
    };
}

