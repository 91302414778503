import {OTHER_KEY} from 'model/customInputMultipleChoice';

const ROLE_EXPERT = 'expert';

export function customInputMultipleChoice() {
    return {
        restrict: 'E',
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/customInputMultipleChoice.html',
        scope: {
            customInput: '=',
            data: '=',
            userPrimaryRole: '=?',
        },
        require: 'ngModel',
        controller: [
            '$scope', function($scope) {
                let defaultValue;
                $scope.multipleAllowed = ($scope.userPrimaryRole === ROLE_EXPERT) ?
                    $scope.customInput.multipleExpert :
                    $scope.customInput.multiple;

                if ($scope.data) {
                    defaultValue = angular.copy($scope.data);
                } else if ($scope.multipleAllowed) {
                    defaultValue = {};
                } else {
                    defaultValue = null;
                }
                $scope.input = {
                    val: defaultValue,
                };

                $scope.OTHER_KEY = OTHER_KEY;
                $scope.otherInput = undefined;
                $scope.otherInputDirty = false;
                $scope.otherInputBackup = '';

                $scope.optionsArray = Object.keys($scope.customInput.options).map(function(key) {
                    return $scope.customInput.options[key];
                });

                const responseCount = () => {
                    let responseCount = 0;
                    for (const id in $scope.input.val) {
                        if (!$scope.input.val.hasOwnProperty(id)) {
                            continue;
                        }

                        if ($scope.input.val[id] === true || (id === OTHER_KEY && $scope.input.val[id])) {
                            responseCount = responseCount + 1;
                        }
                    }
                    return responseCount;
                };

                $scope.onValueChange = () => {
                    $scope.data = {...$scope.input.val};
                    if ($scope.data[OTHER_KEY]) {
                        $scope.data[OTHER_KEY] = $scope.otherInput;
                    }
                    // Let's hack the way we're showing errors. We do not want to show error if $scope.otherInput is not dirty
                    // but option for other option is selected
                    if (!($scope.data.hasOwnProperty(OTHER_KEY) && typeof $scope.otherInput !== 'string')) {
                        $scope.$emit('CustomInputChanged', $scope.customInput, $scope.data);
                    }
                };

                $scope.inputsLocked = () => {
                    if (!$scope.customInput || !$scope.input || !$scope.input.val) {
                        return false;
                    }

                    let responseCountComparator = responseCount();
                    if ($scope.customInput.maxSelectionIgnoresOther && $scope.input.val[OTHER_KEY] && $scope.input.val[OTHER_KEY] !== '') {
                        responseCountComparator = responseCountComparator - 1;
                    }

                    return $scope.multipleAllowed && $scope.customInput.maxSelection && (responseCountComparator >= $scope.customInput.maxSelection);
                };

                $scope.otherInputLocked = () => {
                    if (typeof $scope.otherInput === 'string' && $scope.otherInput.trim() !== '') {
                        // There is text in other.
                        return false;
                    }

                    if ($scope.customInput.maxSelection) {
                        if (responseCount() >= $scope.customInput.maxSelection && !$scope.customInput.maxSelectionIgnoresOther) {
                            return !($scope.input.val.hasOwnProperty(OTHER_KEY) && $scope.input.val !== true);
                        }
                    } else {
                        return false;
                    }
                };

                $scope.getName = (optionid) => {
                    if (!$scope.customInput) {
                        return '';
                    }
                    if ($scope.multipleAllowed) {
                        return 'multiple-choice-' + $scope.customInput.id + '-' + optionid;
                    } else {
                        return 'multiple-choice-' + $scope.customInput.id;
                    }
                };

                $scope.change = (optionid) => {
                    if (!$scope.multipleAllowed) {
                        // For radio buttons we unset the existing value first.
                        $scope.input.val = {};
                        $scope.input.val[optionid] = true;
                        if ($scope.otherInput && optionid !== OTHER_KEY) {
                            $scope.otherInputBackup = $scope.otherInput;
                            $scope.otherInput = "";
                        }
                        if ($scope.otherInputBackup && optionid === OTHER_KEY) {
                            $scope.otherInput = $scope.otherInputBackup;
                            $scope.otherInputBackup = "";
                        }
                    }

                    $scope.onValueChange();
                };

                $scope.changeOtherInput = (changedValue) => {
                    if (!$scope.multipleAllowed) {
                        $scope.input.val = {};
                    }

                    $scope.input.val[OTHER_KEY] = true;
                    $scope.otherInput = changedValue;
                    $scope.onValueChange();
                };

                $scope.clearInput = () => {
                    $scope.input.val = {};
                    $scope.otherInput = '';
                };

                $scope.canClearSelection = () => {
                    if ($scope.multipleAllowed || $scope.customInput.required || $scope.input) {
                        return false;
                    }
                    return Object.keys($scope.input.val).length > 0;
                };

                $scope.$watch('data', () => {
                    if ($scope.data && $scope.data[OTHER_KEY] && $scope.data[OTHER_KEY] !== $scope.otherInput) {
                        $scope.otherInput = $scope.data[OTHER_KEY];
                        $scope.input.val[OTHER_KEY] = true;
                    }
                });
            },
        ],
    };
}
