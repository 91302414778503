/**
 * This directive lets you create a modal quick and easy
 * Use like:
 * <modal-dialog></modal-dialog>
 *
 * @see http://adamalbrecht.com/2013/12/12/creating-a-simple-modal-dialog-directive-in-angular-js/
 */
export function modalDialog() {
    return {
        restrict: 'E',
        scope: {
            show: '=',
            noHide: '=?noHide',
            onHide: '=?',
            grow: '@?',
            disableOverlayClose: '<?',
        },
        transclude: true, // we want to insert custom content inside the directive
        link: function(scope, element, attrs) {
            scope.dialogStyle = {};
            if (attrs.width) {
                scope.dialogStyle['max-width'] = attrs.width;
            }
            if (attrs.height) {
                scope.dialogStyle['max-height'] = attrs.height;
            }
            scope.hideModal = function() {
                if (scope.noHide) {
                    return;
                }
                scope.show = false;
                if (typeof scope.onHide === 'function') {
                    scope.onHide();
                }
            };

            scope.$on('hideModal', scope.hideModal);

            var setFocus = function() {
                var first = null;
                // this is higher than the max tabindex (32767)
                var lowestIndex = 99999;
                $(element).find('[tabindex]').each(function(i, elem) {
                    var $elem = $(elem);
                    var index = parseInt($elem.attr('tabindex'));
                    if (index >= 0 && index < lowestIndex) {
                        first = $elem;
                    }
                });
                if (first) {
                    first.focus();
                }
            };

            scope.$watch('show', function(newVal) {
                if (newVal) {
                    setFocus();
                }
                scope.$broadcast('modal-update');
            });
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/modalDialog.html',
    };
}
