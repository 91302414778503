export function boothInfo() {
    return {
        restrict: 'E',
        scope: {
            'booth': '=',
        },
        templateUrl: '/asset/partials/partner/directives/fairs/boothInfo.html',
        replace: true,
        controller: [
            '$scope',
            function ($scope) {

            }
        ]
    }
}
