export function boothAcceptUploads() {
    return {
        restrict: 'E',
        scope: {
            'acceptUploads': '=',
        },
        templateUrl: '/asset/partials/partner/directives/fairs/boothAcceptUploads.html',
        replace: true,
    }
}
