export function userInputBio() {
    return {
        restrict: 'E',
        scope: {
            data: '=',
            displayType: '@?',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel +
            'ng-template/partials/angular-utilities/directives/userInputs/userInputBio.tpl.html',
        controller: [
            '$scope', function($scope) {
                var once = $scope.$watch('data', function(newVal) {
                    if (newVal) {
                        if (!$scope.data.bio) {
                            $scope.data.bio = $scope.default_text;
                        }
                        once();
                    }
                });
            },
        ],
        link: function(scope, element, attrs) {
            scope.default_text = angular.element(element[0]).find('#user-input-bio').html();
        },
    };
}
