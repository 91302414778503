export const MeetingState_Expert_ExpertAccept = [
    '$scope', function($scope) {
        $scope.template = Evisors.config.cdnRootWhitelabel + 'asset/partials/consultations/meetingStates/expert/expertAccept.html';
        $scope.meetingStartDates = $scope.meeting.get('suggestedTimeSwaths');
        $scope.durationInSeconds = $scope.meeting.get('durationInSeconds');

        var duration = $scope.meeting.get('durationInMinutes');
        var timeLabel = '-minute';
        if (duration >= 60) {
            duration = (duration / 60);
            timeLabel = '-hour';
        }

        $scope.durationLabel = duration + timeLabel;
        $scope.consultationType = $scope.meeting.get('consultationType');
        $scope.consultationTypeBookingLabel = $scope.meeting.get('consultationTypeBookingLabel');

        // whether or not to show the reschedule / cancel dialogs
        $scope.showCancel = false;
        $scope.showReschedule = false;
    },
];
