export function groupExtraInput() {
    return {
        scope: {
            'extraInput': '=',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/groupExtraInput.html',
        controller: [
            '$scope', function($scope) {
                $scope.responseHolder = {};
                if ($scope.extraInput.responses) {
                    for (const response of $scope.extraInput.responses) {
                        $scope.responseHolder[response] = true;
                    }
                }
                $scope.handleResponseChange = () => {
                    $scope.extraInput.responses = [];
                    for (const option in $scope.responseHolder) {
                        if ($scope.responseHolder[option] === true) {
                            $scope.extraInput.responses.push(option);
                        }
                    }
                };
            },
        ],
    };
}
