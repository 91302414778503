import * as TwilioVideo from 'twilio-video';

// Milliseconds to wait before we try and get a new Video Token.
const TOKEN_REFRESH_PERIOD = 60000 * 3; // 3 Minutes.
const LOG_LEVEL = 'warn'; // 'debug', 'info', 'warn', 'error', 'off'

export const VideoConsultationService = [
    '$rootScope', '$q', '$timeout', 'Meeting', 'MeetingConnectionService',
    function($rootScope, $q, $timeout, Meeting, MeetingConnectionService) {
        var errorHandler = function(err) {
            console.error(err);
            $rootScope.$broadcast('growl', 'error');
        };

        let tokenRefreshPromise = null;

        var retVal = {
            typeEnum: {
                'TYPE_LOCAL': 0,
                'TYPE_REMOTE': 1,
            },
            statusEnum: {
                'STATUS_DISABLED': -1,
                'STATUS_LOADING': 0,
                'STATUS_READY': 1,
                'STATUS_CONNECTING': 2,
                'STATUS_CONNECTED': 3,
            },
            videoAccess: {
                'roomName': null,
                'token': null,
            },
            status: -1,
            shouldConnectWhenReady: false,
            isRetrying: false,
            localVideoActive: false,
            remoteVideoActive: false,
            Room: null,
            meeting: null,
            localTracks: null,
            videoDeviceId: null,
            initialize: function() {
                retVal.changeStatus(retVal.statusEnum.STATUS_LOADING);

                Meeting.getByRouteParams().then(function(meeting) {
                    retVal.meeting = meeting;
                    retVal.getVideoAccessToken().then(retVal.finishedLoading, errorHandler);
                }, errorHandler);
            },
            setDevice: function(deviceId) {
                retVal.videoDeviceId = deviceId;
                if (retVal.localTracks && retVal.status >= retVal.statusEnum.STATUS_READY) {
                    retVal.disconnectLocalTracks();
                    retVal.activateLocalVideo();
                }
            },
            getVideoAccessToken: function() {
                if (tokenRefreshPromise) {
                    $timeout.cancel(tokenRefreshPromise);
                    tokenRefreshPromise = null;
                }

                // Callback when we've loaded the necessary Access Token.
                // 'videoAccess' object in the format: { token: '...', roomName: 'consultation-123' }
                var deferred = $q.defer();

                Meeting.getVideoAccess(retVal.meeting).then(function(videoAccess) {
                    retVal.videoAccess = videoAccess;
                    deferred.resolve();

                    tokenRefreshPromise = $timeout(retVal.getVideoAccessToken, TOKEN_REFRESH_PERIOD);
                }, function(error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            },
            finishedLoading: function() {
                retVal.changeStatus(retVal.statusEnum.STATUS_READY);

                if (retVal.shouldConnectWhenReady) {
                    retVal.connect();
                }
            },
            connect: function() {
                if (retVal.status < retVal.statusEnum.STATUS_READY) {
                    retVal.shouldConnectWhenReady = true;
                    return;
                }
                if (retVal.status >= retVal.statusEnum.STATUS_CONNECTING) {
                    return;
                }

                retVal.changeStatus(retVal.statusEnum.STATUS_CONNECTING);

                var localVideoTracks = this.getLocalTracks();

                var connectOptions = {
                    'name': retVal.videoAccess.roomName,
                    'audio': false,
                    'video': false,
                    'tracks': [],
                    'logLevel': LOG_LEVEL,
                };

                if (localVideoTracks.length) {
                    connectOptions.tracks = localVideoTracks;
                }

                TwilioVideo.connect(retVal.videoAccess.token, connectOptions).
                    then(retVal.isConnectedHandler, function(error) {
                        // Maybe the token expired; try and get another one, but only try once.

                        if (retVal.isRetrying) {
                            // Actually, we just tried to get a new token; something is legitimately wrong.
                            errorHandler(error);
                            this.isRetrying = false;
                        } else {
                            retVal.isRetrying = true;
                            retVal.initialize();
                        }
                    });
            },
            isConnectedHandler: function(room) {
                MeetingConnectionService.setLocalVideoConnected(true);

                // We've successfully connected. Reset the "isRetrying" flag, which would keep us from attempting
                // repeatedly.
                retVal.isRetrying = false;
                retVal.changeStatus(retVal.statusEnum.STATUS_CONNECTED);
                // Callback for successful connection to the video chat room.
                retVal.room = room;

                // Turn on video if anyone was present.
                retVal.changeVideoMonitorStatus(retVal.typeEnum.TYPE_REMOTE);

                if (retVal.room.participants) {
                    retVal.room.participants.forEach(retVal.handleParticipant);
                }

                // Watch for new tracks.
                retVal.room.on('participantConnected', retVal.handleParticipant);

                // Handle participant connections and disconnections
                retVal.room.on('trackPublished', function(track) {
                    retVal.remoteVideoActive = true;
                    retVal.changeVideoMonitorStatus(retVal.typeEnum.TYPE_REMOTE);
                });
                retVal.room.on('participantDisconnected', function(participant) {
                    retVal.remoteVideoActive = false;
                    retVal.changeVideoMonitorStatus(retVal.typeEnum.TYPE_REMOTE);
                });

                // Handle eventual disconnect.
                retVal.room.on('disconnected', retVal.disconnect);

                // Publish all of my tracks, if I have any active.
                if (retVal.room.localParticipant && retVal.room.localParticipant.videoTracks) {
                    retVal.publishLocalTrack();
                }
            },
            handleParticipant: function(participant) {
                participant.on('trackSubscribed', function(remoteTrack) {
                    retVal.remoteVideoActive = true;
                    retVal.changeVideoMonitorStatus(retVal.typeEnum.TYPE_REMOTE);
                });
                participant.on('trackUnsubscribed', function(track) {
                    retVal.disconnectRemoteTrack(track);
                });
            },
            disconnect: function() {
                if (retVal.room) {
                    if (retVal.room.state !== 'disconnected') {
                        retVal.disconnectAllRemoteTracks();
                        retVal.unpublishLocalTracks();
                        retVal.room.disconnect();
                    }
                    retVal.room = null;
                }
                retVal.deactivateLocalVideo();
                retVal.remoteVideoActive = false;
                MeetingConnectionService.setLocalVideoConnected(false);
                retVal.changeStatus(retVal.statusEnum.STATUS_READY);
                this.shouldConnectWhenReady = false;
                this.isRetrying = false;
            },
            changeStatus: function(newStatus) {
                retVal.status = newStatus;
                $rootScope.$broadcast('videoStatus', newStatus);
            },
            changeVideoMonitorStatus: function(type) {
                // Broadcast that monitors of this type (remote or local) need to be refreshed.
                $rootScope.$broadcast('videoMonitorStatus', type);
            },
            getMonitorStatus: function(monitorType) {
                switch (monitorType) {
                    case retVal.typeEnum.TYPE_LOCAL:
                        return retVal.localVideoActive;
                    case retVal.typeEnum.TYPE_REMOTE:
                        return retVal.remoteVideoActive;
                }
            },
            activateLocalVideo: function() {
                // Generate a local track.
                retVal.createLocalTracks().then(function(track) {
                    retVal.localVideoActive = true;
                    retVal.changeVideoMonitorStatus(retVal.typeEnum.TYPE_LOCAL);
                });
            },
            deactivateLocalVideo: function() {
                retVal.localVideoActive = false;
                retVal.disconnectLocalTracks();
                retVal.changeVideoMonitorStatus(retVal.typeEnum.TYPE_LOCAL);
            },
            publishLocalTrack: function() {
                if (retVal.status === retVal.statusEnum.STATUS_CONNECTED && retVal.room && retVal.localTracks) {
                    retVal.getLocalTracks().then(function(localTracks) {
                        if (!localTracks) {
                            return;
                        }

                        // OK, we had local tracks. Let's notify the Meeting Service and publish.
                        localTracks.forEach(function(localTrack) {
                            retVal.room.localParticipant.publishTrack(localTrack);
                        });
                    });
                }
            },
            createLocalTracks: function() {
                var deferred = $q.defer();
                retVal.localTracks = deferred.promise;

                // Let's load the tracks!
                if (retVal.status >= retVal.statusEnum.STATUS_READY) {
                    // We need to create a local track.
                    var videoObject = null;
                    if (retVal.videoDeviceId) {
                        videoObject = {
                            deviceId: retVal.videoDeviceId,
                        };
                    }
                    TwilioVideo.createLocalVideoTrack(videoObject).then(function(track) {
                        retVal.publishLocalTrack();
                        // Returns an array of one item.
                        deferred.resolve([track]);
                    });
                } else {
                    deferred.reject('Video Service is not ready to connect local track.');
                }

                return retVal.localTracks;
            },
            getLocalTracks: function() {
                if (retVal.localTracks) {
                    // We have tracks, or at least a promise for tracks.
                    return retVal.localTracks;
                } else {
                    // No tracks and no preexisting promise.
                    // Return an empty array.
                    var deferred = $q.defer();
                    deferred.resolve([]);
                    return deferred.promise;
                }
            },
            disconnectLocalTracks: function() {
                // We immediately blank out the promise for localTracks.
                var oldLocalTracks = retVal.localTracks;
                retVal.localTracks = null;
                MeetingConnectionService.setLocalVideoConnected(false);
                if (!oldLocalTracks) {
                    return;
                }
                oldLocalTracks.then(function(localTracks) {
                    if (localTracks) {
                        localTracks.forEach(function(localTrack) {
                            localTrack.disable();
                            if (retVal.room && retVal.room.localParticipant) {
                                retVal.room.localParticipant.unpublishTrack(localTrack);
                            }
                            localTrack.stop();
                            localTrack.detach();
                        });

                    }
                });
            },
            getRemoteTracks: function() {
                var deferred = $q.defer();

                if (retVal.room) {
                    var tracks = [];
                    retVal.room.participants.forEach(function(participant) {
                        // Handle existing tracks.
                        participant.tracks.forEach(function(publication) {
                            if (publication.isSubscribed) {
                                retVal.remoteVideoActive = true;
                                tracks.push(publication.track);
                            } else {
                                // No Existing tracks to append.
                            }
                        });
                    });

                    deferred.resolve(tracks);
                } else {
                    deferred.resolve([]);
                }

                return deferred.promise;
            },
            disconnectAllRemoteTracks: function() {
                if (retVal.room.participants && retVal.room.participants.length > 0) {
                    retVal.room.participants.forEach(function(participant) {
                        if (participant.tracks && participant.tracks.length > 0) {
                            participant.tracks.forEach(function(remoteTrack) {
                                retVal.disconnectRemoteTrack(remoteTrack);
                            });
                        }
                    });
                }
            },
            unpublishLocalTracks: function() {
                if (retVal.room && retVal.room.localParticipant && retVal.room.localParticipant.videoTracks) {
                    retVal.room.localParticipant.videoTracks.forEach(function(videoTrackPublication) {
                        videoTrackPublication.track.stop();
                        videoTrackPublication.unpublish();
                    });
                }
            },
            disconnectRemoteTrack: function(remoteTrack) {
                // We'll detach from the DOM elements but not worry about removing them here.
                remoteTrack.detach();
                retVal.remoteVideoActive = false;
                MeetingConnectionService.setOtherUserStatusVideo(false);
                retVal.changeVideoMonitorStatus(retVal.typeEnum.TYPE_REMOTE);
            },
        };

        return retVal;
    },
];
