export function profileExperienceEducation() {
    return {
        restrict: 'E',
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/profileExperienceEducation.html',
        replace: true,
        scope: {
            edu: '=education',
        },
    };
}
