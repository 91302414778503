import {whitelabelling as Whitelabelling} from 'whitelabelling';

export const userInputSingleEmployment = [
    '$http', '$q',
    function($http, $q) {
        return {
            restrict: 'E',
            scope: {
                data: '=', // a single employment model
                delta: '=',
                count: '<',
            },
            templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/registration/directives/userInputs/userInputSingleEmployment.html',
            controller: [
                '$scope', 'RegistrationService', function($scope, RegistrationService) {
                    $scope.whitelabelling = Whitelabelling;
                    $scope.errors = {};
                    $scope.employmentDatesOptional = false;
                    $scope.datesRequired = 'month';
                    $scope.employersHook = RegistrationService.employersPrefixComplete;
                    $scope.industryRequired = Whitelabelling.settings['customized:employment:industryRequired'];
                    $scope.roleRequired = Whitelabelling.settings['customized:employment:roleRequired'];

                    function updateIndustryRanks(employerId) {
                        var promise;
                        if (employerId) {
                            promise = $http.get(
                                '/registration/tips/industries-by-employer/' + employerId + '.json?idsOnly=1').
                                then(function(response) {
                                    if (response.data.data && angular.isArray(response.data.data.industryids)) {
                                        return response.data.data.industryids.reduce(function(carry, id, index) {
                                            carry[id] = index - response.data.data.industryids.length;
                                            return carry;
                                        }, {});
                                    }
                                    return {};
                                }).
                                catch(function() {
                                    return {};
                                });
                        } else {
                            promise = $q.when({});
                        }
                        return promise.then(function(topIndustriesMap) {
                            $scope.industries.models.forEach(function(model) {
                                model.rank = (topIndustriesMap && topIndustriesMap[model.id]) || 0;
                            });
                        });
                    }

                    RegistrationService.getRegistrationSettings().then(function(d) {
                        $scope.roles = d.Roles;
                        $scope.industries = new d.Industries.constructor(d.Industries.map(function(model) {
                            return model.clone();
                        }));
                        $scope.employmentDatesOptional = d.WhitelabelSettings.employmentDatesOptional;
                        $scope.datesRequired = d.WhitelabelSettings.employmentDatesOptional ? null : 'month';
                        return updateIndustryRanks($scope.data.Employer && $scope.data.Employer.id);
                    }).then(function() {
                        $scope.$watch('data.Employer.id', function(id, oldId) {
                            if (id && id != oldId) {
                                updateIndustryRanks(id);
                            }
                        });
                    });

                    $scope.checkForErrors = function(specificProperty) {
                        var errors = $scope.data.validateData($scope.employmentDatesOptional);
                        if (specificProperty) {
                            $scope.errors[specificProperty] = errors[specificProperty];
                        } else {
                            $scope.errors = errors;
                        }
                    };

                    $scope.onBlurEmployer = function() {
                        $scope.checkForErrors('Employer');
                    };

                    $scope.$on('showErrors', function() {
                        $scope.checkForErrors();
                    });
                },
            ],
        };
    },
];
