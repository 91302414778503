import * as moment from 'moment-timezone';

export class TwilioMessage {

    constructor() {
        this.id = null;
        this.index = null;
        this.senderId = null;
        this.senderName = null;
        this.messageBody = null;
        this.conversationId = null;
        this.sentAt = null;

        this.pending = true;

        this.sender = {
            displayName: null,
            userId: null,
        };
        this.attachedMedia = null;
        this.assetUploadMedia = null;

    }

    parseTwilioMessageData(rawMessage, firsthandConversationObject) {
        this.id = rawMessage.state.sid;
        this.index = rawMessage.state.index;
        this.senderId = rawMessage.state.author;
        this.senderName = rawMessage.state.author === 'FirsthandBot'
            ? 'Firsthand Chat Bot'
            : firsthandConversationObject.UserNames[rawMessage.state.author];
        this.messageBody = rawMessage.state.body;
        if (rawMessage.attributes?.type === 'asset-upload') {
            this.assetUploadMedia = rawMessage.attributes;
            this.messageBody = null;
        }
        if (rawMessage.state.type === 'media') {
            this.attachedMedia = rawMessage.state.media;
        }
        this.conversationId = firsthandConversationObject.id;
        this.sentAt = rawMessage.state.timestamp;

        this.sender = {
            displayName: this.senderName,
            userId: this.senderId,
        };

        this.pending = false;
    }

    parseNewMessage(messageBody, firsthandConversationObject, conversationId, messageId, myUserId) {
        this.id = messageId;
        this.senderId = myUserId;
        this.senderName = firsthandConversationObject.UserNames[myUserId];
        this.messageBody = messageBody;
        this.conversationId = conversationId;
        this.sentAt = new moment();
        this.pending = true;
    }

}
