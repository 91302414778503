import {whitelabelling as Whitelabelling} from 'whitelabelling';
import {validateEmail} from '../../configurations/utilities';

export const userInputEmailAndPhone = [
    'RegistrationService', '$http',
    function(RegistrationService, $http) {
        return {
            restrict: 'E',
            scope: {
                data: '=',
                primaryRole: '@?',
                displayType: '@?',
                displayPhone: '<?',
                userCanChange: '<?',
                adminCanChange: '<?',
                isAdmin: '<?',
                registration: '<?',
            },
            templateUrl: Evisors.config.cdnRootWhitelabel +
                'ng-template/partials/angular-utilities/directives/userInputs/userInputEmailAndPhone.tpl.html',
            controller: [
                '$scope', function($scope) {
                    $scope.uiMaskPhone = '9?9?9?9?9?9?9?9?9?9?9?9?9?9?9?';
                    $scope.countryCodeElem = null;
                    $scope.whitelabelling = Whitelabelling;
                    $scope.userCanChangeEmail = true;
                    $scope.adminCanChangeEmail = true;
                    $scope.isAdminUser = false;
                    $scope.isRegistrationStep = $scope.registration || false;

                    $scope.emailIsEditable = () => {
                        return ($scope.isAdminUser ? $scope.adminCanChangeEmail : $scope.userCanChangeEmail) && !$scope.isRegistrationStep;
                    }

                    $scope.$watch('userCanChange', (newVal) => $scope.userCanChangeEmail = newVal);
                    $scope.$watch('adminCanChange', (newVal) => $scope.adminCanChangeEmail = newVal);
                    $scope.$watch('isAdmin', (newVal) => $scope.isAdminUser = newVal);

                    $scope.checkIfInputIsOk = function() {
                        // apparently, angular bound variables become undefined if input[type=email] value is not an
                        // email so if it's undefined we're going to treat it as an invalid email @see
                        // https://stackoverflow.com/questions/26837208/input-type-email-breaks-ng-model-data-binding-in-modal-dialog
                        if ($scope.emailInput) {
                            $scope.emailInput = $scope.emailInput.trim();
                            $scope.emailError = !validateEmail($scope.emailInput);
                        } else {
                            $scope.emailError = true;
                        }
                        if (!$scope.emailError) {
                            $scope.data.email = $scope.emailInput;
                        }
                    };
                    RegistrationService.getRegistrationSettings().then(({WhitelabelSettings}) => {
                        $scope.defaultCountryCode = WhitelabelSettings.defaultCountryCode;
                        $scope.phoneRequired = WhitelabelSettings['registration:phoneRequired'];

                        // Having no phone number (vs no country code) is sufficient because country code defaults to 1.
                        if ($scope.data &&
                            (!$scope.data.phone || !$scope.data.phone.code || !$scope.data.phone.number)) {
                            // make sure phone is an object before we start setting props on it
                            $scope.data.phone = $scope.data.phone ? $scope.data.phone : {};
                            $scope.data.phone.code = $scope.defaultCountryCode;
                        }
                    });

                    $scope.$on('showErrors', $scope.checkIfInputIsOk);

                    $scope.$watch('data.email', function() {
                        if ($scope.data && angular.isString($scope.data.email)) {
                            $scope.emailInput = $scope.data.email;
                        }
                    });

                    // @todo: Make a validate function call!
                    $scope.$watch('data.phone.code', function(newValue) {
                        // When the country code changes, change the input mask for the phone number.
                        if (newValue) {
                            $http.get('/phonenumber/format.json', {
                                'params': {
                                    'countryCode': newValue,
                                },
                            }).then(function(response) {
                                if (response.data.mask) {
                                    $scope.uiMaskPhone = response.data.mask;
                                }
                            }, function(error) {
                                console.error(error);
                            });
                        }
                    });
                },
            ],
            link: function(scope, element, attrs) {
                scope.element = element;
                scope.phoneCodeClass = function() {
                    if (scope.data && scope.data.phone) {
                        let classes = attrs.class;
                        if (!classes) {
                            classes = '';
                        }
                        classes += ' phoneCode-' + scope.data.phone.code.length;

                        return classes;
                    }
                };
            },
        };
    },
];
