import {NgModel} from './ngModel';
import {TimeSwath} from './timeSwath';
import * as moment from 'moment-timezone';

export const Availability = NgModel.extend({
    _name: 'Availability',
    parse: function(data) {
        const dataFormatted = this._super('parse', data);

        if (data.startDate) {
            dataFormatted.startDate = moment.tz(dataFormatted.startDate.date, dataFormatted.startDate.timezone);
            dataFormatted.startDateFormattedDay = dataFormatted.startDate.format(this._momentDateFormatString);
            dataFormatted.startDateFormattedTime = dataFormatted.startDate.format('h:mm A');
            dataFormatted.timeSwathStartDate = formatSwathDate(dataFormatted.startDate);
        }

        if (data.endDate) {
            dataFormatted.endDate = moment.tz(dataFormatted.endDate.date, dataFormatted.endDate.timezone);
            dataFormatted.timeSwathEndDate = formatSwathDate(dataFormatted.endDate);
        }

        dataFormatted.timeSwath = new TimeSwath(dataFormatted.timeSwathStartDate, (dataFormatted.timeSwathEndDate - dataFormatted.timeSwathStartDate) / 1000);

        this.__bindManyForAngular(dataFormatted);

        return dataFormatted;
    },
});

Availability.prototype._momentDateFormatString = 'ddd[\n]M[/]D';

const formatSwathDate = (date) => Number(date.format('x'));
