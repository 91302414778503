import {CustomInput} from 'model/customInput';

export const TYPE_FILE = 'file';

export class CustomInputFile extends CustomInput {
    type = TYPE_FILE;

    constructor(data) {
        super(data);
    };

    isValid(inputData) {
        if (!super.isValid(inputData)) {
            return false;
        }

        if (this.required && !inputData.length) {
            this.error = 'This field must not be empty.';
            return false;
        }

        this.error = null;
        return true;
    }
}
