import {Backbone} from 'Vertebrae';
import {ProgrammingEvent} from '../model/programmingEvent';

export const ProgrammingEvents = Backbone.Collection.extend({
    _name: 'ProgrammingEvents',
    url: '/fair/programming-events',
    model: ProgrammingEvent
});

export const FairProgrammingEvents = Backbone.Collection.extend({
    _name: 'ProgrammingEvents',
    url: function() {
        return `/fair/${this.fairId}/programming-events`;
    },
    model: ProgrammingEvent,
    setFairId: function(id) {
        this.fairId = id;

        return this;
    },
});
