import * as _Backbone from 'backbone';
import * as _ from 'underscore';
import {saveAs} from 'file-saver';
import * as moment from 'moment-timezone';

/**
 * Vertebrae is a Backbone extension that allows for full chain inheritance and
 * deep model associations - as well as some helper functions like flatten
 * This one has been customized to handle our server's response package
 *  {
 *    status : ...,
 *    data : ...,
 *    success / error : ...
 *  }
 *
 */

export const Backbone = ((Backbone) => {
    /*********************************************************
     * CLASS INHERITANCE
     *********************************************************/
    var _super = function(functionName) {
        if (!this.parentalCount) {
            this.parentalCount = {};
        }

        var stackTracker = functionName;
        if (stackTracker === 'set') {
            // for the set function, we append the key we're setting so that we can avoid the bug when setting a model
            // attribute inside the change event of a different model attribute.
            //
            // MEMORY REFRESHER:
            // remember when you wanted to make all trigger events asynchronous because the filters on the search
            // page weren't appearing? Well they weren't appearing because you were calling model.set() inside the
            // event handler, so the call stack looked like:
            // model.set('akey')
            //   model.trigger()
            //     model.on()
            //       model.set('adifferentkey')
            //       ^--- this call to set would use the same parentalCount key as the initial set call
            // (i.e., it wouldn't restart the parents chain at the first parent like we would want)
            // So by also appending the key we're setting to the parentalCount stack tracker, we make them unique and
            // therefore resolve the bug without needing to make individual trigger handlers asynchronous
            var key = arguments[1];
            stackTracker = stackTracker + ':-:' + (
                typeof key === 'string'
                    ? key
                    : _.keys(key).join(':-:')
            );
        }

        this.parentalCount[stackTracker] = this.parentalCount[stackTracker] ? this.parentalCount[stackTracker] : 1;

        var thisParentPosition = this.constructor.parents.length - this.parentalCount[stackTracker];

        if (thisParentPosition >= 0 && this.constructor.parents[thisParentPosition].prototype[functionName]) {
            this.parentalCount[stackTracker]++;
            var retVal = this.constructor.parents[thisParentPosition].prototype[functionName].apply(this,
                _.rest(arguments));
            delete this.parentalCount[stackTracker];
            return retVal;
        } else {
            delete this.parentalCount[stackTracker];
        }
    };

    Backbone.Model.prototype._super =
        Backbone.Collection.prototype._super =
            Backbone.Router.prototype._super =
                Backbone.View.prototype._super =
                    Backbone.History.prototype._super =
                        _super;

    var extend = function(protoProps, staticProps) {
        var parent = this;
        var child;

        // The constructor function for the new subclass is either defined by you
        // (the "constructor" property in your `extend` definition), or defaulted
        // by us to simply call the parent constructor.
        if (protoProps && _.has(protoProps, 'constructor')) {
            child = protoProps.constructor;
        } else {
            child = function() {
                return parent.apply(this, arguments);
            };
        }

        // Add static properties to the constructor function, if supplied.
        _.extend(child, parent, staticProps);

        // Set the prototype chain to inherit from `parent`, without calling
        // `parent`'s constructor function and add the prototype properties.
        child.prototype = _.create(parent.prototype, protoProps);
        child.prototype.constructor = child;

        // here we construct the complete inheritance chain for this new class
        child.parents = parent.parents ? parent.parents.slice() : [];

        // and we add this parent to it
        child.parents.push(parent);

        // We keep this here for legacy reasons (in case existing backbone code uses it)
        // but using this function should be avoided in favor of the new _super
        // function which respects the full chain of inheritance
        child.__super__ = parent.prototype;

        return child;
    };

    // overwrite the existing extend function
    Backbone.Model.extend =
        Backbone.Collection.extend =
            Backbone.Router.extend =
                Backbone.View.extend =
                    Backbone.History.extend =
                        extend;

    /*********************************************************
     * JSON PARSING
     *********************************************************/
    /*
     * The parse functionality addresses seamless model nesting. More here:
     * http://stackoverflow.com/questions/6535948/nested-models-in-backbone-js-how-to-approach
     * params: JSON object
     * returns: Object with built associations
     */
    Backbone.Model.prototype.parse = function(data, options) {
        // we pull this model's data to the root (out of its model wrapper)
        if (data[this._name]) {
            for (var attribute in data[this._name]) {
                data[attribute] = data[this._name][attribute];
            }
        }

        if (!options) {
            options = {};
        }

        options.parse = true;

        delete data[this._name];

        var that = this;
        if (this.associations) {
            // we create the associated models
            for (var key in this.associations) {
                if (typeof this.associations[key] === 'string') {
                    throw new Error('Association \'' + key + '\' is a string, this is not supported anymore');
                }
                var embeddedClass = this.associations[key];
                var embeddedData = data[key];
                if (!embeddedData || !embeddedClass) {
                    continue;
                }
                // we create the sub Model / Collection if it's not already instantiated
                if (!(embeddedData instanceof embeddedClass)) {
                    data[key] = new embeddedClass(embeddedData, options);
                }

                // then we bind a listener for change events so they'll propagate up the association chain
                this.listenTo(data[key], 'all', function(name, one, two) {
                    that.trigger(name, one, two);
                });
            }
        }
        return this.defaults ? _.extend(_.extend({}, this.defaults), data) : data;
    };

    /*
     * This parse function takes an array of JSON objects and converts them inline into
     * an array of corresponding Models (defined by the collection's model attribute).
     * params: array of JSON objects
     * returns: array of Models
     */
    Backbone.Collection.prototype.parse = function(data, options) {
        // we pull this model's data to the root (out of its model wrapper)
        if (data[this._name]) {
            return this.parse(data[this._name], options);
        }

        if (!options) {
            options = {};
        }

        if (options._index) {
            this._index = options._index;
        }

        options.parse = true;

        for (var i = 0, len = data.length; i < len; i++) {
            data[i] = new this.model(data[i], options);
        }
        return data;
    };

    Backbone.Collection.prototype.save = function(options) {
        return Backbone.sync('create', this, options);
    };

    Backbone.Collection.prototype.exportCsv = function(options) {
        if (!this.length) {
            throw 'Cannot download no data';
        }

        let baseOptions = {
            valueSeparator: ',',
            rowSeparator: '\n',
            quote: '"',
        };

        baseOptions = {...baseOptions, ...options};

        const DELIMITER = `${baseOptions.quote}${baseOptions.valueSeparator}${baseOptions.quote}`;

        let csv = `${baseOptions.quote}${this.at(0).getDownloadHeaders().join(DELIMITER)}${baseOptions.quote}${baseOptions.rowSeparator}`;
        const now = new Date();
        const dateString = moment.tz(now, moment.tz.guess()).format('Y_M_D');
        const downloadName = `${window.document.title}_${dateString}.csv`;

        this.each(function(row) {
            csv += `${baseOptions.quote}${row.getDownloadData().join(DELIMITER)}${baseOptions.quote}${baseOptions.rowSeparator}`;
        })

        let blob = new Blob([csv], {type: 'text/csv;charstet=utf-8'});
        saveAs(blob, downloadName);
    };



    /*********************************************************
     * MODEL TO JSON
     *********************************************************/
    /*
     * This function returns this model's attributes with each of its associated models also in attribute form
     * There is a similar function for Collections
     * params: none
     * returns: JSON object with attributes and flattened associated models
     */
    Backbone.Model.prototype.flatten = function() {
        var retVal = _.clone(this.attributes);
        if (this.associations) {
            for (var key in this.associations) {
                if (this.get(key)) {
                    retVal[key] = this.get(key).flatten();
                }
            }
        }

        return (_.isEmpty(retVal) ? null : retVal);
    };

    /*
     * This function is used to flatten the collection into an array of its flattened models
     * params: none
     * returns: array of JSON objects
     */
    Backbone.Collection.prototype.flatten = function() {
        var retVal = [];
        for (var i = 0, len = this.models.length; i < len; i++) {
            var data = this.models[i].flatten();
            if (data) {
                retVal.push(data);
            }
        }

        return retVal;
    };

    /*********************************************************
     * SYNCING DATA
     *********************************************************/
    /*
     * This function overrides the Backbone sync to automatically parse the JSON data
     * into expected associated models and collections
     * params : [same as Backbone.sync]
     * return : [same as Backbone.sync]
     */
    Backbone.Model.prototype.sync = function(method, model, options) {
        options = options ? options : {};

        var oldSuccess = options.success;

        options.success = function(data, status, obj) {
            data = data.data;
            if (typeof oldSuccess === 'function') {
                oldSuccess(data, status, obj);
            }
        };
        return Backbone.sync(method, model, options);
    };
    Backbone.Collection.prototype.sync = Backbone.Model.prototype.sync;

    Backbone.Model.prototype.originalUrl = Backbone.Model.prototype.url;

    /*
     * This function will replace url params enclosed in square brackets []
     * with the value of the model's property of the same name. For example:
     * "/users/[id]/fetch"
     * would become
     * "/users/56/fetch"
     */
    Backbone.Model.prototype.url = function() {
        if (!this.urlRoot) {
            return Backbone.Model.prototype.originalUrl.apply(this, arguments);
        }
        var patt = /\[(\w+)\]/g;
        var url = this.urlRoot;
        var parts = url.match(patt);
        if (parts && parts.length) {
            for (var i = 0; i < parts.length; i++) {
                url = url.replace(parts[i], this.get(parts[i].replace(/[\[\]]/g, '')) || '');
            }
        }
        return url;
    };

    Backbone.Model.prototype.cancelChanges = function(options) {
        var changed = this.changedAttributes();

        if (!changed) {
            return;
        }

        var keys = _.keys(changed);
        var prev = _.pick(this.previousAttributes(), keys);

        this.set(prev, options);
    };

    /*********************************************************
     * RENDERING AND UN-RENDERING
     *********************************************************/
    /*
     * This function helps views quickly remove themselves from the DOM
     * params : [boolean] is remove the el or not
     * returns : this view object
     */
    Backbone.View.prototype.unrender = function(removeSelf) {
        if (removeSelf) {
            this.$el.remove();
        } else {
            this.$el.empty();
        }

        return this;
    };

    /*********************************************************
     *********************************************************
     *********************************************************
     *********************************************************/

    return Backbone;
})(_Backbone);
