import {whitelabelling as Whitelabelling} from 'whitelabelling';
import {Exposure} from '../../model/exposure';

export function userInputExposure() {
    return {
        restrict: 'E',
        scope: {
            data: '=',
            isSaving: '=',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/userInputs/userInputExposure.html',
        controller: [
            '$scope', function($scope) {
                var ExposureModel = new Exposure();
                $scope.limits = [
                    {
                        value: ExposureModel.UNLIMITED_CUTOFF,
                        label: 'Unlimited',
                    },
                ];

                var maximumMonthlyConsultsOption = 20;
                if (Whitelabelling.settings.maximumMonthlyConsultsOption) {
                    maximumMonthlyConsultsOption = Whitelabelling.settings.maximumMonthlyConsultsOption;
                }

                for (var i = 0; i <= maximumMonthlyConsultsOption; i++) {
                    $scope.limits.push({
                        value: i,
                        label: i,
                    });
                }

                $scope.hideEvisors = Whitelabelling.hideGlobalAdvisorPoolOptIn;
                $scope.disableSelectForNetworkExposure = Whitelabelling.settings.disallowNetworkExposureChange;
                $scope.affiliatedInstitution = Whitelabelling.settings['custom:affiliatedInstitutionLabel'];

                $scope.confirmationExplanation = '';
                $scope.isOnCAP = function() {
                    return $scope.data && ($scope.data.limit_Contract_0 > 0 || $scope.data.limit_Contract_20 > 0 ||
                        $scope.data.limit_Contract_40 > 0) && !$scope.hideEvisors;
                };

                $scope.isOnEvisors = function() {
                    return $scope.data.limit_Public > 0 && !$scope.hideEvisors;
                };

                $scope.saveData = function() {
                    $scope.$emit('saveData');
                };
            },
        ],
    };
}
