import {MeetingConnectionStatus} from '../model/meetingConnectionStatus';

export const MeetingConnectionService = [
    '$q', '$http', '$timeout', 'Meeting', function($q, $http, $timeout, Meeting) {
        var CONNECTION_STATUS_POLL = 5;

        var lastUpdated = null;
        var status = new MeetingConnectionStatus();
        var previousStatus;
        var promise = null;

        var consumerCallbacks = [];
        var expertCallbacks = [];

        // var pollingTimeout = null;
        // var isPolling = false;

        var isLocalVoipConnected = false;
        var isLocalVideoConnected = false;

        var currentMeeting;
        Meeting.getByRouteParams().then(function(meeting) {
            currentMeeting = meeting;
        });

        // function poll() {
        //     if (!isPolling) {
        //         $timeout.cancel(pollingTimeout);
        //         pollingTimeout = null;
        //     } else {
        //         retVal.update();
        //         pollingTimeout = $timeout(poll, POLL_FREQUENCY);
        //     }
        // }

        // function startPolling() {
        //     if (!isPolling) {
        //         isPolling = true;
        //         poll();
        //     }
        // }

        var retVal = {
            $scopes: [],
            addScope: function($scope) {
                retVal.$scopes.push($scope);
            },
            broadcastMeetingPoll: function(force) {
                if (retVal.$scopes) {
                    if (force || retVal.audioConnectionHasChanged()) {
                        // Only broadcast if a change occurred.
                        for (var i = 0; i < retVal.$scopes.length; i++) {
                            retVal.$scopes[i].$broadcast('meeting-poll');
                        }
                    }
                }
            },
            audioConnectionHasChanged: function() {
                var currentStatus = status.flatten();
                if (currentStatus.consumerConnectedAudio !== previousStatus.consumerConnectedAudio ||
                    currentStatus.consumerConnectedAudioVoip !== previousStatus.consumerConnectedAudioVoip ||
                    currentStatus.consumerConnectedAudioPhone !== previousStatus.consumerConnectedAudioPhone ||
                    currentStatus.expertConnectedAudio !== previousStatus.expertConnectedAudio ||
                    currentStatus.expertConnectedAudioVoip !== previousStatus.expertConnectedAudioVoip ||
                    currentStatus.expertConnectedAudioPhone !== previousStatus.expertConnectedAudioPhone ||
                    currentStatus.expertIsPresent !== previousStatus.expertIsPresent ||
                    currentStatus.consumerIsPresent !== previousStatus.consumerIsPresent) {

                    return true;
                }

                return false;
            },
            notifyCallbacksConsumer: function() {
                _.each(consumerCallbacks, function(callback) {
                    callback(status);
                });
                // clear out the callbacks array
                consumerCallbacks = [];
            },
            notifyCallbacksExpert: function() {
                _.each(expertCallbacks, function(callback) {
                    callback(status);
                });
                // clear out the callbacks array
                expertCallbacks = [];
            },
            update: function(force) {
                // if a request is already pending, just return that
                if (promise) {
                    return promise;
                }

                var deferred = $q.defer();
                promise = deferred.promise;
                Meeting.getByRouteParams().then(function(meeting) {
                    currentMeeting = meeting;
                    if (!status.get('meetingid')) {
                        status.set('meetingid', meeting.get('id'));
                    }

                    previousStatus = status.flatten();

                    status.fetch().then(function() {
                        lastUpdated = new Date();
                        promise = null;
                        deferred.resolve.call(this, status);

                        retVal.broadcastMeetingPoll(force);

                        // if the consumer is connected by phone, we want to notify callbacks for consumer connected
                        if (retVal.isConsumerConnectedAudioPhone() && consumerCallbacks.length) {
                            retVal.notifyCallbacksConsumer();
                        }

                        // if the expert is connected by phone, we want to notify callbacks for expert connected
                        if (retVal.isExpertConnectedAudioPhone() && expertCallbacks.length) {
                            retVal.notifyCallbacksExpert();
                        }

                        // if we have no more watchers set up, we stop polling
                        // isPolling = expertCallbacks.length || consumerCallbacks.length;
                    }, function() {
                        promise = null;
                        deferred.reject.apply(this, arguments);
                    });
                }, function() {
                    promise = null;
                    deferred.reject.apply(this, arguments);
                });

                return deferred.promise;
            }
            , getLastUpdated: function() {
                return lastUpdated;
            }
            , get: function() {
                // if a request is already pending, just return that
                if (promise) {
                    return promise;
                }

                var deferred = $q.defer();
                // we update once every 5 seconds
                if (!lastUpdated || (new Date()) - lastUpdated > (CONNECTION_STATUS_POLL * 1000)) {
                    retVal.update().then(deferred.resolve, deferred.reject);
                } else {
                    deferred.resolve(status);
                }

                return deferred.promise;
            }
            , isCurrentUserConnectedVideo: function() {
                if (currentMeeting.get('visitorIsConsumer')) {
                    return retVal.isConsumerConnectedVideo();
                } else {
                    return retVal.isExpertConnectedVideo();
                }
            }
            , isCurrentUserConnectedAudio: function() {
                if (currentMeeting.get('visitorIsConsumer')) {
                    return retVal.isConsumerConnectedAudio();
                } else {
                    return retVal.isExpertConnectedAudio();
                }
            }
            , isCurrentUserConnectedAudioVoip: function() {
                if (currentMeeting.get('visitorIsConsumer')) {
                    return retVal.isConsumerConnectedAudioVoip();
                } else {
                    return retVal.isExpertConnectedAudioVoip();
                }
            }
            , isCurrentUserConnectedAudioPhone: function() {
                if (currentMeeting.get('visitorIsConsumer')) {
                    return retVal.isConsumerConnectedAudioPhone();
                } else {
                    return retVal.isExpertConnectedAudioPhone();
                }
            }
            , isConsumerPresent: function() {
                return status.get('consumerIsPresent');
            }
            , isConsumerConnectedAudio: function() {
                return status.get('consumerConnectedAudio');
            }
            , isConsumerConnectedAudioVoip: function() {
                return status.get('consumerConnectedAudioVoip');
            }
            , isConsumerConnectedAudioPhone: function() {
                return status.get('consumerConnectedAudioPhone');
            }
            , isConsumerConnectedVideo: function() {
                return status.get('consumerConnectedVideo');
            }
            , isExpertPresent: function() {
                return status.get('expertIsPresent');
            }
            , isExpertConnectedAudio: function() {
                return status.get('expertConnectedAudio');
            }
            , isExpertConnectedAudioVoip: function() {
                return status.get('expertConnectedAudioVoip');
            }
            , isExpertConnectedAudioPhone: function() {
                return status.get('expertConnectedAudioPhone');
            }
            , isExpertConnectedVideo: function() {
                return status.get('expertConnectedVideo');
            }
            , hadConsumerConnectedBefore: function() {
                return status.get('consumerEarlier');
            }
            , hadExpertConnectedBefore: function() {
                return status.get('expertEarlier');
            }
            , requestCall: function() {
                var deferred = $q.defer();

                Meeting.getByRouteParams().then(function(meeting) {
                    $http.post('/consultations/' + meeting.get('id') + '/call.json', {}).
                        then(deferred.resolve, deferred.reject);
                }, deferred.reject);

                return deferred.promise;
            }
            , whenConsumerConnects: function(callback) {
                consumerCallbacks.push(callback);
                // startPolling();
            }
            , whenExpertConnects: function(callback) {
                expertCallbacks.push(callback);
                // startPolling();
            }
            , setConnectionStatus: function(isCurrentUser, isAudio, isConnected) {
                var statusString = '';

                previousStatus = status.flatten();

                if (isCurrentUser) {
                    // Current user.
                    if (currentMeeting.get('visitorIsConsumer')) {
                        // Current user is consumer.
                        statusString = 'consumerConnected';
                    } else {
                        // Current user is expert.
                        statusString = 'expertConnected';
                    }
                } else {
                    // Other user
                    if (currentMeeting.get('visitorIsConsumer')) {
                        // Other user is expert.
                        statusString = 'expertConnected';
                    } else {
                        statusString = 'consumerConnected';
                    }
                }

                if (isAudio) {
                    status.set(statusString + 'Audio', isConnected);
                } else {
                    status.set(statusString + 'Video', isConnected);
                }

                retVal.broadcastMeetingPoll();
            }
            , setCurrentUserStatusAudio: function(isConnected) {
                retVal.setConnectionStatus(true, true, isConnected);
            }
            , setCurrentUserStatusVideo: function(isConnected) {
                retVal.setConnectionStatus(true, false, isConnected);
            }
            , setOtherUserStatusAudio: function(isConnected) {
                retVal.setConnectionStatus(false, true, isConnected);
            }
            , setOtherUserStatusVideo: function(isConnected) {
                retVal.setConnectionStatus(false, false, isConnected);
            }
            , setLocalVoipConnected: function(isConnected) {
                if (isLocalVoipConnected === isConnected) {
                    return;
                }
                isLocalVoipConnected = isConnected;
                retVal.broadcastMeetingPoll(true);
            }
            , setLocalVideoConnected: function(isConnected) {
                if (isLocalVideoConnected === isConnected) {
                    return;
                }
                isLocalVideoConnected = isConnected;
                retVal.broadcastMeetingPoll(true);
            }
            , isLocalVoipConnected: function() {
                return isLocalVoipConnected;
            }
            , isLocalVideoConnected: function() {
                return isLocalVideoConnected;
            }
        };

        return retVal;
    },
];
