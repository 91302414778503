import {whitelabelling as Whitelabelling} from 'whitelabelling';
import {RegistrationData} from 'model/registrationData';
import {Employment} from 'model/employment';
import {CUSTOM_INPUT_FILE_ADDED} from 'directives/customInputFile';

export function editProfile() {
    return {
        restrict: 'E',
        scope: {
            userid: '=?',
            currentSection: '=?',
            onSectionChanged: '&?',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel +
            'ng-template/partials/angular-utilities/directives/editProfile.tpl.html',
        link: function(scope, element, attrs) {
            // If the directive had an "userid" attribute, then we need to wait for that to load.
            // If not, then it's OK to load without a userID, and just load for "myself".
            scope.waitForUserId = 'userid' in attrs;
        },
        controller: [
            '$scope',
            'VisitorService',
            'RegistrationService',
            'CustomInputService',
            '$q',
            '$anchorScroll',
            '$timeout',
            function($scope, VisitorService, RegistrationService, CustomInputService, $q, $anchorScroll, $timeout) {
                $scope.whitelabelling = Whitelabelling;

                $scope.isSavingData = false;
                $scope.error = null;

                $scope.sections = {
                    SECTION_PERSONAL_INFO: 'personal_info',
                    SECTION_ABOUT_ME: 'about_me',
                    SECTION_EDUCATION: 'education',
                    SECTION_WORK_EXPERIENCE: 'work_experience',
                    SECTION_GROUPS: 'groups',
                    SECTION_CAREER_GOALS: 'career_goals',
                    SECTION_CUSTOM: 'custom',
                }

                $scope.currentSection = $scope.currentSection ? $scope.currentSection : $scope.sections.SECTION_PERSONAL_INFO;

                $scope.hasCustomSection = false;

                $scope.affiliatedSchool = Whitelabelling.affiliatedSchool ? Whitelabelling.affiliatedSchool : null;
                $scope.careerBasicsProfile = Whitelabelling.settings['hide:mentoringRelatedFeatures'];

                $scope.changeSection = function(newSection) {
                    if (newSection !== $scope.currentSection) {
                        $scope.currentSection = newSection;
                        angular.isFunction($scope.onSectionChanged) && $scope.onSectionChanged({$event: newSection});
                    }
                };

                $scope.showCustomInputsSubsection = function(section) {
                    for (const [_, value] of Object.entries($scope.sections)) {
                        if (section === value) {
                            return false;
                        }
                    }

                    return true;
                };

                $scope.hideEducation = Whitelabelling.settings['hideField:education'];
                $scope.hideEmployment = Whitelabelling.settings['hideField:employment'];

                $scope.saveData = function() {
                    $scope.isSavingData = true;
                    $scope.registrationData.CustomInputs = $scope.registrationData.CustomInputs || {file: {}};
                    const filesMap = $scope.registrationData.CustomInputs.file; // fileMap will be filled with new files
                    CustomInputService.uploadCustomInputResponseFiles($scope.customInputFilesToAdd, filesMap)
                        .then(() => {
                            $q.when($scope.registrationData.saveProfile()).then(function(responseObj) {
                                $scope.isSavingData = false;
                                if (responseObj.status === 401) {
                                    $scope.error = 'Could not save!';
                                } else if (responseObj.status === 200) {
                                    $scope.showSuccessMessage = true;
                                    $timeout(function() {
                                        $scope.showSuccessMessage = false;
                                    }, 3000);
                                }

                                if ($scope.registrationData && $scope.registrationData.User &&
                                    $scope.registrationData.User.saveErrors &&
                                    $scope.registrationData.User.saveErrors.emailCollision) {
                                    $anchorScroll('user-input-email-anchor');
                                }

                                $scope.$broadcast('profileSaved');
                            });
                        })
                        .catch(_ => {
                            $scope.isSavingData = false;
                            $scope.error = 'Could not upload attachments!';
                        });
                };

                $scope.hasGroups = function() {
                    // must be on a whitelabel that has groups
                    return Whitelabelling.id && $scope.registrationData &&
                        $scope.registrationSettings &&
                        $scope.registrationSettings.AffinityGroupCategories &&
                        $scope.registrationSettings.AffinityGroupCategories.length;
                };

                $scope.hasCareerGoalLocations = function() {
                    return $scope.registrationSettings &&
                        !$scope.registrationSettings.WhitelabelSettings['hideField:careerGoal_locations'];
                };
                $scope.hasCareerGoalIndustries = function() {
                    return $scope.registrationSettings &&
                        !$scope.registrationSettings.WhitelabelSettings['hideField:careerGoal_industries'];
                };
                $scope.hasCareerGoalEmployers = function() {
                    return $scope.registrationSettings &&
                        !$scope.registrationSettings.WhitelabelSettings['hideField:careerGoal_employers'];
                };
                $scope.hasCareerGoalRoles = function() {
                    return $scope.registrationSettings &&
                        !$scope.registrationSettings.WhitelabelSettings['hideField:careerGoal_functions'];
                };

                $scope.hasCareerGoals = function() {
                    // must be on a whitelabel that has groups
                    return ($scope.hasCareerGoalLocations() || $scope.hasCareerGoalIndustries() ||
                        $scope.hasCareerGoalEmployers() || $scope.hasCareerGoalRoles());
                };

                $scope.isConsumer = function() {
                    if ($scope.registrationData instanceof RegistrationData) {
                        return $scope.registrationData.hasRole(RegistrationData.ROLE_CONSUMER);
                    } else {
                        return false;
                    }
                };
                $scope.isExpert = function() {
                    if ($scope.registrationData instanceof RegistrationData) {
                        return $scope.registrationData.hasRole(RegistrationData.ROLE_EXPERT);
                    } else {
                        return false;
                    }
                };

                $scope.$on('CustomInputChanged', function(evt, customInput, newData) {
                    if (evt.currentScope.isSavingData && newData.length === 0) {
                        return;
                    }
                    $scope.customInputs.validate(customInput, newData);
                });

                $scope.customInputFilesToAdd = {};
                $scope.$on(CUSTOM_INPUT_FILE_ADDED, (_, customInputId, files) => {
                    $scope.customInputFilesToAdd[customInputId] = files;
                });

                $scope.$watch('userid', function() {
                    if ($scope.waitForUserId && !$scope.userid) {
                        return;
                    }
                    $q.all([
                        RegistrationService.getRegistrationDataForProfile($scope.userid),
                        RegistrationService.getRegistrationSettings($scope.userid),
                        CustomInputService.getCustomInputs(),
                        VisitorService.getVisitor()
                    ]).then(function([registrationData, registrationSettings, customInputs, visitor]) {
                        $scope.registrationData = registrationData;
                        $scope.registrationSettings = registrationSettings;
                        $scope.customInputs = customInputs;
                        $scope.userCanChangeEmail =  !registrationData.wasSsoProvisioned;
                        $scope.adminCanChangeEmail = !registrationData.wasSsoProvisioned || Whitelabelling.settings['adminCanChangeSSOEmail'];
                        $scope.isAdmin = visitor.isAdmin;

                        let inputs = $scope.customInputs.sections.edit_profile_custom ? $scope.customInputs.sections.edit_profile_custom.inputs : [];
                        $scope.customInputSections = {};
                        if (inputs.length > 0) {
                            inputs.forEach(input => {
                                $scope.customInputSections[input['type'] + '_' + input['id']] = input['label'];
                            });
                        }

                        $scope.hasCustomSection = CustomInputService.hasEditProfileCustomSection();
                        $scope.registrationData = CustomInputService.mergeCustomInputPlaceholdersIntoRegistrationData($scope.registrationData);
                        $scope.registrationData.wasLoggedIn = true;
                        // notify the app that we're ready
                        $scope.isLoaded = true;

                        $scope.$emit('controllerLoaded', true);

                        if ($scope.registrationSettings.AffinityGroupCategories.length) {
                            $scope.categoriesToChooseFrom = $scope.registrationSettings.AffinityGroupCategories;
                        }

                        if ($scope.isExpert() && !$scope.registrationData.EmploymentHistory.length) {
                            // No Education data...
                            $scope.registrationData.EmploymentHistory.add(new Employment({}, {parse: true}));
                        }
                    });
                });
            },
        ],
    };
}
