export function customInputRange() {
    return {
        restrict: 'E',
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/customInputRange.html',
        scope: {
            customInput: '=',
            data: '=',
        },
        require: 'ngModel',
        controller: [
            '$scope', function($scope) {
                $scope.input = {
                    lowerValue: $scope.data ? $scope.data.lowerValue : null,
                    upperValue: $scope.data ? $scope.data.upperValue : null,
                    otherValue: $scope.data ? $scope.data.otherValue : null,
                };

                $scope.$watch('input', () => {
                    $scope.data = $scope.input;
                    $scope.$emit('CustomInputChanged', $scope.customInput, $scope.data);
                }, true);

                $scope.markedAsOther = () => {
                    $scope.data = {'otherValue': $scope.customInput.otherValue};
                    $scope.$emit('goToNextSubStep');
                };
            },
        ],
    };
}
