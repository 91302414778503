import {NgModel} from './ngModel';
import {Locations} from '../collection/locations';
import {Employers} from '../collection/employers';
import {Industries} from '../collection/industries';
import {Roles} from '../collection/roles';

export const CareerGoals = NgModel.extend({
    _name: 'CareerGoals',
    associations: {
        Locations,
        Industries,
        Employers,
        Roles,
    },
    defaults: {
        Locations: new Locations(),
        Industries: new Industries(),
        Employers: new Employers(),
        Roles: new Roles(),
    },
    isComplete: function() {
        this.validateData();
        var errors = this.get('errors');
        var keys = _.keys(errors);
        if (keys.length === 0) {
            return true;
        }
        var isComplete = true;
        _.every(keys, function(k) {
            isComplete = !errors[k];
            return isComplete;
        });
        return isComplete;
    },
    validateData: function() {
        var errors = {};

        errors.Locations = this.get('Locations').length === 0;
        errors.Industries = this.get('Industries').length === 0;
        errors.Employers = this.get('Employers').length === 0;
        errors.Roles = this.get('Roles').length === 0;

        this.set('errors', errors);
        return errors;
    },
});
