import {NgModel} from 'model/ngModel';

// wait half a second before firing the request to prevent spamming
const TIMEOUT_WAIT = 500;

export function prefixComplete() {
    return {
        restrict: 'E',
        scope: {
            resource: '=',
            blur: '=?',
            placeholder: '@',
            allowCustom: '@?',
            labelOnly: '@?',
            labelKey: '@?',
            disabled: '=?',
        },
        require: 'ngModel',
        replace: true,
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/registration/directives/prefixComplete.html',
        controller: [
            '$scope', '$timeout', '$window', function($scope, $timeout, $window) {
                if ($scope.disabled) {
                    $scope.disabled = false;
                }
                $scope.labelKey = $scope.labelKey ? $scope.labelKey : 'label';

                var collection = null;

                var timeout = null;

                $scope.reset = function(resetInput) {
                    $scope.lastValidSelection = null;
                    $scope.loading = false;
                    $scope.selectedIdx = null;

                    if (resetInput) {
                        $scope.input = {
                            val: '',
                            options: [],
                        };
                    }
                };

                $scope.reset(true);

                $scope.keyboardHandler = function(keyEvent) {
                    $scope.$evalAsync(function() {
                        switch (keyEvent.key) {
                            case 'Down':
                            case 'ArrowDown':
                                if ($scope.selectedIdx === null) {
                                    $scope.selectedIdx = 0;
                                } else if ($scope.selectedIdx < ($scope.input.options.length - 1)) {
                                    $scope.selectedIdx++;
                                }
                                if ($scope.input.options[$scope.selectedIdx]) {
                                    $scope.input.val = $scope.input.options[$scope.selectedIdx].label;
                                }
                                break;
                            case 'Up':
                            case 'ArrowUp':
                                if ($scope.selectedIdx < 1) {
                                    $scope.selectedIdx = null;
                                } else {
                                    $scope.selectedIdx--;
                                }
                                if ($scope.input.options[$scope.selectedIdx]) {
                                    $scope.input.val = $scope.input.options[$scope.selectedIdx].label;
                                }
                                break;
                            case 'Enter':
                                if ($scope.selectedIdx !== null && $scope.input.options[$scope.selectedIdx]) {
                                    $scope.handleItemSelect($scope.input.options[$scope.selectedIdx]);
                                    $scope.reset();
                                }
                                break;
                        }
                    });
                };

                $scope.closePopup = function() {
                    $scope.open = false;
                    $scope.selectedIdx = null;
                    $scope.$apply();
                };

                $scope.onChange = function() {
                    if (!$scope.input.val || !$scope.input.val.length) {
                        $scope.handleItemSelect(null);
                        $scope.loading = $scope.open = false;
                        $window.removeEventListener('click', $scope.closePopup);
                        $scope.element[0].removeEventListener('keydown', $scope.keyboardHandler);
                        return;
                    }

                    // there really wasn't a change at all...
                    if ($scope.input.val === $scope.lastValidSelection) {
                        return;
                    }

                    $scope.lastValidSelection = false;

                    $scope.loading = $scope.open = true;
                    $window.addEventListener('click', $scope.closePopup);
                    $scope.element[0].addEventListener('keydown', $scope.keyboardHandler);

                    if (timeout) {
                        // cancel it
                        $timeout.cancel(timeout);
                    }

                    timeout = $timeout(function() {
                        $scope.resource($scope.input.val).then(function(c) {
                            collection = c;

                            if (!collection.length) {
                                $scope.input.options = [];
                            } else {
                                $scope.input.options = collection.models ? collection.models : collection;
                            }

                            $scope.loading = false;
                        });
                    }, TIMEOUT_WAIT);
                };

                $scope.blurred = function() {
                    $timeout(() => {
                        // if we're allowing them to enter their own then we are going to create a custom model with
                        // just a label
                        if ($scope.allowCustom && !$scope.lastValidSelection) {
                            if ($scope.input.val) {
                                // if we know the model type (thanks to the Resource look up) we use that
                                // otherwise, we just make a generic model
                                var model = collection ? collection.model : NgModel;
                                var data = {
                                    id: null,
                                };
                                data[$scope.labelKey] = $scope.input.val;
                                $scope.handleItemSelect(new model(data));
                            } else {
                                $scope.handleItemSelect(null);
                            }
                        }

                        if (typeof $scope.blur === 'function') {
                            $scope.blur($scope.input.val);
                        }
                    }, 300);
                };
            },
        ],
        link: function(scope, element, attrs, ngModel) {
            scope.element = element;
            scope.handleItemSelect = function(i) {
                scope.selectedIdx = null;
                if (i) {
                    scope.open = false;
                    scope.input.val = i[scope.labelKey];
                    scope.lastValidSelection = i[scope.labelKey];
                    // the selected item will be passed with ngModel
                    ngModel.$setViewValue(scope.labelOnly ? i[scope.labelKey] : i);
                } else {
                    scope.lastValidSelection = null;
                    ngModel.$setViewValue(null);
                }
            };

            ngModel.$render = function() {
                let renderVal = null;
                if (ngModel.$modelValue) {
                    renderVal = scope.labelOnly ? ngModel.$modelValue : ngModel.$modelValue[scope.labelKey];
                }
                scope.input.val = renderVal;
            };
        },
    };
}

