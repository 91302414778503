export const CRITERIA_CURRENT_LOCATION = 'current-location';
export const CRITERIA_GROUPS = 'group-membership';
export const CRITERIA_DESIRED_WORK_LOCATION = 'desired-work-location';
export const CRITERIA_DESIRED_EMPLOYER = 'desired-employer';
export const CRITERIA_DESIRED_ROLE = 'desired-role';
export const CRITERIA_DESIRED_INDUSTRY = 'desired-industry';
export const CRITERIA_YEARS_SENIOR = 'years-senior';
export const CRITERIA_RESPONDS_QUICKLY = 'responds-quickly';
export const CRITERIA_HAS_PICTURE = 'has-picture';
export const CRITERIA_EXPERIENCED_EXPERT = 'experienced-expert';
export const CRITERIA_POSITIVELY_REVIEWED = 'positively-reviewed';
export const CRITERIA_JOINED_RECENTLY = 'joined-recently';
export const CRITERIA_RECENTLY_LOGGED_IN = 'recently-logged-in';
export const CRITERIA_NO_RECENT_BOOKINGS = 'no-recent-bookings';
export const CRITERIA_HIGHLY_AVAILABLE = 'highly-available';
export const CRITERIA_DEGREE = 'degree';
export const CRITERIA_CONCENTRATION = 'concentration';
export const CRITERIA_LANGUAGE = 'same-language';
export const CRITERIA_GENDER = 'same-gender';
export const CRITERIA_ETHNICITY = 'minority-status';
export const CRITERIA_CUSTOM_INPUT_PREFIX = 'customInput_';
