import * as _ from 'underscore';
import {JobRelatedExpertComparator} from '../model/jobRelatedExpertComparator';
import {AlgorithmUser} from 'model/algorithmUser';
import * as AlgorithmCriteria from 'algorithmCriteria';

export function jobRelatedExpertCriteria() {
    return {
        restrict: 'E',
        scope: {
            job: '=',
            userCriteria: '=',
            expert: '=',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/directives/search/jobRelatedExpertCriteria.html',
        replace: true,
        controller: [
            '$scope', '$timeout', '$window', 'AlgorithmService', function($scope, $timeout, $window, AlgorithmService) {
                $scope.showMatch = false;
                $scope.loaded = false;

                if ($scope.job && $scope.expert && $scope.userCriteria) {

                    var criteria = [
                        AlgorithmCriteria.CRITERIA_DESIRED_EMPLOYER,
                        AlgorithmCriteria.CRITERIA_DESIRED_INDUSTRY,
                        AlgorithmCriteria.CRITERIA_DESIRED_ROLE,
                        AlgorithmCriteria.CRITERIA_DESIRED_WORK_LOCATION,
                        AlgorithmCriteria.CRITERIA_CONCENTRATION,
                        AlgorithmCriteria.CRITERIA_DEGREE,
                        AlgorithmCriteria.CRITERIA_LANGUAGE,
                        AlgorithmCriteria.CRITERIA_GROUPS,
                    ];

                    // We're given a viewing User and a Job from which we will extract the match criteria to compare
                    // with the Expert.
                    var comparator = new JobRelatedExpertComparator();

                    _.each(criteria, function(criterion) {
                        if (criterion === 'topMatch') {
                            return;
                        }

                        var value;
                        if ($scope.job.get(criterion)) {
                            value = $scope.job.get(criterion);
                        } else if ($scope.userCriteria.hasOwnProperty(criterion)) {
                            value = $scope.userCriteria[criterion];
                        }

                        // Cast to array.
                        if (!Array.isArray(value)) {
                            value = [value];
                        }

                        comparator.set(AlgorithmUser.getUserKeyFromCriteriaKey(criterion, false), value);
                    });

                    AlgorithmService.setCriteria(criteria);
                    var matchedCriteria = AlgorithmService.getTopMatchCriteriaBetweenUsers($scope.expert, comparator,
                        $scope.expert.topMatchContainer, false);
                    AlgorithmService.resetCriteria();

                    $scope.criteriaList = [];

                    // Ensure Industry + Employer are not in same list.
                    _.each(matchedCriteria, function(matchedCriterion) {
                        if (matchedCriterion.key === AlgorithmUser.CRITERIA_DESIRED_INDUSTRY) {
                            if (_.findWhere(matchedCriteria, {key: AlgorithmUser.CRITERIA_DESIRED_EMPLOYER})) {
                                // Employer and Industry both exist. Don't add.
                                return;
                            }
                        }
                        $scope.criteriaList.push(matchedCriterion);
                    });

                    // Sort list
                    var priorities = {};
                    priorities[AlgorithmCriteria.CRITERIA_DESIRED_EMPLOYER] = 1;
                    priorities[AlgorithmCriteria.CRITERIA_DESIRED_ROLE] = 2;
                    priorities[AlgorithmCriteria.CRITERIA_DESIRED_INDUSTRY] = 3;
                    priorities[AlgorithmCriteria.CRITERIA_DESIRED_WORK_LOCATION] = 4;
                    priorities[AlgorithmCriteria.CRITERIA_CONCENTRATION] = 5;
                    priorities[AlgorithmCriteria.CRITERIA_GROUPS] = 6;
                    priorities[AlgorithmCriteria.CRITERIA_LANGUAGE] = 7;
                    priorities[AlgorithmCriteria.CRITERIA_DEGREE] = 8;

                    $scope.criteriaList = _.sortBy($scope.criteriaList, function(sortCriterion) {
                        return priorities[sortCriterion.key];
                    });

                    // we want non-partials at the top
                    $scope.criteriaList = _.sortBy($scope.criteriaList, function(val) {
                        return val.isPartial ? 1 : 0;
                    });
                    $scope.relevantCriteria = _.filter($scope.criteriaList, function(item) {
                        return (item.matches && item.matches.length > 0);
                    });

                    $scope.loaded = true;
                }
            },
        ],
    };
}
