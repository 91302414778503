/**
 * This directive creates a hidden select interface
 * use like:
 * <hidden-select options="..." ng-model="..."></hidden-select>
 *
 * options must be an array of elements with the structure:
 * {
 *   label : '...'
 * }
 */
export function hiddenSelect() {
    return {
        restrict: 'E',
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/hiddenSelect.html',
        require: 'ngModel',
        scope: {
            options: '=',
            open: '=?',
            groupKey: '@?',
            labelKey: '@?',
            ariaLabel: '@?',
            useId: '=?', // an optional boolean to control whether the model should be the selected option object, or
                        // the id property of it
        },
        link: function(scope, element, attrs, ngModel) {
            function updateSelectedValFromModel() {
                if (scope.useId) {
                    scope.selectedVal = _.findWhere(scope.options, {id: ngModel.$modelValue});
                } else {
                    scope.selectedVal = ngModel.$modelValue;
                }
            }

            scope.uniqueString = String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now();
            scope.labelKey = scope.labelKey || 'label';
            scope.ngModel = ngModel;
            updateSelectedValFromModel();

            ngModel.$render = function() {
                updateSelectedValFromModel();
            };

            scope.selectItem = function() {
                scope.open = false;
                if (scope.useId && scope.selectedVal) {
                    ngModel.$setViewValue(scope.selectedVal.id);
                } else {
                    ngModel.$setViewValue(scope.selectedVal);
                }
            };
        },
    };
}
