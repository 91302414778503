import {NgModel} from 'model/ngModel';
import {Backbone} from 'Vertebrae';
import * as _ from 'underscore';

export const SearchFilter = NgModel.extend({
    _name: 'SearchFilter',
    defaults: {
        label: '',
        key: '',
        type: 'text',
        value: null,
        options: null,
        filterConfig: null,
        isFiltering: false,
        isArrayType: false,
    },
    initialize: function(opt) {
        this._super('initialize', opt);

        var that = this;
        this.on('change:value', refreshValueLabels);

        // start us off right!
        if (that.get('value')) {
            refreshValueLabels();
        }

        function refreshValueLabels() {
            // this function is going to convert the values we send to the server (ids and lat lngs and whatever)
            // into human readable strings that we can display at the top of the search results page
            var value = that.get('value');
            var valueIsArray = Array.isArray(value);

            if (value && (!valueIsArray || value.length > 0)) {
                that.set('isFiltering', true);
                var values = [];

                if (valueIsArray) {
                    _.each(value, function(id) {
                        var parsedId = parseInt(id);
                        if (!isNaN(parsedId)) {
                            id = parsedId;
                        }
                        var selectedOption;
                        if (that.get('hasSubFilters')) {
                            that.get('options').every(function(innerFilter) {
                                selectedOption = _.findWhere(innerFilter.get('options'), {value: id});
                                if (selectedOption) {
                                    // break the loop
                                    return false;
                                }
                                return true;
                            });
                        } else {
                            selectedOption = _.findWhere(that.get('options'), {value: id});
                        }

                        if (selectedOption) {
                            values.push(selectedOption.label);

                            // make sure the option is marked as selected (in case it wasn't)
                            // this is needed when initially loading the search page from query params
                            selectedOption.isSelected = true;
                        }
                    });
                } else if (typeof value === 'object') {
                    if (that.get('type') === 'range') {
                        values.push('$' + value.min + ' - $' + value.max);
                    } else {
                        values.push(value.label);
                    }
                } else {
                    values.push(value);
                }

                that.set('valueLabels', values);
            } else {
                that.set('isFiltering', false);
            }
        }
    },
    parse: function(data) {
        var dataFormatted = this._super('parse', data);

        // if options is an array with a type, then it's actually a collection of other SearchFilters
        // TODO: If the filter only has 1 subfilter, then it doesn't really have subfilters... so copy that child's
        // options up
        if (dataFormatted.options && dataFormatted.options.length && dataFormatted.options[0].type) {

            // here we'd like to make options a SearchFilters collection, but that introduces a circular dependency
            // and even though requirejs claims to be able to support this... it seems unreliable
            // (breaking the collection's parse function with claims that "this.model" is undefined based on some
            // race condition). So we're making a generic backbone collection instead and manually instantiating
            // the models it contains
            var that = this;
            var options = [];
            _.each(dataFormatted.options, function(optionData) {
                options.push(new that.constructor(optionData, {parse: true}));
            });

            dataFormatted.options = new Backbone.Collection(options);
            dataFormatted.hasSubFilters = true;
        }

        dataFormatted.startOpen = this.asBoolean(dataFormatted.startOpen);

        dataFormatted.isArrayType = dataFormatted.type === 'multiple' || dataFormatted.type === 'multiple-plus';

        if (dataFormatted.type === 'multiple-plus' && dataFormatted.options && dataFormatted.options.length) {
            dataFormatted.options = dataFormatted.options.slice(0, Math.min(dataFormatted.options.length, 6));
        }

        this.__bindManyForAngular(dataFormatted);

        return dataFormatted;
    },
    removeValueByLabel: function(label) {
        // this function will remove the filter values based on its label
        // this action is not trivial in cases where the filter hasSubFilters, hence it gets its own function
        var that = this;
        var value = this.get('value');
        if (Array.isArray(value)) {
            if (that.get('hasSubFilters')) {
                that.get('options').each(function(innerFilter) {
                    var selectedOption = _.findWhere(innerFilter.get('options'), {label: label});
                    if (selectedOption) {
                        that.toggleOption(selectedOption, false);
                        return false;
                    }
                });
            } else {
                var selectedOption = _.findWhere(that.get('options'), {label: label});
                if (selectedOption) {
                    that.toggleOption(selectedOption, false);
                    return false;
                }
            }
        } else {
            // if it's not an array, then it doesn't matter what the label is - they must be removing the only
            // possible value
            this.set('value', null);
        }
    },
    addOption: function(newOption) {
        // we're going to add an option
        if (this.get('options') && this.get('options').length) {
            if (!_.contains(this.get('options'), newOption)) {
                this.get('options').push(newOption);
            }

            this.toggleOption(newOption, true);
        }
    },
    toggleOption: function(option, status) {
        status = status === undefined ? !option.isSelected : status;
        option.isSelected = status;

        this.refreshValue();
    },
    refreshValue: function(silent) {
        var value;
        var isFiltering;
        var options;
        if (this.get('isArrayType')) {
            value = [];
            if (this.get('hasSubFilters')) {
                this.get('options').each(function(innerFilter) {
                    innerFilter.refreshValue(silent);
                    value = value.concat(innerFilter.get('value'));
                });
            } else {
                _.each(this.get('options'), function(o) {
                    if (o.isSelected) {
                        value.push(o.value);
                    }
                });
            }

            isFiltering = value.length > 0;
        } else {
            value = this.get('value');
            isFiltering = !!value;
        }

        if (silent) {
            options = {silent: true};
        }

        var toSet = {
            value: value,
            isFiltering: isFiltering,
        };
        this.set(toSet, options);
    },
});
