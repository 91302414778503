import {whitelabelling as Whitelabelling} from 'whitelabelling';

/**
 * This directive packages the No Spots Left this month panel
 * Use like:
 */
export function consultationHistoryRow() {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            meeting: '=',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/directives/consultationHistoryRow.html',
        controller: [
            '$scope', '$http', 'VisitorService', function($scope, $http, VisitorService) {
                $scope.whitelabelling = Whitelabelling;

                $scope.SUMMARY_LENGTH = 80;
                $scope.FEEDBACK_STATE_CANCELLED = 'cancelled';
                $scope.FEEDBACK_STATE_COMPLETE = 'complete';
                $scope.FEEDBACK_STATE_MUST_SUBMIT = 'must-submit';
                $scope.FEEDBACK_STATE_WAITING = 'waiting';

                $scope.options = {
                    isExpanded: false,
                };

                VisitorService.getVisitor().then(function(v) {
                    $scope.visitor = v;
                });

                // we determine the feedback submission state
                var submittedFeedback = $scope.meeting.visitorIsConsumer
                    ? $scope.meeting.ExpertFeedback
                    : $scope.meeting.ConsumerFeedback;
                var receivedFeedback = $scope.meeting.visitorIsConsumer
                    ? $scope.meeting.ConsumerFeedback
                    : $scope.meeting.ExpertFeedback;
                var isMeetingComplete = $scope.meeting.get('meetingStateid') == 6;

                $scope.submittedFeedback = submittedFeedback;

                if (receivedFeedback && submittedFeedback) {
                    $scope.feedbackStatus = $scope.FEEDBACK_STATE_COMPLETE;
                } else if (isMeetingComplete && !submittedFeedback) {
                    $scope.feedbackStatus = $scope.FEEDBACK_STATE_MUST_SUBMIT;
                } else if (isMeetingComplete) {
                    // if the meeting's complete, we must be waiting
                    $scope.feedbackStatus = $scope.FEEDBACK_STATE_WAITING;
                } else {
                    // a passed meeting that isn't complete? Must be cancelled
                    $scope.feedbackStatus = $scope.FEEDBACK_STATE_CANCELLED;
                }

                var duration = $scope.meeting.get('durationInMinutes');
                $scope.durationLabel = duration === 60 ? '1h' : (duration + 'm');

                $scope.togglePanelExpanded = function() {
                    $scope.options.isExpanded = !$scope.options.isExpanded;
                };

                $scope.sendReviewReminder = function() {
                    if ($scope.canRemindDisabled) {
                        return;
                    }

                    $scope.sendingReminder = true;
                    $http.post('/api/consultations/' + $scope.meeting.get('id') + '/feedback/request').then(function() {
                        $scope.sendingReminder = false;
                        $scope.canRemindDisabled = true;
                    }, function(responseObj) {
                        alert(responseObj.data.error);
                        $scope.sendingReminder = false;
                        $scope.canRemindDisabled = true;
                    });
                };
            },
        ],
    };
}

