import {ExpertFeedback} from 'model/expertFeedback';
import {ConsumerFeedback} from 'model/consumerFeedback';

export const Feedback = [
    'Meeting', '$http', '$q',
    function(Meeting, $http, $q) {
        var data = {};
        return {
            getMeta: function() {
                var deferred = $q.defer();
                Meeting.getByRouteParams().then(function(meeting) {
                    $http.get('/consultations/' + meeting.get('id') + '/getFeedbackData.json').then(function(resp) {
                        data = resp.data.data;
                        deferred.resolve(data);
                    }, deferred.reject);
                });
                return deferred.promise;
            }
            , isConsumerFeedbackCompleted: function() {
                return data.consumerFeedbackCompleted;
            }
            , isExpertFeedbackCompleted: function() {
                return data.expertFeedbackCompleted;
            }
            , isConsumerFeedbackRequired: function() {
                return data.consumerFeedbackRequired;
            }
            , isExpertFeedbackRequired: function() {
                return data.expertFeedbackRequired;
            }
            , getExpertFeedback: function() {
                var deferred = $q.defer();

                var expertFeedback = new ExpertFeedback({meetingid: data.meetingid});
                expertFeedback.fetch().then(function() {
                    deferred.resolve.call(this, expertFeedback);
                }, deferred.reject);

                return deferred.promise;
            }
            , getConsumerFeedback: function() {
                var deferred = $q.defer();

                var consumerFeedback = new ConsumerFeedback({meetingid: data.meetingid});
                consumerFeedback.fetch().then(function() {
                    deferred.resolve.call(this, consumerFeedback);
                }, deferred.reject);

                return deferred.promise;
            }
            , emailFeedback: function() {
                var deferred = $q.defer();
                Meeting.getByRouteParams().then(function(meeting) {
                    $http.post('/consultations/' + meeting.get('id') + '/emailConsumerFeedback.json').
                        then(function(resp) {
                            data = resp.data.data;
                            deferred.resolve(data);
                        }, deferred.reject);
                });

                return deferred.promise;
            },
        };
    },
];
