import {whitelabelling as Whitelabelling} from 'whitelabelling';

export function assessment() {
    return {
        restrict: 'E',
        scope: {
            data: '=assessment',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/directives/assessment.html',
        replace: true,
        controller: [
            '$scope', function($scope) {
                $scope.whitelabelling = Whitelabelling;
            },
        ],
    };
}

