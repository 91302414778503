export function expertFeedback() {
    return {
        restrict: 'E',
        templateUrl: function(tElement, tAttrs) {
            if (tAttrs.review) {
                return Evisors.config.cdnRootWhitelabel + 'asset/partials/consultations/expertFeedbackReview.html';
            } else {
                return Evisors.config.cdnRootWhitelabel + 'asset/partials/consultations/expertFeedback.html';
            }
        },
        scope: {
            expertName: '=',
            feedback: '=',
            review: '=?',
        },
        controller: [
            '$scope', 'Feedback', function($scope, Feedback) {
                $scope.formErrors = false;
                $scope.isCompleted = $scope.review || Feedback.isExpertFeedbackCompleted();

                $scope.attemptSubmitFeedback = function() {
                    if ($scope.saving) {
                        return;
                    }

                    $scope.formErrors = !$scope.feedback.isComplete(true);
                    if ($scope.formErrors) {
                        return;
                    }

                    $scope.saving = true;
                    $scope.feedback.save().then(function() {
                        $scope.saving = false;
                    }, function() {
                        // TODO: handle errors?
                        $scope.saving = false;
                    });
                };
            },
        ],
    };
}

