import {whitelabelling} from './whitelabelling';
import {timezones} from './timezones';
import {countryCodes} from './country-codes';
import {visitor} from './visitor';

const dataSources = {whitelabelling, timezones, countryCodes, visitor};

export function load(options) {
    const promises = Object.keys(dataSources).
        filter(key => (!options || options[key])).
        map(key => dataSources[key].$load$());

    return $.when(...promises);
}

