import {whitelabelling as Whitelabelling} from 'whitelabelling';
import {Education} from 'model/education';

export function userInputEducation() {
    return {
        restrict: 'E',
        scope: {
            data: '=', // a collection of educations,
            displayType: '@?',
            assumeSameSchool: '<?', // true IFF we should assume all degrees share school (this whitelabel) and grad year
        },
        templateUrl: Evisors.config.cdnRootWhitelabel +
            'ng-template/partials/registration/directives/userInputs/userInputEducation.tpl.html',
        controller: [
            '$scope', function($scope) {
                $scope.now = new Date();
                $scope.whitelabelling = Whitelabelling;
                $scope.dataWasAlreadyComplete = false;

                $scope.schoolWentTitle = Whitelabelling.settings['customized:registrationSchoolWentTitle'] ?? 'I will pursue, am currently pursuing, or have already obtained the following degree(s)&nbsp;&hellip;';
                $scope.schoolWentNoDegree = Whitelabelling.settings['customized:registrationSchoolWentNoDegree'] ?? 'None of the above apply';

                // you gotta pass true AND back it up with data (must be a whitelabel, must have an existing education
                // with school set)
                $scope.assumeSameSchool = $scope.assumeSameSchool
                    && Whitelabelling
                    && $scope.data
                    && $scope.data.length > 0
                    && !!$scope.data.at(0).School
                    && !!$scope.data.at(0).dateGraduated;

                $scope.assumeSameSchool = $scope.assumeSameSchool || (Whitelabelling.id === 273 && $scope.displayType !== 'edit');

                $scope.addEducation = function() {
                    $scope.data.userSetDoesNotHaveDegree = false;
                    const eduData = {};
                    if ($scope.assumeSameSchool) {
                        eduData.School = $scope.relevantEdu.School;
                        eduData.dateGraduated = $scope.relevantEdu.dateGraduated;
                    }
                    $scope.data.add(new Education(eduData, {parse: true}));
                    $scope.setRelevantEdu();
                };

                $scope.setRelevantEdu = function() {
                    if ($scope.assumeSameSchool && Whitelabelling.affiliatedSchool && $scope.data.length) {
                        $scope.filteredCollection = $scope.data.where(function(e) {
                            if (e.get('School') && e.get('School').get('id')) {
                                return e.get('School').get('id') === Whitelabelling.affiliatedSchool.id;
                            } else {
                                return false;
                            }
                        });
                        $scope.relevantEdu = $scope.filteredCollection.length ? $scope.filteredCollection[0] : null;
                    } else if ($scope.data) {
                        $scope.filteredCollection = $scope.data.models;
                    }
                };

                if ($scope.data && $scope.data.isComplete()) {
                    // Display a simpler message if all your Education data was imported already.
                    $scope.dataWasAlreadyComplete = true;
                }

                $scope.changeEducationCollection = function() {
                    if ($scope.data.userSetDoesNotHaveDegree === false) {
                        $scope.data.models = $scope.data.filter(edu => edu.id);
                        $scope.data.forEach(edu => edu.isDeleted = false);
                        if ($scope.data.length === 0) {
                            $scope.addEducation();
                        }
                    } else {
                        $scope.data.forEach(e => {
                            if (e.id === undefined) {
                                $scope.data.remove(e);
                            } else {
                                e.isDeleted = true;
                            }
                        });
                    }
                }

                $scope.goToNextStep = function() {
                    $scope.$emit('goToNextStep');
                };

                $scope.$watch('data', function() {
                    if ($scope.data) {
                        $scope.setRelevantEdu();

                        if (!$scope.data.userSetDoesNotHaveDegree && !$scope.data.length) {
                            $scope.data.add(new Education({}, {parse: true}));
                        }
                    }

                });

                $scope.$watch(function() {
                    if ($scope.data) {
                        let lastEdu = null;
                        if (typeof $scope.data.filterNonDeleted === 'function') {
                            const activeEducations = $scope.data.filterNonDeleted();
                            if (activeEducations) {
                                lastEdu = activeEducations.last();
                            } else {
                            }
                            if (lastEdu) {
                                return lastEdu.isComplete();
                            } else {
                                return true;
                            }
                        }
                    }
                    return true;
                }, function(newVal) {
                    $scope.canAddAnother = newVal;
                });
            },
        ],
    };
}
