import {whitelabelling as Whitelabelling} from 'whitelabelling';

export function userInputCareerGoalsIndustriesEdit() {
    return {
        restrict: 'E',
        scope: {
            data: '=',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/userInputs/userInputCareerGoalsIndustriesEdit.html',
        controller: [
            '$scope', 'RegistrationService', function($scope, RegistrationService) {
                $scope.whitelabelling = Whitelabelling;

                $scope.MAX_TO_ADD = 10;
                $scope.industries = [];

                $scope.industriesSelector = {
                    value: null,
                };

                RegistrationService.getRegistrationSettings().then(function(d) {
                    $scope.industries = d.Industries;
                });

                $scope.removeIndustry = function(e) {
                    $scope.data.remove(e);
                };

                $scope.$on('showErrors', function() {
                    $scope.noInputs = true;
                });

                // because we can add multiple concentrations, we have the intermediate value to store the
                // currently selecting one
                $scope.$watch('industriesSelector.value', function(newVal) {
                    if (newVal) {
                        $scope.data.add(newVal);
                        $scope.noInputs = false;
                        $scope.industriesSelector.value = '';
                    }
                });
            },
        ],
    };
}
