import AbstractHandler from "./AbstractHandler";

export default class SessionExpiredHandler extends AbstractHandler {
    constructor() {
        super();
        this.isCaught = false
    }

    run(exception, cause = null) {
        if (
            exception &&
            typeof exception === 'string' &&
            exception.indexOf('Possibly unhandled rejection: {') > -1
        ) {
            let responseText;
            let ex;

            try {
                ex = JSON.parse(exception.substr('Possibly unhandled rejection: '.length));
                responseText = JSON.parse(ex.responseText);
            } catch (exception2) {
                return false;
            }

            if (ex.status === 403 && responseText.error === 'Unable to authenticate account.') {
                /**
                 * make sure that we fire the event just once so we don't spam the app with events
                 * this handler can be executed multiple times
                 * as there's usually multiple requests which will all return '403 unable to auth' on a single page
                 */
                if (!this.isCaught) {
                    this.isCaught = true;
                    window.dispatchEvent(new Event('FirsthandSessionExpired'));
                }
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
}
