import {AbstractProfileCollection} from './abstractProfileCollection';
import {Education} from 'model/education';

export const Educations = AbstractProfileCollection.extend({
    _name: 'Educations',
    model: Education,
    urlRoot: '/educations/get.json',
    userSetDoesNotHaveDegree: false,
    comparator: function(val1, val2) {
        if (val1.endDate && val2.endDate) {
            if (val1.endDate > val2.endDate) {
                return -1;
            } else if (val1.endDate < val2.endDate) {
                return 1;
            } else {
                return 0;
            }
        } else if (val1.endDate && !val2.endDate) {
            return 1;
        } else if (!val1.endDate && val2.endDate) {
            return -1;
        } else if (!val1.endDate && !val2.endDate) {
            // Go by Start Date.
            if (val1.startDate > val2.startDate) {
                return -1;
            } else if (val1.startDate < val2.startDate) {
                return 1;
            }
        }
        return 0;
    },
    filterNonDeleted: function() {
        return new Educations(this.filter(e => e.isDeleted !== true));
    },
    isComplete: function() {
        if (this.userSetDoesNotHaveDegree) {
            return true;
        }

        if (this.length === 0) {
            return false;
        }

        return this.every(e => e.isComplete());
    },
});
