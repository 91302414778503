/**
 * This directive creates the 10 pint rating scale
 * use like:
 * <ten-point-scale ng-model="...">
 */
export function tenPointScale() {
    return {
        restrict: 'E',
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/consultations/elements/tenPointScale.html',
        replace: true,
        scope: {
            data: '=ngModel',
            disabled: '=?',
        },
        controller: [
            '$scope', function($scope) {
                // we like the data to be a string, thanks
                $scope.data = !isNaN(parseInt($scope.data)) ? ('' + $scope.data) : '';

                $scope.uniqueString = String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now();
                $scope.scaleValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
            },
        ],
    };
}
