import {NgModel} from './ngModel';
import {ProfileUser} from 'model/profileUser';
import {Education} from 'model/education';
import {School} from './school';
import {Educations} from 'collection/educations';
import {AffinityGroups} from 'collection/affinityGroups';
import {Languages} from '../collection/languages';
import {EmploymentHistory} from '../collection/employmentHistory';
import {GeneralAvailabilities} from '../collection/generalAvailabilities';
import {Language} from './language';
import {CareerGoals} from './careerGoals';
import {AboutMe} from './aboutMe';
import {Exposure} from './exposure';
import {Services} from 'collection/services';
import {JobOpenings} from '../collection/jobOpenings';
import {Calendars} from '../collection/calendars';
import {Programs} from 'collection/programs';
import {whitelabelling as Whitelabelling} from 'whitelabelling';

const staticProps = {
    ROLE_CONSUMER: 'consumer',
    ROLE_EXPERT: 'expert',
    ROLE_WEBINAR: 'webinar',
    ROLE_CLIENT_ADMIN: 'partner',
    STATUS_ADDED: 'added',
    STATUS_ACTIVE: 'active',
    STATUS_EXPIRED: 'expired',
    STATUS_INCOMPLETE: 'incomplete',
};

export const RegistrationData = NgModel.extend({
    _name: 'RegistrationData',
    urlRoot: '/registration/data.json',
    associations: {
        User: ProfileUser,
        Languages,
        Education: Educations,
        EmploymentHistory,
        GeneralAvailability: GeneralAvailabilities,
        VerticalServices: Services,
        AffinityGroups,
        DesiredConsultationTypes: Services,
        CareerGoals,
        JobOpenings,
        AboutMe,
        Calendars,
        Programs,
        Exposure,
    },
    profileSection: [
        'User',
        'Languages',
        'AboutMe',
        'CustomInputs',
        'Education',
        'EmploymentHistory',
        'AffinityGroups',
        'CareerGoals',
    ],
    offeringsSection: [
    ],
    availabilitySection: [
        'User',
        'Programs',
        'Exposure',
        'GeneralAvailability',
        'Calendars',
    ],
    defaults: {
        progressDirection: 1,
        User: null,
        Education: null,
        AffinityGroups: null,
        Languages: null,
        VerticalServices: null,
        GeneralAvailability: null,
        DesiredConsultationTypes: null,
        EmploymentHistory: null,
        CareerGoals: null,
        AboutMe: null,
        Exposure: null,
        CustomInputs: null,
        JobOpenings: null,
        Calendars: null,
        Programs: null,
        primaryRole: null,
        dateGraduated: null,
        platformAffiliation: null,
        termsAccepted: null,
        limitRequestsToAffinityGroups: null,
        restrictToPrograms: false,
        wasSsoProvisioned: false,
        ssoAffiliation: null,
        externalid: null,
        userSetDoesNotHaveDegree: false,
        calloutNewProgramId: null,
        calloutNewProgramName: null,
    },
    fetch: function(options) {
        if (options.section) {
            //let's assume it is profile section, because we started with it
            let sectionName = `${options.section}Section`;
            if (!this[sectionName]) {
                sectionName = 'profileSection';
            }
            options.url = `${this.url()}?sections=${(this[sectionName].join(','))}`;
        }
        return this._super('fetch', options);
    },
    parse: function(data) {
        const dataFormatted = this._super('parse', data);
        if (!dataFormatted.User) {
            dataFormatted.User = new ProfileUser({}, {parse: true});
        }

        if (!dataFormatted.Education) {
            dataFormatted.Education = new Educations();
        }

        if (!dataFormatted.Calendars) {
            dataFormatted.Calendars = new Calendars();
        }

        if (!dataFormatted.AffinityGroups) {
            dataFormatted.AffinityGroups = new AffinityGroups();
        }

        if (!dataFormatted.Languages) {
            dataFormatted.Languages = new Languages();
        }

        if (!dataFormatted.EmploymentHistory) {
            dataFormatted.EmploymentHistory = new EmploymentHistory();
        }

        if (!dataFormatted.VerticalServices) {
            dataFormatted.VerticalServices = new Services();
        }

        if (!dataFormatted.Programs) {
            dataFormatted.Programs = new Programs();
        }

        if (!dataFormatted.GeneralAvailability) {
            dataFormatted.GeneralAvailability = new GeneralAvailabilities();
        }

        if (!dataFormatted.Languages.length) {
            dataFormatted.Languages.add(new Language({ // Default with English
                id: 7,
                label: 'English',
            }));
        }

        if (!dataFormatted.DesiredConsultationTypes) {
            dataFormatted.DesiredConsultationTypes = new Services();
        }

        if (!dataFormatted.CareerGoals) {
            dataFormatted.CareerGoals = new CareerGoals();
        }

        if (!dataFormatted.AboutMe) {
            dataFormatted.AboutMe = new AboutMe();
        }

        if (!dataFormatted.Exposure) {
            dataFormatted.Exposure = new Exposure();
        }

        if (!dataFormatted.CustomInputs) {
            dataFormatted.CustomInputs = null;
        }

        if (!dataFormatted.JobOpenings) {
            dataFormatted.JobOpenings = new JobOpenings();
        }

        if (dataFormatted.dateGraduated) {
            dataFormatted.dateGraduated = this.getDate(dataFormatted.dateGraduated);
        }

        //information about not having degrees is stored on user whitelabel, but on frontend we're keeping it
        //Education collection as it's relevant only for Education
        dataFormatted.Education.userSetDoesNotHaveDegree = dataFormatted.userSetDoesNotHaveDegree;

        dataFormatted.termsAccepted = true;

        return dataFormatted;
    },
    validateData: function() {
        const errors = {};
        errors.User = this.get('User') ? this.get('User').validateData() : {};
        errors.Languages = this.get('Languages').length === 0;

        if (this.get('Education').length) {
            errors.Education = [];
            this.get('Education').each(function(e) {
                errors.Education.push(e.validateData());
            });
        } else {
            // need at least 1 education
            errors.Education = true;
        }
        // need at least 1 affinity group?
        // this is probably not going to be used as its conditions are too complicated
        // is one needed? depends on the whitelabel, are any required? etc
        errors.AffinityGroups = this.get('AffinityGroups').length === 0;

        // don't need availability at all
        errors.GeneralAvailability = false;

        if (this.get('primaryRole') === 'consumer') {
            // if you're a consumer, you don't need any VerticalServices, EmploymentHistory
            errors.VerticalServices = false;
            errors.EmploymentHistory = false;
        } else {
            // you need at least 1 vertical service if you're not a consumer
            errors.VerticalServices = this.get('VerticalServices').length > 0;

            // you need employment if you're not a consumer
            errors.EmploymentHistory = [];
            this.get('EmploymentHistory').each(function(e) {
                errors.EmploymentHistory.push(e.validateData());
            });
        }

        if (this.get('primaryRole') === 'expert') {
            // if you're an expert, you don't need any DesiredConsultationTypes, CareerGoals
            errors.DesiredConsultationTypes = false;
            errors.CareerGoals = false;
        } else {
            // not an expert? you need desired types
            errors.DesiredConsultationTypes = this.get('DesiredConsultationTypes').length > 0;

            // if you're not an expert, you need career goals to validated
            errors.CareerGoals = this.get('CareerGoals').validateData();
        }

        errors.primaryRole = _.contains(['consumer', 'expert', 'both'], this.get('primaryRole'));
        errors.dateGraduated = !this.get('dateGraduated');
        errors.platformAffiliation = !this.get('platformAffiliation');
        errors.termsAccepted = !this.get('termsAccepted');

        this.set('errors', errors);
        return errors;
    },
    saveOfferings: function() {
        return this.save({url: `${this.url()}?sections=${(this['offeringsSection'].join(','))}`});
    },
    saveProfile: function() {
        return this.save({url: `${this.url()}?sections=${(this['profileSection'].join(','))}`});
    },
    saveAvailability: function() {
        return this.save({url: `${this.url()}?sections=${(this['availabilitySection'].join(','))}`});
    },
    save: function(options = {}) {
        // we don't want to keep uploaded the picture every time
        const user = this.get('User');
        if (!user.get('hasChangedProfilePicture')) {
            // we set it to null - it's ok, we'll get it refreshed after it saves and syncs
            user.set('avatarURL', null);
        }
        if (this.get('dateGraduated') && this.get('Education').length === 0) {
            // if they marked a graduated date, but they don't yet have an education, we create one
            this.Education.add(new Education({
                School: new School(Whitelabelling.affiliatedSchool, {parse: true}),
                dateGraduated: this.get('dateGraduated'),
            }, {parse: true}));
            this.Education.userSetDoesNotHaveDegree = false;
        }

        this.userSetDoesNotHaveDegree = this.Education.userSetDoesNotHaveDegree;

        return this._super('save', null, options);
    },
    constructTitle: function() {
        let retVal = '';
        const currentEmployment = this.get('EmploymentHistory').findWhere({isCurrent: true});
        if (currentEmployment) {
            retVal = currentEmployment.get('title') + ' at ' + currentEmployment.get('Employer').get('label');
        } else if (this.get('dateGraduated')) {
            const now = new Date();
            const gradYear = this.get('dateGraduated').getFullYear();
            let degreeText = '';

            if (this.get('Education').length) {
                let foundRelevantEducation;

                if (Whitelabelling.affiliatedSchool && Whitelabelling.affiliatedSchool.id) {
                    foundRelevantEducation = this.get('Education').findWhere(function(e) {
                        return e.get('School').get('id') === Whitelabelling.affiliatedSchool.id;
                    });

                } else {
                    foundRelevantEducation = this.get('Education').at(0);
                }
                if (foundRelevantEducation && foundRelevantEducation.get('Concentrations') && foundRelevantEducation.get('Concentrations').at(0)) {
                    degreeText = foundRelevantEducation.get('Concentrations').at(0).get('label');
                }
            }

            retVal = 'Class of ' + gradYear + ' '
                + ((this.get('dateGraduated') > now) ? 'student' : 'alum')
                +
                (degreeText.length ? ((this.get('dateGraduated') > now) ? ', studying ' + degreeText : ' who studied ' +
                    degreeText) : '');
            if (Whitelabelling.affiliatedSchool) {
                retVal += ' at ' + Whitelabelling.affiliatedSchool.label;
            }
        } else if (this.get('platformAffiliation')) {
            retVal = this.get('platformAffiliation');
            switch (retVal) {
                case 'faculty' :
                case 'staff' :
                    retVal += ' member at ' + Whitelabelling.affiliatedSchool.label;
                    break;
                case 'friend' :
                    retVal += ' of ' + Whitelabelling.affiliatedSchool.label;
                    break;
                default :
                    retVal += ' of a student at ' + Whitelabelling.affiliatedSchool.label;
                    break;
            }
        } else {
            // do nothing, we did our best
        }

        return retVal;
    },
    hasRole: function(roleKey) {
        const self = this;
        const roles = self.get('roles');
        if (!angular.isObject(roles)) {
            return false;
        } else {
            return !(!roles[roleKey] || roles[roleKey] === staticProps.STATUS_EXPIRED);
        }
    },
    roleStatus: function(roleKey) {
        const self = this;
        const roles = self.get('roles');
        if (self.hasRole(roleKey)) {
            return roles[roleKey];
        } else {
            return null;
        }
    },
    initialize: function(opt) {
        if (Whitelabelling.settings.jobOpeningsEnabled) {
            this.offeringsSection.push('JobOpenings');
        }
        if (!Whitelabelling.settings.featureNewRegistrationEnabled) {
            this.offeringsSection.push('User');
            this.offeringsSection.push('VerticalServices');
        }
        return this._super('initialize', opt);
    }
}, staticProps);
