import {whitelabelling as Whitelabelling} from 'whitelabelling';

export function userInputCareerGoalsRolesEdit() {
    return {
        restrict: 'E',
        scope: {
            data: '=',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/userInputs/userInputCareerGoalsRolesEdit.html',
        controller: [
            '$scope', 'RegistrationService', function($scope, RegistrationService) {
                $scope.whitelabelling = Whitelabelling;

                $scope.MAX_TO_ADD = 10;

                $scope.rolesSelector = {
                    value: [],
                };

                RegistrationService.getRegistrationSettings().then(function(d) {
                    $scope.roles = d.Roles;
                });

                $scope.removeRole = function(e) {
                    $scope.data.remove(e);
                };

                $scope.$on('showErrors', function() {
                    $scope.noInputs = true;
                });

                // because we can add multiple roles, we have the intermediate value to store the
                // currently selecting one
                $scope.$watch('rolesSelector.value', function(newVal, oldVal) {
                    if (newVal && oldVal && newVal.length > oldVal.length) {
                        $scope.data.add(newVal);
                        $scope.noInputs = false;
                    }
                });
            },
        ],
    };
}
