export function boothHiringInfo() {
    return {
        restrict: 'E',
        scope: {
            'hiringInternship': '=',
            'hiringFulltime': '=',
            'hiringInternational': '=',
        },
        templateUrl: '/asset/partials/partner/directives/fairs/boothHiringInfo.html',
        replace: true,
    }
}
