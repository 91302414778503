import {NgModel} from 'model/ngModel';

export const PaypalForm = NgModel.extend({
    _name: 'PaypalForm',
    urlRoot: '/consultations/[meetingid]/paypalForm.json',
    idAttribute: 'meetingid',
    defaults: {
        'item_number': null,
        'item_name': null,
        'business': null,
        'amount': null,
        'currency_code': 'USD',
        'cmd': '_xclick',
        'no_note': null,
        'no_shipping': null,
        'rm': null,
        'return': null,
        'notify_url': null,
        'action': null,
    },
});
