import {NgModel} from './ngModel';

export const Visitor = NgModel.extend({
    _name: 'Visitor',
    urlRoot: '/visitor/get.json',
    defaults: {
        user: null,
        whitelabel: null,
        admin: null,
        client: null,
        isWhitelabel: null,
        currentClientid: null,
        isLoggedIn: null,
        isUser: null,
        isExpert: null,
        isConsumer: null,
        isAdmin: null,
        isPartnerUser: null,
        isManagerUser: null,
        activeUserRole: null,
        creditsAvailable: 0,
        isExpertOnly: false,
        hasReachedConcurrentMeetingLimit: false,
        isClient: false,
    },
    parse(data) {
        const dataFormatted = this._super('parse', data);

        if (dataFormatted.user) {
            if (dataFormatted.user.avatarHash) {
                dataFormatted.user.cdnAvatarUrl = 'asset/img/avatars/' + dataFormatted.user.id + '/' +
                    dataFormatted.user.avatarHash + '/100.jpg';
            } else {
                dataFormatted.user.cdnAvatarUrl = 'asset/img/avatars/' + dataFormatted.user.id +
                    '/100.jpg';
            }
        }

        dataFormatted.isExpertOnly = dataFormatted.isExpert && !dataFormatted.isConsumer;

        return dataFormatted;
    },
});
