import {whitelabelling as Whitelabelling} from 'whitelabelling';

export const ConsultationsController = [
    '$scope', '$rootScope', '$routeParams', '$q', 'UserDashboardService', 'VisitorService',
    function($scope, $rootScope, $routeParams, $q, UserDashboardService, VisitorService) {
        $rootScope.pageClass = 'consultations-container';
        $scope.TAB_UPCOMING = 'upcoming';
        $scope.TAB_HISTORY = 'history';

        $scope.currentTab = $routeParams.action || $scope.TAB_UPCOMING;
        $scope.whitelabelling = Whitelabelling;

        $scope.pageCount = 10;
        $scope.page = 0;
        $scope.isExpert = false;

        VisitorService.getVisitor().then(function(visitor) {
            if (!visitor.get('isUser')) {
                window.location.href = '/login';
                return;
            }

            $scope.isExpert = visitor.isExpert;

            UserDashboardService.getMeetingsWithFilter('upcoming').then(function(data) {
                $scope.$emit('controllerLoaded', true);
                $scope.upcomingMeetings = data;
            });
        });

        $scope.changePage = function(amt) {
            if (
                ($scope.page === 0 && amt < 0)
                || ($scope.historyMeetings.length < $scope.pageCount && amt > 0)
            ) {
                // do nothing, no more pages
                return;
            }

            $scope.page += amt;
        };

        $scope.$watch('page', function() {
            $scope.loading = true;
            UserDashboardService.getMeetingsWithFilter('passed', $scope.page, $scope.pageCount).
                then(function(meetings) {
                    $scope.historyMeetings = meetings;
                    $scope.historyMeetings.comparator = comparator;
                    $scope.historyMeetings.sort();
                    $scope.loading = false;
                });
        });

        function comparator(c) {
            if (c.getGroupDate() && c.getGroupDate().getTime()) {
                return 0 - c.getGroupDate().getTime();
            }

            return 0 - Number.MAX_SAFE_INTEGER;
        }
    },
];
