import {Backbone} from 'Vertebrae';
import {Employment} from 'model/employment';

export const EmploymentHistory = Backbone.Collection.extend({
    _name: 'EmploymentHistory',
    model: Employment,
    isComplete: function(datesOptional) {
        if (this.length === 0) {
            return false;
        }

        var retVal = true;
        this.every(function(e) {
            retVal = e.isComplete(datesOptional);
            return retVal;
        });

        return retVal;
    },
});
