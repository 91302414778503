import * as angular from 'angular';

export const setupCacheBuster = () => {
    return angular.module('cacheBuster', []).config(["$provide", function($provide) {
        return $provide.decorator("$http", ["$delegate", function ($delegate) {
            var get = $delegate.get;
            $delegate.get = function (url, config) {
                // if it's an asset and doesn't have the revision number yet
                var regex = /\/(asset|ng-template)\//g;
                if (url.match(regex) && url.indexOf(Evisors.config.revision) === -1) {
                    // the position of the file extension beginning
                    var extPosition = url.lastIndexOf('.');
                    if (extPosition !== -1) {
                        url = url.slice(0, extPosition) + '.' + Evisors.config.revision + url.slice(extPosition);
                    }
                }
                return get(url, config);
            };
            return $delegate;
        }]);
    }]);
};
