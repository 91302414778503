import {whitelabelling as Whitelabelling} from 'whitelabelling';
import * as moment from 'moment-timezone';

export const MeetingController = [
    '$rootScope', '$scope', '$routeParams', '$http', '$window', '$timeout', 'Meeting', '$q', 'MeetingConnectionService',
    function($rootScope, $scope, $routeParams, $http, $window, $timeout, Meeting, $q, MeetingConnectionService) {
        $rootScope.pageClass = 'meeting-container';

        // set up page sizing
        const _title = angular.element('title');
        const defaultTitle = _title.text();

        $scope.isLoading = true;
        $scope.hideLoading = false;

        $scope.meetingIsConnected = false;
        $scope.adminForcedVideoConnection = false; // For debugging.

        $scope.unreadChatCount = 0;

        const _window = angular.element($window);
        _window.on('keydown', ev => {
            if (ev.which === 27) {
                $rootScope.$broadcast('hideModal', ev);
                $scope.$apply();
            }
        });

        // for convenience, we set thisUser and otherUser at the root scope
        Meeting.getByRouteParams().then(meeting => {
            $scope.$emit('controllerLoaded', true);

            // we give the whitelabelling to all scopes
            $rootScope.whitelabelling = Whitelabelling;

            if ($routeParams.success
                && $routeParams.success === 1
                && $rootScope.whitelabelling.id === undefined
                && meeting.get('visitorIsConsumer')
            ) {
                initAddShopperGlobalScript();
                fireAddShopperConversionScript(meeting).then(() => {
                    return window.location.href = `/consultations/${meeting.id}/meet?`;
                });
            }

            if (meeting.get('visitorIsConsumer')) {
                $rootScope.thisUser = meeting.get('Consumer');
                $rootScope.otherUser = meeting.get('Expert');
            } else {
                $rootScope.thisUser = meeting.get('Expert');
                $rootScope.otherUser = meeting.get('Consumer');
            }

            $rootScope.meetingExpert = meeting.get('Expert');

            $rootScope.defaultCountry = 'United States';
            const thisUserCurrentLocation = $rootScope.thisUser.get('currentLocation');
            if (thisUserCurrentLocation && thisUserCurrentLocation.country) {
                $rootScope.defaultCountry = thisUserCurrentLocation.country;
            }

            // THESE ARE USED IN THE ADMIN CONTROL BAR
            $rootScope.meetingid = meeting.get('id');
            $rootScope.meetingTime = meeting.get('selectedTimeFormatted');

            $rootScope.callConnectionSet = ($event, userType, action) => {
                const $element = $($event.currentTarget);
                const callConnectionEndpoint = '/admin/consultation/' + $rootScope.meetingid + '/call-connections/' +
                    userType + '.json';

                $element.attr('disabled', true);
                $http.post(callConnectionEndpoint, {
                    'action': action,
                }).finally(() => {
                    $element.attr('disabled', false);
                });
            };

            $rootScope.admin_meetingTimeSetterChange = function() {
                if (!$rootScope.admin_meetingTimeSetter || $rootScope.admin_meetingTimeSetter === 'none' ||
                    $rootScope.admin_meetingTimeSetter === 'custom') {
                    return;
                }

                if (Number($rootScope.admin_meetingTimeSetter) <= 0) {
                    $rootScope.admin_meetingState = '5';
                } else {
                    $rootScope.admin_meetingState = '4';
                }
            };

            $rootScope.consultationAdminChange = $event => {
                const $element = $($event.currentTarget);
                const consultationUpdateEndpoint = '/admin/consultation/' + $rootScope.meetingid + '/set.json';

                $element.attr('disabled', true);

                let selectedDateTime;
                let dateIsNull = false;
                const now = new moment();
                switch ($rootScope.admin_meetingTimeSetter) {
                    case 'none':
                        selectedDateTime = null;
                        dateIsNull = true;
                        break;
                    case 'custom':
                        selectedDateTime = now.set($rootScope.admin_meetingSelectedDate);
                        break;
                    default:
                        const offset = Number($rootScope.admin_meetingTimeSetter);
                        selectedDateTime = now.add(offset, 'seconds');
                        break;
                }

                if (selectedDateTime) {
                    selectedDateTime = selectedDateTime.unix();
                }

                $http.post(consultationUpdateEndpoint, {
                    'selectedDate': selectedDateTime,
                    'dateIsNull': dateIsNull,
                    'stateid': $rootScope.admin_meetingState,
                    'consumerCost': $rootScope.admin_consumerCostSetter,
                    'expertCredit': $rootScope.admin_expertPayoutSetter,
                }).finally(function() {
                    $q.when(Meeting.getByRouteParams(true));
                    $element.attr('disabled', false);
                });
            };

            $rootScope.fakeVideoConnection = () => {
                $scope.meetingIsConnected = !$scope.meetingIsConnected;
                $scope.adminForcedVideoConnection = true;

                const localWrappers = angular.element('.monitor-wrapper-local');
                if (localWrappers.length === 2) {
                    localWrappers.first().removeClass('monitor-wrapper-local');
                    localWrappers.first().addClass('monitor-wrapper-remote');
                }
            };

            $scope.$watch('meetingIsConnected', newVal => {
                if (newVal && 'zE' in window) {
                    window.zE('webWidget', 'updateSettings', {'offset': {'vertical': $scope.meetingIsConnected ? '79px' : '0px'}});
                }
            });

            MeetingConnectionService.addScope($scope);
            $scope.meetingIsConnected = MeetingConnectionService.isCurrentUserConnectedAudioPhone();
            $scope.$on('meeting-poll', () => {
                if (!$scope.adminForcedVideoConnection) {
                    $scope.meetingIsConnected =
                        MeetingConnectionService.isCurrentUserConnectedAudioPhone() ||
                        MeetingConnectionService.isLocalVoipConnected();
                }
            });

            $scope.isLoading = false;
            // one second after the meeting loads, we remove the loading page
            $timeout(() => {
                $scope.hideLoading = true;
            }, 1000);
        }, responseObj => {
            if (responseObj.status === 403) {
                // we let the consultations indexAction route us
                $window.location.href = '/consultations/' + $routeParams.meetingid + '/meet';
            }
        });

        $rootScope.$on('unreadChatCount', (evt, newUnreadChatCount) => {
            if ($scope.unreadChatCount !== newUnreadChatCount) {
                $scope.unreadChatCount = newUnreadChatCount;
                $timeout(function() {
                    $scope.$apply();
                }, 500);
            }
        });
        $rootScope.$on('incrementChatCount', () => {
            $scope.unreadChatCount = $scope.unreadChatCount + 1;
            $timeout(function() {
                $scope.$apply();
            }, 500);
        });

        $scope.$watch('unreadChatCount', newVal => {
            if (newVal > 0) {
                _title.text('(' + newVal + ') ' + defaultTitle);
            } else {
                _title.text(defaultTitle);
            }
        });

        $('body').addClass('no-zendesk');
        $scope.$on('$destroy', () => {
            $('body').removeClass('no-zendesk');
        });

        let initAddShopperGlobalScript = () => {
            window.AddShoppersWidgetOptions = {'loadCss': false};
            (d => {
                const t = d.createElement('script');
                t.type = 'text/javascript';
                t.async = !0;
                t.id = 'AddShoppers';
                t.src = 'https://shop.pe/widget/widget_async.js#602460a893f3c3296e887225';
                d.getElementsByTagName('head')[0].appendChild(t);
            })(document);
        };

        let fireAddShopperConversionScript = meeting => $q(function(resolve) {
            window.AddShoppersConversion =
                {
                    order_id: meeting.id,
                    value: meeting.consumerCost,
                    email: meeting.Consumer.email,
                };
            window.AddShoppersWidget.track_conv();
            resolve();
        });
    },
];
