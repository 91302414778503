class ProfileLinkedinSyncButtonCtrl {

    static $inject = ['$scope', '$location'];

    constructor($scope, $location) {
        this.$scope = $scope;
        this.$location = $location;

        if (!this.onClick) {
            this.onClick = () => this.redirect();
        }
    }

    redirect() {
        this.$location.path('/profile/sync/' + this.section);
    }

}

export const profileLinkedinSyncButton = {
    templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/components/profileLinkedinSyncButton.html',
    bindings: {
        section: '<',
        caption: '@?',
        onClick: '&?',
    },
    controller: ProfileLinkedinSyncButtonCtrl,
};
