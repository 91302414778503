export function userInputLanguage() {
    return {
        restrict: 'E',
        scope: {
            data: '=',
            displayType: '@?',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/registration/directives/userInputs/userInputLanguage.html',
        controller: [
            '$scope', 'RegistrationService', function($scope, RegistrationService) {
                $scope.showTip = false;
                $scope.showAddLanguage = true;

                $scope.hideModal = function() {
                    $scope.showTip = false;
                };

                $scope.languagesSelector = {
                    value: null,
                };

                $scope.languagesHook = RegistrationService.languagePrefixComplete;

                $scope.removeLanguage = function(l) {
                    if (l.id !== 7) {
                        // Can't delete English.
                        $scope.data.remove(l);
                    }
                };

                // because we can add multiple concentrations, we have the intermediate value to store the
                // currently selecting one
                $scope.$watch('languagesSelector.value', function(newVal) {
                    if (newVal) {
                        $scope.data.add(newVal);
                        $scope.languagesSelector.value = '';
                        $scope.showAddLanguage = true;
                    }
                });
            },
        ],
    };
}

