export function videoChatMonitor() {
    return {
        restrict: 'E',
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/directives/video/videoChatMonitor.html',
        replace: true,
        scope: {
            monitorType: '@',
            user: '=?',
        },
        link: function(scope, element, attr) {
            scope.element = element.children()[0];
        },
        controller: [
            '$scope', '$rootScope', '$q', 'VideoConsultationService', 'MeetingConnectionService', 'Meeting',
            function($scope, $rootScope, $q, VideoConsultationService, MeetingConnectionService, Meeting) {
                var getTracksFunction;

                var localVideoSet = new Set();
                $scope.videoActive = false;
                $scope.avatarUrl = null;
                $scope.fullName = null;
                $scope.monitorUserConnectedViaAudio = false;

                $scope.$watch('user', function() {
                    if ($scope.user) {
                        $scope.avatarUrl = $scope.user.avatarURL;
                        $scope.fullName = $scope.user.fullName;

                        var updateMonitorUserConnectionStatus = function() {
                            Meeting.getByRouteParams().then(function(meeting) {
                                if (meeting.get('Expert').get('id') === $scope.user.get('id')) {
                                    // User is Expert.
                                    $scope.monitorUserConnectedViaAudio = MeetingConnectionService.isExpertConnectedAudio();
                                } else {
                                    $scope.monitorUserConnectedViaAudio = MeetingConnectionService.isConsumerConnectedAudio();
                                }
                            });
                        };

                        updateMonitorUserConnectionStatus();
                        MeetingConnectionService.addScope($scope);
                        $scope.$on('meeting-poll', function() {
                            updateMonitorUserConnectionStatus();
                        });
                    }
                });

                var once = $scope.$watch('monitorType', function(newVal) {
                    newVal = Number(newVal);
                    $scope.monitorType = newVal;

                    switch (newVal) {
                        case VideoConsultationService.typeEnum.TYPE_LOCAL:
                            $scope.monitorTypeLabel = 'local';
                            getTracksFunction = VideoConsultationService.getLocalTracks;
                            break;
                        case VideoConsultationService.typeEnum.TYPE_REMOTE:
                            $scope.monitorTypeLabel = 'remote';
                            getTracksFunction = VideoConsultationService.getRemoteTracks;
                            break;
                    }

                    once();
                });

                // Handle changes in video monitor status or the application of an element (to prevent race conditions).
                $rootScope.$on('videoMonitorStatus', function(evt, monitorType) {
                    if ($scope.element && monitorType === $scope.monitorType) {
                        $scope.refresh();
                    }
                });

                $scope.refresh = function() {
                    getTracksFunction().then(function(hasTracks) {
                        $scope.clearElement();
                        if (hasTracks && hasTracks.length > 0) {
                            $scope.videoActive = true;
                            // There are tracks to append; wire them to the DOM.
                            hasTracks.forEach(function(track) {
                                if (!localVideoSet.has(track.sid)) {
                                    localVideoSet.add(track.sid);
                                    $scope.element.appendChild(track.attach());
                                }
                            });
                        } else {
                            // No more tracks; just empty the element.
                            $scope.clearElement();
                        }
                    });
                };

                $scope.clearElement = function() {
                    $scope.videoActive = false;
                    var $element = angular.element($scope.element);
                    if ($element.children.length > 0) {
                        localVideoSet.clear();
                        $element.empty();
                    }
                };
            },
        ],
    };
}

