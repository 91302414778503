import {NgModel} from './ngModel';

export const AFFINITY_GROUP_TYPE_GROUP = 'group';
export const AFFINITY_GROUP_TYPE_BOOTH = 'booth';

export const ProfileAffinityGroup = NgModel.extend({
    _name: 'AffinityGroup',
    defaults: {
        isRestricted: false,
        isAccepted: false,
        isPending: false,
        isRejected: false,
        affinityGroupCategoryid: null,
        categoryLabel: null,
    },
    parse: function(data) {
        var dataFormatted = this._super('parse', data);

        dataFormatted.affinityGroupCategoryid = parseInt(dataFormatted.affinityGroupCategoryid);
        dataFormatted.id = parseInt(dataFormatted.id);
        dataFormatted.isRestricted = this.asBoolean(dataFormatted.isRestricted);
        dataFormatted.isPending = this.asBoolean(dataFormatted.isPending);
        dataFormatted.isAccepted = this.asBoolean(dataFormatted.isAccepted);
        dataFormatted.isRejected = this.asBoolean(dataFormatted.isRejected);

        this.__bindManyForAngular(dataFormatted);

        return dataFormatted;
    },
});
