export function cancelMeeting() {
    return {
        restrict: 'A',
        scope: {
            verb: '@?',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/consultations/cancelMeeting.html',
        controller: [
            '$scope', 'Meeting', 'Payments', function($scope, Meeting, Payments) {
                $scope.verb = $scope.verb ? $scope.verb : 'cancel';
                Meeting.getByRouteParams().then(function(meeting) {
                    $scope.meeting = meeting;

                    if (meeting.get('visitorIsConsumer')) {
                        Payments.getConsumerCancelRefund().then(function(data) {
                            if (data.originalCost) {
                                // we set two values to the view so we can determine what copy to show
                                $scope.refundAmount = data.refundAmount;
                                $scope.consumerCost = data.originalCost;
                            }
                        });
                    } else {
                        Payments.getExpertCancelPayout().then(function(data) {
                            if (data.originalCredit) {
                                // we only need this because if an expert cancels, they are never paid anything
                                $scope.expertCredit = data.originalCredit;
                            }
                        });
                    }
                });

                $scope.cancelMeeting = function() {
                    $scope.isLoading = true;
                    $scope.errorMessage = false;

                    Meeting.cancel($scope.meeting).then(function() {
                        $scope.isLoading = false;
                    }, function(response) {
                        $scope.isLoading = false;
                        $scope.errorMessage = response.data.data.message;
                    });
                };

                $scope.hideModal = function() {
                    $scope.$emit('hideModal');
                };
            },
        ],
    };
}

