import {whitelabelling as Whitelabelling} from 'whitelabelling';

export const EditOfferingsController = [
    '$window', '$scope', '$rootScope', 'VisitorService',
    function($window, $scope, $rootScope, VisitorService) {
        $rootScope.pageClass = 'edit-profile-container';
        $scope.isLoading = true;

        if (Whitelabelling.settings.featureNewRegistrationEnabled && !Whitelabelling.settings.jobOpeningsEnabled) {
            $window.location.href = '/';
            return;
        }

        VisitorService.getVisitor().then(function(visitor) {
            if (!visitor.get('isUser')) {
                $window.location.href = '/login';
                return;
            }

            $scope.isLoading = false;
        });
    },
];
