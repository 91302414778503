import {whitelabelling as Whitelabelling} from 'whitelabelling';
import {ConferenceNumbers} from '../collection/conferenceNumbers';
import {ConferenceNumber} from '../model/conferenceNumber';

export function conferenceNumber() {
    return {
        restrict: 'A',
        scope: {
            prepareMode: '=',
            canCall: '=',
            defaultCountry: '=',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/consultations/conferenceNumber.html',
        controller: [
            '$scope',
            'MeetingConnectionService',
            'Meeting',
            '$geolocation',
            function($scope, MeetingConnectionService, Meeting, $geolocation) {
                const placeholderCountries = [
                    'China'
                ];

                $scope.callMeVisible = false;
                $scope.toggleCallMe = value => $scope.callMeVisible = value;

                $scope.country = [];
                $scope.conferenceCode = '';
                $scope.showCannotRequestMessage = false;
                $scope.callMeError = null;
                $scope.callMeErrorRecommendation = 'Please try dialing out to our system, or <a href="mailto:' +
                    Whitelabelling.supportEmail + '">contact support</a>.';
                $scope.tryingToGuessLocation = true;
                $scope.videoAvailable = true;

                if (!Whitelabelling.settings.allowVideoConsultations) {
                    $scope.videoAvailable = false;
                }

                // whether or not to show the timezone selector
                $scope.showSelect = false;

                // we get the default selected country (which is the device's current location
                $scope.selectedCN = null;

                $scope.numbers = new ConferenceNumbers();

                $scope.numbers.fetch().then(function() {
                    // Then... add placeholder numbers.
                    $scope.$evalAsync(function() {
                        for (var i = 0; i < placeholderCountries.length; i++) {
                            const countryName = placeholderCountries[i];
                            const newNumber = new ConferenceNumber({
                                'id': 'placeholder-' + i,
                                'country': countryName,
                                'isPlaceholder': true,
                            });
                            $scope.numbers.add(newNumber);
                        }

                        // @TODO: Actually look up your country when we have Google Maps available.
                        var foundNumber = $scope.numbers.findWhere({country: $scope.defaultCountry});
                        if (!foundNumber) {
                            // Just use US by default.
                            foundNumber = $scope.numbers.findWhere({country: 'United States'});
                        }
                        $scope.selectedCN = foundNumber;
                    });
                });

                Meeting.getByRouteParams().then(function(meeting) {
                    if (meeting.get('visitorIsConsumer')) {
                        $scope.conferenceCode = meeting.get('consumerCodeLabel');
                        $scope.callNumber = meeting.get('Consumer').get('phone');
                        $scope.callNumberFormatted = meeting.get('Consumer').get('phoneFormatted');
                    } else {
                        $scope.conferenceCode = meeting.get('expertCodeLabel');
                        $scope.callNumber = meeting.get('Expert').get('phone');
                        $scope.callNumberFormatted = meeting.get('Expert').get('phoneFormatted');
                    }
                    $scope.conferenceCodeSegments = $scope.conferenceCode.split('-');
                    $scope.conferenceCodeSegments.push('#');
                });

                $scope.$watchGroup(['selectedCN', 'conferenceCodeSegments'], function(newValues) {
                    if (newValues[0] && newValues[1]) {
                        $scope.conferenceTelLink = 'tel:' + newValues[0].dialNumber + ',,' + newValues[1].join('');
                    }
                });

                MeetingConnectionService.addScope($scope);
                $scope.isConnectedByAudioPhone = MeetingConnectionService.isCurrentUserConnectedAudioPhone();
                $scope.$on('meeting-poll', function() {
                    // Meeting Connection Service changed.
                    $scope.isConnectedByAudioPhone = MeetingConnectionService.isCurrentUserConnectedAudioPhone();
                });

                $scope.hideCannotRequestMessage = function() {
                    $scope.showCannotRequestMessage = false;
                };

                $scope.requestPhoneCall = function() {
                    if (!$scope.canCall) {
                        $scope.showCannotRequestMessage = true;
                    } else {
                        $scope.calling = true;
                        $scope.callMeError = null;
                        MeetingConnectionService.requestCall().then(function() {
                            Meeting.getByRouteParams().then(function(meeting) {
                                if (meeting.get('visitorIsConsumer')) {
                                    MeetingConnectionService.whenConsumerConnects(function() {
                                        $scope.calling = false;
                                    });
                                } else {
                                    MeetingConnectionService.whenExpertConnects(function() {
                                        $scope.calling = false;
                                    });
                                }
                            }, function() {
                                $scope.calling = false;
                            });
                        }, function(error) {
                            if (error.data && error.data.error) {
                                $scope.callMeError = error.data.error;
                            } else {
                                $scope.callMeError = 'Our system tried calling you at +' + $scope.callNumber.code +
                                    ' ' +
                                    $scope.callNumber.number + ', but received an error.';
                            }
                        });
                    }
                };
                $scope.cancelCallMe = function() {
                    $scope.calling = false;
                };
            },
        ],
    };
}
