import {Booth} from "../../control/partner/model/fairs/booths/booth";
import {FAIR_TYPE_CAREER, FAIR_TYPE_ENROLLMENT} from "model/userFair"

export const BoothEditController = [
    '$scope', '$window', '$rootScope', '$routeParams', '$location', '$http', '$timeout', 'VisitorService',
    function ($scope, $window, $rootScope, $routeParams, $location, $http, $timeout, VisitorService) {
        $window.document.title = 'Edit Booth';
        $rootScope.pageClass = 'consultations-container';

        $scope.FAIR_TYPE_CAREER = FAIR_TYPE_CAREER;
        $scope.FAIR_TYPE_ENROLLMENT = FAIR_TYPE_ENROLLMENT;

        $scope.init = function() {
            $scope.errors = {};
            $scope.isLoading = true;

            $scope.booth = new Booth();
            $scope.booth.urlRoot = `/booths/${$routeParams.groupid}`;
            $scope.booth
                .fetch()
                .then((res) => {
                    $scope.$emit('controllerLoaded', true);
                    $scope.isLoading = false;
                })
        }

        $scope.updateBooth = function () {
            $scope.errors = {};
            $scope.booth.transform();
            $http.put(`/booths/${$routeParams.groupid}`, $scope.booth)
                .then((res) => {
                    $scope.booth = new Booth();
                    $scope.isLoading = false;
                    $location.path(`/booths`).search({created: '1'});
                })
                .catch((res) => {
                    if (res.status === 422) {
                        $scope.errors = res.data.error;
                    }
                    $scope.isLoading = false;
                })
        }

    }
]
