import {visitor as Visitor} from 'visitor';
import {Visitor as VisitorModel} from 'model/visitor';

export const VisitorService = [
    '$q',
    function($q) {
        let visitor;

        return {
            getVisitor: function(skipCache) {
                const deferred = $q.defer();
                if (!visitor) {
                    visitor = new VisitorModel(Visitor, {parse: true});
                }

                deferred.resolve(visitor);
                return deferred.promise;
            },
        };
    },
];
