export function lazyLoad() {
    return {
        restrict: 'A',
        scope: {
            lazyLoadSrc: '@',
            lazyLoadLoaded: '=',
        },
        link:
            (scope, element) => {
                const loadImg = changes => {
                    changes.forEach(change => {
                        if (change.intersectionRatio > 0 && scope.lazyLoadLoaded !== 1) {
                            scope.lazyLoadLoaded = 1;
                            change.target.src = scope.lazyLoadSrc;
                        }
                    });
                }

                const observer = new IntersectionObserver(loadImg);
                const img = angular.element(element)[0];

                observer.observe(img);
            },
    };
}
