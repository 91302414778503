import {NgModel} from 'model/ngModel';

export const ConferenceNumber = NgModel.extend({
    _name: 'ConferenceNumber',
    defaults: {
        label: null,
        country: null,
        dialNumber: null,
        isPlaceholder: false, // Whether this has a phone number or should only show VoIP text.
    }
});
