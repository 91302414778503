import {NgModel} from 'model/ngModel';
import moment from 'moment';

export const GroupUserFile = NgModel.extend({
    _name: 'GroupUserFile',
    defaults: {
        fullName: '',
        firstName: '',
        lastName: '',
        userid: null,
        fileName: null,
        fileSize: 0,
        contentType: '',
        dateUploaded: {},
        id: null,
        affinityGroupid: null,
    },
    urlRoot: '/group/[groupid]/files/[fileid]',
    parse: function(data) {
        let dataFormatted = this._super('parse', data);

        dataFormatted.dateUploadedFormatted = moment(data.dateUploaded.date).format('MMM Do YYYY');
        dataFormatted.downloadLink = `${window.location.hostname}/group/${data.affinityGroupid}/files/${data.id}`;

        return dataFormatted;
    },
    getDownloadHeaders: function() {
        return [
            'Date',
            'User',
            'Link',
        ];
    },
    getDownloadData: function() {
        return [
            this.get('dateUploadedFormatted'),
            this.get('fullName'),
            this.get('downloadLink'),
        ];
    },
});
