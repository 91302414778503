import {Backbone} from 'Vertebrae';
import {OutOfOffice} from '../model/outOfOffice';

export const OutOfOffices = Backbone.Collection.extend({
    _name: 'OutOfOffices',
    model: OutOfOffice,
    userid: null,
    url: function() {
        if (this.userid) {
            return '/api/profile/' + this.userid + '/ooo';
        } else {
            return '/api/profile/ooo';
        }
    },
    setUserid: function(userid) {
        this.userid = userid;
    },
});
