import {NgModel} from './ngModel';
import {Employer} from './employer';
import {Location} from './location';
import {Industry} from './industry';
import {Role} from './role';
import * as _ from 'underscore';
import * as moment from 'moment-timezone';
import {whitelabelling} from 'whitelabelling';

export const ProfileEmployment = NgModel.extend({
    _name: 'Employment',
    associations: {
        Employer,
        Location,
        Industry,
        Role,
    },
    defaults: {
        title: null,
        Employer: null,
        Location: null,
        Industry: null,
        Role: null,
        startDate: null,
        endDate: null,
        isCurrent: false,
        isDeleted: false,
    },
    parse: function(data) {
        const dataFormatted = this._super('parse', data);
        // noinspection JSPotentiallyInvalidConstructorUsage
        const now = new moment();

        if (dataFormatted.startDate) {
            dataFormatted.startDate = this.getDate(dataFormatted.startDate);
            const startMoment = moment.tz(dataFormatted.startDate, 'UTC');
            if (startMoment.format('MM DD') === '01 01') {
                dataFormatted.startDateFormatted = startMoment.format('YYYY');
            } else {
                dataFormatted.startDateFormatted = startMoment.format('MMM, YYYY');
            }
        }

        if (dataFormatted.endDate) {
            dataFormatted.endDate = this.getDate(dataFormatted.endDate);
            dataFormatted.purportedEndDate = dataFormatted.endDate;
            dataFormatted.endDateFormatted = moment.tz(data.endDate, 'UTC').format('YYYY');
            const endMoment = moment.tz(dataFormatted.endDate, 'UTC');
            if (endMoment.format('MM DD') === '01 01') {
                dataFormatted.endDateFormatted = endMoment.format('YYYY');
            } else {
                dataFormatted.endDateFormatted = endMoment.format('MMM, YYYY');
            }
        } else {
            dataFormatted.purportedEndDate = now.toDate();
            dataFormatted.endDateFormatted = 'Present';
        }

        // determine the duration in days
        if (dataFormatted.startDate && dataFormatted.purportedEndDate) {
            const timeDiff = Math.abs(dataFormatted.purportedEndDate.getTime() - dataFormatted.startDate.getTime());
            dataFormatted.duration = Math.ceil(timeDiff / 86400000); // 1000 * 3600 * 24 == number of milliseconds in a
                                                                     // day

            // a string representation of the duration
            let years;
            let months = 0;
            if (dataFormatted.duration < 365 || dataFormatted.purportedEndDate.getUTCFullYear() !== parseInt(now.utc().format('YYYY'))) {
                years = Math.floor(dataFormatted.duration / 365);
                months = Math.floor((dataFormatted.duration % 365) / 30);
            } else {
                years = Math.ceil(dataFormatted.duration / 365);
            }
            if (years < 1) {
                years = 0;
            }

            if (dataFormatted.logo) {
                let logoPath = dataFormatted.logo;
                if (logoPath.indexOf('/') === 0) {
                    logoPath = logoPath.substring(1);
                }
                dataFormatted.logo = logoPath;
            }

            dataFormatted.durationFormatted = '';
            if (years) {
                dataFormatted.durationFormatted += years + ' year' + (years > 1 ? 's' : '');
            } else if (!months && dataFormatted.startDate === now) {
                dataFormatted.durationFormatted = ', 1 month';
            } else if (!months) {
                dataFormatted.durationFormatted = '';
            }

            if (months) {
                if (years) {
                    dataFormatted.durationFormatted += ', ';
                }
                dataFormatted.durationFormatted += months + ' month' + (months > 1 ? 's' : '');
            }
        }

        dataFormatted.isCurrent = this.asBoolean(dataFormatted.isCurrent);

        this.__bindManyForAngular(dataFormatted);
        return dataFormatted;
    },
    isComplete: function(employmentDatesOptional) {
        this.validateData(employmentDatesOptional);
        const errors = this.get('errors');
        const keys = _.keys(errors);
        if (keys.length === 0) {
            return true;
        }
        let isComplete = true;
        _.every(keys, function(k) {
            isComplete = !errors[k];
            return isComplete;
        });
        return isComplete;
    },
    validateData: function(employmentDatesOptional) {
        let errors = {};
        let industryRequired = whitelabelling.settings['customized:employment:industryRequired'];
        let roleRequired = whitelabelling.settings['customized:employment:roleRequired'];

        if (!this.get('isDeleted')) {
            errors.title = !this.get('title');
            errors.Employer = !(this.get('Employer') && this.get('Employer').get('label'));
            errors.startDate = (!employmentDatesOptional && !this.get('startDate'));
            errors.endDate = (!this.get('endDate') && (!employmentDatesOptional && !this.get('isCurrent')));
            errors.Industry = industryRequired && !this.get('Industry');
            errors.Role = roleRequired && !this.get('Role');
        }

        this.set('errors', errors);
        return errors;
    },
});
