export const profileSyncItemPseudoRadios = {
    templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/components/profileSyncItemPseudoRadios.html',
    transclude: true,
    bindings: {
        radios: '<',
        selection: '<',
        onSelect: '&',
    },
    controller: function() {
        var $ctrl = this;
        $ctrl.select = function(value) {
            $ctrl.onSelect({value: value});
        };
    },
};
