/**
 * This directive renders a donation prompt specified by the type attribute
 * Use like:
 * <donation-prompt prompt="SOME_MODEL"></donation-prompt>
 */
export function donationPrompt() {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            prompt: '=',
            isPreview: '=?',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/donationPrompt.html',
    };
}
