export function userBooths() {
    return {
        restrict: 'E',
        scope: {
            booths: '=',
            user: '=',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/directives/userBooths.html',
        controller: [
            '$scope', function($scope) {

            }
        ]
    };
}

