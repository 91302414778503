// noinspection NpmUsedModulesInstalled
import {whitelabelling as Whitelabelling} from 'whitelabelling';
import {Conversations} from '../collection/conversations';

export const MessagesController = [
    '$scope', '$window', '$rootScope', '$q', '$location', 'ChatService', '$timeout', 'VisitorService',
    ($scope, $window, $rootScope, $q, $location, ChatService, $timeout, VisitorService) => {
        $window.document.title = 'Messages';
        $rootScope.pageClass = 'messages-container';
        $scope.whitelabelling = Whitelabelling;

        $scope.showNewMessageBox = false;
        $scope.isLoading = true;
        $scope.countUsersShown = 10;
        $scope.myUserid = null;

        $scope.inputVars = {
            userSearch: undefined,
        };

        $scope.TAB_CHAT = 'tab-chat';
        $scope.TAB_HISTORY = 'tab-history';
        $scope.TAB_FILES = 'tab-files';
        $scope.mobileTab = $scope.TAB_CHAT;
        $scope.userInOneToOneMatching = false;

        const filterConversationList = keyword => {
            if (keyword) {
                const newValLower = keyword.toLowerCase();
                $scope.filteredConversations = $scope.conversations.filter(c => {
                    const otherUser = c.get('OtherUser');
                    if (otherUser) {
                        const firstName = otherUser.get('firstName') ? otherUser.get('firstName').toLowerCase() : null;
                        const lastName = otherUser.get('lastName') ? otherUser.get('lastName').toLowerCase() : null;
                        const fullName = (firstName && lastName) ? firstName + ' ' + lastName : null;

                        return (
                            (firstName ? firstName.indexOf(newValLower) > -1 : false)
                            ||
                            (lastName ? lastName.indexOf(newValLower) > -1 : false)
                            ||
                            (fullName ? fullName.indexOf(newValLower) > -1 : false)
                        );
                    }
                });
            } else {
                $scope.filteredConversations = $scope.conversations.models;
            }
        };

        $scope.$watch('inputVars.userSearch', newVal => {
            filterConversationList(newVal);
        });

        $scope.showMoreUsers = () => {
            $scope.countUsersShown += 10;
        };

        // this updates the last message peak in the side panel
        $scope.$on('chatMessageAdded', (evt, message) => {
            $scope.conversations.sort();
            // refresh our filtered list
            filterConversationList($scope.inputVars.userSearch);
        });

        const search = $location.search();
        $scope.conversations = new Conversations();
        $q.all([
            $scope.conversations.fetch(),
            VisitorService.getVisitor(),
            ChatService.getClient(),
        ]).then(([_, Visitor, __]) => {
            if (Visitor.isUser) {
                $scope.myUserid = Visitor.user.id;

                if (Visitor.user.consumerInOneToOneMatching) {
                    $scope.userInOneToOneMatching = true;
                }
            }

            $scope.$emit('controllerLoaded', true);
            $scope.isLoading = false;

            if (search.userid) {
                $scope.enterConversation(search.userid);
            } else if ($scope.conversations.length) {
                $scope.enterConversation($scope.conversations.at(0).get('OtherUser').get('id'));
            }
        });

        $scope.exitConversation = () => {
            $scope.selectedConversation = undefined;
            $scope.layerConversation = undefined;
            $scope.selectedUserid = undefined;
        };

        $scope.$on('markAllChatsRead', () => {
            // you might want to use selectedConversation, but you can't because that's a different conversation object,
            // so we need to get the conversation it represents in our collection and set the message count on that
            const conversation = $scope.conversations.findWhere(obj => obj.OtherUser.id === parseInt($scope.selectedUserid));
            if (conversation) {
                conversation.set('unreadMessageCount', 0);
            }
        });

        const showErrorMessage = message => {
            $scope.error = message;
        };

        $scope.enterConversation = userid => {
            if ($scope.selectedUserid === userid) {
                return;
            }
            $location.search({userid: userid});

            $scope.mobileTab = $scope.TAB_CHAT;
            $scope.loadingChat = true;
            $scope.selectedUserid = userid;
            ChatService.getConversationWithUser(userid).then(conversation => {
                ChatService.getConversation(conversation.sid).then(channel => {
                    $scope.$evalAsync(() => {
                        $scope.layerConversation = channel;
                        $scope.selectedConversation = conversation;
                        $scope.loadingChat = false;
                    });
                }, error => {
                    console.log(error);
                    $scope.loadingChat = false;
                    showErrorMessage('Error: ' + error);
                });
            }, error => {
                $scope.loadingChat = false;
                showErrorMessage('Error: ' + error);
            });
        };
        // we can deeplink to a specific conversation
        if (search && search.userid) {
            $scope.enterConversation(search.userid);
        }
    },
];
