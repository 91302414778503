import {NgModel} from './ngModel';
import {AffinityGroupCategories} from 'collection/affinityGroupCategories';
import {Verticals} from 'collection/verticals';
import {Services} from 'collection/services';
import {Roles} from '../collection/roles';
import {Industries} from '../collection/industries';

export const RegistrationSettings = NgModel.extend({
    _name: 'RegistrationSettings',
    associations: {
        AffinityGroupCategories,
        VerticalServices: Services,
        Verticals,
        Roles,
        Industries,
    },
    urlRoot: '/registration/settings/get.json',
});
