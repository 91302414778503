import {Backbone} from 'Vertebrae';
import {Navigation} from '../model/navigation';

export const Navigations = Backbone.Collection.extend({
    _name: 'Navigations',
    model: Navigation,
    url: '/navigation.json',
    markSelectedByUrl: function(url) {
        var retVal = null;
        this.each(function(nav) {
            nav.set('isSelected', false);

            // if we already found our item, just continue
            if (retVal) {
                // make sure the sub navs are set to not selected
                if (nav.get('Navigations')) {
                    nav.get('Navigations').each(function(subNav) {
                        subNav.set('isSelected', false);
                    });
                }

                // then just continue
                return true;
            }

            // we didn't find the nav yet...

            // check if this one is it (by literal URL or angular relative url)
            if (nav.get('url') === url || nav.get('url') === ('#!' + url)) {
                // if it is, mark it as the retVal
                retVal = nav;
            } else if (nav.get('Navigations')) {
                // if it's not the one, but it does have sub navigations,
                // check if the sub ones are it
                retVal = nav.get('Navigations').markSelectedByUrl(url);

                // if a subNav is the one we wanted, we mark the parent as selected also
                if (retVal) {
                    nav.set('isSelected', true);
                }
            }
        });

        // if we found one, mark it selected
        if (retVal) {
            retVal.set('isSelected', true);
        }

        return retVal;
    },
});
