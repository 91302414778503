/**
 * This directive sets up the Firsthand "hinted input" functionality without requiring page load
 * <div ... hinted-input type="" label="" default="" ng-change=""...></div>
 */
export function hintedInput() {
    return {
        restrict: 'A',
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/hintedInput.html',
        scope: {
            label: '@label',
            type: '@type',
            value: '=ngModel',
        },
        require: 'ngModel',
        controller: [
            '$scope', function($scope) {
                $scope.uniqueString = String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now();
            },
        ],
        link: function(scope, element, attrs, controller) {
            var _elem = $(element);
            _elem.addClass('input');

            scope.blur = scope.focus = function() {
                if (scope.type === 'date' || scope.type === 'time' || scope.value) {
                    if (!_elem.hasClass('active')) {
                        _elem.addClass('active');
                    }
                } else {
                    _elem.removeClass('active');
                }
            };

            scope.valueChange = function() {
                scope.focus();
                controller.$setViewValue(scope.value);
            };

            controller.$render = function() {
                scope.value = controller.$modelValue;
            };

            scope.focus();

            scope.$on('refreshInput', scope.focus);
        },
    };
}
