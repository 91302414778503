export function userInputName() {
    return {
        restrict: 'E',
        scope: {
            data: '=',
            displayType: '@?',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/registration/directives/userInputs/userInputName.html',
        controller: [
            '$scope', function($scope) {
                $scope.errors = {};

                $scope.$on('showErrors', function() {
                    $scope.errors = $scope.data.validateData();
                });

                $scope.checkProperty = function() {
                    $scope.errors = $scope.data.validateData();
                };
            },
        ],
    };
}

