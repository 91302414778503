import {Location} from '../model/location';
import {getGoogleMaps} from 'googleMaps';
import {ProxiedGoogleLocationCollection} from '../collection/proxiedGoogleLocationCollection';

const address_component_fields = {
    sublocality: 'city',
    locality: 'city',
    administrative_area_level_1: 'state',
    country: 'country',
};

export function locationInput() {
    return {
        restrict: 'E',
        require: 'ngModel',
        scope: {
            'googlePlace': '=',
            'error': '=?',
            'ngDisabled': '=?',
            'locationPlaceholder': '@?',
            'required': '=?',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/registration/directives/locationInput.html',
        controller: [
            '$scope', '$q', '$timeout', '$http', function($scope, $q, $timeout, $http) {
                if (!$scope.proxiedLocation) {
                    $scope.proxiedLocation = { val: null };
                }
                $scope.locationData = {};
                $scope.locationInput = '';

                var isRequired = function() { // default: true
                    return angular.isDefined($scope.required) ? !!$scope.required : true;
                };

                if (!$scope.placeholder) {
                    $scope.placeholder = 'Enter a location';
                }

                $scope.$watch('googlePlace', function() {
                    $scope.handleSelectedPlace($scope.googlePlace);
                });

                $scope.$watch('proxiedLocation.val', function() {
                    $scope.handleSelectedPlace($scope.proxiedLocation.val);
                });

                $scope.clearLocation = function() {
                    $scope.locationData = {};
                    $scope.error = isRequired();
                    $scope.setData({});
                };
                $scope.checkForLocation = function() {
                    if ($scope.isValid()) {
                        $scope.error = false;
                        // if it is valid, we set the ngModel data
                        $scope.setData(new Location($scope.locationData, {parse: true}));
                    } else {
                        if (isRequired()) {
                            $scope.error = true;
                        } else {
                            $scope.error = $scope.locationInput !== '';
                        }
                    }
                };
                $scope.isValid = function() {
                    return $scope.locationData && $scope.locationData.placeid && $scope.locationData.label;
                };

                $scope.handleSelectedPlace = function(place) {
                    if (!place || !place.place_id) {
                        return;
                    }

                    var locationParams = {
                        'placeid': place.place_id,
                        'label': place.formatted_address ? place.formatted_address : place.description,
                    };

                    for (var component_key in place.address_components) {
                        if (!place.address_components.hasOwnProperty(component_key)) {
                            continue;
                        }
                        for (i = 0; i < place.address_components[component_key].types.length; i++) {

                            var type = place.address_components[component_key].types[i];

                            if (!!address_component_fields[type]) {
                                var value_key = 'long_name';
                                locationParams[address_component_fields[type]] = place.address_components[component_key][value_key];
                            }
                        }
                    }

                    if (place.geometry && place.geometry.location && place.geometry.location.lat() && place.geometry.location.lng()) {
                        var geometryItems = ['lat', 'lng'];
                        for (var i = 0; i < geometryItems.length; i++) {
                            // Handle geometry.
                            var value = place.geometry.location[geometryItems[i]]();
                            if (value) {
                                locationParams[geometryItems[i]] = value;
                            }
                        }
                    }

                    // Otherwise we add hidden inputs for all the data we collected.
                    $scope.locationData = locationParams;
                    $scope.locationInput = $scope.locationData.label;
                    $scope.checkForLocation();
                };

                // For proxied-lookup.
                $scope.resource = function(lookupString) {
                    var deferred = $q.defer();

                    if (!lookupString) {
                        // if there's no prefix, resolve an empty array
                        $timeout(function() {
                            deferred.resolve(new ProxiedGoogleLocationCollection([]));
                        }, 0);
                    } else {
                        $http.get('/api/google-proxy-location.json', {params: { autocomplete: lookupString} }).then(function(responseObj) {
                            deferred.resolve(new ProxiedGoogleLocationCollection(responseObj.data));
                        }, deferred.reject);
                    }

                    return deferred.promise;
                };
            },
        ],
        link: function(scope, element, attrs, ngModel) {
            scope.locationIsProxied = false;
            const googleMaps = getGoogleMaps();

            if (googleMaps) {
                scope.elem = element.children()[0];
                scope.autocomplete = new googleMaps.places.Autocomplete(scope.elem, {types: ['(regions)']});
                googleMaps.event.addDomListener(scope.elem, 'keydown', function(event) {
                    // when location search is on, we disable the auto-submit when enter is pressed
                    // because it submits the form before the location is entered.
                    if (event.which === 13 || event.keyCode === 13) {
                        event.preventDefault();
                    }
                });
                googleMaps.event.addListener(scope.autocomplete, 'place_changed', function() {
                    var place = scope.autocomplete.getPlace();
                    scope.handleSelectedPlace(place);

                    // we refocus on the input for two reasons:
                    // 1- it fixes a bug where the "Please select a location" kept appearing
                    // 2- allows them to naturally tab to the next input in the form
                    scope.elem.focus();
                    // we then blur because we don't actually want the autocomplete box to popup...
                    scope.elem.blur();
                });
            } else {
                scope.locationIsProxied = true;
            }

            scope.setData = function(d) {
                ngModel.$setViewValue(d);
            };
            ngModel.$render = function() {
                scope.locationData = ngModel.$modelValue;

                if (!scope.locationData) {
                    scope.locationInput = '';
                    scope.setData(null);
                } else if (scope.locationData && scope.locationData.label) {
                    if (!scope.proxiedLocation) {
                        scope.proxiedLocation = { val: null };
                    }
                    scope.locationInput = scope.locationData.label;
                    scope.proxiedLocation.val = scope.locationData;
                    scope.setData(scope.locationData);
                }
            };
        },
    };
}
