import {NgModel} from './ngModel';
import {ProfileUser} from 'model/profileUser';
import {Meeting} from './meeting';
import {Employment} from 'model/employment';
import * as moment from 'moment-timezone';

export const Assessment = NgModel.extend({
    _name: 'Assessment',
    associations: {
        User: ProfileUser,
        UserEmployment: Employment,
        Meeting: Meeting,
    },
    parse: function(data) {
        var dataFormatted = this._super('parse', data);

        if (data.dateCreated) {
            dataFormatted.dateCreated = this.getDate(data.dateCreated);
            var momentDate = moment.tz(data.dateCreated, moment.tz.guess());
            dataFormatted.dateCreatedFormatted = momentDate.format('MMMM Do, YYYY');
            dataFormatted.timeSinceFormatted = momentDate.fromNow(true) + ' ago';
        }

        this.__bindManyForAngular(dataFormatted);

        return dataFormatted;
    },
});
