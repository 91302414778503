export const ConsultationHeaderController = [
    '$scope', 'Meeting',
    function($scope, Meeting) {
        var _pageContent = $('#consultationPageContent');

        Meeting.getByRouteParams().then(function(meeting) {
            var duration = meeting.get('durationInMinutes');
            var timeLabel = 'Minute';
            if (duration >= 60) {
                duration = (duration / 60);
                timeLabel = 'Hour';
            }

            $scope.duration = duration;
            $scope.timeLabel = timeLabel;
            $scope.consultationType = meeting.consultationType;
            $scope.verticalCategoryLabel = meeting.verticalCategoryLabel;

            $scope.tabNumber = 1;

            $scope.clickTab = function(tabNumber) {
                $scope.tabNumber = tabNumber;

                if (tabNumber == 2) {
                    _pageContent.addClass('tab2');
                } else {
                    _pageContent.removeClass('tab2');
                }
            };
        });
    },
];
