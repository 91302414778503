import {Backbone} from 'Vertebrae';
import {ProfileUser} from 'model/profileUser';
import {Assessments} from 'collection/assessments';
import {Availabilities} from 'collection/availabilities';
import {ProfileCareerGoals} from 'collection/profileCareerGoals';
import {Educations} from 'collection/educations';
import {Employments} from 'collection/employments';
import {Languages} from 'collection/languages';
import {Reviews} from 'collection/reviews';
import {UnAvailabilities} from 'collection/unAvailabilities';
import {JobOpenings} from 'collection/jobOpenings';
import {ProfileAffinityGroups} from 'collection/profileAffinityGroups';
import {Services} from 'collection/services';

export const FullProfile = Backbone.Model.extend({
    _name: 'Profile',
    urlRoot: '/profile/[userid]/getProfile.json',
    url: function() {
        this.set('userid', this.get('User').get('id'));
        return this._super('url');
    },
    associations: {
        User: ProfileUser,
        Assessments,
        Availabilities,
        CareerGoals: ProfileCareerGoals,
        Educations,
        Employments,
        Languages,
        Reviews,
        Services,
        UnAvailabilities,
        JobOpenings,
        AffinityGroups: ProfileAffinityGroups,
    },
});
