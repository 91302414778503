export const EditAvailabilityController = [
    '$scope', '$rootScope', 'VisitorService',
    function($scope, $rootScope, VisitorService) {
        $rootScope.pageClass = 'edit-profile-container';
        $scope.isLoading = true;

        VisitorService.getVisitor().then(function(visitor) {
            if (!visitor.get('isUser')) {
                window.location.href = '/login';
                return;
            }

            $scope.isLoading = false;
        });
    },
];
