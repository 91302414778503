import {Backbone} from 'Vertebrae';
import {ProfileUser as UserModel} from 'model/profileUser';
import {Reviews} from 'collection/reviews';
import {Services} from '../../user/collection/services';
import {Languages} from 'collection/languages';
import {JobOpenings} from 'collection/jobOpenings';
import {FullProfile} from 'model/fullProfile';

export const Profile = [
    '$window', '$http', '$q', 'TimezonesService',
    function($window, $http, $q, TimezonesService) {
        // ---------------------------------------------------------------
        // SINGLETON VARIABLES - for caching purposes
        const users = {};
        const reviews = {};
        const services = {};

        // all of these currently only work for the page user (identified by userid variable)
        let languages;
        let jobOpenings;

        // ---------------------------------------------------------------
        // PROMISES - to avoid duplicating requests
        const userPromises = {};
        const fullProfilePromise = {};

        const retVal = {
            refreshUser: function(fetchId, consumerOnly) {
                if (userPromises[fetchId]) {
                    return userPromises[fetchId];
                }

                const deferred = $q.defer();

                userPromises[fetchId] = deferred.promise;

                // instantiate a new one and fetch
                const u = new UserModel({id: fetchId});
                u.fetch({parse: true}).then(function() {
                    users[fetchId] = u;
                    delete userPromises[fetchId];

                    // we treat them as one or the other
                    if (consumerOnly) {
                        u.set('expertid', null);
                    } else {
                        u.set('consumerid', null);
                    }

                    deferred.resolve(users[fetchId]);
                }, function() {
                    deferred.reject(arguments[2]);
                    delete userPromises[fetchId];
                });

                return deferred.promise;
            },
            /**
             * @returns {Promise}
             */
            getUser: function(fetchId) {
                const deferred = $q.defer();

                // if we've already loaded the user, just kick it back
                if (users[fetchId]) {
                    deferred.resolve(users[fetchId]);
                } else {
                    retVal.refreshUser(fetchId).then(deferred.resolve, deferred.reject);
                }

                return deferred.promise;
            },


            /**
             * Reviews are what other users left for this user
             * @see getReviews
             * @returns {Promise}
             */
            async getReviews(userid) {
                const deferred = $q.defer();

                if (reviews[userid]) {
                    deferred.resolve(reviews[userid]);
                }

                reviews[userid] = new Reviews();
                reviews[userid].setUser(new Backbone.Model({'id': userid}));
                try {
                    await reviews[userid].fetch();
                    deferred.resolve(reviews[userid]);
                }
                catch (e) {
                    deferred.reject.apply(this, arguments);

                }

                return deferred.promise;
            },


            getJobOpenings: function(userid) {
                const deferred = $q.defer();

                jobOpenings = new JobOpenings();
                retVal.getUser(userid).then(function(u) {
                    jobOpenings.setUser(u);
                    jobOpenings.fetch().then(function() {
                        deferred.resolve(jobOpenings);
                    }, function() {
                        deferred.reject.apply(this, arguments);
                    });
                });

                return deferred.promise;
            },

            getLanguages: function(userid) {
                const deferred = $q.defer();

                languages = new Languages();
                retVal.getUser(userid).then(function(u) {
                    languages.setUser(u);
                    languages.fetch().then(function() {
                        deferred.resolve(languages);
                    }, function() {
                        deferred.reject.apply(this, arguments);
                    });
                });

                return deferred.promise;
            },

            /**
             * @returns {Promise}
             */
            getServices: function(userid) {
                const deferred = $q.defer();

                if (services[userid]) {
                    deferred.resolve(services[userid]);
                }

                // if we've already loaded the services, just kick it back
                services[userid] = new Services();
                retVal.getUser(userid).then(function(u) {
                    services[userid].setUser(u);
                    services[userid].fetch().then(function() {
                        parseServices(services[userid], u);
                        deferred.resolve(services[userid]);
                    }, function() {
                        deferred.reject.apply(this, arguments);
                    });
                });

                return deferred.promise;
            },

            /**
             * @returns {Promise}
             */
            getFullProfile: function(userid, consumerOnly, loadBoth) {
                if (fullProfilePromise[userid]) {
                    return fullProfilePromise[userid];
                }

                const deferred = $q.defer();
                fullProfilePromise[userid] = deferred.promise;

                retVal.getUser(userid).then(function(u) {
                    const profile = new FullProfile({User: u});

                    const params = {};
                    if (consumerOnly) {
                        params.consumerOnly = true;
                    } else if (loadBoth) {
                        params.loadBoth = true;
                    }

                    const data = {
                        data: $.param(params),
                    };

                    profile.fetch(data).then(function() {
                        parseServices(profile.get('Services'), u);
                        deferred.resolve(profile);
                        fullProfilePromise[userid] = undefined;
                    }, function(jqXHR, textStatus, errorThrown) {
                        deferred.reject(errorThrown);
                        fullProfilePromise[userid] = undefined;
                    });
                });

                return deferred.promise;
            },

            bookMeeting: function(meeting, files) {
                const suggestedTimes = meeting.get('suggestedTimes');
                const timestamps = [];
                suggestedTimes.forEach(function(t) {
                    timestamps.push(t.getTime() / 1000);
                });

                const consumer = meeting.get('Consumer');
                const deferred = $q.defer();

                const fd = new FormData();

                // if there are files, we need to add them
                if (Array.isArray(files) && files.length > 0) {
                    files.forEach(function(f) {
                        fd.append('files[]', f);
                    });
                }

                timestamps.forEach(function(t) {
                    fd.append('times[]', t);
                });

                fd.append('expertUserid', meeting.get('Expert').get('id'));
                fd.append('serviceid', meeting.get('Service').get('id'));
                fd.append('timezone', TimezonesService.getDeviceTimezone().locationCode);
                fd.append('comments', meeting.get('comments'));
                fd.append('userEmail', consumer.get('email'));
                fd.append('firstName', consumer.get('firstName'));
                fd.append('lastName', consumer.get('lastName'));
                fd.append('userPhone', consumer.get('phone').number);
                fd.append('userCountry', consumer.get('countryid'));

                $http({
                    method: 'POST',
                    url: '/consultations/book.json',
                    data: fd,
                    headers: {
                        'Content-Type': undefined, // @see
                        // http://www.uncorkedstudios.com/blog/multipartformdata-file-upload-with-angularjs/
                    },
                }).then(function({data: {data: {meetingid}}}) {
                    meeting.set('id', meetingid);
                    deferred.resolve(meeting);
                }, function(responseObj) {
                    deferred.reject(responseObj.data.error);
                });

                return deferred.promise;
            },

            notifyMeWhenAvailable: function(expertid, status) {
                const deferred = $q.defer();

                const fd = new FormData();
                fd.append('status', status);
                fd.append('expertid', expertid);

                $http({
                    method: 'POST',
                    url: '/api/expert/set_email_me',
                    data: fd,
                    headers: {
                        'Content-Type': undefined, // @see
                        // http://www.uncorkedstudios.com/blog/multipartformdata-file-upload-with-angularjs/
                    },
                }).then(function(responseObj) {
                    deferred.resolve(responseObj.data.data);
                }, function() {
                    deferred.reject.apply(this, arguments);
                });

                return deferred.promise;
            },
        };

        // This function is used to append the consultation cost to the label of each of Service if needed
        function parseServices(services, user) {
            if (!services || !services.length) {
                return null;
            }

            for (const s of services.models) {
                let labelWithCost = s.get('label') + ' (' + s.get('durationInMinutes') + ' min)';

                if (user.get('expertid')) {
                    if (user.get('needsPayment')) {
                        // We're seeing an expert on a platform that includes payment, and they're bookable at a Public pricing type rate.
                        const price = (parseFloat(s.get('durationInHours')) * parseFloat(user.get('hourlyRateRaw')));
                        if (price === 0) {
                            labelWithCost += ' - Free';
                        } else {
                            labelWithCost += ' - $' + price.toFixed(2);
                        }
                    } else if (user.get('platformIncludesConsumerPayments')) {
                        // We're seeing an expert on a platform that includes payment, but they're free to the end user.
                        labelWithCost += ' - Free Special';
                    }
                }
                s.set('labelWithCost', labelWithCost);
            }
        }

        return retVal;
    },
];
