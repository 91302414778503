import {whitelabelling as Whitelabelling} from 'whitelabelling';

export function editAvailability() {
    return {
        restrict: 'E',
        scope: {
            userid: '=?',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/editAvailability.html',
        link: function(scope, element, attrs) {
            // If the directive had a "userid" attribute, then we need to wait for that to load.
            // If not, then it's OK to load without a userID, and just load for "myself".
            scope.waitForUserId = 'userid' in attrs;
        },
        controller: [
            '$scope',
            'VisitorService',
            'RegistrationService',
            '$q',
            '$routeParams',
            function($scope, VisitorService, RegistrationService, $q, $routeParams) {
                $scope.isSavingData = false;
                $scope.error = null;
                $scope.whitelabelling = Whitelabelling;

                $scope.SECTION_EXPOSURE = 'exposure';
                $scope.SECTION_CALENDAR_SETTINGS = 'calendar_settings';
                $scope.SECTION_PREFERRED_TIMES = 'preferred_times';
                $scope.SECTION_OUT_OF_OFFICE = 'out_of_office';
                $scope.SECTION_PROGRAMS = 'programs';

                $scope.section = $routeParams.action ? $routeParams.action : $scope.SECTION_EXPOSURE;

                $scope.canEditPrograms = true;

                $scope.changeSection = function(newSection) {
                    if (newSection !== $scope.section) {
                        $scope.section = newSection;
                    }
                };

                $scope.$on('saveData', function() {
                    $scope.saveData();
                });

                $scope.saveData = function() {
                    $scope.isSavingData = true;
                    $q.when($scope.registrationData.saveAvailability()).then(function(responseObj) {
                        $scope.isSavingData = false;
                        bindChangeListeners();
                        if (responseObj.status === 401) {
                            $scope.error = 'Could not save!';
                        }
                    });
                };

                $scope.$watch('userid', function() {
                    if ($scope.waitForUserId && !$scope.userid) {
                        return;
                    }
                    $q.all([
                        RegistrationService.getRegistrationDataForAvailability($scope.userid),
                        RegistrationService.getRegistrationSettings($scope.userid),
                    ]).then(function(data) {
                        $scope.registrationData = data[0];
                        $scope.registrationSettings = data[1];

                        if (!$scope.registrationData.hasRole('expert')) {
                            $scope.registrationData = {};
                            $scope.error = 'Sorry, you don\'t have permission to access this page.';
                        } else {
                            bindChangeListeners();

                            if (!$scope.registrationData.Programs.length ||
                                !$scope.registrationSettings.WhitelabelSettings.allowExpertProgramsChoice) {
                                $scope.canEditPrograms = false;
                            }
                        }

                        // notify the app that we're ready
                        $scope.isLoaded = true;
                        $scope.$emit('controllerLoaded', true);
                    });
                });

                function bindChangeListeners() {
                    // watch for changes to the preferredTimes data set
                    $scope.preferredTimesChanged = false;
                    $scope.registrationData.GeneralAvailability.on('add remove', function() {
                        $scope.preferredTimesChanged = true;
                    });

                    var startingValue = $scope.registrationData.restrictToPrograms;
                    $scope.$watch('registrationData.restrictToPrograms', function(newVal) {
                        $scope.changeMadeToProgramRestriction = (newVal !== startingValue);
                    });
                }
            },
        ],
    };
}
