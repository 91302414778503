export function slideToggle() {
    return {
        scope: {
            isExpanded: '=',
        },
        restrict: 'A',
        controller: [
            '$scope', '$timeout', function($scope, $timeout) {
                $scope.$watch(function() {
                    return $scope.$content.height();
                }, function(newVal, oldVal) {
                    if (!$scope.isExpanded && (newVal !== oldVal)) {
                        $scope.closedHeight = newVal;
                    }
                });

                $scope.$watch('isExpanded', function(newVal, oldVal) {
                    if (newVal) {
                        $scope.$element.css('height', $scope.closedHeight + 'px');

                        $timeout(function() {
                            $scope.$content.css('border', '1px solid rgba(0,0,0,0)');
                            var y = $scope.$content.height();
                            $scope.$content.css('border', 0);
                            $scope.$element.css('height', y + 'px');
                        }, 0);
                    } else if ($scope.closedHeight !== undefined) {
                        $scope.$element.css('height', $scope.closedHeight + 'px');
                    }
                });
            },
        ],
        compile: function(element) {
            // wrap tag
            var contents = element.html();
            var wrapper = $('<div style="margin:0; padding:0;">' + contents + '</div>');
            element.html(wrapper);

            return function postLink(scope, element, attrs) {
                scope.$element = element;
                scope.$content = wrapper;
                scope.closedHeight = undefined;

                // default properties
                attrs.duration = (!attrs.duration) ? 500 : attrs.duration;
                attrs.easing = (!attrs.easing) ? 'ease-out' : attrs.easing;

                element.css({
                    overflow: 'hidden',
                    boxSizing: 'content-box',
                    transitionProperty: 'height',
                    transitionDuration: attrs.duration + 'ms',
                    transitionTimingFunction: attrs.easing,
                });
            };
        },
    };
}
