import {ControllersModule} from 'controllers/module';
import {ProfileController} from './profile';
import {BookController} from './book';
import {EditProfileController} from './editProfile';
import {EditAvailabilityController} from './editAvailability';
import {EditOfferingsController} from './editOfferings';
import {SyncProfileController} from './syncProfile';
import {UserDashboardController} from './userDashboard';
import {ProgrammingEventsController} from './programmingEvents';
import {SearchController} from './search';
import {ResourcesController} from './resources';
import {ResourceController} from './resource';
import {GroupsController} from './groups';
import {GroupBaseController} from './groupBase';
import {GroupHomeController} from './groupHome';
import {GroupMembersController} from './groupMembers';
import {GroupDiscussionsController} from './groupDiscussions';
import {DiscussionDetailController} from './discussionDetails';
import {JobsBoardController} from './jobsBoard';
import {MessagesController} from './messages';
import {MeetingController} from './meeting';
import {UserProfileController} from './userProfile';
import {ConsultationHeaderController} from './consultationHeader';
import {MeetingStatesHelper} from './meetingStatesHelper';
import {ChatController} from './chatController';
import {ConsultationsController} from './consultations';
import {PeopleController} from './people';
import {MeetingState_Both_Expired} from './meetingStates/both/expired';
import {MeetingState_Both_Prepare} from './meetingStates/both/prepare';
import {MeetingState_Both_Meet} from './meetingStates/both/meet';
import {MeetingState_Both_SelfReschedule} from './meetingStates/both/selfReschedule';
import {MeetingState_Both_OtherReschedule} from './meetingStates/both/otherReschedule';
import {MeetingState_Both_SelfCancelled} from './meetingStates/both/selfCancelled';
import {MeetingState_Both_OtherCancelled} from './meetingStates/both/otherCancelled';
import {MeetingState_Both_SelfNoShow} from './meetingStates/both/selfNoShow';
import {MeetingState_Both_OtherNoShow} from './meetingStates/both/otherNoShow';
import {MeetingState_Both_BothNoShow} from './meetingStates/both/bothNoShow';
import {MeetingState_Consumer_Complete} from './meetingStates/consumer/complete';
import {MeetingState_Consumer_EmailVerification} from './meetingStates/consumer/emailVerification';
import {MeetingState_Consumer_ExpertAccept} from './meetingStates/consumer/expertAccept';
import {MeetingState_Consumer_Payment} from './meetingStates/consumer/payment';
import {MeetingState_Expert_Complete} from './meetingStates/expert/complete';
import {MeetingState_Expert_EmailVerification} from './meetingStates/expert/emailVerification';
import {MeetingState_Expert_ExpertAccept} from './meetingStates/expert/expertAccept';
import {MeetingState_Expert_Payment} from './meetingStates/expert/payment';
import {BoothsController} from "./boothsController";
import {BoothEditController} from "./boothEditController";
import {GroupEventsController} from "./groupEvents";
import {GroupResourcesController} from "./groupResources";
import {BoothResumesController} from "./boothResumes";
import {SyllabusController} from "./SyllabusController";
import {GroupJobsController} from "./groupJobs";

export default ControllersModule.
    // PROFILE
    controller('ProfileController', ProfileController).
    controller('BookController', BookController).
    controller('EditProfileController', EditProfileController).
    controller('EditAvailabilityController', EditAvailabilityController).
    controller('EditOfferingsController', EditOfferingsController).
    controller('SyncProfileController', SyncProfileController).
    // DASHBOARD
    controller('UserDashboardController', UserDashboardController).
    // RECEPTION
    controller('ProgrammingEventsController', ProgrammingEventsController).
    // SEARCH
    controller('SearchController', SearchController).
    // RESOURCES
    controller('ResourcesController', ResourcesController).
    controller('ResourceController', ResourceController).
    // JOBS BOARD
    controller('JobsBoardController', JobsBoardController).
    // Groups
    controller('GroupsController', GroupsController).
    controller('GroupBaseController', GroupBaseController).
    controller('GroupHomeController', GroupHomeController).
    controller('GroupMembersController', GroupMembersController).
    controller('GroupDiscussionsController', GroupDiscussionsController).
    // Discussion detail
    controller('DiscussionDetailController', DiscussionDetailController).
    // MESSAGES
    controller('MessagesController', MessagesController).
    // CONSULTATIONS
    controller('MeetingController', MeetingController).
    controller('UserProfileController', UserProfileController).
    controller('ConsultationHeaderController', ConsultationHeaderController).
    controller('MeetingStatesHelper', MeetingStatesHelper).
    controller('ConsultationHeaderController', ConsultationHeaderController).
    controller('ChatController', ChatController).
    controller('ConsultationsController', ConsultationsController).
    controller('PeopleController', PeopleController).
    // MEETING STATES - SHARED
    controller('MeetingState_Both_Expired', MeetingState_Both_Expired).
    controller('MeetingState_Both_Prepare', MeetingState_Both_Prepare).
    controller('MeetingState_Both_Meet', MeetingState_Both_Meet).
    controller('MeetingState_Both_SelfReschedule', MeetingState_Both_SelfReschedule).
    controller('MeetingState_Both_OtherReschedule', MeetingState_Both_OtherReschedule).
    controller('MeetingState_Both_SelfCancelled', MeetingState_Both_SelfCancelled).
    controller('MeetingState_Both_OtherCancelled', MeetingState_Both_OtherCancelled).
    controller('MeetingState_Both_SelfNoShow', MeetingState_Both_SelfNoShow).
    controller('MeetingState_Both_OtherNoShow', MeetingState_Both_OtherNoShow).
    controller('MeetingState_Both_BothNoShow', MeetingState_Both_BothNoShow).
    // MEETING STATES - CONSUMER
    controller('MeetingState_Consumer_Complete', MeetingState_Consumer_Complete).
    controller('MeetingState_Consumer_EmailVerification', MeetingState_Consumer_EmailVerification).
    controller('MeetingState_Consumer_ExpertAccept', MeetingState_Consumer_ExpertAccept).
    controller('MeetingState_Consumer_Payment', MeetingState_Consumer_Payment).
    // MEETING STATES - EXPERT
    controller('MeetingState_Expert_Complete', MeetingState_Expert_Complete).
    controller('MeetingState_Expert_EmailVerification', MeetingState_Expert_EmailVerification).
    controller('MeetingState_Expert_ExpertAccept', MeetingState_Expert_ExpertAccept).
    controller('MeetingState_Expert_Payment', MeetingState_Expert_Payment).
    // BOOTHS
    controller('BoothsController', BoothsController).
    controller('BoothEditController', BoothEditController).
    controller('GroupEventsController', GroupEventsController).
    controller('GroupResourcesController', GroupResourcesController).
    controller('BoothResumesController', BoothResumesController).
    controller('SyllabusController', SyllabusController).
    controller('GroupJobsController', GroupJobsController);
