import {ResourcesSearchResults} from "../collection/resourcesSearchResults";

export function userResources() {
    return {
        restrict: 'E',
        scope: {
            resources: '=',
            user: '=',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/directives/userResources.html',
        controller: [
            '$scope', function($scope) {
                $scope.results = new ResourcesSearchResults($scope.resources, {parse: true});
            }
        ]
    };
}

