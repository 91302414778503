import * as angular from 'angular';
import * as Raven from 'raven-js';
import * as RavenAngularPlugin from 'raven-js/plugins/angular';
import {whitelabelling as Whitelabelling} from 'whitelabelling';

export const setupBugTracking = () => {
    Raven.config('https://cfbbef6126814e3aa45b7d3e3759131b@sentry.io/1275285', {
        environment: Evisors.config.environment,
        release: Evisors.config.revision,
        tags: {
            whitelabelid: Whitelabelling && Whitelabelling.id || 0,
        },
        shouldSendCallback: () => true,
        autoBreadcrumbs: {
            console: false,
        },
    }).addPlugin(RavenAngularPlugin, angular).install();
};

export const setUserContext = context => Raven.setUserContext(context);
