import {whitelabelling as Whitelabelling} from 'whitelabelling';

export function searchResult() {
    return {
        restrict: 'E',
        templateUrl: Evisors.config.cdnRootWhitelabel +
            'ng-template/partials/user/directives/search/searchResult.tpl.html',
        scope: {
            data: '=',
        },
        replace: true,
        controller: [
            '$scope', '$timeout', '$window', 'Profile', ($scope, $timeout, $window, Profile) => {
                $scope.whitelabelling = Whitelabelling;
                $scope.startingShowLimit = $scope.employmentLimit = 3;
                $scope.reviews = undefined;
                $scope.showReviewsModal = false;


                $scope.elementIsObject = (element) => {
                    return typeof element === 'object';
                }

                $scope.$watch(() => {
                    if ($scope.whitelabelling.id === 426) {
                        $scope.data['interests'] = $scope.data['customInput_multipleChoice_3'] ? $scope.data['customInput_multipleChoice_3'].join(', ') : null;
                        $scope.data['concatenated_services'] = $scope.data['services'] ? $scope.data['services'].join(', ') : null;
                    }
                });

                $scope.toggleEmploymentLimit = $event => {
                    $event.preventDefault();
                    $scope.employmentLimit = ($scope.employmentLimit > $scope.startingShowLimit)
                        ? $scope.startingShowLimit
                        : 999;
                };

                $scope.hideReviewsPanel = () => {
                    $scope.showReviewsModal = false;
                };

                $scope.showReviewsPanel = $event => {
                    // we don't show the panel if they don't have reviews
                    if ($scope.data.numReviews === 0) {
                        return;
                    }
                    $event.preventDefault();

                    if ($scope.reviews === undefined) {
                        $scope.showReviewsModal = true;
                        $scope.loadingReviews = true;
                        Profile.getReviews($scope.data.userid).then(reviews => {
                            $scope.$apply(() => {
                                $scope.reviews = reviews;
                                $scope.loadingReviews = false;
                            })
                        });
                    } else {
                        $scope.showReviewsModal = true;
                    }
                };

                $scope.toggleReviewsPanel = $event => {
                    $event.preventDefault();

                    if ($scope.showReviewsModal) {
                        $scope.hideReviewsPanel($event);
                    } else {
                        $scope.showReviewsPanel($event);
                    }
                };
            },
        ],
    };
}

