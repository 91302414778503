import {NgModel} from 'model/ngModel';
import * as moment from "moment-timezone";

export const Resource = NgModel.extend({
    _name: 'Resource',
    defaults: {
        id: null,
        title: "",
        description: "",
        source: "",
        imageUrl: null,
        uploadedImageData: null,
        tags: [],
        audience: [],
        participants: [],
    },
    urlRoot: '/control/partner/resources/[id]',
    parse: function(data) {
        let dataFormatted = this._super('parse', data);

        dataFormatted.dateCreated = this.getDate(data.dateCreated);
        dataFormatted.dateCreatedFormatted = moment.tz(data.dateCreated, moment.tz.guess()).format('MMM D, YYYY');

        dataFormatted.dateUpdated = this.getDate(data.dateUpdated);
        dataFormatted.dateUpdatedFormatted = moment.tz(data.dateUpdated, moment.tz.guess()).format('MMM D, YYYY');

        this.__bindManyForAngular(dataFormatted);
        return dataFormatted
    },
    getDownloadHeaders: function() {
        return [
            'Date Added',
            'Title',
            'Video',
            'Views',
            'Likes',
        ];
    },
    getDownloadData: function() {
        return [
            this.get('dateCreatedFormatted'),
            this.get('title'),
            this.get('source'),
            this.get('views'),
            this.get('likes'),
        ];
    }
});
