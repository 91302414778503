import {GroupBaseController} from './groupBase';

export class GroupHomeController extends GroupBaseController {
    static $inject = ['$scope', '$rootScope', '$routeParams', '$q', '$window', '$http', 'VisitorService', 'FileUploader', '$analytics'];

    constructor($scope, $rootScope, $routeParams, $q, $window, $http, VisitorService, FileUploader, $analytics) {
        super($scope, $rootScope, $routeParams, $q, $window, $http, VisitorService, $analytics, 5, 2, 1, 3, 1)
        $scope.position = 1;

        $scope.$on('controllerLoaded', () => {
            $window.document.title = $scope.selectedGroup.isBooth ? 'Booth Home' : 'Group Home';
            $scope.hiringLabel = null;
            $scope.isHiring = $scope.selectedGroup.hiringInternship ||
                $scope.selectedGroup.hiringFulltime ||
                $scope.selectedGroup.hiringInternational;

            if ($scope.isHiring) {
                $scope.hiringLabel = [
                    $scope.selectedGroup.hiringInternship ? 'Internship' : null,
                    $scope.selectedGroup.hiringFulltime ? 'Full-time' : null,
                    $scope.selectedGroup.hiringInternational ? 'International candidates' : null
                ].filter((el) => el !== null).join(", ")
            }
        })

        $scope.fileUploadStatus = [];
        $scope.selectedResumeFileName = null;
        $scope.uploadingInitiated = false;
        $scope.uploadingFinished = false;
        $scope.uploadErrors = null;
        $scope.fileUploader = new FileUploader({url: `/group/${$routeParams.groupid}/files`});

        $scope.$watch('fileUploader.queue.length', function() {
            if ($scope.fileUploader.queue.length === 0) {
                return;
            }
            $scope.selectedResumeFileName = $scope.fileUploader.queue[0]._file.name;
        });

        $scope.clearSelectedResumeFile = function(e) {
            e.preventDefault();
            $scope.fileUploader.clearQueue();
            $scope.selectedResumeFileName = null;
        };

        $scope.uploadResume = function(e) {
            e.preventDefault();
            $scope.uploadingInitiated = true;
            $scope.fileUploader.uploadAll();
        };

        $scope.fileUploader.onSuccessItem = function(item, response, status, headers) {
            $scope.uploadErrors = null;
            $scope.uploadingFinished = true;
        };
        $scope.fileUploader.onErrorItem = function(item, response, status, headers) {
            $scope.uploadingFinished = false;
            $scope.uploadErrors = 'Error uploading resume.'; // WIP show concrete error reason
        };
    };
}
