import {whitelabelling as Whitelabelling} from 'whitelabelling';
import {JobOpening} from '../../model/jobOpening';
import {guid} from '../../configurations/utilities';
import {PlatformAgnosticPosition} from '../../model/jobOpeningBase';

export function userInputSingleJobOpening() {
    return {
        restrict: 'E',
        scope: {
            data: '=', // a single jobOpening model
            delta: '=?', // the positon of this input in a list
            canReList: '=?', // true IFF we should show the re-list options
            abridged: '<?',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/registration/directives/userInputs/userInputSingleJobOpening.html',
        controller: [
            '$scope', 'RegistrationService', function($scope, RegistrationService) {
                if ($scope.data.isPlatformAgnostic.hasOwnProperty('id')) {
                    $scope.data.isPlatformAgnostic = $scope.data.isPlatformAgnostic.id === PlatformAgnosticPosition;
                }
                $scope.abridged = $scope.abridged || false;
                $scope.whitelabelling = Whitelabelling;

                if (!Whitelabelling.isAMP) {
                    const once = $scope.$watch('data', function(newVal) {
                        // if it's not an AMP, the posting *must* be platform-agnostic
                        $scope.setIsPlatformAgnostic(true);
                        once();
                    });
                }

                $scope.errors = {};
                $scope.datesRequired = 'month';
                $scope.employersHook = RegistrationService.employersPrefixComplete;
                $scope.UNIQUE_STRING = guid();
                $scope.now = Date.now();

                $scope.obj = {
                    dateExpiredInput: $scope.data.dateExpired,
                };

                RegistrationService.getRegistrationSettings().then(function(d) {
                    $scope.roles = d.Roles;
                    $scope.industries = d.Industries;
                });

                $scope.levels = JobOpening.LEVELS;
                $scope.employmentTypes = JobOpening.EMPLOYMENTTYPES;

                const now = new Date();
                $scope.$watch('obj.dateExpiredInput', function(newVal, oldVal) {
                    if (!newVal && !oldVal || newVal === oldVal) {
                        return;
                    }

                    // if they're editing a date, it must be in the future
                    if (newVal > now) {
                        $scope.data.set('dateExpired', newVal);
                        $scope.errors['dateExpired'] = false;
                    } else {
                        $scope.data.set('dateExpired', undefined);
                        $scope.errors['dateExpired'] = true;
                    }
                });

                $scope.checkForErrors = function(specificProperty) {
                    const errors = $scope.data.validateData();
                    if (specificProperty) {
                        $scope.errors[specificProperty] = errors[specificProperty];
                    } else {
                        $scope.errors = errors;
                    }
                };

                $scope.onBlurEmployer = function() {
                    $scope.checkForErrors('Employer');
                };

                $scope.$on('showErrors', function() {
                    $scope.checkForErrors();
                });

                $scope.setIsPlatformAgnostic = function(value) {
                    $scope.data.set('isPlatformAgnostic', value);
                    $scope.$broadcast('hideModal');
                };

                $scope.relist = function(days) {
                    // 86400000 == number of milliseconds in a day
                    const milliseconds = days * 86400000;
                    $scope.data.set('dateExpired', new Date($scope.now + milliseconds));
                    $scope.data.set('isDeleted', false);
                };

                $scope.deleteJobOpening = function() {
                    $scope.data.set('isDeleted', true);
                    if (!$scope.data.get('id')) {
                        // just remove it from the collection entirely
                        $scope.data.destroy();
                    }
                };

                $scope.removeRole = (i) => {
                    $scope.data.Roles.splice(i, 1);
                };

                $scope.removeIndustry = (i) => {
                    $scope.data.Industries.splice(i, 1);
                };
            },
        ],
    };
}
