/**
 * This directive renders a single navigation item (it uses a navigation model)
 *
 * <li navigation="NAVIGATION MODEL"></li>
 */
const NAV_EXPANDED_CLASS = 'nav-expanded';

export function navigation() {
    return {
        restrict: 'A',
        scope: {
            navigation: '=',
            navBadge: '=?',
        },
        replace: true,
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/navigation.html',
        controller: [
            '$scope', function($scope) {
                // this function runs when we click a nav item that has a sublist
                $scope.clickNavList = function() {
                    if ($scope.$container.hasClass(NAV_EXPANDED_CLASS)) {
                        $scope.navigation.set('isOpen', !$scope.navigation.get('isOpen'));
                    } else {
                        // we open the nav - should this be an angular event?
                        $scope.navigation.set('isOpen', true);
                        $scope.$container.addClass(NAV_EXPANDED_CLASS);
                    }

                    $scope.update();
                };

                $scope.$on('navToggleEvent', function(event, navIsOpen) {
                    if (navIsOpen === false) {
                        $scope.navigation.set('isOpen', false);
                    }
                    $scope.update();
                });

                $scope.update = function() {
                    if ($scope.navigation.Navigations) {
                        // Calculate height, for smooth opening. Can this be done better??
                        var baseHeight = $scope.$elem.height();

                        // NOTE: We filter where URL has a value because we pass undefined in navigationService.js when
                        // a whitelabel doesn't have a page and backbone converts that undefined into an empty model
                        // (because it wants only models in collections) so if we ever stop adding undefined, this line
                        // could be changed to... var childrenNum = $scope.navigation.Navigations.models.length
                        var childrenNum = $scope.navigation.Navigations.where('url').length;

                        // Multiply the base height times how many children there are
                        var totalHeight = baseHeight * childrenNum;

                        var $navigationElem = $scope.$elem.children('.nav-sublist');

                        if ($scope.navigation.get('isOpen')) {
                            $navigationElem.height(totalHeight + 'px');
                        } else {
                            $navigationElem.height('0px');
                        }
                    }
                };

                $scope.$watch('navBadge', function(newVal) {
                    refreshMyBadge();
                    if (newVal) {
                        $scope.navBadge.on('change sync', refreshMyBadge);
                    }
                });

                function refreshMyBadge() {
                    if (!$scope.navigation || !$scope.navBadge) {
                        $scope.myBadge = undefined;
                    } else {
                        $scope.myBadge = $scope.navBadge.getBadgeForNavigation($scope.navigation);
                    }
                }
            },
        ],
        link: function(scope, element) {
            scope.$elem = $(element);
            scope.$container = scope.$elem.parents('#page-container');
        },
    };
}

