export function connectionMessage() {
    return {
        restrict: 'E',
        scope: {
            'url': '@?',
            'function': '=?',
            'isConnected': '=?',
        },
        require: 'ngModel',
        controller: [
            '$scope', '$timeout', '$http', function($scope, $timeout, $http) {
                $scope.pollingFailed = false;
                $scope.pollingReconnected = false;
                $scope.isConnected = true;

                var passivePollTimeout = null;

                function passivePoll() {
                    // it's possible when this first runs, angular hasn't bound the scope properties yet
                    if ($scope.function || $scope.url) {
                        // determine if we should poll via a promise returning function or a passed url
                        var promise = $scope.function
                            ? $scope.function()
                            : $http({
                                method: 'GET',
                                url: $scope.url,
                            });

                        // if we ever have an issue, we need to display a big-ol-error message
                        promise.then(function() {
                            // if this is a good connection after a previously bad one,
                            if ($scope.pollingFailed) {
                                connectionReEstablished();
                            }
                        }, function() {
                            $scope.pollingFailed = true;
                            $scope.isConnected = false;
                        });
                    }
                    passivePollTimeout = $timeout(passivePoll, 60 * 1000);
                }

                // start passive polling
                passivePoll();

                // this function handles showing the reconnected message and hiding the polling failed message
                function connectionReEstablished() {
                    // mark us connected
                    $scope.isConnected = true;

                    // we show the reconnected message
                    $scope.pollingReconnected = true;

                    // wait half a second
                    $timeout(function() {
                        // mark poling has no longer failed
                        $scope.pollingFailed = false;
                    }, 500);

                    // wait 5 seconds,
                    $timeout(function() {
                        // and hide the reconnected message
                        $scope.pollingReconnected = false;
                    }, 5000);
                }

                $scope.$on('$destroy', function() {
                    $timeout.cancel(passivePollTimeout);
                });
            },
        ],
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/connectionMessage.html',
    };
}
