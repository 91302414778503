const MIN_WIDTH = 72;
const MIN_HEIGHT = 72;

export function imageUpload() {
    return {
        restrict: 'E',
        scope: {
            /**
             * Backbone Model or an object which will be assigned with image props
             */
            'entity': '=',
            /**
             * Image width in pixels
             */
            'width': '=',
            /**
             * Image height in pixels
             */
            'height': '=',
            /**
             * URL to retrieve gallery images from (@see AffinityGroupsController::getGroupImagesAction for reference)
             * Set to null to not retrieve gallery
             */
            'galleryImagesUrl': '=',
            /**
             * Prop name which contains gallery images
             */
            'galleryImagesPropName': '=',
            /**
             * Title which will be displayed in the view
             */
            'title': '=',
            /**
             * Description which will be displayed in the view
             */
            'description': '=',
        },
        templateUrl: '/asset/partials/partner/directives/imageUpload.html',
        replace: true,
        controller: [
            '$scope',
            'FileUploader',
            '$http',
            'ExifService',
            function ($scope, FileUploader, $http, ExifService) {
                $scope.imageDimensions = {
                    width: $scope.width + "px",
                    height: $scope.height + "px",
                }
                $scope.fileUploader = new FileUploader();
                $scope.showGallery = false;
                $scope.cropUploadedImage = false;
                $scope.uploadedImageData = null;

                $scope.entityImages = [];
                if ($scope.galleryImagesUrl) {
                    $http.get($scope.galleryImagesUrl).then((res) => {
                        $scope.entityImages = res.data.data[$scope.galleryImagesPropName];
                    });
                }

                $scope.loadImage = function() {
                    if ($scope.fileUploader.queue.length === 0) {
                        return;
                    }

                    // Check file type.
                    if (!['image/jpeg', 'image/png'].includes($scope.fileUploader.queue[0].file.type)) {
                        $scope.imageError = 'Sorry, you may upload only JPG and PNG images.';
                        $scope.fileUploader.clearQueue();
                    }

                    var reader = new FileReader();
                    reader.readAsDataURL($scope.fileUploader.queue[0]._file);
                    reader.onload = function(res) {
                        var image = new Image();

                        image.src = reader.result;
                        image.onload = function() {
                            if (image.width < MIN_WIDTH || image.height < MIN_HEIGHT) {
                                $scope.$evalAsync(function() {
                                    $scope.imageError = 'Sorry, your image must be at least ' + $scope.width + ' by ' + $scope.height + ' pixels.';
                                    $scope.fileUploader.clearQueue();
                                });
                            } else {
                                $scope.imageError = null;
                                ExifService.getData(image, function() {
                                    $scope.$evalAsync(function($scope) {
                                        $scope.orientation = ExifService.getTag(image, 'Orientation');
                                    });
                                });

                                $scope.$evalAsync(function() {
                                    $scope.fileUploader.clearQueue();
                                    $scope.uploadedImageData = reader.result;
                                    $scope.cropUploadedImage = true;
                                });
                            }
                        };
                    };
                };
                $scope.$watch('fileUploader.queue.length', $scope.loadImage);

                $scope.saveCroppedImage = () => {
                    $scope.$broadcast('croppie-get-result');
                };
                $scope.croppieResultFn = (result) => {
                    $scope.$evalAsync(() => {
                        $scope.entity.imageUrl = result;
                        $scope.entity.uploadedImageData = result;
                        $scope.cropUploadedImage = false;
                    });
                };

                $scope.setGroupImage = function (image) {
                    $scope.entity.set('imageid', image.id);
                    $scope.entity.set('imageUrl', image.url);
                    $scope.showGallery = false;
                }

                $scope.showGalleryModal = () => {
                    $scope.showGallery = true;
                }

                $scope.hideGalleryModal = () => {
                    $scope.showGallery = false;
                }
            }
        ]
    }
}
