import {whitelabelling as Whitelabelling} from 'whitelabelling';
import {getTimeUnits} from 'configurations/utilities';

/**
 * This directive packages the assessment panel items
 * Use like:
 * <dashboard-consultation-card></dashboard-consultation-card>
 */
export function dashboardConsultationCard() {
    return {
        restrict: 'E',
        scope: {
            data: '=',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/directives/dashboard/dashboardConsultationCard.html',
        replace: true,
        controller: [
            '$scope',
            'Meeting',
            'TimezonesService',
            '$location',
            function($scope, Meeting, TimezonesService, $location) {
                $scope.whitelabelling = Whitelabelling;

                // email verification, payment, expert accept, consumer reschedule, expert reschedule
                $scope.useRedConsultationStatus = !!~[0, 3, 1, 7, 9].indexOf($scope.data.get('meetingStateid'));

                // if it's a state where we're waiting on a response
                if (!!~[1, 7, 9].indexOf($scope.data.get('meetingStateid'))) {
                    // show the confirm button if we're the correct role and in an actionable state
                    $scope.showConfirmButton = ($scope.data.get('meetingStateid') === 9 &&
                            $scope.data.get('visitorIsConsumer'))
                        || (!!~[1, 7].indexOf($scope.data.get('meetingStateid')) && $scope.data.get('visitorIsExpert'));

                    if ($scope.showConfirmButton) {
                        const oneDayFromNow = Date.now() + (24 * 60 * 60 * 1000);
                        const expiry = $scope.data.get('expiry');
                        if (expiry && expiry.getTime() < oneDayFromNow) {
                            $scope.consultationStatusAction = 'expiring';
                            const obj = getTimeUnits((expiry.getTime() - Date.now()) / 1000);
                            $scope.consultationStatusLabel = 'Expires in ' + obj.time + obj.timeUnitsShort;
                        } else {
                            $scope.consultationStatusLabel = 'Request';
                            $scope.consultationStatusAction = 'request';
                        }
                    } else {
                        $scope.consultationStatusLabel = 'Request Sent';
                        $scope.consultationStatusAction = 'waiting-for-response';
                    }
                } else if ($scope.data.get('meetingStateid') === 0) {
                    $scope.consultationStatusLabel = 'Verify Email';
                    $scope.consultationStatusAction = 'verify-email';
                } else if ($scope.data.get('meetingStateid') === 3) {
                    $scope.consultationStatusLabel = 'Payment Needed';
                    $scope.consultationStatusAction = 'payment-needed';
                } else if ($scope.data.get('meetingStateid') === 5) {
                    $scope.consultationStatusLabel = 'Happening now';
                    $scope.consultationStatusAction = 'meet';
                } else {
                    $scope.consultationStatusLabel = 'Confirmed';
                    $scope.consultationStatusAction = 'no-action';
                }
                $scope.groupDate = $scope.data.getGroupDate();
                const duration = $scope.data.get('durationInMinutes');
                $scope.durationLabel = duration === 60 ? '1h' : (duration + 'm');

                $scope.locationCode = TimezonesService.getDeviceTimezone().label;

                $scope.confirmMeeting = function(value) {
                    $scope.confirmLoading = true;
                    Meeting.selectTime($scope.data, value.getTime() / 1000).then(function() {
                        $scope.confirmLoading = false;

                        $location.path('/consultations/' + $scope.data.get('id') + '/meet');
                    }, function(res) {
                        let message = 'There was an error processing your request. Please refresh the page and try again';
                        if (res.status === 422 && res.data.data.message) {
                            message = res.data.data.message;
                        }

                        alert(message);
                    });
                };
            },
        ],
    };
}

