import {whitelabelling as Whitelabelling} from 'whitelabelling';
import {timesAreAllOnTheSameDay, validateEmail} from 'configurations/utilities';
import {MeetingTime} from 'model/meetingTime';
import {Meeting} from 'model/meeting';
import {Service} from '../model/service';
import {ProfileUser as User} from 'model/profileUser';
import {countryCodes as CountryCodes} from 'country-codes';

export const BookController = [
    '$scope',
    '$rootScope',
    '$timeout',
    '$window',
    '$location',
    '$routeParams',
    '$q',
    'Profile',
    'VisitorService',
    'FileUploader',
    'TimezonesService',
    function(
        $scope, $rootScope, $timeout, $window, $location, $routeParams, $q, Profile, VisitorService, FileUploader, TimezonesService) {

        $rootScope.pageClass = 'book-container';
        $scope.countryCodes = CountryCodes;
        $scope.whitelabelling = Whitelabelling;
        $scope.calendarOptions = {
            weekDifference: 0,
        };
        $scope.timeStep = '';
        $scope.useCalendar = true;

        $scope.showSimilarAdvisorsTip = false;
        $scope.showPageError = false;
        $scope.pageError = null;
        $scope.isLoaded = false;
        $scope.useTemplate = true;
        $scope.hasSeenMessage = false;
        $scope.hasActiveFairs = false;
        $scope.selectedTimezone = TimezonesService.getDeviceTimezone();

        $scope.visitorServiceFunction = function() {
            return Profile.refreshUser($routeParams.userid);
        };

        $scope.minimumMessageLength = 25;
        $scope.selectedTimes = [];

        // this object stores validation results, object properties will be true if issues are found
        $scope.dataErrors = {};

        // for RFP
        $scope.leaveRfpAsIs = false;
        $scope.originalRfpMessage = false;
        $scope.rfpMessage = false;
        $scope.showedRfpNameWarning = false;

        $scope.fileUploader = new FileUploader();

        $scope.isFairType = false;
        $scope.serviceid = null;

        $scope.message = {
            body: '',
            template1: 'Thanks for offering to help! I need help with a ... because ...',
            template2: 'During our meeting, I was hoping we could focus on ...',
        };

        const defaultTemplates = {
            template1: $scope.message.template1,
            template2: $scope.message.template2,
        };

        $q.all([
            Profile.getUser($routeParams.userid),
            VisitorService.getVisitor(),
            Profile.getServices($routeParams.userid),
        ]).then(function(data) {
            $scope.$emit('controllerLoaded', true);
            $scope.expert = data[0];
            $scope.visitor = data[1];

            if ($scope.visitor.isUser === false) {
                $scope.visitor.user = new User({}, {parse: true});
            }

            if ($scope.visitor.user.id === $scope.expert.id) {
                // TODO: This should be more elegant, but whatever. They can only get here by being sneaky so it's fine
                // for now
                $scope.pageError = 'You cannot book yourself.';
                $scope.showPageError = true;
                return;
            }

            $scope.services = data[2];

            // build the meeting object
            $scope.meeting = new Meeting({
                Expert: $scope.expert,
                Consumer: $scope.visitor.user,
            }, {parse: true});

            if ($routeParams.serviceid) {
                if ($routeParams.serviceid == 55) {
                    // job openings aren't included in the services list, so we need to make a placeholder service for
                    // them
                    $scope.selectService(new Service({id: 55, label: 'Job Opening'}));
                } else {
                    // we check if they passed a service id to this controller
                    $scope.selectService($scope.services.get($routeParams.serviceid));
                }

                // if they already picked one, don't show it
                $scope.showServiceInput = false;
            } else {
                // we just set it to the first one
                $scope.selectService($scope.services.at(0));

                // and indicate we should show the service input option
                $scope.showServiceInput = $scope.services.length > 1;
            }

            if ($routeParams.time) {
                const passedDate = new Date(parseInt($routeParams.time));
                if (passedDate > new Date()) {
                    $scope.selectedTimes.push(
                        new MeetingTime({
                            date: passedDate,
                        }, {parse: true}),
                    );
                }
            }
            $scope.isLoaded = true;
        });

        $($window).on('resize', function() {
            $timeout(checkMobile, 1);
        });
        checkMobile();

        $scope.$watchCollection(function() {
            return $scope.selectedTimes.length;
        }, function(newVal, oldVal) {
            if ($scope.isFairType && newVal >= 1) {
                // Fairs auto-book an expert, assuming [s]he is available, with only one suggested datetime.
                $scope.gotoTab(2);
            }
            if (newVal >= 3 && oldVal < 3 && !timesAreAllOnTheSameDay($scope.selectedTimes)) {
                $scope.gotoTab(2);
            }
        });

        function changeToMessage() {
            $scope.dataErrors.templateMessage = false;
            $scope.dataErrors.message = false;
        }

        $scope.$watch('message.template1', changeToMessage);
        $scope.$watch('message.template2', changeToMessage);
        $scope.$watch('message.body', changeToMessage);

        // -------------------------------------------------------------------------
        // DIRECTIVE HOOKS
        // -------------------------------------------------------------------------

        $scope.selectService = function(s) {
            $scope.meeting.set('Service', s);

            $scope.isFairType = s.get('isFairType');
            $scope.serviceid = s.get('id');

            if (s.get('id') == 55) {
                $scope.message.template1 = 'Thanks for offering to help! I would like to discuss the ' +
                    window.HACKY_JOB_LABEL + ' position you posted because ...';
            } else {
                $scope.message.template1 = 'Thanks for offering to help! I ' + s.get('templateLabelPhrase') +
                    ' because ...';
            }

            // make sure we properly update the default template
            defaultTemplates.template1 = $scope.message.template1;
            $scope.meetingDuration = s.get('durationInSeconds');
        };

        $scope.toggleUseTemplate = function() {
            $scope.useTemplate = !$scope.useTemplate;
        };

        $scope.sendRequest = function() {
            const message = $scope.useTemplate
                ? ($scope.message.template1 + '\n\n' + $scope.message.template2)
                : $scope.message.body;

            let welcome = 'Dear ' + $scope.expert.get('firstName') + ',';
            let signature = 'Sincerely, \n' + $scope.visitor.user.fullName;

            if (!$scope.isFairType) {
                // Check message text only if not a fair type.
                if ($scope.useTemplate && (
                    $scope.message.template1.trim() === defaultTemplates.template1.trim()
                    || $scope.message.template2.trim() === defaultTemplates.template2.trim()
                )) {
                    $scope.dataErrors.templateMessage = true;
                    return;
                }

                if (message.length < $scope.minimumMessageLength) {
                    $scope.dataErrors.message = message.length < $scope.minimumMessageLength
                    return;
                }
                $scope.meeting.set('comments', welcome + '\n\n' + message + '\n\n' + signature);
            } else {
                // For Fair Type, message is blank.
                $scope.meeting.set('comments', '');
            }

            $scope.sendingRequest = true;
            $scope.meeting.setSuggestedTimes($scope.selectedTimes);

            let files = undefined;
            if ($scope.fileUploader.queue.length) {
                files = [];
                $scope.fileUploader.queue.forEach(function(f) {
                    files.push(f._file);
                });
            }
            Profile.bookMeeting($scope.meeting, files).then(()=> {
               $scope.gotoMeetingDashboard();
            }, function(errorMessage) {
                $scope.pageError = errorMessage;
                $scope.showPageError = true;
                $scope.sendingRequest = false;
            });
        };

        $scope.gotoTab = function(num) {
            $scope.errorMessage = false;
            const $elem = angular.element('#booking-form-container');
            switch (num) {
                case 1:
                    // Going back to the first tab in a "Fair Consultation" type means you have to pick a new slot.
                    if ($scope.isFairType) {
                        $scope.selectedTimes = [];
                    }
                    break;
                case 2:
                    // we skip this tab if they're an authed user
                    if ($scope.visitor.isUser) {
                        const currentTab = $elem.attr('selected-tab');
                        return $scope.gotoTab(num + (num - currentTab));
                    }
                    break;
                case 3:
                    if (!$scope.visitor.isUser) { // if they're an authed user, we check nothing
                        $scope.dataErrors.visitor = {
                            user: {
                                firstName: !$scope.visitor.user.firstName,
                                lastName: !$scope.visitor.user.lastName,
                                phone: !$scope.visitor.user.phone.number,
                                email: !validateEmail($scope.visitor.user.email),
                                confirmEmail: $scope.visitor.user.email !== $scope.visitor.user.confirmEmail,
                            },
                        };
                        if ($scope.dataErrors.visitor.user.firstName
                            || $scope.dataErrors.visitor.user.lastName
                            || $scope.dataErrors.visitor.user.phone
                            || $scope.dataErrors.visitor.user.email
                            || $scope.dataErrors.visitor.user.confirmEmail
                        ) {
                            return;
                        } else {
                            $scope.message.signature = 'Sincerely, \n' + $scope.visitor.user.firstName + ' ' +
                                $scope.visitor.user.lastName;
                        }
                    }
                    break;
                case 4:
                default:
                    // do nothing
                    break;
            }

            $elem.attr('selected-tab', num);
            $('body').animate({scrollTop: 0}, 'fast');
        };

        $scope.browserBack = function() {
            $window.history.back();
        };

        $scope.unattachFile = function(file) {
            for (let i = 0; i < $scope.fileUploader.queue.length; i++) {
                if ($scope.fileUploader.queue[i].file === file) {
                    $scope.fileUploader.removeFromQueue($scope.fileUploader.queue[i]);
                    return;
                }
            }
        };

        $scope.gotoMeetingDashboard = () => {
            if (!$scope.meeting.get('id')) {
                throw 'No meeting id';
            }
            // go to the dashboard
            if (Whitelabelling.settings.featureNewMeetingsEnabled) {
                window.location.href = `/meetings/${$scope.meeting.get('id')}`;
            } else {
                window.location.href = `/consultations/${$scope.meeting.get('id')}/meet`;
            }
        };

        $scope.hideTip = function() {
            $scope.showSimilarAdvisorsTip = false;
        };

        $scope.hideError = function() {
            $scope.pageError = '';
            $scope.showPageError = false;
        };

        // -------------------------------------------------------------------------
        // HELPER FUNCTIONS
        // -------------------------------------------------------------------------
        function checkMobile() {
            $scope.isMobile = $window.innerWidth < 640;
        }

    },
];
