import {Backbone} from 'Vertebrae';
import {AlgorithmUser} from 'model/algorithmUser';

export const AlgorithmUsers = Backbone.Collection.extend({
    _name: 'AlgorithmUsers',
    model: AlgorithmUser,
    url: function() {
        return '/control/partner/algorithm/users/' + this.userid + '/results.json';
    },
    setUser: function(u) {
        this.userid = u.get('id');
    },
    setSelectedExpert: function(u) {
        var others = this.where({'selected': true});
        _.each(others, function(o) {
            o.set('selected', false);
        });
        u.set('selected', true);
    },
});
