import {NgModel} from './ngModel';
import {AffinityGroups} from 'collection/affinityGroups';

export const AffinityGroupCategory = NgModel.extend({
    _name: 'AffinityGroupCategory',
    associations: {
        AffinityGroups,
    },
    parse: function(data) {
        var dataFormatted = this._super('parse', data);

        dataFormatted.id = parseInt(dataFormatted.id);

        this.__bindManyForAngular(dataFormatted);
        return dataFormatted;
    },
});
