import {UserEngagement as UserEngagementModel} from '../model/userEngagement';

// The plus sign (+) is our generic affirmative value
const ENGAGEMENT_AFFIRMATIVE = '+';

export class UserEngagementService {
    static $inject = ['$http', '$q'];
    #engagements = {};
    #engagementsPromise = {};
    #hasRespondedToFirstEngagement = false;
    http;
    q;

    constructor($http, $q) {
        this.http = $http;
        this.q = $q;
    }

    getFirstUnrespondedEngagement($scopeToBeUpdated) {
        const deferred = this.q.defer();
        if (this.#hasRespondedToFirstEngagement) {
            deferred.resolve({});
            return deferred.promise;
        }

        if (this.#engagementsPromise.FIRST_UNRESPONDED) {
            return this.#engagementsPromise.FIRST_UNRESPONDED;
        }

        this.#engagementsPromise.FIRST_UNRESPONDED = deferred.promise;

        this.http.get(Evisors.config.cdnRootWhitelabel + 'user-engagements/first.json').then(({data:{data:{UserEngagement}}}) => {
            this.#engagementsPromise.FIRST_UNRESPONDED = undefined;
            let engagement = false;
            if (UserEngagement) {
                engagement = new UserEngagementModel(UserEngagement);
                this.#engagements[engagement.get('label')] = engagement;
            }

            deferred.resolve({'scope': $scopeToBeUpdated, 'engagement': engagement});
        }).catch(e => {
            this.#engagementsPromise.FIRST_UNRESPONDED = undefined;
            deferred.reject.apply(this, arguments);
        });

        return deferred.promise;
    }

    markUserEngagementIgnored(engagement) {
        return this.http.post(Evisors.config.cdnRootWhitelabel + 'user-engagements/ignore.json', {label: engagement.get('label')});
    }

    markUserEngagementResponded(engagement, value = ENGAGEMENT_AFFIRMATIVE) {
        return this.http.post(Evisors.config.cdnRootWhitelabel + 'user-engagements/record.json', {label: engagement.get('label'), value: value});
    }

    engagementResponseHandler({scope, engagement}) {
        this.#hasRespondedToFirstEngagement = true;
        const watchedScope = scope;

        if (engagement) {
            watchedScope.schowUserEnagement = true;
            watchedScope.userEngagement = engagement;
            watchedScope.watchForEngagementIgnore = watchedScope.$watch('showUserEngagement', (newVal, oldVal) => {
                if (!newVal && oldVal) {
                    this.markUserEngagementIgnored(engagement);
                    watchedScope.watchForEngagementIgnore();
                }
            });
        }
    }
}
