import {Backbone} from 'Vertebrae';
import {Conversation} from '../model/conversation';

export const Conversations = Backbone.Collection.extend({
    _name: 'Conversations',
    model: Conversation,
    comparator: function(item) {
        return -1 * item.get('dateLastMessageSent').getTime();
    },
    url: '/conversations.json',
});
