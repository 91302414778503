import * as _ from 'underscore';
import {whitelabelling as Whitelabelling} from 'whitelabelling';

export function meetingMultimedia() {
    return {
        restrict: 'E',
        scope: {
            meeting: '&',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/directives/meetingMultimedia.html',
        replace: true,
        controller: [
            '$scope',
            'VisitorService',
            'VoipConsultationService',
            'VideoConsultationService',
            'Meeting',
            'MeetingConnectionService',
            function($scope, VisitorService, VoipConsultationService, VideoConsultationService, Meeting, MeetingConnectionService) {
                $scope.audioType = 'device';
                $scope.showSettingsAsModal = false;
                $scope.otherUser = null;
                $scope.thisUser = null;
                $scope.selectedVideoDevice = null;

                $scope.allowChangeAudioInput = () => VoipConsultationService.canChangeInput;
                $scope.allowChangeAudioOutput = () => VoipConsultationService.canChangeOutput;
                $scope.allowChangeVideo = true;

                $scope.videoTypeEnum = VideoConsultationService.typeEnum;
                $scope.videoStatusEnum = VideoConsultationService.statusEnum;

                $scope.audioPermissionGrantText = 'Allow access to&nbsp;Microphone?';
                $scope.videoPermissionGrantText = 'Allow access to&nbsp;Camera?';

                $scope.showDiagnostics = false;
                VisitorService.getVisitor().then(function(visitor) {
                    $scope.showDiagnostics = visitor.isAdmin;
                    $scope.localVideo = VideoConsultationService;
                });

                Meeting.getByRouteParams().then(function(meeting) {
                    $scope.otherUser = meeting.get('visitorIsConsumer') ? meeting.get('Expert') : meeting.get('Consumer');
                    $scope.thisUser = meeting.get('visitorIsConsumer') ? meeting.get('Consumer') : meeting.get('Expert');

                    $scope.defaultCountry = 'United States';
                    var thisUserCurrentLocation = $scope.thisUser.get('currentLocation');
                    if (thisUserCurrentLocation && thisUserCurrentLocation.country) {
                        $scope.defaultCountry = thisUserCurrentLocation.country;
                    }

                    MeetingConnectionService.addScope($scope);
                    if (MeetingConnectionService.isCurrentUserConnectedAudioPhone()) {
                        $scope.audioType = 'phone';
                    }
                    $scope.$on('meeting-poll', function() {
                        if (MeetingConnectionService.isCurrentUserConnectedAudioPhone()) {
                            $scope.audioType = 'phone';
                        }

                        if (MeetingConnectionService.isLocalVoipConnected() || MeetingConnectionService.isCurrentUserConnectedAudioPhone()) {
                            if ($scope.videoStatus > VideoConsultationService.statusEnum.STATUS_DISABLED
                                && VideoConsultationService.status < VideoConsultationService.statusEnum.STATUS_CONNECTING
                            ) {
                                // I'm connected (via either VoIP or Phone), and videoStatus is not disabled.
                                VideoConsultationService.connect();
                            }
                        } else if ($scope.videoStatus >= VideoConsultationService.statusEnum.STATUS_CONNECTING) {
                            // User was disconnected from audio and Video was connected; ensure I disconnect from the
                            // Video Room.
                            VideoConsultationService.disconnect();
                        }
                    });
                });

                $scope.containerClass = function() {
                    return {
                        'meeting-multimedia-phone': $scope.audioType === 'phone',
                        'meeting-multimedia-device': $scope.audioType === 'device',
                        'settings-modal': $scope.showSettingsAsModal,
                    };
                };

                $scope.$watch('selectedAudioOutputDevice', function(newVal, oldVal) {
                    if (newVal && newVal !== oldVal) {
                        VoipConsultationService.setOutputDevice(newVal);
                    }
                });
                $scope.$watch('selectedAudioInputDevice', function(newVal, oldVal) {
                    if (newVal && newVal !== oldVal) {
                        VoipConsultationService.setInputDevice(newVal);
                    }
                });
                $scope.$watch('selectedVideoDevice', function(newVal, oldVal) {
                    if (newVal && newVal !== oldVal) {
                        VideoConsultationService.setDevice(newVal);
                    }
                });

                $scope.videoDisabledTechnically = function() {
                    if (/MSIE 10/i.test(navigator.userAgent)) {
                        return true;
                    }
                    if (/MSIE 9/i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent)) {
                        return true;
                    }
                    if (/Edge\/\d./i.test(navigator.userAgent)) {
                        return true;
                    }
                    return false;
                };

                $scope.videoIsAllowed = function() {
                    return Whitelabelling.settings.allowVideoConsultations;
                };

                // Video initialization function
                var initializeVideo = function() {
                    if (!$scope.videoIsAllowed() || $scope.videoDisabledTechnically()) {
                        $scope.videoStatus = VideoConsultationService.statusEnum.STATUS_DISABLED;
                    } else {
                        $scope.videoMonitors = {};
                        $scope.videoMonitors[VideoConsultationService.typeEnum.TYPE_LOCAL] = false;
                        $scope.videoMonitors[VideoConsultationService.typeEnum.TYPE_REMOTE] = false;

                        if (!$scope.videoLockedOut) {
                            VideoConsultationService.initialize();
                        }
                    }
                };

                // VoIP initialization function
                var initializeVoip = function() {
                    if ($scope.voipStatus <= VoipConsultationService.statusEnum.STATUS_LOADING) {
                        $scope.voipStatus = VoipConsultationService.statusEnum.STATUS_LOADING;
                    }

                    if (!$scope.audioLockedOut) {
                        VoipConsultationService.initialize();
                        $scope.connectVoip = VoipConsultationService.connect;
                        $scope.disconnectVoip = VoipConsultationService.disconnect;
                    }
                };

                $scope.refreshDevices = function() {
                    return navigator.mediaDevices.enumerateDevices().then(function(devices) {
                        $scope.audioLockedOut = true;
                        $scope.videoLockedOut = true;

                        $scope.videoDevices = [];
                        $scope.audioInputDevices = [];
                        $scope.audioOutputDevices = [];

                        for (var key in devices) {
                            var device = devices[key];
                            switch (device.kind) {
                                case 'videoinput':
                                    if (_.findWhere($scope.videoDevices, {'deviceId': device.deviceId})) {
                                        // Already exists.
                                        break;
                                    }
                                    $scope.videoDevices.push(device);
                                    if (device.label !== '') {
                                        $scope.videoLockedOut = false;
                                    }
                                    break;
                                case 'audioinput':
                                    if (_.findWhere($scope.audioInputDevices, {'deviceId': device.deviceId})) {
                                        // Already exists.
                                        break;
                                    }
                                    $scope.audioInputDevices.push(device);
                                    if (device.label !== '') {
                                        $scope.audioLockedOut = false;
                                    }
                                    break;
                                case 'audiooutput':
                                    if (_.findWhere($scope.audioOutputDevices, {'deviceId': device.deviceId})) {
                                        // Already exists.
                                        break;
                                    }
                                    $scope.audioOutputDevices.push(device);
                                    if (device.label !== '') {
                                        $scope.audioLockedOut = false;
                                    }
                                    break;
                            }
                        }

                        if (!$scope.selectedVideoDevice && $scope.videoDevices[0] && $scope.videoDevices[0].deviceId) {
                            $scope.selectedVideoDevice = $scope.videoDevices[0].deviceId;
                        }
                        if (!$scope.selectedAudioInputDevice && $scope.audioInputDevices[0] && $scope.audioInputDevices[0].deviceId) {
                            $scope.selectedAudioInputDevice = $scope.audioInputDevices[0].deviceId;
                        }
                        if (!$scope.selectedAudioOutputDevice && $scope.audioOutputDevices[0] && $scope.audioOutputDevices[0].deviceId) {
                            $scope.selectedAudioOutputDevice = $scope.audioOutputDevices[0].deviceId;
                        }

                        initializeVideo();
                        initializeVoip();
                    });
                };
                $scope.refreshDevices();

                navigator.mediaDevices.ondevicechange = () => {
                    navigator.mediaDevices.enumerateDevices().then(devices => {
                        const devicesByKind = {
                            videoinput: [],
                            audioinput: [],
                            audiooutput: [],
                        };

                        devices.forEach(device => {
                            devicesByKind[device.kind].push(device);
                        });

                        $scope.videoDevices = devicesByKind.videoinput;
                        $scope.audioInputDevices = devicesByKind.audioinput;
                        $scope.audioOutputDevices = devicesByKind.audiooutput;

                        if ($scope.selectedVideoDevice && !$scope.videoDevices.find(device => device.deviceId === $scope.selectedVideoDevice)) {
                            $scope.selectedVideoDevice = $scope.videoDevices.length ? $scope.videoDevices[0].deviceId : null;
                        }
                        if ($scope.selectedAudioInputDevice && !$scope.audioInputDevices.find(device => device.deviceId === $scope.selectedAudioInputDevice)) {
                            $scope.selectedAudioInputDevice = $scope.audioInputDevices.length ? $scope.audioInputDevices[0].deviceId : null;
                        }
                        if ($scope.selectedAudioOutputDevice && !$scope.audioOutputDevices.find(device => device.deviceId === $scope.selectedAudioOutputDevice)) {
                            $scope.selectedAudioOutputDevice = $scope.audioOutputDevices.length ? $scope.audioOutputDevices[0].deviceId : null;
                        }
                    });
                };

                $scope.grantAudioPermissions = function() {
                    var getUserMediaVideo = !$scope.videoLockedOut;
                    navigator.mediaDevices.getUserMedia({'audio': true, 'video': getUserMediaVideo}).
                        then(function(stream) {
                            $scope.$evalAsync(function() {
                                $scope.audioLockedOut = false;
                                $scope.refreshDevices().then(function() {
                                    stream.getAudioTracks()[0].stop();
                                    VoipConsultationService.initialize();
                                });
                            });
                        }).
                        catch(function(err) {
                            console.error(err);
                            $scope.$evalAsync(function() {
                                $scope.audioPermissionGrantText = 'Could not request audio permissions!';
                            });
                        });
                };
                $scope.grantVideoPermissions = function() {
                    var getUserMediaAudio = !$scope.audioLockedOut;
                    navigator.mediaDevices.getUserMedia({'audio': getUserMediaAudio, 'video': {facingMode: 'user'}}).
                        then(function(stream) {
                            $scope.$evalAsync(function() {
                                stream.getVideoTracks()[0].stop();
                                $scope.refreshDevices().then(function() {
                                    stream.getVideoTracks()[0].stop();
                                    $scope.videoLockedOut = false;
                                });
                            });
                        }).
                        catch(function(err) {
                            $scope.$evalAsync(function() {
                                console.error(err);
                                $scope.videoPermissionGrantText = 'Could not request video permissions!';
                            });
                        });
                };

                $scope.activateLocalVideo = VideoConsultationService.activateLocalVideo;
                $scope.deactivateLocalVideo = VideoConsultationService.deactivateLocalVideo;

                $scope.closeSettingsModal = function() {
                    $scope.showSettingsAsModal = false;
                };

                $scope.$on('voipStatus', function(ctx, newStatus) {
                    var oldStatus = $scope.voipStatus;
                    $scope.voipStatus = newStatus;

                    if ($scope.videoStatus > VideoConsultationService.statusEnum.STATUS_LOADING) {
                        if ($scope.voipStatus === 1 && oldStatus > 1) {
                            VideoConsultationService.disconnect();
                        }
                    }
                });

                $scope.$on('videoStatus', function(ctx, newStatus) {
                    $scope.videoStatus = newStatus;
                });
                $scope.$on('videoMonitorStatus', function(ctx, monitorType) {
                    $scope.videoMonitors[monitorType] = VideoConsultationService.getMonitorStatus(monitorType);
                });

                $scope.$on('toggle-multimedia-settings', function() {
                    $scope.showSettingsAsModal = !$scope.showSettingsAsModal;
                });

                $scope.$on('meeting-state-change', function() {
                    if (VideoConsultationService.status >= 0) {
                        VideoConsultationService.disconnect();
                    }
                    if (VoipConsultationService.status >= 0) {
                        VoipConsultationService.disconnect();
                    }
                });
            },
        ],
    };
}

