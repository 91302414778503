import * as moment from 'moment-timezone';

export const dateTable = [
    '$rootScope', function($rootScope) {
        return {
            restrict: 'A',
            templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/consultations/dateTable.html',
            scope: {
                meetingStartDates: '=meetingStartDates',
                durationInSeconds: '=durationInSeconds',
                label: '@label',
                showCheckboxes: '=?withSelect',
            },
            controller: [
                '$scope', 'Meeting', 'TimezonesService', function($scope, Meeting, TimezonesService) {
                    var i;
                    var startDateMoment;
                    var endDateMoment;

                    // create a list of timezone options
                    $scope.timezones = [
                        TimezonesService.getDeviceTimezone(),
                        TimezonesService.getLocationObjectFromCode($rootScope.thisUser.timezone),
                    ];

                    // whether or not to show the timezone selector
                    $scope.showSelect = false;

                    // the selectedTimezone will be the device (unless the device is UTC then it's the profile)
                    $scope.selectedTimezone = TimezonesService.getDeviceTimezone();

                    $scope.$watch('selectedTimezone', function() {
                        TimezonesService.setDeviceTimezone($scope.selectedTimezone);

                        // we convert the suggested time swaths stored in the Meeting model into template ready objects
                        $scope.suggestedTimes = [];
                        if ($scope.meetingStartDates) {
                            for (i = 0; i < $scope.meetingStartDates.length; i++) {

                                // we create moments to format the dates
                                startDateMoment = moment.tz($scope.meetingStartDates[i].getStartDate(),
                                    $scope.selectedTimezone.locationCode);
                                endDateMoment = moment.tz($scope.meetingStartDates[i].getEndDate(),
                                    $scope.selectedTimezone.locationCode);

                                $scope.suggestedTimes.push({
                                    dateLabel: startDateMoment.format('ddd, MMM Do'),
                                    timeSwathLabel: startDateMoment.format('h:mma') + ' - ' +
                                        endDateMoment.format('h:mma'),
                                    id: i,
                                });
                            }
                        }

                        // if we're showing checkboxes, we want to update the selected time label
                        if ($scope.showCheckboxes) {
                            $scope.updateSelectedLabel();
                        }
                    });

                    // if we're showing checkboxes
                    if ($scope.showCheckboxes) {
                        $scope.checkbox = {selectedTimeid: null};
                        // this function is run when they click on a radio button
                        $scope.updateSelectedLabel = function() {
                            var newVal = $scope.checkbox.selectedTimeid;
                            if (newVal === undefined || newVal === null) {
                                return;
                            }
                            $scope.selectedDateFormatted = $scope.suggestedTimes[newVal].dateLabel;
                            $scope.selectedTimeFormatted = $scope.suggestedTimes[newVal].timeSwathLabel
                                + ' ' + $scope.selectedTimezone.abbreviation;
                        };

                        // when they click the save button after selecting a time (if showCheckboxes is true)
                        $scope.saveSelectedTime = function() {
                            $scope.isLoading = true;
                            // we get the timestamp of the date they selected
                            var selectedTime = $scope.meetingStartDates[$scope.checkbox.selectedTimeid].getStartDate().
                                getTime() / 1000;
                            Meeting.getByRouteParams().then(function(m) {
                                Meeting.selectTime(m, selectedTime).then(function() {
                                    $scope.isLoading = false;
                                }, function() {
                                    // TODO: Something with the error
                                    $scope.isLoading = false;
                                });
                            });
                        };
                    }
                },
            ],
        };
    }
];
