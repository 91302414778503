import {whitelabelling as Whitelabelling} from 'whitelabelling';

/**
 * This directive packages the assessment panel items
 * Use like:
 * <dashboard-consultation-card></dashboard-consultation-card>
 */
export function dashboardUserCard() {
    return {
        restrict: 'E',
        scope: {
            data: '=',
            actionLabel: '@?',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/directives/dashboard/dashboardUserCard.html',
        replace: true,
        controller: [
            '$scope', function($scope) {
                var consultData = $scope.data.get('consultData');
                $scope.upcomingMeetingid = consultData && consultData.upcomingMeetingid ? parseInt(
                    consultData.upcomingMeetingid) : null;

                // top match score is being used as a proxy variable to determine if this user is loaded from search
                // results
                $scope.topMatchScore = $scope.data.get('topMatchFinal');
                $scope.consultationCount = consultData ? parseInt(consultData.completedConsults) : $scope.data.get(
                    'consultationCount');

                $scope.actionLabel = $scope.actionLabel ||
                    (!!$scope.upcomingMeetingid ? 'Message' : ($scope.topMatchScore ? 'View Profile' : 'Rebook'));
                $scope.action = '#!/profile/' + $scope.data.get('id');
                $scope.avatarBaseURL = '/asset/img/avatars/'+ $scope.data.userid + '/' + $scope.data.avatarHash;

                $scope.cardType = $scope.topMatchScore ? '' : ($scope.data.get('expertid') ? 'mentor' : 'mentee');

                $scope.whitelabelling = Whitelabelling;
            },
        ],
    };
}

