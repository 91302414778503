import {NgModel} from 'model/ngModel';

export const Service = NgModel.extend({
    _name: 'Service',
    parse: function(data) {
        var dataFormatted = this._super('parse', data);

        dataFormatted.durationInMinutes = dataFormatted.consultationDuration;
        dataFormatted.durationInHours = dataFormatted.durationInMinutes / 60;
        dataFormatted.durationInSeconds = dataFormatted.durationInMinutes * 60;
        delete dataFormatted.consultationDuration;
        dataFormatted.isFairType = this.asBoolean(dataFormatted.isFairType);

        this.__bindManyForAngular(dataFormatted);

        return dataFormatted;
    },
});
