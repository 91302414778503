export class SyllabusController {
    static $inject = ['Profile', 'VisitorService', '$compile', '$location', '$q', '$scope', '$window'];

    constructor(Profile, VisitorService, $compile, $location, $q, $scope, $window) {
        this.window = $window;
        this.scope = $scope;
        this.q = $q;
        this.VisitorService = VisitorService;
        this.Profile = Profile;
        this.location = $location;
        this.compile = $compile;

        $scope.bookMeeting = () => {
            let url = '/profile/' + this.scope.user.id + '/book';
            if ($scope.selectedService) {
                url += '/service/' + this.scope.selectedService.id;
            }
            this.location.path(url);
        };

        this.init();
    }

    init() {
        this.expert = null;
        this.scope.user = {};
        this.scope.services = [];
        this.scope.selectedService = null;

        this.window.document.title = 'Syllabus';
        this.getAssignedExpert();
    }

    getAssignedExpert() {
        this.VisitorService.getVisitor().then((visitor) => {
            if (visitor.isUser && visitor.user.consumerInOneToOneMatching) {
                this.scope.oneToOneProgram = visitor.user.oneToOneProgram;
                this.compile($("#syllabus-content").html(this.scope.oneToOneProgram.syllabusContent).contents())(this.scope);
                const userid = visitor.user.oneToOneMatchedExpertUserId;
                this.q.all([
                    this.Profile.getUser(userid),
                    this.Profile.getFullProfile(userid, false),
                ]).then(([user, profile]) => {
                    this.scope.user = user;
                    this.scope.services = profile.get('Services');
                    this.scope.selectedService = profile.get('Services').first();
                    this.scope.$emit('controllerLoaded', true);
                });
            } else {
                window.location.href = '/app#!/dashboard';
            }
        });
    }
}
