import SessionExpiredHandler from "./handlers/SessionExpiredHandler";
import MeetingAccessHandler from "./handlers/MeetingAccessHandler";

export default function ($log) {
    /**
     * @type {AbstractHandler[]}
     */
    const handlers = [
        new SessionExpiredHandler(),
        new MeetingAccessHandler(),
        // add your new handlers here...
    ];

    return function (exception, cause) {
        let isHandled = false;

        handlers.forEach((h) => {
            let result = h.run(exception, cause);

            if (!isHandled && result) {
                isHandled = true;
            }
        });

        /**
         * if none of the handlers caught the error, make sure that it is still logged
         * otherwise the error wouldn't be reported anywhere and could be missed completely
         */
        if (!isHandled) {
            $log.error(exception);
        }
    }
}
