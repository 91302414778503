import * as _ from 'underscore';
import {AlgorithmUser} from 'model/algorithmUser';

export const topMatch = [
    '$http',
    function($http) {
        return {
            restrict: 'E',
            scope: {
                user: '=',
                scores: '=',
            },
            templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/directives/search/topMatch.html',
            replace: false,
            controller: [
                '$scope',
                '$timeout',
                '$window',
                'AlgorithmService',
                function($scope, $timeout, $window, AlgorithmService) {
                    $scope.showMatch = false;
                    $scope.loaded = false;

                    $scope.toggleShowMatch = function($event) {
                        $scope.showMatch = !$scope.showMatch;

                        if ($scope.showMatch && !$scope.loaded) {
                            // now we get their algorithm profiles
                            $http.get('/profile/users/~/algorithm/profile.json?userid=' + $scope.user.userid).
                                then(function(data) {
                                    $scope.consumerUser = new AlgorithmUser(data.data.data.consumer, {parse: true});
                                    $scope.expertUser = new AlgorithmUser(data.data.data.expert, {parse: true});

                                    $scope.criteriaList = AlgorithmService.getTopMatchCriteriaBetweenUsers(
                                        $scope.expertUser, $scope.consumerUser, $scope.scores, true);

                                    // we want non-partials at the top
                                    $scope.criteriaList = _.sortBy($scope.criteriaList, function(val) {
                                        return val.isPartial ? 1 : 0;
                                    });
                                    $scope.relevantCriteria = _.filter($scope.criteriaList, function(item) {
                                        return (item.matches && item.matches.length > 0);
                                    });

                                    $scope.loaded = true;
                                });
                        }

                        $event.preventDefault();
                    };

                    // handle opening / closing the match details panel
                    $scope.$watch('showMatch', function(newVal) {
                        if (newVal === true) {
                            // we do a timeout here so that the click event which opened the panel doesn't then
                            // immediately close it
                            $timeout(function() {
                                $window.addEventListener('click', closePopup);
                            }, 0);

                        } else if (newVal === false) {
                            $window.removeEventListener('click', closePopup);
                        }
                    });

                    // this closes the match details panel
                    // it should only ever be called after native click events on the window
                    function closePopup() {
                        $scope.showMatch = false;

                        // because this function is only called via native click events, we need to apply a digest
                        $scope.$apply();
                    }
                },
            ],
        };
    },
];
