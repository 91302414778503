import {CustomInput} from 'model/customInput';

export const OTHER_KEY = '-OTHER-';
export const TYPE_MULTIPLE_CHOICE = 'multipleChoice';

export class CustomInputMultipleChoice extends CustomInput {
    type = TYPE_MULTIPLE_CHOICE;

    multiple = false;
    allowOther = false;
    options = [];
    maxSelection = null;
    minSelection = null;
    maxSelectionIgnoresOther = false;

    constructor(data) {
        super(data);

        Object.assign(this, data);
        this.multipleEnabled = data.multiple || data.multipleExpert;
    };

    isValid(inputData) {
        if (!super.isValid(inputData)) {
            return false;
        }

        let responseCount = 0;
        for (const id in inputData) {
            if (!inputData.hasOwnProperty(id)) {
                continue;
            }

            if (inputData[id] === true || (id === OTHER_KEY && inputData[id])) {
                responseCount = responseCount + 1;
            }
        }

        if (responseCount === 0 && this.required) {
            this.error = 'This field must not be empty.';
            return false;
        }

        const responseContainsOther = !!inputData[OTHER_KEY];
        if (!this.multipleEnabled && inputData.length > 1) {
            this.error = 'You\'ve selected too many options.';
            return false;
        } else if (this.multipleEnabled) {
            if (this.maxSelection && responseCount > this.maxSelection) {
                // More responses than maxSelection allows...
                if (responseContainsOther && this.maxSelectionIgnoresOther && ((responseCount - 1) === this.maxSelection)) {
                    // Response contained an "other" input, and we can ignore it, so it's not too many inputs.
                } else {
                    // Otherwise, you're boned! Too many inputs!
                    this.error = 'You\'ve selected too many options. Please select a maximum of ' + this.maxSelection;
                    if (this.maxSelectionIgnoresOther) {
                        this.error += ' excluding your "Other" input.';
                    } else {
                        this.error += '.';
                    }
                    return false;
                }
            }

            if (this.minSelection && responseCount < this.minSelection) {
                this.error = 'You must select at least ' + this.minSelection + ' options.';
                return false;
            }
        }

        this.error = null;
        return true;
    }
}
