export const EditProfileController = [
    '$scope', '$window', '$rootScope', '$route', '$routeParams', 'VisitorService',
    function($scope, $window, $rootScope, $route, $routeParams, VisitorService) {
        $window.document.title = 'Edit Profile';
        $rootScope.pageClass = 'edit-profile-container';
        $scope.isLoading = true;

        $scope.section = $routeParams.section;
        $scope.onSectionChanged = function(section) {
            $route.updateParams({section: section});
        };

        // Do not reload on section change
        const route = $route.current;
        $scope.$on('$locationChangeSuccess', function() {
            if (route.$$route.originalPath === $route.current.$$route.originalPath) {
                $route.current = route;
            }
        });

        VisitorService.getVisitor().then(function(visitor) {
            if (!visitor.get('isUser')) {
                window.location.href = '/login';
                return;
            }

            $scope.isLoading = false;
        });
    },
];
