export function userInputRace() {
    return {
        restrict: 'E',
        scope: {
            data: '=',
            displayType: '@?',
            customRaces: '=?',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/registration/directives/userInputs/userInputRace.html',
        controller: [
            '$scope', function($scope) {
                $scope.showTip = false;
                $scope.hideModal = function() {
                    $scope.showTip = false;
                };
            },
        ],
    };
}

