import {Booth} from "../../control/partner/model/fairs/booths/booth";
import {GroupUserFiles} from "../../control/partner/collection/groupUserFiles";

export const BoothResumesController = [
    '$scope', '$window', '$rootScope', '$routeParams', '$location', '$http', '$timeout', 'VisitorService',
    function ($scope, $window, $rootScope, $routeParams, $location, $http, $timeout, VisitorService) {
        $window.document.title = 'Resumes';
        $rootScope.pageClass = 'consultations-container';

        $scope.init = function() {
            $scope.errors = {};
            $scope.isLoading = true;

            $scope.booth = new Booth();
            $scope.booth.urlRoot = `/booths/${$routeParams.groupid}`;
            $scope.booth
                .fetch()
                .then((res) => {
                    $scope.isLoading = false;
                    $window.document.title = 'Resumes | ' + $scope.booth.label;
                    $scope.$emit('controllerLoaded', true);
                })

            $scope.userFiles = new GroupUserFiles();
            $scope.userFiles.groupid = $routeParams.groupid;
            $scope.userFiles.fetch().then((res) => {
                $scope.isLoading = false;
            })
        }
    }
]
