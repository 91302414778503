import {DirectivesModule} from 'directives/module';
import {alertSessionExpired} from '../../angular-utilities/directives/alertSessionExpired';
import {assessment} from './assessment';
import {boothAcceptUploads} from "../../control/partner/directives/fairs/boothAcceptUploads";
import {boothEvents} from "../../control/partner/directives/fairs/boothEvents";
import {boothHiringInfo} from "../../control/partner/directives/fairs/boothHiringInfo";
import {boothInfo} from "../../control/partner/directives/fairs/boothInfo";
import {boothParticipants} from "../../control/partner/directives/fairs/boothParticipants";
import {boothResources} from "../../control/partner/directives/fairs/boothResources";
import {cancelMeeting} from './cancelMeeting';
import {chatInputArea} from './chat/chatInputArea';
import {chatSection} from './chat/chatSection';
import {conferenceNumber} from './conferenceNumber';
import {connectionMessage} from 'directives/connectionMessage';
import {consultationHistoryRow} from './consultationHistoryRow';
import {consumerFeedback} from './consumerFeedback';
import {customInputFile} from 'directives/customInputFile';
import {customInputMultipleChoice} from 'directives/customInputMultipleChoice';
import {customInputRange} from 'directives/customInputRange';
import {customInputSection} from 'directives/customInputSection';
import {customInputText} from 'directives/customInputText';
import {dashboardConsultationCard} from './dashboard/dashboardConsultationCard';
import {dashboardUserCard} from './dashboard/dashboardUserCard';
import {dashboardWebinarCard} from './dashboard/dashboardWebinarCard';
import {dateInputText} from 'directives/dateInputText';
import {dateTable} from './dateTable';
import {datetimeInput} from 'directives/datetimeInput';
import {diffHl} from './diffHl';
import {donationPrompt} from 'directives/donationPrompt';
import {editAvailability} from 'directives/editAvailability';
import {editOfferings} from 'directives/editOfferings';
import {editProfile} from 'directives/editProfile';
import {expertFeedback} from './expertFeedback';
import {firsthandCroppie} from "../../angular-utilities/directives/firsthandCroppie";
import {forwardClick} from 'directives/forwardClick';
import {groupAdminForumSection} from "directives/groupAdminForumSection";
import {groupEvent} from "./groupEvent";
import {groupExtraInputs} from "directives/groupExtraInputs";
import {groupExtraInput} from "directives/groupExtraInput";
import {groupMember} from './groupMember';
import {groupResource} from "./groupResource";
import {hiddenSelect} from 'directives/hiddenSelect';
import {hintedInput} from 'directives/hintedInput';
import {icon} from 'directives/icon';
import {imageUpload} from "../../control/partner/directives/imageUpload";
import {jobOpening} from './jobOpening';
import {jobRelatedExpertCriteria} from './jobRelatedExpertCriteria';
import {lazyLoad} from './lazyLoad';
import {loadingBlock} from 'directives/loadingBlock';
import {loadingDots} from 'directives/loadingDots';
import {locationInput} from 'directives/locationInput';
import {meetingConnectionControls} from './meetingConnectionControls';
import {meetingMultimedia} from './meetingMultimedia';
import {modalDialog} from 'directives/modalDialog';
import {navigation} from 'directives/navigation';
import {noSpotsLeft} from './noSpotsLeft';
import {onKeyEnter} from 'directives/onKeyEnter';
import {paypalForm} from './paypalForm';
import {pickThreeDates} from 'directives/pickThreeDates';
import {pluralize} from './helpers/pluralize';
import {prefixComplete} from 'directives/prefixComplete';
import {profileCareerGoals} from 'directives/profileCareerGoals';
import {profileExperienceEducation} from 'directives/profileExperienceEducation';
import {profileExperienceEmployment} from 'directives/profileExperienceEmployment';
import {profileHeadline} from 'directives/profileHeadline';
import {profileStats} from './profileStats';
import {question} from './question';
import {rescheduleMeeting} from './rescheduleMeeting';
import {resourceCard} from './resourceCard';
import {scopeElement} from './helpers/scopeElement';
import {searchFilter} from './search/searchFilter';
import {searchResult} from './search/searchResult';
import {similarExperts} from 'directives/similarExperts';
import {siteBanner} from 'directives/siteBanner';
import {slideToggle} from 'directives/slideToggle';
import {starRating} from 'directives/starRating';
import {stickyFooter} from './stickyFooter';
import {stickyHeader} from './stickyHeader';
import {tenPointScale} from './helpers/tenPointScale';
import {textLimit} from 'directives/textLimit';
import {timestamp} from 'directives/timestamp';
import {timezoneSelect} from 'directives/timezoneSelect';
import {topMatch} from './search/topMatch';
import {upcomingConsultationsTimeline} from './upcomingConsultationsTimeline';
import {userBooths} from "./userBooths";
import {userEngagement} from 'directives/userEngagement';
import {userInputAboutMe} from 'directives/userInputs/userInputAboutMe';
import {userInputAffinityGroupsEdit} from 'directives/userInputs/userInputAffinityGroupsEdit';
import {userInputAvailabilitySpecific} from 'directives/userInputs/userInputAvailabilitySpecific';
import {userInputAvailability} from 'directives/userInputs/userInputAvailability';
import {userInputBio} from 'directives/userInputs/userInputBio';
import {userInputCalendarSync} from 'directives/userInputs/userInputCalendarSync';
import {userInputCareerGoalsEmployersEdit} from 'directives/userInputs/userInputCareerGoalsEmployersEdit';
import {userInputCareerGoalsIndustriesEdit} from 'directives/userInputs/userInputCareerGoalsIndustriesEdit';
import {userInputCareerGoalsLocationsEdit} from 'directives/userInputs/userInputCareerGoalsLocationsEdit';
import {userInputCareerGoalsRolesEdit} from 'directives/userInputs/userInputCareerGoalsRolesEdit';
import {userInputConsultTypes} from 'directives/userInputs/userInputConsultTypes';
import {userInputCurrentLocation} from 'directives/userInputs/userInputCurrentLocation';
import {userInputEducation} from 'directives/userInputs/userInputEducation';
import {userInputEmailAndPhone} from 'directives/userInputs/userInputEmailAndPhone';
import {userInputEmploymentHistory} from 'directives/userInputs/userInputEmploymentHistory';
import {userInputExposure} from 'directives/userInputs/userInputExposure';
import {userInputExternalid} from 'directives/userInputs/userInputExternalid';
import {userInputGender} from 'directives/userInputs/userInputGender';
import {userInputGraduationDate} from 'directives/userInputs/userInputGraduationDate';
import {userInputHeadline} from 'directives/userInputs/userInputHeadline';
import {userInputJobOpenings} from 'directives/userInputs/userInputJobOpenings';
import {userInputLanguage} from 'directives/userInputs/userInputLanguage';
import {userInputName} from 'directives/userInputs/userInputName';
import {userInputOutOfOffice} from 'directives/userInputs/userInputOutOfOffice';
import {userInputPicture} from 'directives/userInputs/userInputPicture';
import {userInputPlatformAffiliationRelative} from 'directives/userInputs/userInputPlatformAffiliationRelative';
import {userInputPlatformAffiliation} from 'directives/userInputs/userInputPlatformAffiliation';
import {userInputProgramRestriction} from 'directives/userInputs/userInputProgramRestriction';
import {userInputRace} from 'directives/userInputs/userInputRace';
import {userInputSingleEducation} from 'directives/userInputs/userInputSingleEducation';
import {userInputSingleEmployment} from 'directives/userInputs/userInputSingleEmployment';
import {userInputSingleJobOpening} from 'directives/userInputs/userInputSingleJobOpening';
import {userInputVerticalServices} from 'directives/userInputs/userInputVerticalServices';
import {userResources} from './userResources';
import {videoChatMonitor} from './video/videoChatMonitor';
import {pendo} from 'directives/scripts/pendo';

export default DirectivesModule.
    directive('alertSessionExpired', alertSessionExpired).
    directive('assessment', assessment).
    directive('boothAcceptUploads', boothAcceptUploads).
    directive('boothEvents', boothEvents).
    directive('boothHiringInfo', boothHiringInfo).
    directive('boothInfo', boothInfo).
    directive('boothParticipants', boothParticipants).
    directive('boothResources', boothResources).
    directive('cancelMeeting', cancelMeeting).
    directive('chatInputArea', chatInputArea).
    directive('chatSection', chatSection).
    directive('conferenceNumber', conferenceNumber).
    directive('connectionMessage', connectionMessage).
    directive('consultationHistoryRow', consultationHistoryRow).
    directive('consumerFeedback', consumerFeedback).
    directive('customInputFile', customInputFile).
    directive('customInputMultipleChoice', customInputMultipleChoice).
    directive('customInputRange', customInputRange).
    directive('customInputSection', customInputSection).
    directive('customInputText', customInputText).
    directive('dashboardConsultationCard', dashboardConsultationCard).
    directive('dashboardUserCard', dashboardUserCard).
    directive('dashboardWebinarCard', dashboardWebinarCard).
    directive('dateInputText', dateInputText).
    directive('dateTable', dateTable).
    directive('datetimeInput', datetimeInput).
    directive('diffHl', diffHl).
    directive('donationPrompt', donationPrompt).
    directive('editAvailability', editAvailability).
    directive('editOfferings', editOfferings).
    directive('editProfile', editProfile).
    directive('expertFeedback', expertFeedback).
    directive('firsthandCroppie', firsthandCroppie).
    directive('forwardClick', forwardClick).
    directive('groupAdminForumSection', groupAdminForumSection).
    directive('groupEvent', groupEvent).
    directive('groupExtraInput', groupExtraInput).
    directive('groupExtraInputs', groupExtraInputs).
    directive('groupMember', groupMember).
    directive('groupResource', groupResource).
    directive('hiddenSelect', hiddenSelect).
    directive('hintedInput', hintedInput).
    directive('icon', icon).
    directive('imageUpload', imageUpload).
    directive('jobOpening', jobOpening).
    directive('jobRelatedExpertCriteria', jobRelatedExpertCriteria).
    directive('lazyLoad', lazyLoad).
    directive('loadingBlock', loadingBlock).
    directive('loadingDots', loadingDots).
    directive('locationInput', locationInput).
    directive('meetingConnectionControls', meetingConnectionControls).
    directive('meetingMultimedia', meetingMultimedia).
    directive('modalDialog', modalDialog).
    directive('navigation', navigation).
    directive('noSpotsLeft', noSpotsLeft).
    directive('onKeyEnter', onKeyEnter).
    directive('paypalForm', paypalForm).
    directive('pickThreeDates', pickThreeDates).
    directive('pluralize', pluralize).
    directive('prefixComplete', prefixComplete).
    directive('profileCareerGoals', profileCareerGoals).
    directive('profileExperienceEducation', profileExperienceEducation).
    directive('profileExperienceEmployment', profileExperienceEmployment).
    directive('profileHeadline', profileHeadline).
    directive('profileStats', profileStats).
    directive('question', question).
    directive('rescheduleMeeting', rescheduleMeeting).
    directive('resourceCard', resourceCard).
    directive('scopeElement', scopeElement).
    directive('searchFilter', searchFilter).
    directive('searchResult', searchResult).
    directive('similarExperts', similarExperts).
    directive('siteBanner', siteBanner).
    directive('slideToggle', slideToggle).
    directive('starRating', starRating).
    directive('stickyFooter', stickyFooter).
    directive('stickyHeader', stickyHeader).
    directive('tenPointScale', tenPointScale).
    directive('textLimit', textLimit).
    directive('timestamp', timestamp).
    directive('timezoneSelect', timezoneSelect).
    directive('topMatch', topMatch).
    directive('upcomingConsultationsTimeline', upcomingConsultationsTimeline).
    directive('userBooths', userBooths).
    directive('userEngagement', userEngagement).
    directive('userInputAboutMe', userInputAboutMe).
    directive('userInputAffinityGroupsEdit', userInputAffinityGroupsEdit).
    directive('userInputAvailability', userInputAvailability).
    directive('userInputAvailabilitySpecific', userInputAvailabilitySpecific).
    directive('userInputBio', userInputBio).
    directive('userInputCalendarSync', userInputCalendarSync).
    directive('userInputCareerGoalsEmployersEdit', userInputCareerGoalsEmployersEdit).
    directive('userInputCareerGoalsIndustriesEdit', userInputCareerGoalsIndustriesEdit).
    directive('userInputCareerGoalsLocationsEdit', userInputCareerGoalsLocationsEdit).
    directive('userInputCareerGoalsRolesEdit', userInputCareerGoalsRolesEdit).
    directive('userInputConsultTypes', userInputConsultTypes).
    directive('userInputCurrentLocation', userInputCurrentLocation).
    directive('userInputEducation', userInputEducation).
    directive('userInputEmailAndPhone', userInputEmailAndPhone).
    directive('userInputEmploymentHistory', userInputEmploymentHistory).
    directive('userInputExposure', userInputExposure).
    directive('userInputExternalid', userInputExternalid).
    directive('userInputGender', userInputGender).
    directive('userInputGraduationDate', userInputGraduationDate).
    directive('userInputHeadline', userInputHeadline).
    directive('userInputJobOpenings', userInputJobOpenings).
    directive('userInputLanguage', userInputLanguage).
    directive('userInputName', userInputName).
    directive('userInputOutOfOffice', userInputOutOfOffice).
    directive('userInputPicture', userInputPicture).
    directive('userInputPlatformAffiliation', userInputPlatformAffiliation).
    directive('userInputPlatformAffiliationRelative', userInputPlatformAffiliationRelative).
    directive('userInputProgramRestriction', userInputProgramRestriction).
    directive('userInputRace', userInputRace).
    directive('userInputSingleEducation', userInputSingleEducation).
    directive('userInputSingleEmployment', userInputSingleEmployment).
    directive('userInputSingleJobOpening', userInputSingleJobOpening).
    directive('userInputVerticalServices', userInputVerticalServices).
    directive('userResources', userResources).
    directive('pendo', pendo).
    directive('videoChatMonitor', videoChatMonitor);
