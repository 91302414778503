export function meetingConnectionControls() {
    return {
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/consultations/meetingConnectionControls.html',
        replace: true,
        controller: [
            '$scope', '$rootScope', 'MeetingConnectionService', function($scope, $rootScope, MeetingConnectionService) {
                // whether or not to show the connected message
                $scope.thisUserIsPresent = false;
                $scope.thisUserConnectedAudio = false;
                $scope.thisUserConnectedVideo = false;
                $scope.thisUserNeverConnectedAudio = true;
                $scope.otherUserIsPresent = false;
                $scope.otherUserConnectedAudio = false;
                $scope.otherUserConnectedVideo = false;
                $scope.otherUserNeverConnectedAudio = true;

                // -----------------------------------------------------------------
                // Determine if the expert and consumer were connected before (in cases of page refresh, etc)
                MeetingConnectionService.addScope($scope);
                MeetingConnectionService.update(true).then(function(status) {
                    if (MeetingConnectionService.hadExpertConnectedBefore()) {
                        if ($scope.meeting.get('visitorIsExpert')) {
                            $scope.thisUserNeverConnectedAudio = false;
                        } else {
                            $scope.otherUserNeverConnectedAudio = false;
                        }
                    }

                    if (MeetingConnectionService.hadConsumerConnectedBefore()) {
                        if ($scope.meeting.get('visitorIsConsumer')) {
                            $scope.thisUserNeverConnectedAudio = false;
                        } else {
                            $scope.otherUserNeverConnectedAudio = false;
                        }
                    }
                });

                // -----------------------------------------------------------------
                var refresh = function() {
                    var oldAudioConnectionStatus = $scope.thisUserConnectedAudio;

                    if ($scope.meeting.get('visitorIsConsumer')) {
                        $scope.thisUserIsPresent = MeetingConnectionService.isConsumerPresent();
                        $scope.thisUserConnectedAudio = MeetingConnectionService.isConsumerConnectedAudio();
                        $scope.thisUserConnectedVideo = MeetingConnectionService.isConsumerConnectedVideo();
                        $scope.thisUserNeverConnectedAudio = !MeetingConnectionService.hadConsumerConnectedBefore();
                        $scope.otherUserIsPresent = MeetingConnectionService.isExpertPresent();
                        $scope.otherUserConnectedAudio = MeetingConnectionService.isExpertConnectedAudio();
                        $scope.otherUserConnectedVideo = MeetingConnectionService.isExpertConnectedVideo();
                        $scope.otherUserNeverConnectedAudio = !MeetingConnectionService.hadExpertConnectedBefore();
                    } else {
                        $scope.thisUserIsPresent = MeetingConnectionService.isExpertPresent();
                        $scope.thisUserConnectedAudio = MeetingConnectionService.isExpertConnectedAudio();
                        $scope.thisUserConnectedVideo = MeetingConnectionService.isExpertConnectedVideo();
                        $scope.thisUserNeverConnectedAudio = !MeetingConnectionService.hadExpertConnectedBefore();
                        $scope.otherUserIsPresent = MeetingConnectionService.isConsumerPresent();
                        $scope.otherUserConnectedAudio = MeetingConnectionService.isConsumerConnectedAudio();
                        $scope.otherUserConnectedVideo = MeetingConnectionService.isConsumerConnectedVideo();
                        $scope.otherUserNeverConnectedAudio = !MeetingConnectionService.hadConsumerConnectedBefore();
                    }

                    if ($scope.otherUserIsPresent) {
                        if ($scope.otherUserNeverConnectedAudio) {
                            $scope.otherUserConnectionStatus = 'here but not connected yet';
                        } else {
                            $scope.otherUserConnectionStatus = 'here but disconnected';
                        }
                        if ($scope.otherUserConnectedAudio) {
                            $scope.otherUserConnectionStatus = 'connected';
                            if ($scope.otherUserConnectedVideo) {
                                $scope.otherUserConnectionStatus += ' with video';
                            }
                        }
                    }
                };

                // Dial Status change event handling
                $scope.$on('meeting-poll', refresh);
            },
        ],
    };
}
