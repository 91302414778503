import {whitelabelling as Whitelabelling} from 'whitelabelling';

export function userInputCareerGoalsEmployersEdit() {
    return {
        restrict: 'E',
        scope: {
            data: '=',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/userInputs/userInputCareerGoalsEmployersEdit.html',
        controller: [
            '$scope', 'RegistrationService', function($scope, RegistrationService) {
                $scope.whitelabelling = Whitelabelling;
                $scope.MAX_TO_ADD = 10;

                $scope.employersSelector = {
                    value: null,
                };

                $scope.employersHook = RegistrationService.employersPrefixComplete;

                $scope.removeEmployer = function(e) {
                    $scope.data.remove(e);
                };

                $scope.$on('showErrors', function() {
                    $scope.noInputs = true;
                });

                // because we can add multiple concentrations, we have the intermediate value to store the
                // currently selecting one
                $scope.$watch('employersSelector.value', function(newVal) {
                    if (newVal) {
                        $scope.data.add(newVal);
                        $scope.noInputs = false;
                        $scope.employersSelector.value = '';
                    }
                });
            },
        ],
    };
}
