import {SimilarExperts} from '../collection/similarExperts';

export const ExpertsService = [
    '$q',
    function($q) {
        var promise = null;
        return {
            getSimilar: function(expertid, number) {
                if (promise) {
                    return promise;
                }

                var deferred = $q.defer();
                promise = deferred.promise;

                // then we create a collection of similar experts using this expert
                var similarExperts = new SimilarExperts();
                similarExperts.fetch({
                    data: {
                        expertid: expertid,
                        count: number,
                    },
                }).then(function() {
                    promise = null;
                    deferred.resolve.call(this, similarExperts);
                }, function() {
                    promise = null;
                    deferred.reject.apply(this, arguments);
                });

                return deferred.promise;
            },
        };
    },
];
