import * as moment from 'moment-timezone';

/**
 * Collects a date via text input.
 *
 * ************
 * Attributes:
 *
 * ng-model: You need something to store your data!
 *
 * granularity: of one of the three strings:
 *      year
 *      month
 *      day
 *
 * required-granularity: the minimum granularity that needs to be entered.
 *      (That is, you could have a granularity of "day", but only require "year".)
 *
 * separated: If any value is set, determines whether to use a single input like "YYYY-MM" or
 *      break out into two separate inputs; one of of "YYYY" and one of "MM".
 *
 * label-prefix: A word to preface the labels with ("Graduation" for "Graduation Year", "Graduation Month").
 *      Only used when separated.
 *
 * date-input-disabled: expression to disable input.
 *
 * ************
 * Example:
 *
 * <date-input-text granularity="month" required-granularity="year" label-prefix="Birth" separated="false"
 * date-input-disabled="isLoading" />
 */
const MIN_YEAR = 1900;
const MAX_YEAR = 2100;

const ERROR_MESSAGE = 'Please enter a valid date';

const GRANULARITY_YEAR = 'year';
const GRANULARITY_MONTH = 'month';
const GRANULARITY_DAY = 'day';

const initializeDateComponents = function(granularity, requiredGranularity, initialDate) {
    var components = {};

    var year = null,
        month = null,
        day = null;

    if (initialDate) {
        year = initialDate.format('YYYY');
        month = initialDate.format('MM');
        day = initialDate.format('DD');
    }

    components[GRANULARITY_YEAR] = {
        value: year,
        label: 'Year',
        placeholder: 'YYYY',
        dateFormat: '9999',
        required: true,
    };

    if (granularity === GRANULARITY_MONTH || granularity === GRANULARITY_DAY) {
        components[GRANULARITY_MONTH] = {
            value: month,
            label: 'Month',
            placeholder: 'MM',
            dateFormat: '99',
            required: false,
        };
        if (requiredGranularity === GRANULARITY_MONTH || requiredGranularity === GRANULARITY_DAY) {
            components[GRANULARITY_MONTH].required = true;
        }
    }
    if (granularity === GRANULARITY_DAY) {
        components[GRANULARITY_DAY] = {
            value: day,
            label: 'Day',
            placeholder: 'DD',
            dateFormat: '99',
            required: false,
        };
        if (requiredGranularity === GRANULARITY_DAY) {
            components[GRANULARITY_MONTH].required = true;
        }
    }

    return components;
};

export function dateInputText() {
    return {
        restrict: 'E',
        require: 'ngModel',
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/dateInputText.html',
        scope: {
            'dateInputDisabled': '=?',
            'error': '=?',
            'requiredGranularity': '@?',
        },
        controller: [
            '$scope', function($scope) {
                $scope.date = {
                    value: null,
                };
                $scope.isValid = true;
                $scope.error = null;
                $scope.hasBeenModified = false;
                $scope.components = null;

                $scope.handleModification = function() {
                    if (!$scope.hasBeenModified) {
                        $scope.hasBeenModified = true;
                        $scope.date.value = null;
                    }

                    if ($scope.hasBeenModified && !$scope.bindModel) {
                        $scope.date.value = null;
                    }
                };

                $scope.checkDateValidity = function(year, month, day) {
                    $scope.error = null;

                    if ([GRANULARITY_YEAR, GRANULARITY_MONTH, GRANULARITY_DAY].indexOf($scope.granularity) !== -1) {
                        if ($scope.requiredGranularity && !year) {
                            $scope.isValid = false;
                            $scope.error = ERROR_MESSAGE;
                        } else if (year < MIN_YEAR || year > MAX_YEAR) {
                            $scope.isValid = false;
                            $scope.error = ERROR_MESSAGE;
                        } else {
                            $scope.isValid = true;
                        }
                    }
                    if ($scope.isValid && [GRANULARITY_MONTH, GRANULARITY_DAY].indexOf($scope.granularity) !== -1) {
                        if (($scope.requiredGranularity === GRANULARITY_MONTH || $scope.requiredGranularity ===
                            GRANULARITY_DAY) && !month) {
                            $scope.isValid = false;
                            $scope.error = ERROR_MESSAGE;
                        } else if (month && (month > 12 || month < 1)) {
                            $scope.isValid = false;
                            $scope.error = ERROR_MESSAGE;
                        } else {
                            $scope.isValid = true;
                        }
                    }
                    if ($scope.isValid && $scope.granularity === 'day') {
                        if (($scope.requiredGranularity === GRANULARITY_DAY) && !day) {
                            $scope.isValid = false;
                            $scope.error = ERROR_MESSAGE;
                        } else if (day && (!day || day > 31 || day < 1)) {
                            $scope.isValid = false;
                            $scope.error = ERROR_MESSAGE;
                        } else {
                            $scope.isValid = true;
                        }
                    }

                    if ($scope.isValid) {
                        var formattedDate = year;
                        var format = 'YYYY';
                        if (month) {
                            formattedDate += '-' + month;
                            format = 'YYYY-MM';
                        }
                        if (day) {
                            formattedDate += '-' + day;
                            format = 'YYYY-MM-DD';
                        }
                        var newDate = moment.utc(formattedDate, format);
                        $scope.date.value = newDate.toDate();
                        $scope.$emit('inputError', null);
                    } else if ($scope.hasBeenModified) {
                        $scope.date.value = null;
                        $scope.$emit('inputError', $scope.error);
                    }
                };

                $scope.$watch('requiredGranularity', function(newVal) {
                    $scope.requiredGranularity = newVal;
                });

                // Initialize separate inputs, if that is the case.
                $scope.$watch('separated', function(isSeparated) {
                    if (isSeparated) {
                        $scope.$watch('components', function(newVal) {
                            if (
                                newVal && (
                                    (!$scope.requiredGranularity) ||
                                    ($scope.requiredGranularity === GRANULARITY_YEAR && newVal[GRANULARITY_YEAR].value) ||
                                    ($scope.requiredGranularity === GRANULARITY_MONTH && newVal[GRANULARITY_YEAR].value &&
                                        newVal[GRANULARITY_MONTH].value) ||
                                    ($scope.requiredGranularity === GRANULARITY_DAY && newVal[GRANULARITY_YEAR].value &&
                                        newVal[GRANULARITY_MONTH].value && newVal[GRANULARITY_DAY].value)
                                )
                            ) {
                                // Only run validator if we have data for all required things.
                                $scope.checkDateValidity(
                                    newVal[GRANULARITY_YEAR] ? newVal[GRANULARITY_YEAR].value : null,
                                    newVal[GRANULARITY_MONTH] ? newVal[GRANULARITY_MONTH].value : null,
                                    newVal[GRANULARITY_DAY] ? newVal[GRANULARITY_DAY].value : null);
                            }
                        }, true);
                    }
                });

                // Watch changes to the bindModel. If components are not separate inputs, this is changed directly.
                // Otherwise $scope.components changes first, then this gets set.
                $scope.$watch('bindModel', function(newVal) {
                    if ($scope.separated) {
                        return;
                    }
                    if (!newVal || !(newVal.toString()) || newVal.length === 0) {
                        return;
                    }

                    var data = newVal.split('-');
                    var year = data[0] ? data[0] : null;
                    var month = data[1] ? data[1] : null;
                    var day = data[2] ? data[2] : null;

                    $scope.checkDateValidity(year, month, day);
                });

                $scope.$watch('date.value', function(newVal) {
                    if ($scope.hasBeenModified) {
                        $scope.setData(newVal);
                    }
                });
            },
        ],
        link: function(scope, element, attrs, ngModel) {
            scope.dateFormat = '';
            scope.placeholder = '';
            scope.granularity = attrs.granularity;
            scope.labelPrefix = attrs.labelPrefix;
            scope.separated = !!attrs.separated;

            // Propagate out ng-disabled
            scope.$parent.$watch(attrs.ngDisabled, function(newVal) {
                scope.disabled = newVal;
            });

            scope.setData = function(d) {
                ngModel.$setViewValue(d);
            };
            if (scope.granularity) {
                switch (scope.granularity) {
                    case GRANULARITY_YEAR:
                        scope.dateFormat = '9999';
                        scope.placeholder = 'YYYY';
                        break;
                    case GRANULARITY_MONTH:
                        scope.dateFormat = '9999-99';
                        scope.placeholder = 'YYYY-MM';
                        break;
                    case GRANULARITY_DAY:
                        scope.dateFormat = '9999-99-99';
                        scope.placeholder = 'YYYY-MM-DD';
                        break;
                }
            }

            function makeMoment(value) {
                return moment.tz(value, 'UTC');
            }

            ngModel.$render = function() {
                if (scope.separated) {
                    var preexistingDate = ngModel.$modelValue ? makeMoment(ngModel.$modelValue) : null;
                    scope.components = initializeDateComponents(scope.granularity, scope.requiredGranularity,
                        preexistingDate);
                } else {
                    scope.bindModel = ngModel.$modelValue
                        ? makeMoment(ngModel.$modelValue).format(scope.placeholder)
                        : null;
                }
            };
        },
    };
}
