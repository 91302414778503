/**
 * This directive creates the star rating interface
 * use like:
 * <star-rating ng-model="...">
 */
export function starRating() {
    return {
        restrict: 'E',
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/starRating.html',
        replace: true,
        scope: {
            data: '=ngModel',
            disabled: '=?',
            showLabel: '@?',
        },
        controller: [
            '$scope', function($scope) {
                var getStarUnderMouse = function(evt) {
                    var relativeX = 100 * (evt.offsetX / $scope.input.width());
                    /*
                     0 -> 20 = 1,
                     20 -> 40 = 2,
                     40 -> 60 = 3,
                     60 -> 80 = 4,
                     80 -> 100 = 5
                     */

                    // we multiply by 2 because the styles prefer values 0-10
                    return 2 * (parseInt(relativeX / 20) + 1);
                };

                $scope.handleOver = function(evt) {
                    if ($scope.disabled) {
                        return;
                    }
                    var stars = getStarUnderMouse(evt);
                    $scope.container.attr('data-stars-hover', stars);
                };

                $scope.handleOut = function() {
                    if ($scope.disabled) {
                        return;
                    }
                    $scope.container.removeAttr('data-stars-hover');
                };

                $scope.setStars = function(evt) {
                    if ($scope.disabled) {
                        return;
                    }
                    $scope.data = getStarUnderMouse(evt);
                };
            },
        ],
        link: function(scope, elem, attrs) {
            scope.container = elem;
            scope.input = scope.container.children().first();

            var _LEFT = 37;
            var _RIGHT = 39;
            elem.on('keydown', function(ev) {
                if (ev.which == _LEFT || ev.which == _RIGHT) {
                    var changeAmount = (ev.which == _LEFT) ? -2 : 2;
                    // this will toggle through the stars like
                    // 2, 4, 8, 10, 2, 4, 8, 10, 2
                    scope.data = (scope.data % 10) + changeAmount;

                    // we cannot go back to 0 stars, so we ever go that low, we set it back to 10 (5 stars)
                    if (scope.data <= 0) {
                        scope.data = 10 + scope.data;
                    }

                    // we call apply because this is some asynched stuff
                    scope.$apply();
                }
            });
        },
    };
}

