import {whitelabelling} from 'whitelabelling';

/**
 * This directive prints a computed "headline" for a given user (like: "VP of Engineering @ Firsthand") preferring
 * self-specified expert headlines first, then work experience, then, basically "student" for advice seekers.
 *
 * You can pass in an employment, either loaded separately or as part of the user's full profile, to include that
 * in the computation.
 *
 * A "limit" attribute can be specified if you want to limit the headline to a certain character limit.
 *
 * Use like:
 * <profile-headline user="someUserObject" employment="hisEmployment" limit="20"></div>
 */
export function profileHeadline() {
    return {
        restrict: 'E',
        template: '<ng-include src="getTemplateUrl()"/>',
        replace: true,
        scope: {
            user: '=',
            employment: '=?',
            customInputs: '=?',
            limit: '=',
            small: '<?',
        },
        controller: [
            '$scope', $scope => {
                $scope.getTemplateUrl = () => `/asset/partials/angular-utilities/directives/${$scope.small === true ? 'profileHeadlineSmall.html' : 'profileHeadline.html'}`;

                $scope.$watch('user', newVal => {
                    if (!newVal || !newVal.cid) {
                        return;
                    }

                    $scope.now = Date.now();
                    $scope.headlineType = 'customHeadline';

                    if (whitelabelling.id === 392) {
                        if ($scope.employment && $scope.employment.company) {
                            $scope.headlineType = 'expertHeadline';
                            $scope.user.expertHeadline = $scope.employment.company;
                        } else if ($scope.user.expertHeadline) {
                            $scope.headlineType = 'expertHeadline';
                            $scope.user.expertHeadline = $scope.user.expertHeadline.replace('Alum of ', '');
                        }
                    } else {
                        if ($scope.user.expertid && $scope.user.expertHeadline) {
                            $scope.customHeadline =  $scope.user.expertHeadline;
                            $scope.headlineType = 'expertHeadline';
                        } else if (!$scope.user.expertid && $scope.user.headline) {
                            $scope.customHeadline = $scope.user.headline;
                        } else if ($scope.employment) {
                            $scope.headlineType = 'employment';
                        } else if ($scope.user.consumerSchool) {
                            $scope.headlineType = 'consumer';
                        }
                    }
                });
            },
        ],
    };
}
