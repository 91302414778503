/**
 * This directive will float as a fixed footer to the bottom of the page up until it would overlap with the site footer in which case it sticks to the footer
 * <div sticky-footer></div>
 */
const stuckStyles = {
    position: 'absolute',
    zIndex: '99',
    left: 0,
    bottom: 0,
};

const floatingStyles = {
    position: 'fixed',
    zIndex: '99',
    left: 0,
    bottom: 0,
};

const inactiveStyles = {
    position: 'static',
};

const FLOATING_CLASS = 'sticky-window';
const STUCK_CLASS = 'sticky-page';

export function stickyFooter() {
    return {
        restrict: 'A',
        scope: {
            shouldBeActive: '&stickyFooter',
        },
        controller: [
            '$scope', '$window', '$timeout', '$document', function($scope, $window, $timeout, $document) {
                // this stores the breakpoint (whereby the footer changes styles) according to the actor values
                let breakPoint = false;
                let isStuck = false;

                // this function determine the primary actor heights
                function recalculate() {
                    // it's possible (though unlikely) that the footer element has not yet been loaded
                    // either because the DOM is still being built or because the link function hasn't run yet
                    if (!$scope.$footer) {
                        return;
                    }

                    // update the placeholder's height to match the elem's (it may have changed)
                    var offset = $scope.$placeHolder.offset();

                    // recalculate the breakpoint
                    breakPoint = offset.top - $window.innerHeight + $scope.$placeHolder.height();

                    $scope.$placeHolder.css('height', $scope.$elem.outerHeight() + 'px');

                    // call scroll so we can re-determine isStuck or not
                    // NOTE: This may not be needed if resize always fires a scroll event (by virtue of the frame
                    // dimensions changing) I can't be sure, so best to just fire it and be safe
                    updatePosition(true);
                }

                function reset() {
                    if ($scope.$elem) {
                        $scope.$elem.css(inactiveStyles);
                        $scope.$placeHolder.css({display: 'none'});
                    }
                }

                function updatePosition(force) {
                    if ($scope.shouldBeActive() === false) {
                        reset();
                        return;
                    }

                    // don't do anything until we know the breakpoint
                    if (breakPoint === false) {
                        recalculate();
                        return;
                    }
                    // if we've scrolled far enough down
                    if ($window.scrollY > breakPoint) {
                        // and we're not yet stuck to the bottom,
                        if (!isStuck || force === true) {
                            // stick to the bottom
                            $scope.$placeHolder.css({display: 'block'});
                            $scope.$elem.css(stuckStyles).removeClass(FLOATING_CLASS).addClass(STUCK_CLASS);
                            isStuck = true;
                        }
                    } else if (isStuck || force === true) { // we haven't scrolled far enough, but we're still in stuck mode
                        // unstick from the bottom
                        $scope.$elem.css(floatingStyles).removeClass(STUCK_CLASS).addClass(FLOATING_CLASS);
                        isStuck = false;
                    } else {
                        // all's ok, change nothing
                    }
                }

                // we call recalculate once to ensure we have a starting point
                recalculate();

                // if the window dimensions ever change, we need to recalculate
                $($window).resize(recalculate);

                // if the document height ever changes, we need to recalculate
                $scope.$watch(function() {
                    return $document.height();
                }, recalculate);

                // we can also force a refresh
                $scope.$on('recalculateFooter', function() {
                    $timeout(recalculate, 500);
                });

                // when the window scrolls, we determine where the stickyFooter should be
                $($window).on('scroll', updatePosition);
            },
        ],
        link: function(scope, element) {
            // start off in floating mode
            scope.$elem = $(element);

            // create a placeholder that we can position over when we stick to the bottom of the page
            scope.$placeHolder = $('<div class="placeholder placeholder-sticky-footer">');
            scope.$elem.after(scope.$placeHolder);

            // store reference to the footer
            scope.$footer = $('footer[role=contentinfo]');
        },
    };
}

