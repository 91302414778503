export function userInputPicture() {
    return {
        restrict: 'E',
        scope: {
            data: '=',
            displayType: '@?',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/registration/directives/userInputs/userInputPicture.html',
        controller: [
            '$scope', 'FileUploader', 'ExifService', function($scope, FileUploader, ExifService) {
                $scope.rawImageJustUploaded = false;
                $scope.fileUploader = new FileUploader();

                $scope.orientation = 1;
                $scope.getProfileImageClass = function() {
                    var cssClass = {
                        'profile-image': true,
                    };
                    cssClass['rotated-orientation-' + $scope.orientation] = true;
                    return cssClass;
                };

                $scope.getProfileImageStyle = function() {
                    const avatarHashSpecifier = $scope.data.avatarHash ? ('/' + $scope.data.avatarHash) : '';
                    const scaledAvatarURI = '/asset/img/avatars/' + $scope.data.id + avatarHashSpecifier + '/250.jpg';
                    const backgroundURI   = $scope.rawImageJustUploaded ? $scope.rawImageJustUploaded : scaledAvatarURI;
                    const styleArray = {
                        'background-image': 'url(' + backgroundURI + ')'
                    };

                    return styleArray;
                };

                $scope.resetImage = function() {
                    $scope.rawImageJustUploaded = false;
                    $scope.data.avatarURL = null;
                    $scope.fileUploader.clearQueue();
                };

                $scope.loadImage = function() {
                    if ($scope.fileUploader.queue.length === 0) {
                        return;
                    }

                    var reader = new FileReader();
                    reader.readAsDataURL($scope.fileUploader.queue[0]._file);
                    reader.onload = function() {
                        var image = new Image();

                        image.src = reader.result;
                        image.onload = function() {
                            ExifService.getData(image, function() {
                                $scope.$evalAsync(function($scope) {
                                    $scope.orientation = ExifService.getTag(image, 'Orientation');
                                });
                            });
                        };

                        $scope.$evalAsync(function() {
                            $scope.fileUploader.clearQueue();
                            $scope.rawImageJustUploaded = reader.result;
                            $scope.data.set('avatarURL', reader.result);
                            $scope.data.set('hasChangedProfilePicture', true);
                        });
                    };
                };

                $scope.$on('profileSaved', function() {
                    $scope.orientation = 1;
                    $scope.data.set('hasChangedProfilePicture', false);
                });

                $scope.goToNextStep = function() {
                    $scope.$emit('goToNextStep');
                };

                $scope.$watch('fileUploader.queue.length', $scope.loadImage);
            },
        ],
        link: function(scope, element, attrs) {
            scope.element = element;
        },
    };
}

