import {ServicesModule} from 'services/module';
import {Meeting} from './meeting';
import {DeviceLocation} from './deviceLocation';
import {MeetingConnectionService} from './meetingConnectionService';
import {Feedback} from './feedback';
import {ChatService} from './chatService';
import {PaypalForm} from './paypalForm';
import {Payments} from './payments';
import {UserDashboardService} from './userDashboardService';
import {VoipConsultationService} from './voipConsultationService';
import {VideoConsultationService} from './videoConsultationService';
import {ProfileLinkedinSyncService} from './profileLinkedinSyncService';
import {Profile} from 'services/profile';
import {VisitorService} from 'services/visitorService';
import {AlgorithmService} from 'services/algorithmService';
import {ExpertsService} from 'services/expertsService';
import {TimezonesService} from 'services/timezonesService';
import {NavigationService} from 'services/navigationService';
import {RegistrationService} from 'services/registrationService';
import {AvailabilityService} from 'services/availabilityService';
import {UserEngagementService} from 'services/userEngagementService';
import {ExifService} from 'services/exifService';
import {CustomInputService} from 'services/customInputService';

export default ServicesModule.
    service('Meeting', Meeting).
    service('DeviceLocation', DeviceLocation).
    service('MeetingConnectionService', MeetingConnectionService).
    service('Feedback', Feedback).
    service('ChatService', ChatService).
    service('PaypalForm', PaypalForm).
    service('Payments', Payments).
    service('UserDashboardService', UserDashboardService).
    service('VoipConsultationService', VoipConsultationService).
    service('VideoConsultationService', VideoConsultationService).
    service('ProfileLinkedinSyncService', ProfileLinkedinSyncService).

    service('Profile', Profile).
    service('VisitorService', VisitorService).
    service('AlgorithmService', AlgorithmService).
    service('ExpertsService', ExpertsService).
    service('TimezonesService', TimezonesService).
    service('NavigationService', NavigationService).
    service('RegistrationService', RegistrationService).
    service('AvailabilityService', AvailabilityService).
    service('UserEngagementService', UserEngagementService).
    service('ExifService', ExifService).
    service('CustomInputService', CustomInputService);
