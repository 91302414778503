import {whitelabelling as Whitelabelling} from 'whitelabelling';
import {DonationPrompt} from '../../../model/donationPrompt';

export const MeetingState_Consumer_Complete = [
    '$scope', '$routeParams', '$q', 'Feedback', function($scope, $routeParams, $q, Feedback) {
        $scope.template = Evisors.config.cdnRootWhitelabel + 'asset/partials/consultations/meetingStates/consumer/complete.html';
        $scope.feedbackData = null;
        $scope.emailedFeedback = false;

        $scope.hideEmailSent = function() {
            $scope.emailedFeedback = false;
        };

        $scope.emailFeedback = function() {
            Feedback.emailFeedback().then(function() {
                $scope.emailedFeedback = true;
            });
        };

        var checkState = function() {
            Feedback.getMeta().then(function() {
                if (Feedback.isExpertFeedbackRequired() && !Feedback.isExpertFeedbackCompleted()) {
                    prepareWithFeedback();
                } else {
                    prepareSansFeedback();
                }
            });
        };

        var prepareWithFeedback = function() {
            Feedback.getExpertFeedback().then(function(feedback) {
                $scope.feedbackData = feedback;
                $scope.template = Evisors.config.cdnRootWhitelabel + 'asset/partials/consultations/meetingStates/consumer/complete_feedback.html';

                // after the form is saved
                $scope.feedbackData.bind('sync', function() {
                    checkState();
                });
            });
        };

        var prepareSansFeedback = function() {
            $scope.template = Evisors.config.cdnRootWhitelabel + 'asset/partials/consultations/meetingStates/consumer/complete.html';
            $scope.completedFeedback = Feedback.isExpertFeedbackCompleted();
            $scope.feedbackReady = Feedback.isConsumerFeedbackCompleted();

            if (Whitelabelling.isAMP) {
                $scope.donationPrompt = new DonationPrompt({type: 'feedback'});
                $q.when($scope.donationPrompt.fetch()).then(function() {
                    if ($scope.donationPrompt.target) {
                        $scope.showDonationPrompt = true;
                    }
                });
            }

            if ($scope.completedFeedback) {
                Feedback.getExpertFeedback().then(function(feedback) {
                    $scope.feedbackData = feedback;

                    if ($routeParams.action && $routeParams.action === 'show-feedback') {
                        $scope.showSubmittedFeedback = true;
                    }
                });
            }
        };

        checkState();
    },
];
