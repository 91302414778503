import {whitelabelling as Whitelabelling} from 'whitelabelling';
import * as _ from 'underscore';
import {GeneralAvailabilities} from '../../collection/generalAvailabilities';

export function userInputAvailability() {
    return {
        restrict: 'E',
        scope: {
            data: '=',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel +
            'ng-template/partials/registration/directives/userInputs/userInputAvailability.tpl.html',
        controller: [
            '$scope', function($scope) {
                $scope.whitelabelling = Whitelabelling;

                $scope.clearErrors = function() {
                    $scope.hasErrors = false;
                };
                $scope.checkIfInputIsOk = function() {
                    $scope.hasErrors = $scope.data.length === 0;
                };
                $scope.$on('showErrors', function() {
                    $scope.checkIfInputIsOk();
                });

                // first we build an exhaustive list of every hour of every day
                var allTimes = new GeneralAvailabilities();
                for (var day = 0; day <= 6; day++) {
                    for (var hour = 0; hour <= 23; hour++) {
                        allTimes.add(new allTimes.model({
                            hour: hour,
                            day: day,
                        }, {parse: true}));
                    }
                }

                // 0 == Monday, 6 == Sunday
                var weekdays = allTimes.where(function(ava) {
                    return ava.get('day') < 5;
                });

                // here we construct the buttons we want to show on the page
                // as well as what time periods they represent
                $scope.hasSelectedAll = false;
                $scope.options = [
                    {
                        label: 'Weekday Mornings',
                        isSelected: false,
                        isSelectAll: false,
                        times: _.filter(weekdays, function(ava) {
                            // (6:00am to 11:00am)
                            return ava.get('hour') >= 6 && ava.get('hour') <= 11;
                        }),
                    },
                    {
                        label: 'Weekday Afternoons',
                        isSelected: false,
                        isSelectAll: false,
                        times: _.filter(weekdays, function(ava) {
                            // (12:00pm to 5:00pm)
                            return ava.get('hour') >= 12 && ava.get('hour') <= 17;
                        }),
                    },
                    {
                        label: 'Weekday Evenings',
                        isSelected: false,
                        isSelectAll: false,
                        times: _.filter(weekdays, function(ava) {
                            // (6:00pm to 11:00pm)
                            return ava.get('hour') >= 18 && ava.get('hour') <= 23;
                        }),
                    },
                    {
                        label: 'Weekends',
                        isSelected: false,
                        isSelectAll: false,
                        times: allTimes.where(function(ava) {
                            return ava.get('day') >= 5 && ava.get('hour') >= 8 && ava.get('hour') <= 23;
                        }),
                    },
                    {
                        label: 'Anytime is fine',
                        isSelected: false,
                        isSelectAll: true,
                        times: allTimes.where(function(ava) {
                            return ava.get('hour') >= 8 && ava.get('hour') <= 23;
                        }),
                    },
                ];

                // this function handles updating the state of our associated data based on what
                // option was selected / deselected
                $scope.selectOption = function(o) {
                    o.isSelected = !o.isSelected;

                    $scope.hasSelectedAll = o.isSelectAll && o.isSelected;

                    // if we are now selected
                    if (o.isSelected) {
                        if (o.isSelectAll) {
                            // deselect all the others and remove all times
                            _.each(_.where($scope.options, {isSelectAll: false}), function(item) {
                                item.isSelected = false;
                            });
                        } else {
                            // deselect the select all option
                            var allOptions = _.findWhere($scope.options, {isSelectAll: true});
                            allOptions.isSelected = false;
                        }
                        // add our times to the list
                        addTimes(o.times);
                    } else { // we are now not selected

                        // if they're deselecting the select all, we empty the array of times
                        if (o.isSelectAll) {
                            $scope.data.reset();
                        } else {
                            // we simply remove this times array
                            removeTimes(o.times);
                        }
                    }

                    $scope.checkIfInputIsOk();
                };

                // one day we might not have such simple actions...
                function addTimes(timesArray) {
                    $scope.data.add(timesArray);
                }

                function removeTimes(timesArray) {
                    $scope.data.remove(timesArray);
                }
            },
        ],
    };
}

