import * as Flatpickr from 'flatpickr';
import * as moment from 'moment-timezone';

export const datetimeInput = [
    'TimezonesService',
    function(TimezonesService) {
        return {
            restrict: 'A',
            require: 'ngModel',
            scope: {
                disabled: '=',
                readonly: '=',
                minDate: '=',
                maxDate: '=',
                toTimezone: '<?',
            },
            link: function(scope, element, attrs, ngModel) {
                const minuteIncrement = parseInt(attrs.step) || 1;
                // round down to nearest increment from 0 hour
                const minDate = attrs.past !== 'false' ? null : 'today';
                const minTime = attrs.past !== 'false' ? null : moment().format('HH:mm');

                const timeEnabled = (attrs.step !== undefined);

                const pickr = new Flatpickr(element[0], {
                    wrap: true,
                    utc: true,
                    minDate: minDate,
                    minTime: minTime,
                    allowInput: !scope.readonly,
                    defaultDate: null,
                    enableTime: timeEnabled,
                    altInput: true,
                    minuteIncrement: minuteIncrement,
                    altFormat: timeEnabled ? 'Y-m-d h:i K' : 'Y-m-d',
                    onOpen: function() {
                        if (scope.minDate) {
                            pickr.set('minDate', scope.minDate);
                        }
                        if (scope.maxDate) {
                            pickr.set('maxDate', scope.maxDate);
                        }
                    },
                    onChange: function(selectedDates) {
                        scope.$apply(function() {
                            // the date object is going to account for the TimezonesService shift and store device time
                            ngModel.$setViewValue(TimezonesService.getDateAndTimeWithOtherTimezone(selectedDates[0], scope.toTimezone));
                        });
                    },
                });

                ngModel.$render = function() {
                    if (ngModel.$modelValue) {
                        pickr.selectedDates = [new Date(ngModel.$modelValue)];
                        // the display time is going to be in the TimezonesService timezone
                        const formatted = pickr.formatDate(
                            TimezonesService.getDateAndTimeInOtherTimezone(ngModel.$modelValue, scope.toTimezone),
                            pickr.config.altFormat);
                        $(pickr.altInput).val(formatted);
                    } else {
                        pickr.selectedDates = [];
                        $(pickr.altInput).val('');
                    }
                };
            },
            templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/datetimeInput.html',
        };
    },
];
