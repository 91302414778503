import {whitelabelling as Whitelabelling} from 'whitelabelling';
import {JobOpeningSearchResults} from '../collection/jobOpeningSearchResults';
import {JobBoardSearchFilters as JobOpeningSearchFilters} from '../collection/jobBoardSearchFilters';
import {JobOpeningRelatedExperts} from '../collection/jobOpeningRelatedExperts';

export const JobsBoardController = [
    '$scope', '$rootScope', '$location', '$timeout', '$window', '$q', '$http', 'RegistrationService', 'VisitorService',
    function($scope, $rootScope, $location, $timeout, $window, $q, $http, RegistrationService, VisitorService) {
        $window.document.title = 'Job Openings';
        $rootScope.pageClass = 'jobs-board-container search-container';

        var startingQuery = $location.search();

        $scope.querying = false;
        $scope.loading = true;
        $scope.numberPerPage = 10;
        $scope.page = 0;
        $scope.queryCount = 0;
        $scope.showAdvancedFilters = false;
        $scope.whitelabelling = Whitelabelling;

        $scope.userMatchCriteria = null;
        var matchCriteriaPromise = $http({
            'method': 'GET',
            'url': '/jobs/user-match-criteria.json',
        });

        $scope.relatedExpertsMap = new Map();

        $scope.filters = new JobOpeningSearchFilters();
        $scope.results = new JobOpeningSearchResults();

        $scope.isConsumer = false;

        $scope.canPostJobs = false;

        VisitorService.getVisitor().then(function(visitor) {
            $scope.isConsumer = visitor.get('isConsumer');
            if (visitor.get('isExpert') || visitor.get('isManagerUser') || visitor.get('isPartnerUser')) {
                $scope.canPostJobs = true;
            }
        });

        $scope.performSearch = function() {
            $scope.results.reset();
            $scope.querying = true;
            $q.when($scope.results.search($scope.filters, $scope.numberPerPage, $scope.page)).then(function() {
                // After 1/10th of a second; try and load related experts. (Give that page time to render!)
                $timeout($scope.refreshRelatedExperts, 100);

                var data = $scope.filters.convertToQueryObject();
                $scope.querying = false;

                // we take this moment to update our query count on the advanced-toggle-icon
                var count = 0;
                _.each(data, function(d, k) {
                    count += Array.isArray(d) ? d.length : !!d;
                });
                $scope.queryCount = count;

                // rebuild our parameters
                // we call replace so that we don't add a new history event (so our back button still navigates away
                // from search)
                $location.replace();
                $location.search(data);
            }, function() {
                $scope.querying = false;
            });
        };

        $scope.relatedExpertsCanNext = function(jO) {
            return (jO.relatedExperts && (jO.relatedExperts.length - 2 >= jO.showRelated));
        };
        $scope.relatedExpertsCanPrev = function(jO) {
            return (jO.relatedExperts && jO.showRelated > 0);
        };

        $scope.nextRelatedExperts = function(jO) {
            if ($scope.relatedExpertsCanNext(jO)) {
                jO.showRelated++;
            }
        };
        $scope.previousRelatedExperts = function(jO) {
            if ($scope.relatedExpertsCanPrev(jO)) {
                jO.showRelated--;
            }
        };

        $scope.relatedExpertsOffsetClass = function(jO, isFirst) {
            var classObj = {};
            if (isFirst) {
                classObj['related-expert-offset-' + jO.showRelated] = true;
            }
            return classObj;
        };

        $q.all([
            $scope.filters.fetch(),
            matchCriteriaPromise,
        ]).then(function(promiseResponses) {
            $scope.userMatchCriteria = promiseResponses[1].data.data;

            $scope.$emit('controllerLoaded', true);
            $scope.loading = false;

            $scope.filters.each(function(filter) {
                switch (filter.key) {
                    case 'employers':
                        filter.set('prefixHook', RegistrationService.employersPrefixComplete);
                        break;
                    case 'employers-jobs':
                        filter.set('prefixHook', RegistrationService.employersJobsPrefixComplete);
                        break;
                    case 'typesOfRole':
                        filter.set('prefixHook', RegistrationService.rolesPrefixComplete);
                        break;
                    case 'industries':
                        filter.set('prefixHook', RegistrationService.industriesPrefixComplete);
                        break;
                    default:
                        // do nothing
                        break;
                }
            });

            // add the keyword field
            $scope.keywordFilter = $scope.filters.findWhere({key: 'keyword'});
            if ($scope.keywordFilter) {
                $scope.keywordFilter.set({
                    noSection: true,
                    inputPlaceholder: 'Search by keyword',
                    ignoreInLoop: true,
                });
            }

            $scope.filters.loadFromQueryObject(startingQuery);
            $scope.filters.on('change:value', () => {
                $scope.page = 0;
                $scope.performSearch();
            });

            // this will handle the initial search because this will always fire a change when the watcher is bound
            $scope.$watch('page', $scope.performSearch);

            // this is kind of a nice-to-have which makes sure our subfilters have the correct count
            // it needs to happen after some other stuff loads, so I put 2 second timeout on it
            $timeout(function() {
                $scope.filters.each(function(f) {
                    f.refreshValue(true);
                });
            }, 2000);
        });

        $scope.refreshRelatedExperts = function() {
            $scope.results.forEach(function(item, key) {
                if (!item.relatedExperts && !$scope.relatedExpertsMap.has(item.id)) {
                    var relatedExperts = new JobOpeningRelatedExperts();
                    relatedExperts.setJob(item.id);
                    $q.when(relatedExperts.fetch()).then(function() {
                        $scope.relatedExpertsMap.set(item.id, relatedExperts);
                        if ($scope.results.at(key)) {
                            $scope.results.at(key).relatedExperts = relatedExperts;
                        }
                    });
                } else if ($scope.relatedExpertsMap.has(item.id)) {
                    if ($scope.results.at(key)) {
                        $scope.results.at(key).relatedExperts = $scope.relatedExpertsMap.get(item.id);
                    }
                }
            });
        };

        $scope.toggleShowRelated = function(jobOpening) {
            if (jobOpening.showRelated >= 0) {
                jobOpening.showRelated = -1;
            } else {
                jobOpening.showRelated = 0;
            }
        };

    },
];
