import {whitelabelling as Whitelabelling} from 'whitelabelling';

export function groupMember() {
    return {
        restrict: 'E',
        scope: {
            'member': '<',
            'last': '<',
            'selectedGroup': '<',
        },
        templateUrl: '/asset/partials/user/directives/groupMember.html',
        replace: true,
        controller: ['$scope', function($scope) {
            $scope.expertNoun = Whitelabelling.expertNoun;
            $scope.consumerNoun = Whitelabelling.consumerNoun;
            $scope.showMessaging = !Whitelabelling.settings['disable-user-messaging'];
        }]
    };
}
