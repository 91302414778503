import {Navigations} from '../collection/navigations';
import {NavBadge} from '../model/navBadge';
import {whitelabelling as Whitelabelling} from 'whitelabelling';

const ONE_MINUTE = 60000;

export function siteBanner() {
    return {
        restrict: 'E',
        scope: {},
        replace: true,
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/siteBanner.html',
        controller: [
            '$scope',
            '$rootScope',
            '$location',
            '$timeout',
            '$interval',
            '$window',
            '$q',
            'VisitorService',
            function($scope, $rootScope, $location, $timeout, $interval, $window, $q, VisitorService) {
                $scope.isOpened = false;

                $scope.navigations = new Navigations();
                $scope.topLevelNavigations = new Navigations();
                $scope.helpCenterNavigations = new Navigations();
                $scope.navBadge = new NavBadge();
                $scope.creditsAvailable = 0;
                $scope.whitelabelling = Whitelabelling;

                $scope.logoLink = '/';

                $q.all([
                    VisitorService.getVisitor(),
                    $scope.navigations.fetch({data: {isAdminApp: $rootScope.isAdminApp}}),
                    $scope.navBadge.fetch(),
                ]).then(function([Visitor]) {
                    $scope.user = Visitor.get('isUser') ? Visitor.get('user') : null;

                    if ($scope.user) {
                        $scope.logoLink = '/welcome';
                        if ($scope.user.needsFairReceptionPage) {
                            $scope.logoLink = '#!/reception';
                        }
                    }
                    $scope.isConsumer = Visitor.get('isConsumer');
                    const creditsAvailable = Visitor.get('creditsAvailable');
                    if (creditsAvailable === 1) {
                        $scope.creditsAvailable = creditsAvailable + ' credit left this month';
                    } else if(creditsAvailable !== null) {
                        $scope.creditsAvailable = creditsAvailable + ' credits left this month';
                    }
                    $scope.topLevelNavigations.push($scope.navigations.where({isTopLevel: true}));
                    $scope.helpCenterNavigations = $scope.navigations.filter(isHelpCenterItem);
                    $scope.navigations = new Navigations($scope.navigations.where(item => !isHelpCenterItem(item)));

                    // refresh our nav badges every minute
                    var interval = $interval(function() {
                        refreshBadges().then(null, function() {
                            // if there's ever an error, just stop polling
                            $interval.cancel(interval);
                        });
                    }, ONE_MINUTE);
                });

                function closeDropdown() {
                    $scope.isOpened = false;
                    $scope.$apply();
                }

                $scope.$on('refreshNavBadges', function() {
                    // we wait a full second because we need to make sure our "mark as read" gives time for Layer to
                    // process it before us
                    $timeout(refreshBadges, 1000);
                });

                // watch for opening / closing the control window
                $scope.$watch('isOpened', function(newVal) {
                    if (newVal === true) {
                        // we do a timeout here so that the click event which opened the filter doesn't then
                        // immediately close it
                        $timeout(function() {
                            $window.addEventListener('click', closeDropdown);
                        }, 0);

                    } else if (newVal === false) {
                        $window.removeEventListener('click', closeDropdown);
                    }
                });

                // watch for path changes
                $scope.$watch(function() {
                    return $location.path();
                }, function(newVal) {
                    // always close the nav dropdown if the page changes
                    $scope.isOpened = false;
                    $scope.navigations.markSelectedByUrl(newVal);
                    $scope.topLevelNavigations.markSelectedByUrl(newVal);
                });

                function refreshBadges() {
                    return $q.when($scope.navBadge.fetch());
                }
            },
        ],
    };
}

function isHelpCenterItem(item) {
    return item.class && item.class.split(' ').indexOf('nav-help-item') !== -1;
}
