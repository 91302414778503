import {Backbone} from 'Vertebrae';

export const AbstractProfileCollection = Backbone.Collection.extend({
    _name: null, // this must be specified by an inheriting collection
    model: null, // this must be specified by an inheriting collection
    urlRoot: null, // similar to Backbone's native model urlRoot, this is the relative address after
                   // /profile/[userid]
    url: function() {
        return '/profile/' + this.userid + this.urlRoot;
    },
    setUser: function(u) {
        this.userid = u.get('id');
    },
});

