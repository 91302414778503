import {whitelabelling as Whitelabelling} from 'whitelabelling';

/**
 * This directive packages the No Spots Left this month panel
 * Use like:
 * <no-spots-left></no-spots-left>
 */
export function noSpotsLeft() {
    return {
        restrict: 'E',
        scope: {
            myHistory: '=',
            reason: '=',
            allowEmailMe: '=',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/directives/noSpotsLeft.html',
        controller: [
            '$scope', 'Profile', function($scope, Profile) {
                $scope.whitelabelling = Whitelabelling;

                $scope.buttonText = Whitelabelling.expertNoun + ' not currently available';
                if (!$scope.allowEmailMe) {
                    $scope.buttonText = 'Booking Not Allowed';
                }

                $scope.$watch('myHistory.notifyMeWhenAvailable', function(newVal, oldVal) {
                    if (oldVal !== undefined && newVal !== oldVal) {
                        Profile.notifyMeWhenAvailable($scope.myHistory.OtherUser.expertid, newVal);
                    }
                });
            },
        ],
    };
}

