export const ChatController = [
    '$scope', '$rootScope', 'ChatService', 'Meeting', '$http',
    function($scope, $rootScope, ChatService, Meeting, $http) {
        var lastMeetingBeforeDeploy = 46252;

        $scope.chatLoaded = false;
        $scope.legacyMessages = [];

        var showErrorMessage = function(message) {
            $scope.error = message;
        };

        Meeting.getByRouteParams().then(function(meeting) {
            // if we're in a state before the expert has been notified, we just return
            // do not initialize
            if (!meeting.get('MeetingState').expertHasBeenNotified()) {
                var message = 'This chat box is disabled for now. Please ';

                switch (meeting.get('MeetingState').get('status')) {
                    case 3:
                        message += 'complete payment to continue.';
                        break;
                    case 0:
                        message += 'verify your email address to continue.';
                        break;
                    default :
                        message += 'follow the instructions to the left.';
                        break;
                }

                showErrorMessage(message);
                return false;
            }

            var meetingId = meeting.get('id');

            // If this is an old meeting, load any legacy messages, NOT from our chat provider.
            if (meetingId < lastMeetingBeforeDeploy) {
                var legacyMessagesUrl = '/api/chat/' + meetingId + '/legacyMessages.json';
                $http.get(legacyMessagesUrl).then(function(response) {
                    // Successfully got Legacy Messages..
                    $scope.legacyMessages = response.data;
                }, function(error) {
                    // Failed to get Legacy Messages... Oh well.
                    console.error(error);
                });
            }

            let otherUser;
            if (meeting.visitorIsConsumer) {
                otherUser = meeting.Expert;
            } else {
                otherUser = meeting.Consumer;
            }

            ChatService.getConversationWithUser(otherUser.get('id')).then(function(conversation) {
                ChatService.getConversation(conversation.conversationid).then(function(channel) {
                    $scope.$evalAsync(function() {
                        $scope.layerConversation = channel;
                        $scope.conversation = conversation;
                        $scope.chatLoaded = true;
                    });
                }, function(error) {
                    console.log(error);
                    $scope.chatLoaded = true;
                    showErrorMessage("Error: " + error);
                });
            }, function (error) {
                // TODO: Maybe put in a "retry" button.
                $scope.chatLoaded = true;
                showErrorMessage("Error: " + error);
            });

        // Handle height
        });
    }
];
