import {whitelabelling as Whitelabelling} from 'whitelabelling';

export function userInputSingleEducation() {
    return {
        restrict: 'E',
        scope: {
            data: '=', // a single education model
            delta: '=',
            showSchoolAndGradDate: '=?',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/registration/directives/userInputs/userInputSingleEducation.html',
        controller: [
            '$scope', 'RegistrationService', function($scope, RegistrationService) {
                $scope.whitelabelling = Whitelabelling;

                $scope.concentrationSelector = {
                    value: null,
                };
                $scope.showAddConcentration = $scope.data.Concentrations && $scope.data.Concentrations.length > 0;

                if ($scope.showSchoolAndGradDate) {
                    $scope.schoolsHook = RegistrationService.schoolsPrefixComplete;
                }

                $scope.degreesHook = RegistrationService.degreesPrefixComplete;
                $scope.concentrationsHook = RegistrationService.concentrationsPrefixComplete;

                $scope.errors = {};

                // because we can add multiple concentrations, we have the intermediate value to store the
                // currently selecting one
                $scope.$watch('concentrationSelector.value', function(newVal) {
                    if (newVal) {
                        // no ids for concentrations
                        newVal.set('id', null);
                        $scope.data.Concentrations.add(newVal);
                        $scope.concentrationSelector.value = '';
                        $scope.errors.Concentrations = null;
                        $scope.showAddConcentration = true;
                    }
                });

                $scope.checkForErrors = function(specificProperty) {
                    var errors = $scope.data.validateData();
                    if (specificProperty) {
                        $scope.errors = {};
                        $scope.errors[specificProperty] = errors[specificProperty];
                    } else {
                        $scope.errors = errors;
                    }
                };

                $scope.$on('showErrors', function() {
                    $scope.checkForErrors();
                });

                $scope.removeConcentration = function(c) {
                    $scope.data.Concentrations.remove(c);
                };

                $scope.onBlurSchool = function() {
                    return $scope.checkForErrors('School');
                };
                $scope.onBlurDegree = function() {
                    return $scope.checkForErrors('Degree');
                };
                $scope.onBlurConcentrations = function() {
                    return $scope.checkForErrors('Concentrations');
                };
            },
        ],
    };
}

