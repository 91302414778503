import * as _ from 'underscore';
import * as moment from 'moment-timezone';
import {AlgorithmUser} from 'model/algorithmUser';
import * as AlgorithmCriteria from 'algorithmCriteria';
import {AlgorithmUsers} from 'collection/algorithmUsers';
import {getTimeUnits} from 'configurations/utilities';
import {AlgorithmHelper} from 'algorithmHelper';

const ONE_YEAR = 31536000;
const TODAY = new Date();
TODAY.setHours(0, 0, 0, 0);

export const AlgorithmService = [
    '$q', 'CustomInputService',
    function($q, CustomInputService) {
        /**
         * This is criteria that is not compared to the consumer, but just generally scored
         * @type {string[]}
         */
        var standAloneCriteria = [
            AlgorithmCriteria.CRITERIA_RESPONDS_QUICKLY,
            AlgorithmCriteria.CRITERIA_HAS_PICTURE,
            AlgorithmCriteria.CRITERIA_EXPERIENCED_EXPERT,
            AlgorithmCriteria.CRITERIA_POSITIVELY_REVIEWED,
            AlgorithmCriteria.CRITERIA_JOINED_RECENTLY,
            AlgorithmCriteria.CRITERIA_RECENTLY_LOGGED_IN,
            AlgorithmCriteria.CRITERIA_NO_RECENT_BOOKINGS,
            AlgorithmCriteria.CRITERIA_HIGHLY_AVAILABLE,
        ];

        const criteriaLabels = AlgorithmHelper.criteriaLabels();
        CustomInputService.getCustomInputsForAlgorithm().then((customInputs) => {
            AlgorithmHelper.injectCustomInputLabels(customInputs);
            retVal.resetCriteria();
        });

        var retVal = {
            allCriteria: _.keys(criteriaLabels),
            setCriteria: function(newCriteria) {
                retVal.allCriteria = newCriteria;
            },
            resetCriteria: function() {
                retVal.allCriteria = _.keys(criteriaLabels);
            },
            /**
             * @param user A User model
             * @param weights An AlgorithmWeightsCollection
             */
            search: function(user, weights) {
                var deferred = $q.defer();
                var data;

                var results = new AlgorithmUsers();
                results.setUser(user);

                if (weights) {
                    data = {
                        data: {
                            weights: weights.flatten(),
                        },
                    };
                }

                results.fetch(data).then(function() {
                    deferred.resolve(results);
                }, function(responseObj) {
                    var message = '';
                    if (responseObj.data && responseObj.data.error) {
                        message = responseObj.data.error;
                    } else if (responseObj.responseText) {
                        message = responseObj.responseText;
                    }
                    deferred.reject(message);
                });

                return deferred.promise;
            },

            standAloneCriteria: standAloneCriteria,

            getTopMatchCriteriaBetweenUsers: function(expert, consumer, topMatchData, comparedOnly) {
                var keysToInclude;
                if (comparedOnly) {
                    if (comparedOnly === true) {
                        keysToInclude = _.difference(retVal.allCriteria, standAloneCriteria);
                    } else if (Array.isArray(comparedOnly)) {
                        keysToInclude = comparedOnly;
                    } else {
                        // don't know what this is, you messed up, just use all keys
                        console.error('Invalid criteria filter, using \'all\'');
                    }
                } else {
                    keysToInclude = retVal.allCriteria;
                }

                // We're going to build our criteriaList (and what values to show)
                var list = [];
                _.each(keysToInclude, function(key) {
                    // if this is not a key we're including, skip it
                    var matches = getMatches(expert, consumer, key, topMatchData);
                    list.push({
                        key: key,
                        matches: matches,
                        prefix: getPrefix(expert, key, matches),
                        suffix: getSuffix(expert, key, matches),
                        isPartial: topMatchData[key] < 80,
                    });
                });

                return list;
            },
        };

        return retVal;

        /**
         * This function determines what data was should show on the user card
         * @param {*} consumer
         * @param {*} expert
         * @param {string} criteriaKey
         * @param {*} topMatchData
         * @return {string[]}
         */
        function getMatches(expert, consumer, criteriaKey, topMatchData) {
            if (!_.has(topMatchData, criteriaKey)) {
                return [];
            }
            var consumerKey = AlgorithmUser.getUserKeyFromCriteriaKey(criteriaKey, false);
            var consumerData = consumer.get(consumerKey);

            var expertKey = AlgorithmUser.getUserKeyFromCriteriaKey(criteriaKey, true);
            var expertData = expert.get(expertKey);

            if (_.contains(standAloneCriteria, criteriaKey)) {
                return getStandAloneMatch(expertData, criteriaKey);
            } else {
                return getComparedMatch(expertData, consumerData, criteriaKey);
            }
        }

        /**
         * How to format data that is not compared to the consumer
         * @param {*} data
         * @param {string} criteriaKey
         * @return {string[]}
         */
        function getStandAloneMatch(data, criteriaKey) {
            var matches = null;
            var units;
            var diff;

            if (!data && criteriaKey !== AlgorithmCriteria.CRITERIA_NO_RECENT_BOOKINGS) {
                return matches;
            }

            switch (criteriaKey) {
                case AlgorithmCriteria.CRITERIA_RESPONDS_QUICKLY:
                    units = getTimeUnits(data, true);
                    matches = units.time + ' ' + units.timeUnits;
                    break;
                case AlgorithmCriteria.CRITERIA_HAS_PICTURE :
                    matches = 'Has photo';
                    break;
                case AlgorithmCriteria.CRITERIA_EXPERIENCED_EXPERT :
                    matches = data;
                    break;
                case AlgorithmCriteria.CRITERIA_POSITIVELY_REVIEWED :
                    matches = data + '%';
                    break;
                case AlgorithmCriteria.CRITERIA_JOINED_RECENTLY :
                case AlgorithmCriteria.CRITERIA_RECENTLY_LOGGED_IN :
                    diff = parseInt((Date.now() - data.getTime()) / 1000);
                    units = getTimeUnits(diff);
                    matches = units.time + ' ' + units.timeUnits;
                    break;
                case AlgorithmCriteria.CRITERIA_NO_RECENT_BOOKINGS :
                    if (!data) {
                        matches = '0 consultations completed';
                    } else {
                        diff = parseInt((Date.now() - data.getTime()) / 1000);
                        if (diff > 31536000) { // 0 in last year?
                            matches = '> 1 year';
                        } else {
                            units = getTimeUnits(diff);
                            matches = units.time + ' ' + units.timeUnits;
                        }
                    }
                    break;
                case AlgorithmCriteria.CRITERIA_HIGHLY_AVAILABLE:
                    matches = Math.min(data, 15) + (data > 15 ? '+' : '');
                    break;
            }

            return matches ? [matches] : [];
        }

        /**
         * How to format data that IS compared to the consumer
         * @param {*} userData
         * @param {*} otherData
         * @param {string} criteriaKey
         * @return {string[]}
         */
        function getComparedMatch(userData, otherData, criteriaKey) {
            var matches = [];
            if (!userData || !otherData) {
                return matches;
            }

            switch (criteriaKey) {
                case AlgorithmCriteria.CRITERIA_YEARS_SENIOR:
                    if (userData && otherData) {
                        var diff = parseInt((otherData.getTime() - userData.getTime()) / 1000);
                        if (diff >= ONE_YEAR) {
                            var units = getTimeUnits(diff);
                            if (units.timeUnits !== 'year' && units.timeUnits !== 'years') {
                                // do nothing, not a "match"
                            } else {
                                matches.push(units.time + ' ' + units.timeUnitsShort);
                            }
                        }
                    }
                    break;
                case AlgorithmCriteria.CRITERIA_CURRENT_LOCATION:
                    if (otherData) {
                        matches.push(userData);
                    }
                    break;
                case AlgorithmCriteria.CRITERIA_DESIRED_WORK_LOCATION:
                    if (otherData) {
                        matches = userData;
                    }
                    break;
                case AlgorithmCriteria.CRITERIA_GENDER:
                    if (userData.toLowerCase() === otherData.toLowerCase()) {
                        matches.push(userData);
                    }
                    break;
                case AlgorithmCriteria.CRITERIA_ETHNICITY:
                    // Minorities will match. Currently "minority" means "non-white".
                    if (userData.toLowerCase() !== 'white') {
                        matches.push(userData);
                    }
                    break;
                case AlgorithmCriteria.CRITERIA_GROUPS:
                case AlgorithmCriteria.CRITERIA_DESIRED_INDUSTRY:
                case AlgorithmCriteria.CRITERIA_DESIRED_EMPLOYER:
                case AlgorithmCriteria.CRITERIA_DESIRED_ROLE:
                case AlgorithmCriteria.CRITERIA_CONCENTRATION:
                case AlgorithmCriteria.CRITERIA_DEGREE:
                case AlgorithmCriteria.CRITERIA_LANGUAGE:
                default:
                    matches = _.intersection(otherData, userData);
                    break;
            }

            return matches;
        }

        function getPrefix(user, criteriaKey, matches) {
            if (!matches || !matches.length) {
                return;
            }
            var prefix = null;
            var careerData;
            switch (criteriaKey) {
                case AlgorithmCriteria.CRITERIA_CURRENT_LOCATION:
                    prefix = 'Lives in';
                    break;
                case AlgorithmCriteria.CRITERIA_DESIRED_WORK_LOCATION:
                case AlgorithmCriteria.CRITERIA_DESIRED_EMPLOYER:
                case AlgorithmCriteria.CRITERIA_DESIRED_ROLE:
                case AlgorithmCriteria.CRITERIA_DESIRED_INDUSTRY:
                    careerData = user.getCareerData(criteriaKey, matches);
                    if (careerData) {
                        var timeUnits = getTimeUnits(careerData.duration);
                        prefix = (timeUnits.timeRaw > 0
                            ? (timeUnits.time + ' ' + timeUnits.timeUnitsShort)
                            : '< 1 yr')
                            + ' ' + (criteriaKey === AlgorithmCriteria.CRITERIA_DESIRED_EMPLOYER ? 'at' : 'in');
                    }
                    break;
                case AlgorithmCriteria.CRITERIA_JOINED_RECENTLY:
                    prefix = 'Joined';
                    break;
                case AlgorithmCriteria.CRITERIA_RECENTLY_LOGGED_IN:
                    prefix = 'Logged in';
                    break;
                case AlgorithmCriteria.CRITERIA_EXPERIENCED_EXPERT:
                    prefix = 'Completed';
                    break;
                case AlgorithmCriteria.CRITERIA_LANGUAGE:
                    prefix = 'Speaks';
                    break;
                case AlgorithmCriteria.CRITERIA_CONCENTRATION:
                    prefix = 'Studied';
                    break;
                case AlgorithmCriteria.CRITERIA_NO_RECENT_BOOKINGS:
                case AlgorithmCriteria.CRITERIA_GROUPS:
                case AlgorithmCriteria.CRITERIA_RESPONDS_QUICKLY:
                case AlgorithmCriteria.CRITERIA_HAS_PICTURE:
                case AlgorithmCriteria.CRITERIA_POSITIVELY_REVIEWED:
                case AlgorithmCriteria.CRITERIA_YEARS_SENIOR:
                case AlgorithmCriteria.CRITERIA_HIGHLY_AVAILABLE:
                case AlgorithmCriteria.CRITERIA_ETHNICITY:
                case AlgorithmCriteria.CRITERIA_GENDER:
                case AlgorithmCriteria.CRITERIA_DEGREE:
                default:
                    // do nothing
                    break;
            }

            return prefix;
        }

        function getSuffix(user, criteriaKey, matches) {
            if (!matches || !matches.length) {
                return;
            }
            var suffix = null;
            var careerData;

            // noinspection FallThroughInSwitchStatementJS
            switch (criteriaKey) {
                case AlgorithmCriteria.CRITERIA_RESPONDS_QUICKLY:
                    suffix = 'avg response time';
                    break;
                case AlgorithmCriteria.CRITERIA_EXPERIENCED_EXPERT:
                    suffix = 'consultation' +
                        (user.get(AlgorithmUser.getUserKeyFromCriteriaKey(criteriaKey, true)) !== 1 ? 's' : '');
                    break;
                case AlgorithmCriteria.CRITERIA_HIGHLY_AVAILABLE:
                    suffix = 'slots per month';
                    break;
                case AlgorithmCriteria.CRITERIA_RECENTLY_LOGGED_IN:
                case AlgorithmCriteria.CRITERIA_JOINED_RECENTLY:
                    suffix = 'ago';
                    break;
                case AlgorithmCriteria.CRITERIA_POSITIVELY_REVIEWED:
                    suffix = 'net promoter score';
                    break;
                case AlgorithmCriteria.CRITERIA_DESIRED_ROLE:
                    suffix = 'role ';
                // no break
                case AlgorithmCriteria.CRITERIA_DESIRED_WORK_LOCATION:
                case AlgorithmCriteria.CRITERIA_DESIRED_INDUSTRY:
                case AlgorithmCriteria.CRITERIA_DESIRED_EMPLOYER:
                    // not sure if fall through, got to at least have an empty string
                    suffix = suffix ? suffix : '';
                    careerData = user.getCareerData(criteriaKey, matches);
                    if (careerData && careerData.startDate && careerData.endDate) {
                        suffix += moment.tz(careerData.startDate, 'UTC').format('\'YY')
                            + '&nbsp;-&nbsp;'
                            + (careerData.endDate < TODAY
                                ? moment.tz(careerData.endDate, 'UTC').format('\'YY')
                                : 'Present');
                    }
                    break;
                case AlgorithmCriteria.CRITERIA_GROUPS:
                    suffix = 'group';
                    break;
                case AlgorithmCriteria.CRITERIA_YEARS_SENIOR:
                    suffix = 'more experienced';
                    break;
                case AlgorithmCriteria.CRITERIA_NO_RECENT_BOOKINGS:
                    var expertData = user.get(AlgorithmUser.getUserKeyFromCriteriaKey(criteriaKey, true));
                    if (expertData) {
                        suffix = 'since last request';
                    }
                    break;
                case AlgorithmCriteria.CRITERIA_CURRENT_LOCATION:
                case AlgorithmCriteria.CRITERIA_HAS_PICTURE:
                case AlgorithmCriteria.CRITERIA_DEGREE:
                case AlgorithmCriteria.CRITERIA_CONCENTRATION:
                case AlgorithmCriteria.CRITERIA_LANGUAGE:
                case AlgorithmCriteria.CRITERIA_ETHNICITY:
                case AlgorithmCriteria.CRITERIA_GENDER:
                default:
                    // do nothing
                    break;
            }

            return suffix;
        }
    },
];
