import {whitelabelling as Whitelabelling} from 'whitelabelling';
import {MyHistory} from '../model/myHistory';
import {an} from 'configurations/utilities';
import {Services} from '../collection/services';

export const ProfileController = [
    'paramType',
    '$scope',
    '$rootScope',
    '$location',
    '$window',
    '$q',
    '$routeParams',
    '$route',
    'Profile',
    'VisitorService',
    'TimezonesService',
    'AvailabilityService',
    'CustomInputService',
    function(
        paramType, $scope, $rootScope, $location, $window, $q, $routeParams, $route, Profile, VisitorService, TimezonesService, AvailabilityService, CustomInputService) {
        $rootScope.pageClass = 'profile-container';
        $scope.whitelabelling = Whitelabelling;
        $scope.user = {};
        $scope.employments = {};
        $scope.educations = {};
        $scope.reviews = {};
        $scope.services = {};
        $scope.languages = {};
        $scope.visitor = {};
        $scope.assessments = {};
        $scope.jobOpenings = {};
        $scope.customInputs = [];
        $scope.resources = [];
        $scope.booths = [];
        $scope.availabilities = [];

        $scope.isMobile = false;
        $scope.showBookingBlackout = false;
        $scope.bookingService = null;
        $scope.bookingTime1 = null;

        $scope.isLoaded = false;
        $scope.showFullPitch = false;
        $scope.showFullBio = false;
        $scope.hasEditAccess = false;

        $scope.hideEducation = Whitelabelling.settings['hideField:education'];
        $scope.hideEmployment = Whitelabelling.settings['hideField:employment'];
        $scope.customizedCareerGoalsLabel = Whitelabelling.settings['customized:careerGoals'];

        $scope.returnUrl = encodeURIComponent($location.url());

        let panelOffset = 0;
        let panelPromise = null;

        $scope.toggleShowPitch = function() {
            $scope.showFullPitch = !$scope.showFullPitch;
        };

        $scope.toggleShowFullBio = function() {
            $scope.showFullBio = !$scope.showFullBio;
        };

        $scope.reviewsToShow =
            $scope.employmentsToShow =
                $scope.educationsToShow =
                    $scope.assessmentsToShow =
                        $scope.jobOpeningsToShow =
                            $scope.defaultShowLimit = 3;
        $scope.servicesToShow = $scope.defaultShowLimit * 2;

        $scope.disableLeftPanelNav = false;
        $scope.loadingPanel = false;
        $scope.availabilityColumns = 3;
        $scope.availabilityRows = [0, 1, 2, 3];
        $scope.weeksAhead = -1;
        $scope.isBookable = true;
        $scope.allowEmailWhenAvailable = true;
        $scope.unbookableReason = null;
        $scope.myHistory = new MyHistory({otherUserid: $routeParams.userid});
        $scope.hasActiveFairs = false;
        $scope.customInputsCount = 0;

        const consumerOnly = paramType === 'consumer';

        $q.all([
            Profile.getUser($routeParams.userid),
            VisitorService.getVisitor(),
            Profile.getFullProfile($routeParams.userid, consumerOnly, false),
            $scope.myHistory.fetch(),
            CustomInputService.fetchVisitorResponses(),
        ]).then(([User, Visitor, Profile, _, CustomInputs]) => {
            $scope.$emit('controllerLoaded', true);
            $scope.user = User;

            $window.document.title = $scope.user.get('firstName') + ' ' + $scope.user.get('lastName');

            $scope.visitor = Visitor;
            $scope.educations = Profile.get('Educations');
            $scope.employments = Profile.get('Employments');
            $scope.reviews = Profile.get('Reviews');
            $scope.languages = Profile.get('Languages');
            $scope.careerGoals = Profile.get('CareerGoals');
            $scope.assessments = Profile.get('Assessments');
            $scope.jobOpenings = Profile.get('JobOpenings');
            $scope.services = Profile.get('Services');
            $scope.affinityGroups = Profile.get('AffinityGroups');
            $scope.customInputs = Profile.get('CustomInputs');
            $scope.resources = Profile.get('Resources');
            $scope.booths = Profile.get('Booths');
            $scope.hasActiveFairs = Profile.get('HasActiveFairs');
            $scope.currentEmployment = getCurrentEmploymentIfAny(Profile.get('Employments'));

            $scope.selectedService = {'value': null};

            $scope.$watch('customInputs', () => {
                $scope.customInputsCount = Object.keys($scope.customInputs).length;
            });

            if ($scope.services && $scope.services.length && !$scope.selectedService.value) {
                // default to the first one
                $scope.selectedService.value = $scope.services.at(0);
            }

            if ($scope.visitor.isUser && $scope.visitor.user.id === $scope.user.id) {
                $scope.hasEditAccess = true;
            }

            if ($scope.visitor.isUser && $scope.visitor.creditsAvailable !== null && $scope.user.creditsPerHour !== null && $scope.user.creditsPerHour > 0) {
                // Calculate if there are any consultation types the user can book.
                const expertCreditsPerHour = $scope.user.creditsPerHour;
                const userCreditsAvailable = $scope.visitor.creditsAvailable;

                $scope.$evalAsync(function() {
                    const filtered = $scope.services.where(function(service) {
                        const serviceCost = service.durationInHours * expertCreditsPerHour;
                        return serviceCost <= userCreditsAvailable;
                    });
                    $scope.services = new Services(filtered);

                    if ($scope.services.length === 0) {
                        $scope.isBookable = false;
                        $scope.unbookableReason = 'Sorry, ' + $scope.user.firstName + ' is currently unavailable.';
                    }
                });
            }
            if ($scope.isBookable && $scope.visitor.isUser && $scope.visitor.user.id === $scope.user.id) {
                $scope.isBookable = false;
                $scope.allowEmailWhenAvailable = false;
                $scope.unbookableReason = 'You are unable to book a consultation with yourself.';
            }
            if ($scope.isBookable && $scope.visitor.hasReachedConcurrentMeetingLimit) {
                $scope.isBookable = false;
                $scope.unbookableReason = 'You\'re at your limit of ' + Whitelabelling.settings.concurrentMeetingLimit +
                    ' pending consultations. Complete or cancel one to book another.';
            }
            if ($scope.isBookable && !Whitelabelling.settings.pendingExpertsBookable && $scope.user.isPending) {
                $scope.isBookable = false;
                $scope.unbookableReason = $scope.user.firstName + ' is still pending administrative review.';
            }
            if ($scope.isBookable && $scope.user.outOfOffice) {
                $scope.isBookable = false;
                $scope.unbookableReason = $scope.user.firstName + ' is currently out of office.';
            }
            if ($scope.isBookable && $scope.user.consultationsRemaining === 0) {
                $scope.isBookable = false;
                $scope.unbookableReason = $scope.user.firstName + ' is fully booked this month';
            }
            if ($scope.isBookable && !$scope.visitor.isConsumer) {
                $scope.isBookable = false;
                $scope.allowEmailWhenAvailable = false;
                if ($scope.visitor.isClient) {
                    $scope.unbookableReason = 'As the platform administrator, you are unable to book with ' +
                        Whitelabelling.expertNounPlural + '. If you would like to book with this user, please login ' +
                        'with ' + an(Whitelabelling.consumerNoun, true) + ' account.';
                } else {
                    $scope.unbookableReason = 'Your account does not have booking rights. Please contact your platform administrator if this is in error.';
                }
            }
            if ($scope.isBookable && $scope.user.restrictedToPrograms === 1) {
                $scope.isBookable = false;
                $scope.unbookableReason = $scope.user.firstName + ' restricted meetings to users within his programs.';
            }
            if ($scope.isBookable && $scope.customInputs) {
                const customInputsRestrictingBooking = CustomInputService.customInputsRestrictingBooking(CustomInputs, $scope.customInputs);
                // Check how many (or null) Custom Inputs are blocking booking. We'll get an array of labels.
                if (customInputsRestrictingBooking) {
                    $scope.isBookable = false;
                    let blockingLabel = customInputsRestrictingBooking.join(', ');
                    $scope.unbookableReason = `Sorry, ${$scope.user.firstName} has not opted into meetings with members of your ${blockingLabel}.`;
                    $scope.allowEmailWhenAvailable = false;
                }
            }
            if ($scope.affinityGroups) {
                const groupedAffinityGroups = {};
                $scope.affinityGroups.each(function(group) {
                    if (!groupedAffinityGroups[group.affinityGroupCategoryid]) {
                        groupedAffinityGroups[group.affinityGroupCategoryid] = {
                            id: group.affinityGroupCategoryid,
                            label: group.categoryLabel,
                            groups: [],
                        };
                    }
                    groupedAffinityGroups[group.affinityGroupCategoryid].groups.push(group);
                });

                $scope.groupedAffinityGroups = groupedAffinityGroups;
            }

            $scope.timezone = TimezonesService.getDeviceTimezone();

            if ($scope.isBookable) {
                // start with this week loaded
                $scope.getInitialLoad();
            }

            $scope.isLoaded = true;
            checkMobile();
            $scope.$broadcast('recalculateFooter');
            $scope.$broadcast('recalculateHeader');
        });

        $scope.visitorServiceFunction = function() {
            return Profile.refreshUser($routeParams.userid);
        };

        $($window).on('resize', function() {
            checkMobile();
            $scope.$apply();
        });

        //--------------------------------------------------------------------------------------------
        // CONTROLLER HOOKS
        //--------------------------------------------------------------------------------------------

        $scope.book = function() {
            if ($scope.visitor.isUser && $scope.visitor.user.id === $scope.user.id) {
                alert('You cannot book yourself.');
                return;
            }
            let url = '/profile/' + $scope.user.id + '/book';
            if ($scope.selectedService.value) {
                url += '/service/' + $scope.selectedService.value.get('id');
            }
            $location.path(url);
        };

        $scope.bookWithTime = function(meetingTime) {
            if ($scope.visitor.isUser && $scope.visitor.user.id === $scope.user.id) {
                alert('You cannot book yourself.');
                return;
            }
            let url = '/profile/' + $scope.user.id + '/book/time/' + meetingTime.date.getTime();
            if ($scope.selectedService.value) {
                url += '/service/' + $scope.selectedService.value.get('id');
            }
            $location.path(url);
        };

        $scope.changePanelDays = function(dir) {
            if (panelOffset === 0 && dir < 0) {
                return;
            }

            panelOffset += dir;
            panelOffset = Math.max(0, panelOffset);

            $scope.disableLeftPanelNav = (panelOffset === 0);

            const panelGroup = $scope.availabilities.slice(panelOffset, panelOffset + $scope.availabilityColumns);

            // we do this to preload the next week before they get there
            if ((panelOffset + (2 * $scope.availabilityColumns)) > $scope.availabilities.length) {
                getAvailability();
            }

            // if they clicked fast, we may not even have enough to show
            // in that case, we need to wait until we have enough get availability on the next week
            if (panelGroup.length < $scope.availabilityColumns) {
                $scope.loadingPanel = true;
                return getAvailability().then(() => {
                    $scope.changePanelDays(0);
                });
            } else {
                if (panelOffset === 0 && dir === 0) {
                    return ensureAvailabilityIsVisible(panelGroup);
                }
                $scope.panelGroup = panelGroup;
                $scope.loadingPanel = false;
            }
        };

        function ensureAvailabilityIsVisible(panelGroup) {
            // when it first loads, we check if any of the days have available slots,
            let hasSomeSlots = false;
            for (let i = 0; i < $scope.availabilityColumns && i < panelGroup.length; i++) {
                hasSomeSlots = hasSomeSlots || panelGroup[i].slots.length > 0;
            }

            // if none do, we move the panel up 1 full cycle of columns
            if (!hasSomeSlots) {
                panelOffset += 3;
                // we do this to preload the next week before they get there
                if ((panelOffset + (2 * $scope.availabilityColumns)) > $scope.availabilities.length) {
                    getAvailability().then(function() {
                        ensureAvailabilityIsVisible(
                            $scope.availabilities.slice(panelOffset, panelOffset + $scope.availabilityColumns));
                    });
                } else if ($scope.weeksAhead < 12) {
                    return ensureAvailabilityIsVisible(
                        $scope.availabilities.slice(panelOffset, panelOffset + $scope.availabilityColumns));
                } else {
                    // Went 12 weeks ahead and found nothing. Don't keep looking.
                    $scope.panelGroup = panelGroup;
                    $scope.loadingPanel = false;
                }
            } else {
                $scope.panelGroup = panelGroup;
                $scope.loadingPanel = false;
            }
        }

        /**
         * This function is used to select the preferred times (during the day) from the list of all available slots
         * @param {array} slots
         * @param {number} index
         */
        $scope.getPreferredTime = function(slots, index) {
            // I wrote a really awesome time selector that sorted the available times according to their proximity to a
            // desired range (10:00 - 18:00), but jenna hated it and told me to just show times starting from 9:00am if
            // they have a full day's slot... so that's what I'm doing.
            if (slots.length === 24) {
                return slots[index + 9];
            } else if (slots.length === 23) {
                if (slots[2].date.getHours() === 3) {
                    // daylight savings -- Fall backwards
                    return slots[index + 8];
                } else {
                    // I honestly don't know why sometimes this happens (november 7th)... But whatever
                    return slots[index + 9];
                }
            } else if (slots.length === 25) {
                // daylight savings -- Spring forwards
                return slots[index + 10];
            }

            return slots[index];
        };

        //--------------------------------------------------------------------------------------------
        // HELPER FUNCTIONS
        //--------------------------------------------------------------------------------------------
        const getAvailability = (startFromBeginning = false) => {
            // response Buffer in hours - one full day by default
            const selectedService = $scope.selectedService.value;

            // if a user is in a currently active fair, they should have virtually no buffer (15 min)
            // otherwise apply the whitelabel buffer minimum
            let responseBuffer = $scope.hasActiveFairs ? 0.25 : Math.max(Whitelabelling.settings.bookingHourBuffer, 0);

            let durationInSeconds = 3600;
            let serviceId = null;
            try {
                durationInSeconds = selectedService.get('durationInSeconds');
                serviceId = selectedService.get('id');
            }
            catch (e) {
            }

            $scope.weeksAhead = startFromBeginning ? 0 : $scope.weeksAhead + 1;

            panelPromise = AvailabilityService.getUserAvailabilityMerged($scope.user.id, $scope.weeksAhead, null,
                responseBuffer, durationInSeconds, 0, serviceId);
            panelPromise.then(blocks => {
                if (startFromBeginning === true) {
                    $scope.availabilities = [];
                }
                $scope.availabilities = $scope.availabilities.concat(blocks);
                $scope.$broadcast('recalculateFooter');
                $scope.$broadcast('recalculateHeader');
            }).finally(() => panelPromise = undefined);

            return panelPromise;
        };

        function checkMobile() {
            $scope.isMobile = $window.innerWidth < 640;
        }

        function getCurrentEmploymentIfAny(employments) {
            const currentEmployment = employments.filter(emp => emp.endDate === null);

            if (currentEmployment.length) {
                return currentEmployment[0];
            }

            return null;
        }

        $scope.getInitialLoad = () => {
            getAvailability(true).then(() =>
                $scope.changePanelDays(0),
            );
        };
    },
];
