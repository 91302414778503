/**
 * This directive creates a timezone select interface to alter the Timezoneservice's device timezone property
 * use like:
 * <timezone-select>
 */
export function timezoneSelect() {
    return {
        restrict: 'E',
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/timezoneSelect.html',
        replace: true,
        scope: {},
        controller: [
            '$scope', 'TimezonesService', function($scope, TimezonesService) {
                $scope.timezones = TimezonesService.getTimezones();
                $scope.selectedTimezone = TimezonesService.getDeviceTimezone();

                $scope.$watch('selectedTimezone', function() {
                    TimezonesService.setDeviceTimezone($scope.selectedTimezone);
                });

                $scope.$watch(function() {
                    return TimezonesService.getDeviceTimezone();
                }, function(newVal, oldVal) {
                    $scope.selectedTimezone = newVal;
                });
            },
        ],
    };
}

