/**
 * This directive lets you include an asset (like an SVG) as if it were embedded inline.
 * Use like:
 * <div icon asset="/asset/img/pages/consultations/paper_airplane.svg"></div>
 */
export function icon() {
    return {
        restrict: 'A',
        templateUrl: function(element, attrs) {
            return attrs.asset;
        },
    };
}
