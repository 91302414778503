export function boothParticipants() {
    return {
        restrict: 'E',
        scope: {
            'users': '=',
        },
        templateUrl: '/asset/partials/partner/directives/fairs/boothParticipants.html',
        replace: true,
        controller: [
            '$scope', 'RegistrationService',
            function ($scope, RegistrationService) {
                $scope.usersHook = RegistrationService.usersPrefixComplete;
                $scope.newParticipant = "";

                $scope.$watch('newParticipant', (newVal) => {
                    if (!newVal || (_.isObject(newVal) && _.isEmpty(newVal)) || (Array.isArray(newVal) && !newVal.length)) {
                        return;
                    }

                    for (let i = 0; i < $scope.users.length; i++) {
                        if ($scope.users[i].userid === newVal.id) {
                            // user already added, don't add doubles
                            $scope.newParticipant = "";
                            return;
                        }
                    }

                    $scope.users.push({
                        id: null,
                        userid: newVal.id,
                        isExhibitor: false,
                        isVisible: true,
                        isPinned: false,
                        attributes: newVal
                    });
                    $scope.newParticipant = "";
                });

                $scope.removeUser = function(i) {
                    $scope.users.splice(i, 1);
                };
            }
        ]
    }
}
