import {NgModel} from './ngModel';
import * as _ from 'underscore';
import {School} from './school';
import {Degree} from './degree';
import {Concentrations} from 'collection/concentrations';

export const ProfileEducation = NgModel.extend({
    _name: 'Education',
    associations: {
        School,
        Degree,
        Concentrations,
    },
    defaults: {
        School: null,
        Degree: null,
        Concentrations: null,
        dateGraduated: null,
        isDeleted: false,
    },
    parse: function(data) {
        const dataFormatted = this._super('parse', data);
        if (!dataFormatted.Concentrations) {
            dataFormatted.Concentrations = new Concentrations();
        }

        if (dataFormatted.dateGraduated) {
            dataFormatted.dateGraduated = this.getDate(dataFormatted.dateGraduated);
        }

        if (dataFormatted.logo) {
            let logoPath = dataFormatted.logo;
            if (logoPath.indexOf('/') === 0) {
                logoPath = logoPath.substring(1);
            }
            dataFormatted.logo = logoPath;
        }

        this.__bindManyForAngular(dataFormatted);
        return dataFormatted;
    },
    isComplete: function() {
        this.validateData();
        const errors = this.get('errors');
        const keys = _.keys(errors);
        if (keys.length === 0) {
            return true;
        }
        let isComplete = true;
        _.every(keys, function(k) {
            isComplete = !errors[k];
            return isComplete;
        });
        return isComplete;
    },
    validateData: function() {
        const errors = {};

        if (!this.get('isDeleted')) {
            errors.School = !this.get('School');
            errors.dateGraduated = !this.get('dateGraduated');
        }

        this.set('errors', errors);
        return errors;
    },
});
