import {whitelabelling as Whitelabelling} from 'whitelabelling';
import {Navigations} from 'collection/navigations';
import {Navigation} from 'model/navigation';

export const NavigationService = [
    function() {
        const northStarAdminEndpoint = '/partner';
        const matchingUrl = Whitelabelling.settings.featureNewMentorSearchEnabled ? '#!/boosters' : '#!/algorithm';
        const hasJobsEnabled = Whitelabelling.settings.allowJobsBoard;
        console.log({hasJobsEnabled});
        return {
            getAdminSideNavPanel: function() {
                return new Navigations([
                    new Navigation({
                        class: 'nav-whitelabel-settings',
                        label: 'Whitelabels',
                        Navigations: new Navigations([
                            new Navigation({
                                label: 'General',
                                url: '#!/whitelabel/settings',
                            }),
                            new Navigation({
                                label: 'IP Auth',
                                url: '#!/whitelabel/ip-auth',
                            }),
                            new Navigation({
                                label: 'Additional domains',
                                url: '#!/whitelabel/additional-domains',
                            }),
                            new Navigation({
                                label: 'Domains',
                                url: '#!/whitelabel/email-domains'
                            })
                        ]),
                    }),
                    new Navigation({
                        class: 'nav-whitelabel-global',
                        label: 'Global',
                        Navigations: new Navigations([
                            new Navigation({
                                label: 'Gated Blacklist',
                                url: '#!/globals/gated-blacklist',
                            })
                        ])
                    }),
                    new Navigation({
                        'class': 'nav-standardize',
                        label: 'Standardizer',
                        Navigations: new Navigations([
                            new Navigation({
                                'class': 'nav-standardize-employers',
                                label: 'Employers',
                                url: '#!/standardizer/employers',
                            }),
                            new Navigation({
                                'class': 'nav-standardize-schools',
                                label: 'Schools',
                                url: '#!/standardizer/schools',
                            }),
                            new Navigation({
                                'class': 'nav-standardize-degrees',
                                label: 'Degrees',
                                url: '#!/standardizer/degrees',
                            }),
                            new Navigation({
                                'class': 'nav-standardize-concentrations',
                                label: 'Fields of Study',
                                url: '#!/standardizer/concentrations',
                            }),
                            new Navigation({
                                'class': 'nav-standardize-languages',
                                label: 'Languages',
                                url: '#!/standardizer/languages',
                            }),
                        ]),
                    }),
                    new Navigation({
                        'class': 'nav-scrubber',
                        label: 'Scrubber',
                        url: '#!/scrubber',
                    }),
                    new Navigation({
                        'class': 'nav-search-index',
                        label: 'Search Index',
                        Navigations: new Navigations([
                            new Navigation({
                                'class': 'nav-search-index-users',
                                label: 'Users',
                                url: '#!/search-index/users',
                            }),
                            hasJobsEnabled ?
                            new Navigation({
                                'class': 'nav-search-index-jobs',
                                label: 'Jobs',
                                url: '#!/search-index/jobs',
                            }) : undefined,
                            new Navigation({
                                'class': 'nav-search-index-resources',
                                label: 'The Library',
                                url: '#!/search-index/resources',
                            }),
                        ]),
                    }),
                    new Navigation({
                        'class': 'nav-email-reports',
                        label: 'Email Reports',
                        url: '#!/email-reports',
                    }),
                    new Navigation({
                        'class': 'nav-sso',
                        label: 'SSO Settings',
                        url: '#!/sso',
                    }),
                    new Navigation({
                        'class': 'nav-group-images',
                        label: 'Group Images',
                        url: '#!/group-images',
                    }),
                ]);
            },

            getManagerSideNavPanel: function() {
                return new Navigations([
                    new Navigation({
                        'class': 'nav-dashboard',
                        label: 'Dashboard',
                        Navigations: new Navigations([
                            new Navigation({
                                url: '/partner/dashboard',
                                label: 'Overview',
                            }),
                            new Navigation({
                                url: '/partner/users/top',
                                label: 'Leaderboard',
                            }),
                        ]),
                    }),
                    new Navigation({
                        'class': 'nav-users',
                        label: 'Users',
                        Navigations: new Navigations([
                            new Navigation({
                                url: `${northStarAdminEndpoint}/users/create`,
                                label: 'Add Users',
                            }),
                            new Navigation({
                                url: `${northStarAdminEndpoint}/users`,
                                label: 'Manage Users',
                            }),
                            (
                                (Whitelabelling.isCAP || Whitelabelling.isHybrid) ?
                                    new Navigation({
                                        url: '#!/consultations/users/credits',
                                        label: 'User Credits',
                                    }) : undefined
                            ),
                            (
                                Whitelabelling.isAMP && !Whitelabelling.settings['hideField:education'] ?
                                    new Navigation({
                                        url: '#!/education',
                                        label: 'Education',
                                    }) : undefined
                            ),
                            (
                                Whitelabelling.isAMP && !Whitelabelling.settings['hideField:employment'] ?
                                    new Navigation({
                                        url: '#!/employment',
                                        label: 'Employment',
                                    }) : undefined
                            ),
                            !Whitelabelling.settings['hide:mentoringRelatedFeatures'] ? new Navigation({
                                url: '#!/career-goals',
                                label: Whitelabelling.settings['customized:careerGoals'] || 'Career Goals',
                            }) : undefined,
                        ]),
                    }),
                    new Navigation({
                        'class': 'nav-messaging',
                        label: 'Messaging',
                        Navigations: new Navigations([
                            new Navigation({
                                label: 'Send an Email',
                                url: '#!/messaging/emails/schedule',
                            }),
                            new Navigation({
                                label: 'Emails Overview',
                                url: '#!/messaging/emails',
                            }),
                            new Navigation({
                                label: 'Lists',
                                url: '#!/messaging/lists',
                            }),
                            new Navigation({
                                label: 'Welcome Campaigns',
                                url: '#!/messaging/campaigns',
                            }),
                        ]),
                    }),
                    !Whitelabelling.settings['hide:mentoringRelatedFeatures'] ? new Navigation({
                        'class': 'nav-consultations',
                        label: 'Consultations',
                        Navigations: new Navigations([
                            new Navigation({
                                url: '#!/consultations/overview',
                                label: 'Overview',
                            }),
                            new Navigation({
                                url: '#!/consultations',
                                label: 'Activity',
                            }),
                            new Navigation({
                                url: '#!/services',
                                label: 'Manage Types',
                            }),
                        ]),
                    }) : undefined,
                    (
                        Whitelabelling.isAMP && !Whitelabelling.settings['hide:mentoringRelatedFeatures'] ?
                            new Navigation({
                                'class': 'nav-groups',
                                label: 'Communities',
                                url: '#!/groups',
                            })
                            : undefined
                    ),
                    (
                        Whitelabelling.settings['hasFairs'] && !Whitelabelling.settings['hide:mentoringRelatedFeatures'] ?
                            new Navigation({
                                'class': 'nav-fairs',
                                label: 'Fairs',
                                url: '#!/fairs',
                            })
                            : undefined
                    ),
                    !Whitelabelling.settings['hide:mentoringRelatedFeatures'] ? new Navigation({
                        'class': 'nav-algorithm',
                        label: 'Matching',
                        url: matchingUrl,
                    }) : undefined,
                ]);
            },

            getPartnerSideNavPanel: function() {
                return new Navigations([
                    new Navigation({
                        'class': 'nav-dashboard',
                        label: 'Dashboard',
                        Navigations: new Navigations([
                            new Navigation({
                                url: `${northStarAdminEndpoint}/dashboard`,
                                label: 'Overview',
                            }),
                            new Navigation({
                                url: `${northStarAdminEndpoint}/users/top`,
                                label: 'Leaderboard',
                            }),
                        ]),
                    }),
                    new Navigation({
                        'class': 'nav-users',
                        label: 'Users',
                        Navigations: new Navigations([
                            new Navigation({
                                url: `${northStarAdminEndpoint}/users/create`,
                                label: 'Add Users',
                            }),
                            new Navigation({
                                url: `${northStarAdminEndpoint}/users`,
                                label: 'Manage Users',
                            }),
                            (
                                (Whitelabelling.isCAP || Whitelabelling.isHybrid) ?
                                    new Navigation({
                                        url: '#!/consultations/users/credits',
                                        label: 'User Credits',
                                    }) : undefined
                            ),
                            (
                                Whitelabelling.isAMP && !Whitelabelling.settings['hideField:education'] ?
                                    new Navigation({
                                        url: '#!/education',
                                        label: 'Education',
                                    }) : undefined
                            ),
                            (
                                Whitelabelling.isAMP && !Whitelabelling.settings['hideField:employment'] ?
                                    new Navigation({
                                        url: '#!/employment',
                                        label: 'Employment',
                                    }) : undefined
                            ),
                            !Whitelabelling.settings['hide:mentoringRelatedFeatures'] ? new Navigation({
                                url: '#!/career-goals',
                                label: Whitelabelling.settings['customized:careerGoals'] || 'Career Goals',
                            }) : undefined,
                            (
                                Whitelabelling.settings.passcodeProvisioning ?
                                    new Navigation({
                                        url: '#!/users/passcodes',
                                        label: 'Passcodes',
                                    }) : undefined
                            ),
                        ]),
                    }),
                    new Navigation({
                        'class': 'nav-admins',
                        label: 'Admins',
                        Navigations: new Navigations([
                            new Navigation({
                                url: `${northStarAdminEndpoint}/admins`,
                                label: 'Manage Admins',
                            }),
                            new Navigation({
                                url: `${northStarAdminEndpoint}/admins/create`,
                                label: 'Add New Admin',
                            }),
                        ]),
                    }),
                    new Navigation({
                        'class': 'nav-messaging',
                        label: 'Messaging',
                        Navigations: new Navigations([
                            new Navigation({
                                label: 'Send an Email',
                                url: '#!/messaging/emails/schedule',
                            }),
                            new Navigation({
                                label: 'Emails Overview',
                                url: '#!/messaging/emails',
                            }),
                            new Navigation({
                                label: 'Lists',
                                url: '#!/messaging/lists',
                            }),
                            new Navigation({
                                label: 'Welcome Campaigns',
                                url: '#!/messaging/campaigns',
                            }),
                        ]),
                    }),
                    !Whitelabelling.settings['hide:mentoringRelatedFeatures'] ? (
                        Whitelabelling.settings['matching:allowOneToOne'] ?
                            new Navigation({
                                'class': 'nav-algorithm',
                                label: 'Matching',
                                Navigations: new Navigations([
                                    new Navigation({
                                        label: '1:1 Matching',
                                        url: '#!/one-to-one-matching',
                                    }),
                                    new Navigation({
                                        label: 'Algorithm',
                                        url: matchingUrl,
                                    }),
                                ]),
                            })
                            : new Navigation({
                                'class': 'nav-algorithm',
                                label: 'Matching',
                                url: matchingUrl,
                            })
                    ) : undefined,
                    !Whitelabelling.settings['hide:mentoringRelatedFeatures'] ? new Navigation({
                        'class': 'nav-consultations',
                        label: 'Consultations',
                        Navigations: new Navigations([
                            new Navigation({
                                url: '#!/consultations/overview',
                                label: 'Overview',
                            }),
                            new Navigation({
                                url: '#!/consultations',
                                label: 'Activity',
                            }),
                            new Navigation({
                                url: '#!/services',
                                label: 'Manage Types',
                            }),
                            (
                                ((Whitelabelling.isCAP || Whitelabelling.isHybrid) && (Whitelabelling.contractType && Whitelabelling.contractType !== 'Contract_0')) ?
                                    new Navigation({
                                        url: '#!/credits',
                                        label: 'Manage Credits',
                                    }) : undefined
                            ),
                        ]),
                    }) : undefined,
                    (
                        Whitelabelling.isAMP && !Whitelabelling.settings['hide:mentoringRelatedFeatures'] ?
                            new Navigation({
                                'class': 'nav-groups',
                                label: 'Communities',
                                Navigations: new Navigations([
                                    new Navigation({
                                        url: '#!/groups',
                                        label: 'Groups',
                                    }),
                                    new Navigation({
                                        url: '#!/categories',
                                        label: 'Categories',
                                    }),
                                    new Navigation({
                                        url: '#!/discussions',
                                        label: 'Forums',
                                    }),
                                ]),
                            })
                            : undefined
                    ),
                    hasJobsEnabled ? new Navigation({
                        'class': 'nav-jobs',
                        label: 'Jobs',
                        Navigations: new Navigations([
                            new Navigation({
                                url: '#!/jobs/create',
                                label: 'Add Jobs',
                            }),
                            new Navigation({
                                url: '#!/jobs',
                                label: 'Manage Jobs',
                            }),
                        ]),
                    }) : undefined,
                    (
                        Whitelabelling.isAMP && !Whitelabelling.settings['hide:mentoringRelatedFeatures'] ?
                            new Navigation({
                                'class': 'nav-programs',
                                label: 'Programs',
                                url: '#!/programs',
                            })
                            : undefined
                    ),
                    (
                        Whitelabelling.settings['hasFairs'] && !Whitelabelling.settings['hide:mentoringRelatedFeatures'] ?
                            new Navigation({
                                'class': 'nav-fairs',
                                label: 'Fairs',
                                Navigations: new Navigations([
                                    new Navigation({
                                        url: '#!/fairs',
                                        label: 'Overview',
                                    }),
                                    new Navigation({
                                        url: '#!/booth-managers',
                                        label: 'Booth Users',
                                    }),
                                ]),
                            })
                            : undefined
                    ),
                    new Navigation({
                        'class': 'nav-resources',
                        label: 'The Library',
                        Navigations: new Navigations([
                            new Navigation({
                                url: '#!/resources',
                                label: 'Overview',
                            }),
                            new Navigation({
                                url: '#!/resources/tags',
                                label: 'Manage Tags',
                            }),
                            new Navigation({
                                'class': 'nav-webinars',
                                url: '#!/resources/all/views',
                                label: 'Views',
                            }),
                        ]),
                    }),
                    !Whitelabelling.settings['hide:mentoringRelatedFeatures'] ? new Navigation({
                        'class': 'nav-donations',
                        label: 'Donations',
                        Navigations: new Navigations([
                            new Navigation({
                                url: '#!/donations',
                                label: 'Performance',
                            }),
                            new Navigation({
                                url: '#!/donations/settings',
                                label: 'Settings',
                            }),
                        ]),
                    }) : undefined,
                    new Navigation({
                        'class': 'nav-integrations',
                        label: 'Integrations',
                        Navigations: new Navigations([
                            new Navigation({
                                url: '/partner/integrations/learning-cloud',
                                label: 'Learning Cloud',
                            }),
                            Whitelabelling.showApi ? new Navigation({
                                url: '#!/integrations/api',
                                label: 'API',
                            }) : null,
                            Whitelabelling.showHandshake ? new Navigation({
                                url: '#!/integrations/handshake',
                                label: 'Handshake',
                            }) : null,
                            Whitelabelling.showJobadder ? new Navigation({
                                url: '#!/integrations/jobadder',
                                label: 'JobAdder',
                            }) : null,
                        ]),
                    }),
                    new Navigation({
                        'class': 'nav-settings',
                        url: '#!/settings',
                        label: 'Settings',
                    }),
                ]);
            },
        };
    },
];
