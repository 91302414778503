import 'angular';
import 'angular-route';
import 'angular-elastic';
import 'angularjs-scroll-glue';
import 'angular-cookies';
import 'angular-sanitize';
import 'angular-animate';
import 'angular-inview';
import 'ui-select';
import 'angular-file-upload';
import 'angularjs-slider';
import 'angular-aria';
import 'ngGeolocation/ngGeolocation';
import 'ngmap';
import 'angular-ui-mask';
import 'ng-infinite-scroll';
import './controllers';
import './services';
import './filters';
import './directives';
import './components';
import 'angulartics';
import 'angulartics-google-tag-manager';
import {DIMENSION_USERID, DIMENSION_WHITELABELID} from 'configurations/googleAnalyticsDimensions';

import {setupBugTracking, setUserContext} from 'configurations/bugTracking';
import {setupCacheBuster} from 'configurations/cacheBuster';
import {setupFirsthandAnimate} from 'configurations/firsthandAnimate';
import exceptionHandler from '../utilities/exceptionHandler';
import {whitelabelling as Whitelabelling} from 'whitelabelling';

setupBugTracking();
setupCacheBuster();
setupFirsthandAnimate();

export const app = angular.module('app', [
    'angular-inview',
    'angularFileUpload',
    'angulartics',
    'angulartics.google.tagmanager',
    'app.controllers',
    'app.directives',
    'app.filters',
    'app.services',
    'cacheBuster',
    'firsthandAnimate',
    'infinite-scroll',
    'luegg.directives',
    'monospaced.elastic',
    'ngAria',
    'ngCookies',
    'ngGeolocation',
    'ngMap',
    'ngRaven',
    'ngRoute',
    'ngSanitize',
    'rzSlider',
    'ui.mask',
    'ui.select',
]);

// this will control our FullPageLoading
app.run([
    '$analytics',
    '$cookies',
    '$rootScope',
    '$routeParams',
    '$timeout',
    'fileUploaderOptions',
    'UserEngagementService',
    'VisitorService',
    (
        $analytics,
        $cookies,
        $rootScope,
        $routeParams,
        $timeout,
        fileUploaderOptions,
        engagementService,
        VisitorService,
    ) => {
        let userProperties = {};
        userProperties[DIMENSION_WHITELABELID] = Whitelabelling.id ? Whitelabelling.id : 0;
        $analytics.setUserProperties(userProperties);

        $rootScope.checkIsMeetingsV2AppEnabled = (meetingId = undefined, action = undefined) => {
            switch (meetingId) {
                case undefined:
                    return Whitelabelling.settings.featureNewMeetingsEnabled ? Evisors.config.cdnRootWhitelabel + 'meetings' : false;
                default:
                    if (Whitelabelling.settings.featureNewMeetingsEnabled) {
                        return Evisors.config.cdnRootWhitelabel + `meetings/${meetingId}`;
                    } else if (Whitelabelling.settings.isMeetingsV2AppEnabled) {
                        return Evisors.config.cdnRootWhitelabel + 'a/meetings/' + meetingId + (action ? '?action=' + action : '');
                    }
                    return false;
            }
        };

        $rootScope.profileEditPage = Whitelabelling.settings.featureNewRegistrationEnabled ? '/profile/edit' : '/app#!/profile/edit';

        $rootScope.isLoading = true;
        $rootScope.hideLoading = false;
        $rootScope.loadingError = null;

        try {
            $rootScope.$on('controllerLoaded', function() {
                $timeout(function() {
                    $rootScope.isLoading = false;

                    $timeout(function() {
                        $rootScope.hideLoading = true;
                        $rootScope.$apply();
                    }, 1000);
                }, 1000);
            });
        }
        catch (exception) {
            $rootScope.loadingError = 'Oh no! Something went wrong. Please refresh or try contacting us!';
        }

        const xsrfToken = $cookies.get('XSRF-TOKEN');
        if (xsrfToken) {
            fileUploaderOptions.headers['X-XSRF-TOKEN'] = xsrfToken;
            $.ajaxSetup({
                headers: {'X-XSRF-TOKEN': xsrfToken},
            });
        }

        VisitorService.getVisitor().then(function(v) {
            // Set up Google Analytics.
            userProperties[DIMENSION_USERID] = v.user && v.user.id ? v.user.id : 0;
            $analytics.setUserProperties(userProperties);

            if (v.user && v.user.id) {
                // Set up Sentry.io
                setUserContext({
                    id: v.user.id,
                });
                //setup ajax error handling
                $.ajaxSetup({
                    error: function(responseObj, status, error) {
                        if (responseObj.status === 403) {
                            // check that we're still logged in
                            VisitorService.getVisitor().then(function(v) {

                                // if we are, then this error should be reported
                                if (v.user && v.user.id) {
                                    throw error;
                                } else {
                                    // if we're not logged in (and we know we were before because this ajaxSetup only
                                    // runs if we are logged in), then we return to the login page.
                                    window.location.href = '/login';
                                }
                            }, function() {
                                // this will probably never happen
                                throw error;
                            });
                        }
                    },
                });
            }
        });

        $rootScope.connectionFunction = function() {
            return VisitorService.getVisitor(true);
        };

        if (Whitelabelling.id) {
            engagementService.getFirstUnrespondedEngagement($rootScope).then(data => {
                engagementService.engagementResponseHandler(data);
            });
        }
    },
]);

app.config([
    '$routeProvider', '$sceDelegateProvider', function($routeProvider, $sceDelegateProvider) {
        $routeProvider.when('/profile/edit/:section?', { // --- EDITING PROFILE ---
            templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/controllers/editProfile.html',
            controller: 'EditProfileController',
        }).when('/profile/sync/:section?', {
            templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/controllers/syncProfile.html',
            controller: 'SyncProfileController',
        }).when('/profile/availability', {
            templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/controllers/editAvailability.html',
            controller: 'EditAvailabilityController',
        }).when('/profile/availability/:action', {
            templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/controllers/editAvailability.html',
            controller: 'EditAvailabilityController',
        }).when('/profile/offerings', {
            templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/controllers/editOfferings.html',
            controller: 'EditOfferingsController',
        }).when('/profile/offerings/:action', {
            templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/controllers/editOfferings.html',
            controller: 'EditOfferingsController',
        }).when('/profile/:userid', { // --- PROFILE ---
            templateUrl: Evisors.config.cdnRootWhitelabel + 'ng-template/partials/user/controllers/profile.tpl.html',
            controller: 'ProfileController',
            resolve: {
                paramType: () => undefined,
            },
        }).when('/profile/:userid/consumer', {
            templateUrl: Evisors.config.cdnRootWhitelabel + 'ng-template/partials/user/controllers/profile.tpl.html',
            controller: 'ProfileController',
            resolve: {
                paramType: () => 'consumer',
            },
        }).when('/profile/:userid/book', { // --- FOR BOOKING ---
            templateUrl: Evisors.config.cdnRootWhitelabel + 'ng-template/partials/user/controllers/book.tpl.html',
            controller: 'BookController',
        }).when('/profile/:userid/book/service/:serviceid', {
            templateUrl: Evisors.config.cdnRootWhitelabel + 'ng-template/partials/user/controllers/book.tpl.html',
            controller: 'BookController',
        }).when('/profile/:userid/book/time/:time', {
            templateUrl: Evisors.config.cdnRootWhitelabel + 'ng-template/partials/user/controllers/book.tpl.html',
            controller: 'BookController',
        }).when('/profile/:userid/book/service/:serviceid/time/:time', {
            templateUrl: Evisors.config.cdnRootWhitelabel + 'ng-template/partials/user/controllers/book.tpl.html',
            controller: 'BookController',
        }).when('/profile/:userid/book/time/:time/service/:serviceid', {
            templateUrl: Evisors.config.cdnRootWhitelabel + 'ng-template/partials/user/controllers/book.tpl.html',
            controller: 'BookController',
        }).when('/consultations/:meetingid/meet', { // --- CONSULTATIONS ---
            templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/controllers/meeting.html',
            controller: 'MeetingController',
            resolve: {
                accessible: [
                    '$rootScope', '$route', ($rootScope, $route) => {
                        const url = $rootScope.checkIsMeetingsV2AppEnabled($route.current.params.meetingid);
                        if (url) {
                            top.location = url;
                        }
                    },
                ],
            },
        }).when('/consultations/:meetingid/meet/:action', {
            templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/controllers/meeting.html',
            controller: 'MeetingController',
            resolve: {
                accessible: [
                    '$rootScope', '$route', ($rootScope, $route) => {
                        const url = $rootScope.checkIsMeetingsV2AppEnabled($route.current.params.meetingid, $route.current.params.action);
                        if (url) {
                            top.location = url;
                        }
                    },
                ],
            },
        }).when('/consultations', {
            templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/controllers/consultations.html',
            controller: 'ConsultationsController',
            resolve: {
                accessible: [
                    '$rootScope', ($rootScope) => {
                        const url = $rootScope.checkIsMeetingsV2AppEnabled();
                        if (url) {
                            top.location = url;
                        }
                    },
                ],
            },
        }).when('/consultations/:action', {
            templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/controllers/consultations.html',
            controller: 'ConsultationsController',
            resolve: {
                accessible: [
                    '$rootScope', '$route', ($rootScope, $route) => {
                        const url = $rootScope.checkIsMeetingsV2AppEnabled(undefined, $route.current.params.action);
                        if (url) {
                            top.location = url;
                        }
                    },
                ],
            },
        }).when('/people', { // --- PEOPLE ---
            templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/controllers/people.html',
            controller: 'PeopleController',
        }).when('/people/:actorType', {
            templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/controllers/people.html',
            controller: 'PeopleController',
        }).when('/dashboard', { // --- DASHBOARD ---
            templateUrl: Evisors.config.cdnRootWhitelabel +
                'ng-template/partials/user/controllers/userDashboard.tpl.html',
            controller: 'UserDashboardController',
        }).when('/reception', { // --- RECEPTION ---
            templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/controllers/programmingEvents.html',
            controller: 'ProgrammingEventsController',
        }).when('/search', { // --- SEARCH ---
            templateUrl: Evisors.config.cdnRootWhitelabel + 'ng-template/partials/user/controllers/search.tpl.html',
            controller: 'SearchController',
            reloadOnSearch: false, // this prevents the page from reloading when the query params change
            resolve: {
                accessible: [
                    'VisitorService', '$location', (VisitorService, $location) => {
                        VisitorService.getVisitor().then(visitor => {
                            if (visitor.isExpertOnly && Whitelabelling.settings['hideExpertsFromOtherExperts']) {
                                return $location.path('/profile/availability');
                            }
                        });
                    },
                ],
            },
        }).when('/jobs', {
            templateUrl: Evisors.config.cdnRootWhitelabel + 'ng-template/partials/user/controllers/jobsBoard.tpl.html',
            controller: 'JobsBoardController',
            reloadOnSearch: false, // this prevents the page from reloading when the query params change
        }).when('/groups', {
            templateUrl: Evisors.config.cdnRootWhitelabel + 'ng-template/partials/user/controllers/groups.tpl.html',
            controller: 'GroupsController',
            reloadOnSearch: false, // this prevents the page from reloading when the query params change
        }).when('/program-groups/:programid', {
            templateUrl: Evisors.config.cdnRootWhitelabel + 'ng-template/partials/user/controllers/groups.tpl.html',
            controller: 'GroupsController',
            reloadOnSearch: false, // this prevents the page from reloading when the query params change
        }).when('/group/:groupid', {
            templateUrl: Evisors.config.cdnRootWhitelabel + 'ng-template/partials/user/controllers/groupHome.tpl.html',
            controller: 'GroupHomeController as ctrl',
            reloadOnSearch: false, // this prevents the page from reloading when the query params change
        }).when('/group/:groupid/members', {
            templateUrl: Evisors.config.cdnRootWhitelabel + 'ng-template/partials/user/controllers/groupMembers.tpl.html',
            controller: 'GroupMembersController as ctrl',
            reloadOnSearch: false, // this prevents the page from reloading when the query params change
        }).when('/group/:groupid/discussions', {
            templateUrl: Evisors.config.cdnRootWhitelabel + 'ng-template/partials/user/controllers/groupDiscussions.tpl.html',
            controller: 'GroupDiscussionsController as ctrl',
            reloadOnSearch: false, // this prevents the page from reloading when the query params change
        }).when('/group/:groupid/events', {
            templateUrl: Evisors.config.cdnRootWhitelabel + 'ng-template/partials/user/controllers/groupEvents.tpl.html',
            controller: 'GroupEventsController as ctrl',
            reloadOnSearch: false, // this prevents the page from reloading when the query params change
        }).when('/group/:groupid/resources', {
            templateUrl: Evisors.config.cdnRootWhitelabel + 'ng-template/partials/user/controllers/groupResources.tpl.html',
            controller: 'GroupResourcesController as ctrl',
            reloadOnSearch: false, // this prevents the page from reloading when the query params change
        }).when('/group/:groupid/jobs', {
            templateUrl: Evisors.config.cdnRootWhitelabel + 'ng-template/partials/user/controllers/groupJobs.tpl.html',
            controller: 'GroupJobsController as ctrl',
            reloadOnSearch: false, // this prevents the page from reloading when the query params change
        }).when('/group/:groupid/discussion/:discussionid', {
            templateUrl: Evisors.config.cdnRootWhitelabel + 'ng-template/partials/user/controllers/discussionDetails.tpl.html',
            controller: 'DiscussionDetailController as ctrl',
            reloadOnSearch: false,
        }).when('/messages', { // --- MESSAGES ---
            templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/controllers/messages.html',
            controller: 'MessagesController',
            reloadOnSearch: false, // this prevents the page from reloading when the query params change
        }).when('/booths', {
            templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/controllers/booths.html',
            controller: 'BoothsController',
        }).when('/booths/:groupid/edit', {
            templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/controllers/boothEdit.html',
            controller: 'BoothEditController',
        }).when('/booths/:groupid/resumes', {
            templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/controllers/boothResumes.html',
            controller: 'BoothResumesController',
        }).when('/syllabus', {
            templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/controllers/syllabus.html',
            controller: 'SyllabusController',
        }).otherwise({redirectTo: '/dashboard'}); // --- END ---

        if (Whitelabelling.hasWebinars) {
            $routeProvider.when('/resources', { // --- RESOURCES ---
                templateUrl: Evisors.config.cdnRootWhitelabel + 'ng-template/partials/user/controllers/resources.tpl.html',
                controller: 'ResourcesController',
                reloadOnSearch: false, // this prevents the page from reloading when the query params change
            }).when('/resources/:id', {
                templateUrl: '/asset/partials/user/controllers/resource.html',
                controller: 'ResourceController',
            });
        }

        const resourceUrlWhitelist = [
            'self',
            Evisors.config.cdnRootWhitelabel + '**',
        ];
        if (Evisors.config.cdnUrl) {
            resourceUrlWhitelist.push(Evisors.config.cdnUrl + '**');
        }
        $sceDelegateProvider.resourceUrlWhitelist(resourceUrlWhitelist);
    },
]);

app.factory('$exceptionHandler', ['$log', exceptionHandler]);

if ($) {
    // Handle sitewide alert.
    $('.sitewide-alert .hide-this').on('click', () => {
        $(this).parent('.sitewide-alert').slideUp();
    });
}
