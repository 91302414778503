/**
 * This directive packages the user stats (response rate, consults completed, etc) panel
 * Use like:
 * <profile-stats></profile-stats>
 */
export function profileStats() {
    return {
        restrict: 'E',
        scope: {
            data: '=user',
            customInputs: '=',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/user/directives/profileStats.html',
    };
}

