import {NgModel} from './ngModel';

export const Calendar = NgModel.extend({
    _name: 'Calendar',
    defaults: {
        profileid: null,
        profileName: null,
        providerName: null,
        primaryCalendar: null,
        isSynced: false,
    },
    parse: function(data) {
        var dataFormatted = this._super('parse', data);

        dataFormatted.isSynced = this.asBoolean(dataFormatted.isSynced);

        this.__bindManyForAngular(dataFormatted);
        return dataFormatted;
    },
});
