import {whitelabelling as Whitelabelling} from 'whitelabelling';
import {DonationPrompt} from '../../../model/donationPrompt';

export const MeetingState_Expert_Complete = [
    '$scope', '$routeParams', '$q', 'Feedback', function($scope, $routeParams, $q, Feedback) {
        $scope.template = Evisors.config.cdnRootWhitelabel + 'asset/partials/consultations/meetingStates/both/complete_loading.html';
        $scope.feedbackData = null;
        $scope.similarExperts = null;

        $scope.consultationType = $scope.meeting.get('consultationType');
        $scope.assessmentType = $scope.meeting.get('assessmentType');

        var checkState = function() {
            Feedback.getMeta().then(function() {
                if (Feedback.isConsumerFeedbackRequired() && !Feedback.isConsumerFeedbackCompleted()) {
                    prepareWithFeedback();
                } else {
                    prepareSansFeedback();
                }
            });
        };

        var prepareWithFeedback = function() {
            Feedback.getConsumerFeedback().then(function(feedback) {
                $scope.feedbackData = feedback;
                $scope.template = Evisors.config.cdnRootWhitelabel + 'asset/partials/consultations/meetingStates/expert/complete_feedback.html';

                // after the form is saved
                $scope.feedbackData.bind('sync', function() {
                    checkState();
                });
            });
        };

        var prepareSansFeedback = function() {
            $scope.template = Evisors.config.cdnRootWhitelabel + 'asset/partials/consultations/meetingStates/expert/complete.html';
            $scope.completedFeedback = Feedback.isConsumerFeedbackCompleted();
            $scope.feedbackReady = Feedback.isExpertFeedbackCompleted();

            if (Whitelabelling.isAMP) {
                $scope.donationPrompt = new DonationPrompt({type: 'feedback'});
                $q.when($scope.donationPrompt.fetch()).then(function() {
                    if ($scope.donationPrompt.target) {
                        $scope.showDonationPrompt = true;
                    }
                });
            }

            if ($scope.completedFeedback) {
                Feedback.getConsumerFeedback().then(function(feedback) {
                    $scope.feedbackData = feedback;

                    if ($routeParams.action && $routeParams.action === 'show-feedback') {
                        $scope.showSubmittedFeedback = true;
                    }
                });
            }
        };

        checkState();
    },
];
