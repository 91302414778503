import {whitelabelling as Whitelabelling} from 'whitelabelling';
import {timesAreAllOnTheSameDay as utilTimesAreAllOnTheSameDay} from '../configurations/utilities';
import * as moment from 'moment-timezone';

const STEP_START = 'start';
const STEP_TIME = 'time';
const STEP_DAY = 'day';
const FAIR_BOOKING_BUFFER = 0.25;

export function pickThreeDates() {
    return {
        restrict: 'E',
        scope: {
            times: '=',
            userid: '=',
            step: '=?',
            meetingDuration: '=?',
            isFairType: '<?',
            serviceid: '=?',
        },
        replace: true,
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/pickThreeDates.html',
        controller: [
            '$scope',
            'TimezonesService',
            'AvailabilityService',
            ($scope, TimezonesService, AvailabilityService) => {
                let firstLoad = true;
                let bookingHourBuffer = Whitelabelling.settings.bookingHourBuffer;

                if ($scope.isFairType) {
                    bookingHourBuffer = FAIR_BOOKING_BUFFER;
                }

                $scope.loading = false;
                $scope.STEP_START = STEP_START;
                $scope.STEP_TIME = STEP_TIME;
                $scope.STEP_DAY = STEP_DAY;
                if (!$scope.step) {
                    // If a specific step was requested, go there. Otherwise, if we have no times yet, go directly to the day picker. Otherwise, go to the "Start" view.
                    $scope.step = $scope.times.length === 0 ? STEP_DAY : STEP_START;
                }

                $scope.selectedTimezone = TimezonesService.getDeviceTimezone();
                $scope.daysThisWeek = [];
                $scope.selectedDay = null;
                $scope.weekDifference = 0;
                $scope.protip = null;

                $scope.changeWeek = direction => {
                    $scope.weekDifference += direction;
                    updateAvailability();
                };

                $scope.setSelectedDay = d => {
                    $scope.selectedDay = d;
                    $scope.step = STEP_TIME;
                };

                $scope.$watch('times', () => {
                    if ($scope.step === STEP_START && $scope.times.length === 0) {
                        // If something external set the "step" to the STEP_START, but we have no times selected; go straight to the day picker.
                        $scope.step = STEP_DAY;
                    }
                });

                $scope.$watch(() => TimezonesService.getDeviceTimezone(), (newVal, oldVal) => {
                    if (oldVal && newVal && newVal.locationCode && newVal.locationCode !== oldVal.locationCode) {
                        $scope.selectedTimezone = newVal;
                        // reload the visible times list
                        updateAvailability();
                    }
                });

                $scope.setSelectedTime = obj => {
                    if ($scope.times.length === 3) {
                        // we're going to just overwrite the last one
                        $scope.removeTime(2);
                    }

                    $scope.times.push(obj);

                    $scope.protip = null;

                    if (utilTimesAreAllOnTheSameDay($scope.times)) {
                        if ($scope.times.length < 3) {
                            $scope.protip = 'Add your last time on a different day!';
                        } else {
                            $scope.protip = 'It\'s best to avoid 3 times on the same day.';
                        }
                    }

                    $scope.step = STEP_START;
                };

                $scope.removeTime = index => {
                    $scope.times.splice(index, 1);
                    $scope.showProTip = utilTimesAreAllOnTheSameDay($scope.times);
                };

                $scope.isNotPickable = date => {
                    // if this time is in the past, it's obviously not pickable
                    if (date.getTime() < Date.now()) {
                        return true;
                    }

                    // date comes from our selectedDay.slots array and represents the start of an hour
                    return $scope.times.some(obj => {
                        // a slot is considered selected if it is actually selected
                        // OR - is within
                        return (obj.date.getTime() === date.getTime()) || ($scope.duration);
                    });

                };

                // The size of a block makes an issue for upcoming fairs, as fair consultations lasts 10 mins.
                // We need to set block to minimum of 15 mins and consultation duration
                const blockSizeInSeconds = $scope.meetingDuration;
                const blockSizeInMinutes = blockSizeInSeconds / 60;
                const updateAvailability = () => {
                    if (!$scope.userid) {
                        return;
                    }
                    $scope.loading = true;

                    // we want our blocks to be sized to 15 minutes (or less if appointments take less)
                    AvailabilityService.getUserAvailabilityMerged(
                        $scope.userid,
                        $scope.weekDifference,
                        $scope.times,
                        bookingHourBuffer,
                        blockSizeInSeconds,
                        // if we don't know the meeting duration, make it 1 hour
                        ($scope.meetingDuration || 3600) - blockSizeInSeconds,
                        $scope.serviceid
                    ).then(blocks => {
                        // if there are no timeslots this week and we're on the first week (and we just loaded)
                        if (!blocks.length && $scope.weekDifference === 0 && firstLoad) {
                            // we try again with next week
                            $scope.weekDifference++;
                            updateAvailability();
                            return;
                        }

                        // we want to show these 15 minutes block along a single hour row
                        // so we regroup our single array of 4 into a 2D array
                        blocks.forEach(function(dayBlock) {
                            const group = {};
                            let lastSlot = null;
                            dayBlock.slots.forEach(function(s) {
                                s.groupKey = moment(s.date).tz(TimezonesService.getDeviceTimezone().locationCode).hour();
                                if (!group[s.groupKey]) {
                                    group[s.groupKey] = [];

                                    // we only want to do this once for a single row
                                    // so we do it within this groupKey property check
                                    // because that is only empty on the first slot of every row
                                    if (s.isPM && lastSlot && lastSlot.isAM) {
                                        s.showNoon = true;
                                    }
                                }

                                // instead of adding colspan which breaks the design (we're in 2020 and are using flex),
                                // let's create missing slots and flag them as disabled
                                const lastSlotMinutes = (lastSlot && lastSlot.groupKey === s.groupKey)
                                    ? lastSlot.date.getMinutes()
                                    : -blockSizeInMinutes;
                                const blocksTaken = (s.date.getMinutes() - lastSlotMinutes) / blockSizeInMinutes;
                                if (blocksTaken > 1) {
                                    for (let i = blocksTaken - 1; i--; i > 1) {
                                        let missingBlock = s.clone();
                                        let missingBlockDate = new Date(missingBlock.get('date'));
                                        missingBlockDate.setMinutes(missingBlockDate.getMinutes() - blockSizeInMinutes * (i + 1));
                                        missingBlock.set('date', missingBlockDate);
                                        missingBlock.set('disabled', true);
                                        group[s.groupKey].push(missingBlock);
                                    }
                                }
                                group[s.groupKey].push(s);

                                lastSlot = s;
                            });
                            dayBlock.slotsGrouped = group;
                        });

                        $scope.daysThisWeek = blocks;
                        $scope.loading = false;
                        firstLoad = false;
                    });
                };

                $scope.$watch('userid', updateAvailability);
            },
        ],
    };
}
