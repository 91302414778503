import * as Twilio from 'twilio-client';

const SHOW_DEBUG = false;
// Milliseconds to wait before we try and get a new VoIP Token.
const TOKEN_REFRESH_PERIOD = 60000 * 4; // 4 Minutes.

export const VoipConsultationService = [
    '$rootScope', '$timeout', '$q', 'Meeting', 'MeetingConnectionService', 'VideoConsultationService',
    function($rootScope, $timeout, $q, Meeting, MeetingConnectionService, VideoConsultationService) {
        var connectionParams = {};
        let inputDeviceId = null;
        let outputDeviceId = null;

        var retVal = {
            statusEnum: {
                'STATUS_LOADING': 0,
                'STATUS_READY': 1,
                'STATUS_CONNECTED': 2,
            },
            setInputDevice: function(deviceId) {
                inputDeviceId = deviceId;
                if (retVal.status >= retVal.statusEnum.STATUS_READY) {
                    retVal.Device.audio.setInputDevice(deviceId).then(function() {
                        console.info('Audio Input device changed.');
                    }, function(err) {
                        retVal.canChangeInput = false;
                        retVal.setInputDevice(null);
                        console.error('Audio Input device cannot be changed.');
                    });
                }
            },
            setOutputDevice: function(deviceId) {
                outputDeviceId = deviceId;
                if (retVal.status >= retVal.statusEnum.STATUS_READY) {
                    retVal.Device.audio.speakerDevices.set(deviceId).then(function() {
                        console.info('Audio Output device changed.');
                    }, function(err) {
                        retVal.canChangeOutput = false;
                        retVal.setOutputDevice(null);
                        console.error('Audio Output device cannot be changed.');
                    });
                }
            },
            canChangeInput: true,
            canChangeOutput: true,
            token: null,
            Device: new Twilio.Device(),
            status: this.STATUS_LOADING,
        };

        var twilioDeviceOptions = {
            'closeProtection': true,
            'debug': SHOW_DEBUG,
            'enableIceRestart': true,
            'audioConstraints': {
                audio: true,
                video: false,
            },
            codecPreferences: ['opus', 'pcmu'],
        };

        var disconnectHandler = function() {
            retVal.status = retVal.statusEnum.STATUS_READY;
            $timeout(function() {
                MeetingConnectionService.setLocalVoipConnected(false);
                VideoConsultationService.disconnect();
                $rootScope.$broadcast('voipStatus', retVal.statusEnum.STATUS_READY);
            }, 0);
        };

        var errorHandler = function(errorMessage, error) {
            console.error(errorMessage, error);
            $rootScope.$broadcast('growl', 'error');
        };

        let tokenRefreshPromise = null;

        retVal.getToken = function() {
            if (tokenRefreshPromise) {
                $timeout.cancel(tokenRefreshPromise);
                tokenRefreshPromise = null;
            }

            // Callback when we've loaded the necessary Access Token.
            var deferred = $q.defer();

            Meeting.getByRouteParams().then(function(meeting) {
                Meeting.getClientCapabilityToken(meeting).then(function(clientCapabilityToken) {
                    retVal.token = clientCapabilityToken;
                    retVal.Device.setup(retVal.token, twilioDeviceOptions);
                    deferred.resolve();

                    tokenRefreshPromise = $timeout(retVal.getToken, TOKEN_REFRESH_PERIOD);
                }, errorHandler);
            });

            return deferred.promise;
        };

        retVal.initialize = function() {
            if (retVal.status > retVal.statusEnum.STATUS_LOADING) {
                return;
            }

            Meeting.getByRouteParams().then(function(meeting) {
                // Set the unique-identifying digits to the meeting controller.
                if (!connectionParams.Digits && meeting.get('visitorIsConsumer')) {
                    connectionParams.Digits = meeting.get('consumerCode');
                } else if (!connectionParams.Digits && meeting.get('visitorIsExpert')) {
                    connectionParams.Digits = meeting.get('expertCode');
                }

                retVal.getToken();
            }, errorHandler);
        };

        // Initialize Twilio VoIP callbacks.
        retVal.Device.on('connect', function(connection) {
            retVal.status = retVal.statusEnum.STATUS_CONNECTED;
            $timeout(function() {
                MeetingConnectionService.setLocalVoipConnected(true);
                $rootScope.$broadcast('voipStatus', retVal.statusEnum.STATUS_CONNECTED);
            }, 0);
        });
        retVal.Device.on('ready', function(device) {
            if (retVal.status === retVal.statusEnum.STATUS_CONNECTED) {
                retVal.disconnect();
            }
            retVal.status = retVal.statusEnum.STATUS_READY;
            $timeout(function() {
                $rootScope.$broadcast('voipStatus', retVal.statusEnum.STATUS_READY);
            }, 0);

            if (inputDeviceId) {
                retVal.setInputDevice(inputDeviceId);
            }
            if (outputDeviceId) {
                retVal.setOutputDevice(outputDeviceId);
            }
        });
        retVal.Device.on('error', function(error) {
            errorHandler('VoIP Device Error: ' + error.message, error);
            // disconnectHandler();
        });
        retVal.Device.on('cancel', disconnectHandler);
        retVal.Device.on('disconnect', disconnectHandler);
        retVal.Device.on('offline', function(device) {
            // Client Capability Token expired -- reinitialize.
            retVal.initialize();
        });

        retVal.connect = function() {
            retVal.disconnect();
            retVal.Device.connect(connectionParams);
        };
        retVal.disconnect = function() {
            if (retVal.Device.activeConnection()) {
                retVal.Device.disconnectAll();
            }
        };

        return retVal;
    },
];
