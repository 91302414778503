import _ from 'lodash';

export function makeLoader(url, key, result = {}) {
    let loaded = false;
    Object.defineProperty(result, '$load$', {
        value: () => loaded ? $.when(result) : $.ajax(url).then(response => {
            if (response && response.data && response.data[key]) {
                result = _.merge(result, response.data[key]);
                loaded = true;
                return result;
            } else {
                throw new Error('Could not load ' + key);
            }
        }),
        writable: false,
        enumerable: false
    });
    return result;
}
