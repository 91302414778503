import * as moment from 'moment-timezone';

export function consumerFeedback() {
    return {
        restrict: 'E',
        templateUrl: function(tElement, tAttrs) {
            if (tAttrs.usePreparedAssessment) {
                if (tAttrs.review) {
                    return Evisors.config.cdnRootWhitelabel + 'asset/partials/consultations/consumerFeedbackPreparedReview.html';
                } else {
                    return Evisors.config.cdnRootWhitelabel + 'asset/partials/consultations/consumerFeedbackPrepared.html';
                }
            } else {
                if (tAttrs.review) {
                    return Evisors.config.cdnRootWhitelabel + 'asset/partials/consultations/consumerFeedbackReview.html';
                } else {
                    return Evisors.config.cdnRootWhitelabel + 'asset/partials/consultations/consumerFeedback.html';
                }
            }
        },
        scope: {
            consumerName: '=',
            feedback: '=',
            consultationType: '=',
            usePreparedAssessment: '=?',
            review: '=?',
        },
        controller: [
            '$scope', 'Feedback', '$http', function($scope, Feedback, $http) {
                $scope.formErrors = false;
                $scope.isCompleted = !!$scope.review || Feedback.isConsumerFeedbackCompleted();

                // there will be a dateCreated if the expert met with the consumer before.
                // the dateCreated will store the previous assessment date
                if ($scope.feedback && $scope.feedback.dateCreated) {
                    $scope.dateCreatedLabel = moment($scope.feedback.dateCreated).format('MMMM Do YYYY');
                }

                var starCache = {};
                $scope.handleNotApplicableChange = function() {
                    // if they now marked not applicable
                    if ($scope.feedback.get('isNotApplicable')) {
                        starCache.starRatingOverall = $scope.feedback.get('starRatingOverall');
                        starCache.starRatingSkills = $scope.feedback.get('starRatingSkills');
                        starCache.starRatingMotivation = $scope.feedback.get('starRatingMotivation');
                        starCache.starRatingCulture = $scope.feedback.get('starRatingCulture');
                        starCache.assessmentText = $scope.feedback.get('assessmentText');

                        $scope.feedback.set('starRatingOverall', 3);
                        $scope.feedback.set('starRatingSkills', 3);
                        $scope.feedback.set('starRatingMotivation', 3);
                        $scope.feedback.set('starRatingCulture', 3);
                        $scope.feedback.set('assessmentText', 'Not Applicable');
                    } else {
                        $scope.feedback.set('starRatingOverall', starCache.starRatingOverall);
                        $scope.feedback.set('starRatingSkills', starCache.starRatingSkills);
                        $scope.feedback.set('starRatingMotivation', starCache.starRatingMotivation);
                        $scope.feedback.set('starRatingCulture', starCache.starRatingCulture);
                        $scope.feedback.set('assessmentText', starCache.assessmentText);
                    }
                };

                $scope.attemptSubmitFeedback = function() {
                    if ($scope.saving) {
                        return;
                    }

                    $scope.formErrors = !$scope.feedback.isComplete(true);
                    if ($scope.formErrors) {
                        return;
                    }

                    $scope.saving = true;
                    $scope.feedback.save().then(function() {
                        $scope.saving = false;
                    }, function() {
                        // TODO: handle errors?
                        $scope.saving = false;
                    });
                };

                if ($scope.review) {
                    // TODO: Could/should this go into the Feedback service?
                    $scope.toggleShare = function() {
                        $http.post(
                            '/api/consultations/' + $scope.feedback.get('meetingid') + '/feedback/consumer/set_share', {
                                status: $scope.feedback.get('isShared'),
                            }).then(function() {

                        });
                    };
                }
            },
        ],
    };
}

