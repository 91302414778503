import {ProgrammingEvents as ProgrammingEventsCollection} from '../collection/programmingEvents';
import {whitelabelling as Whitelabelling} from 'whitelabelling';
import moment from 'moment';
import {visitor as Visitor} from 'visitor';

export const ProgrammingEventsController = [
    '$scope', '$window', '$http', '$q',
    function($scope, $window, $http, $q) {
        $scope.$emit('controllerLoaded', true);
        $scope.platform = Whitelabelling.label;
        $scope.fairsData = [];
        $window.document.title = 'Programming Events';
        $scope.isLoading = true;

        $q.all([
            fetchRelevantFairAndProgrammingEvents(),
        ]).finally(() => {
            $scope.isLoading = false;
        });

        function createTimingString({eventDateStart, eventDateEnd}) {
            if (typeof eventDateStart === 'object') {
                eventDateStart = eventDateStart.date + 'Z';
            }

            if (typeof eventDateEnd === 'object') {
                eventDateEnd = eventDateEnd.date + 'Z';
            }

            const timezone = Visitor.user.timezone;
            const startDate = moment(eventDateStart).tz(timezone);
            const endDate = moment(eventDateEnd).tz(timezone);

            const meridiemFormat = 'A';
            const dateFormat = 'MMM D';
            const timeFormat = 'hh:mm';

            const isSameMeridiem = startDate.format(meridiemFormat) === endDate.format(meridiemFormat);
            const isSameDay = startDate.dayOfYear() === endDate.dayOfYear();

            return `${startDate.format(dateFormat)} ${startDate.format(timeFormat)} ${isSameMeridiem ? '' : ' ' + startDate.format(meridiemFormat)} - ${isSameDay ? ''
                : endDate.format(dateFormat + ' ')} ${endDate.format(timeFormat)} ${endDate.format(meridiemFormat)}`;
        }

        function fetchRelevantFairAndProgrammingEvents() {
            return $http.get('/fair/reception-fair-programming-events ').then(({data: {data: {Fairs}}}) => {
                Fairs.forEach(fair => {
                    let events = new ProgrammingEventsCollection(fair.events, {parse: true});
                    let past = events.where({timeSpan: 'past'});
                    let happening = events.where({timeSpan: 'happening'});
                    let upcoming = events.where({timeSpan: 'upcoming'});
                    let timing = createTimingString(fair.fair);
                    $scope.fairsData.push({
                        'fairName': fair.fair.label,
                        'id': fair.fair.id,
                        'showPast': false,
                        'showEvents': upcoming.length + happening.length > 0,
                        timing,
                        past,
                        happening,
                        upcoming,
                    });
                });
            });
        }

        $scope.showPastEvents = fair => {
            fair.showPast = !fair.showPast;
        };
    },
];
