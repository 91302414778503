import {NgModel} from 'model/ngModel';
import {Meeting} from 'model/meeting';
import {ProfileUser} from 'model/profileUser';

export const MyHistory = NgModel.extend({
    _name: 'MyHistory',
    urlRoot: '/profile/[otherUserid]/my_history.json',
    associations: {
        upcomingConsultation: Meeting,
        OtherUser: ProfileUser,
    },
    defaults: {
        canMessage: false,
        upcomingConsultation: null,
    },
});
