import * as Moment from 'moment';

/**
 * This directive creates a basic timestamp, at least originally for Chat timestamps.
 * use like:
 * <timestamp data="..."></timestamp>
 */
export let timestamp = () => ({
    restrict: 'E',
    replace: true,
    templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/timestamp.html',
    scope: {
        data: '<',
        useShort: '=?',
    },
    controller: [
        '$scope', '$timeout', ($scope, $timeout) => {
            let timestamp = undefined;

            const calcLabel = () => {
                const now = new Moment();
                const duration = Moment.duration(now.diff(timestamp));
                const isNow = Math.abs(duration.asSeconds()) < 2;
                const isCurrentMinute = Math.abs(duration.asMinutes()) < 1;
                const isCurrentHour = Math.abs(duration.asMinutes()) < 60;
                const isCurrentDate = timestamp.isSame(now, 'day');
                const isCurrentWeek = timestamp.isSame(now, 'week');
                const isCurrentYear = timestamp.isSame(now, 'year');

                if ($scope.useShort) {
                    if (isNow) {
                        $scope.label = 'Now';
                        // wait 5 seconds, calc again
                        $timeout(calcLabel, 5000);
                    } else if (isCurrentHour) {
                        $scope.label = Math.ceil(duration.asMinutes()) + 'm ago';
                        // wait 1 minute, calc again
                        $timeout(calcLabel, 60000);
                    } else if (isCurrentDate) {
                        $scope.label = timestamp.format('h:mm a');
                    } else if (isCurrentWeek) {
                        $scope.label = timestamp.format('ddd');
                    } else if (isCurrentYear) {
                        $scope.label = timestamp.format('MMM D');
                    } else {
                        $scope.label = timestamp.format('MM[/]DD[/]YY');
                    }
                } else {
                    if (isNow) {
                        $scope.label = 'Just now';
                        // wait 5 seconds, calc again
                        $timeout(calcLabel, 5000);
                    } else if (isCurrentMinute) {
                        $scope.label = 'A few seconds ago';
                        // wait 1 minute, calc again
                        $timeout(calcLabel, 60000);
                    } else if (isCurrentDate) {
                        $scope.label = timestamp.format('h:mm a') + ' today';
                    } else {
                        // Show date and time for older messages.
                        $scope.label = timestamp.format('h:mm a [on] MMM D, YYYY');
                    }
                }
            };

            $scope.$watch('data', (newVal, oldVal) => {
                // don't recalculate if nothing really changed
                if (timestamp
                    && newVal instanceof Date
                    && oldVal instanceof Date
                    && newVal.getTime() === oldVal.getTime()
                ) {
                    return;
                }
                timestamp = new Moment($scope.data);
                calcLabel();
            });
        },
    ],
});

