import {NgModel} from 'model/ngModel';
import * as _ from 'underscore';

export const SearchResult = NgModel.extend({
    _name: 'SearchResult',
    defaults: {
        employmentFormatted: [],
        educationFormatted: [],
        pendingAmps: [],
        newUntilTimestamp: undefined,
    },
    parse: function(data, options) {
        const dataFormatted = this._super('parse', data);

        if (dataFormatted.affinityGroups && dataFormatted.affinityGroups.length > 0) {
            _.each(dataFormatted.affinityGroups, function(s, i) {
                if (typeof s !== 'string') {
                    return;
                }
                const parts = s.split('||');
                if (parts.length === 2) {
                    dataFormatted.affinityGroups[i] = {
                        id: parts[0],
                        label: parts[1],
                    };
                }
            });
        }

        if (dataFormatted.employmentFormatted) {
            dataFormatted.employmentFormatted.forEach((singleEmployment, index) => {
                dataFormatted.employmentFormatted[index] = singleEmployment.split('|:|');
            });
        }

        if (dataFormatted.educationFormatted) {
            dataFormatted.educationFormatted.forEach((singleEducation, index) => {
                dataFormatted.educationFormatted[index] = singleEducation.split('|:|');
            });
        }

        if (options.alumniPlatformid) {
            dataFormatted.isPending = false;
            _.each(dataFormatted.pendingAmps, pendingAmpid => {
                if (options.alumniPlatformid == pendingAmpid) {
                    dataFormatted.isPending = true;
                }
            });
        }


        dataFormatted.isNew = dataFormatted.newUntilTimestamp > 0 &&
            (Math.floor(new Date().getTime() / 1000) < dataFormatted.newUntilTimestamp);

        this.__bindManyForAngular(dataFormatted);

        return dataFormatted;
    },
});
