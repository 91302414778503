import {GroupBaseController} from './groupBase';

export class GroupJobsController extends GroupBaseController {
    static $inject = ['$scope', '$rootScope', '$routeParams', '$q', '$window', '$http', 'VisitorService', '$analytics'];

    constructor($scope, $rootScope, $routeParams, $q, $window, $http, VisitorService, $analytics) {
        super($scope, $rootScope, $routeParams, $q, $window, $http, VisitorService, $analytics);
        $window.document.title = 'Group Jobs';
        $scope.position = 6;
    };
}
