import {Meetings as MeetingsCollection} from '../collection/meetings';
import {Users as UsersCollection} from '../collection/users';
import {Webinars as WebinarsCollection} from '../collection/webinars';

export const UserDashboardService = [
    '$q', function($q) {
        return {
            getMeetingsWithFilter: function(filter, page, count) {
                var deferred = $q.defer();

                var retVal = new MeetingsCollection();

                retVal.fetch({
                    data: {
                        filter: filter,
                        count: count,
                        page: page,
                    },
                }).then(function() {
                    deferred.resolve(retVal);
                }, function(responseObj) {
                    deferred.reject((responseObj && responseObj.data) ? responseObj.data.error : 'Error');
                });

                return deferred.promise;
            },
            getMentors: function(number, page) {
                var deferred = $q.defer();
                var mentors = new UsersCollection();

                mentors.fetch({
                    data: {
                        filter: 'mentors',
                        count: number || 10,
                        page: page || 0,
                    },
                }).then(function() {
                    deferred.resolve(mentors);
                }, function(responseObj) {
                    deferred.reject((responseObj && responseObj.data) ? responseObj.data.error : 'Error');
                });

                return deferred.promise;
            },

            getMentees: function(number, page) {
                var deferred = $q.defer();
                var mentees = new UsersCollection();

                mentees.fetch({
                    data: {
                        filter: 'mentees',
                        count: number || 10,
                        page: page || 0,
                    },
                }).then(function() {
                    deferred.resolve(mentees);
                }, function(responseObj) {
                    deferred.reject((responseObj && responseObj.data) ? responseObj.data.error : 'Error');
                });

                return deferred.promise;
            },

            getMatchedMentors: function(number, page) {
                var deferred = $q.defer();
                var matches = new UsersCollection();

                matches.fetch({
                    data: {
                        filter: 'matches',
                        count: number || 10,
                        page: page || 0,
                    },
                }).then(function() {
                    deferred.resolve(matches);
                }, function(responseObj) {
                    deferred.reject((responseObj && responseObj.data) ? responseObj.data.error : 'Error');
                });

                return deferred.promise;
            },

            peopleSort: function(m) {
                var consultData = m.get('consultData');
                var topMatchFinal = parseFloat(m.get('topMatchFinal')) || 0;
                var retVal = 0;
                if (consultData) {
                    var upcomingMeetingid = parseInt(consultData.upcomingMeetingid) || 0;
                    var completedConsults = parseInt(consultData.completedConsults) || 0;

                    // if someone has an upcoming meeting, they need to be first (favoring more recent ones)
                    // next most important is completed consults
                    retVal = (100 * upcomingMeetingid) + (10 * completedConsults);
                } else if (topMatchFinal) {
                    // in this case, the user has no mutual consultation data, so we use their match rating --
                    // keep it less than 1
                    retVal = topMatchFinal / 100;
                }

                // make it negative
                return -retVal;
            },

            getWebinars: function() {
                var deferred = $q.defer();
                var webinars = new WebinarsCollection();

                webinars.fetch({data: {count: 3}}).then(function() {
                    deferred.resolve(webinars);
                }, function(responseObj) {
                    deferred.reject((responseObj && responseObj.data) ? responseObj.data.error : 'Error');
                });

                return deferred.promise;
            },
        };
    },
];
