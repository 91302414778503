export const MeetingState_Both_OtherReschedule = [
    '$scope', function($scope) {
        $scope.template = Evisors.config.cdnRootWhitelabel + 'asset/partials/consultations/meetingStates/both/otherReschedule.html';
        $scope.meetingStartDates = $scope.meeting.get('suggestedTimeSwaths');
        $scope.durationInSeconds = $scope.meeting.get('durationInSeconds');

        // whether or not to show the reschedule / cancel dialogs
        $scope.showCancel = false;
        $scope.showReschedule = false;
    },
];
