import {Backbone} from 'Vertebrae';
import {GroupUserFile} from '../model/groupUserFile';

export const GroupUserFiles = Backbone.Collection.extend({
    _name: 'GroupUserFiles',
    urlRoot: '/group/[id]/files',
    url: function() {
        return '/group/' + this.groupid + '/files';
    },
    model: GroupUserFile,
});
