import {whitelabelling as Whitelabelling} from 'whitelabelling';
import {JobOpening} from '../../model/jobOpening';

export function userInputJobOpenings() {
    return {
        restrict: 'E',
        scope: {
            data: '=', // a collection of job opening models
        },
        templateUrl: Evisors.config.cdnRootWhitelabel + 'asset/partials/registration/directives/userInputs/userInputJobOpenings.html',
        controller: [
            '$scope', '$anchorScroll', '$q', function($scope, $anchorScroll, $q) {
                $scope.whitelabelling = Whitelabelling;
                $scope.showExpiredListings = false;
                var now = new Date();

                $scope.addJobOpening = function() {
                    if ($scope.data) {
                        $scope.data.add(new JobOpening({}, {parse: true}));
                        $scope.canAddAnother = false;
                    }
                };

                $scope.data.on('change:dateExpired change:isDeleted', refreshActive);

                $scope.$watch('data.length', refreshActive);

                $scope.$watch(function() {
                    if ($scope.data && $scope.data.length > 0) {
                        return $scope.data.last().isComplete();
                    }
                    return false;
                }, function(newVal) {
                    $scope.canAddAnother = newVal;
                });

                $scope.gotoExpiredListings = function() {
                    $anchorScroll('expired');
                    $scope.showExpiredListings = true;
                };

                function refreshActive() {
                    $scope.activeOpenings = $scope.data.filter(function(job) {
                        return !job.isDeleted && (!job.dateExpired || job.dateExpired > now);
                    });

                    $scope.expiredOpenings = $scope.data.filter(function(job) {
                        return job.id && (job.isDeleted || (job.dateExpired && job.dateExpired < now));
                    });
                }
            },
        ],
    };
}

