import {NgModel} from './ngModel';
import * as _ from 'underscore';

export const NavBadge = NgModel.extend({
    _name: 'NavBadge',
    url: '/navigation/badges.json',
    defaults: {
        'nav-messages': 0,
    },
    parse: function(data) {
        var dataFormatted = this._super('parse', data);

        var sum = 0;
        _.each(this.defaults, function(z, key) {
            dataFormatted[key] = parseInt(dataFormatted[key] || 0);
            if (dataFormatted[key] > 0) {
                sum += dataFormatted[key];
            }
        });

        dataFormatted.total = sum;

        this.__bindManyForAngular(dataFormatted);
        return dataFormatted;
    },
    getBadgeForNavigation: function(nav) {
        // we overload the class property @see NavigationController
        if (nav.hasOwnProperty('class') && nav.get('class') && this.get(nav.get('class'))) {
            return this.get(nav.get('class'));
        }
    },
});
