import Croppie from 'croppie';

/**
 * Produce a "croppie" cropping element.
 * @see https://foliotek.github.io/Croppie/
 *
 * Must specify a boundary and viewport width/height, and viewport shape can be "square" or "circle".
 *
 * You can trigger croppie to generate a result by $broadcast'ing 'croppie-get-result' into its parent scope.
 * "result-fn" is a function you must pass in to get a result out of the Firsthand Croppie directive.
 */
export function firsthandCroppie() {
    return {
        restrict: 'E',
        scope: {
            src: "=",
            resultFn: '=',
            boundaryWidth: '@',
            boundaryHeight: '@',
            viewportWidth: '@',
            viewportHeight: '@',
            viewportShape: '@?',
        },
        link: function(scope, element) {
            if (!scope.viewportShape) {
                scope.viewportShape = 'square';
            }
            scope.opts = {
                boundary: { width: scope.boundaryWidth, height: scope.boundaryHeight },
                viewport: { width: scope.viewportWidth, height: scope.viewportHeight, type: scope.viewportShape },
                showZoomer: true,
                enableExif: true,
            };

            const rebind = () => {
                if (scope.opts.url) {
                    scope.croppieElement.bind(scope.opts);
                }
            };

            // Create croppie element.
            scope.croppieElement = new Croppie(element[0], scope.opts);

            scope.$watch('src', () => {
                scope.opts.url = scope.src;
                rebind();
            });

            // If we have a callback function, listen for 'croppie-get-result' events and return to that function.
            if (scope.resultFn) {
                scope.$on('croppie-get-result', () => {
                    scope.croppieElement.result({
                        type: 'base64',
                        size: 'original',
                    }).then(scope.resultFn);
                });
            }

            // Handle re-binding on visibility changes.
            scope.$on('modal-update', () => {
                rebind(scope.src);
            });
        },
    };
}
