import {SearchFilters} from './searchFilters';

const bookmarkedKey = 'only-bookmarked'

export const ResourcesSearchFilters = SearchFilters.extend({
    url: '/resources/filters',
    setOnlyBookmarked: function () {
        this.each((f) => {
            if (f.key === bookmarkedKey) {
                f.set('value', true)
            }
        })
    },
    unsetOnlyBookmarked: function () {
        this.each((f) => {
            if (f.key === bookmarkedKey) {
                f.set('value', null)
            }
        })
    }
});
