export class CustomInput {
    id = null;
    key = null;
    type = null;
    weight = 10;
    required = false;
    customInputSectionid = null;
    dateCreated = null;
    label = null;
    description = null;
    descriptionForConsumer = null;
    descriptionForExpert = null;
    example = null;
    exampleShown = false;

    pristine = true;
    error = null;

    constructor(data) {
        this.id = data.id;
        this.key = data.key;
        this.weight = data.weight;
        this.required = data.required;
        this.customInputSectionid = data.customInputSectionid;
        this.dateCreated = data.dateCreated;
        this.label = data.label;
        this.description = data.description;
        this.descriptionForConsumer = data.descriptionForConsumer;
        this.descriptionForExpert   = data.descriptionForExpert;
        this.example = data.example;
    };

    isValid(inputData) {
        if (this.required && !inputData) {
            this.error = 'This field must not be empty.';
            return false;
        }

        this.error = null;
        return true;
    }

    requiresInput(defaultData) {
        return this.pristine && (this.required && !defaultData);
    }

    markNotPristine() {
        this.pristine = false;
    }
}
