import {JobOpeningBase} from './jobOpeningBase';

export const JobOpening = JobOpeningBase.extend({
    urlRoot: 'job-openings/[id]',
    isComplete: function() {
        this.validateData();
        var errors = this.get('errors');
        var keys = _.keys(errors);
        if (keys.length === 0) {
            return true;
        }
        var isComplete = true;
        _.every(keys, function(k) {
            isComplete = !errors[k];
            return isComplete;
        });
        return isComplete;
    },
});
