const SORT_DATE_ASC = 'dateAsc';
const SORT_DATE_DESC = 'dateDesc';
const SORT_ALPHA_ASC = 'alphaAsc';

/**
 * @param sort string
 * @param inputGroups array
 * @returns {*[]}
 */
export default function sortGroupOrder(sort, inputGroups) {
    let distinctGroups = inputGroups.filter((g) => g.hasDistinction === true);
    let nonDistinctGroups = inputGroups.filter((g) => g.hasDistinction === false);

    if (sort === SORT_DATE_ASC) {
        distinctGroups = sortByDateAsc(distinctGroups);
        nonDistinctGroups = sortByDateAsc(nonDistinctGroups);
    } else if (sort === SORT_DATE_DESC) {
        distinctGroups = sortByDateDesc(distinctGroups);
        nonDistinctGroups = sortByDateDesc(nonDistinctGroups);
    } else if (sort === SORT_ALPHA_ASC) {
        distinctGroups = sortByAlphaAsc(distinctGroups);
        nonDistinctGroups = sortByAlphaAsc(nonDistinctGroups);
    }

    return [...distinctGroups, ...nonDistinctGroups];
}

function sortByDateAsc(groups) {
    return groups.sort((a, b) => a.dateCreated < b.dateCreated ? -1 : 1);
}

function sortByDateDesc(groups) {
    return groups.sort((a, b) => a.dateCreated > b.dateCreated ? -1 : 1);
}

function sortByAlphaAsc(groups) {
    return groups.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : -1);
}
