import {whitelabelling as Whitelabelling} from 'whitelabelling';

/**
 * This directive will render the appropriate template for a passed template
 */
export function userEngagement() {
    return {
        restrict: 'E',
        scope: {
            data: '=',
        },
        template: '<div class="user-engagement-container"><ng-include src="getTemplateUrl()"/></div>',
        replace: true,
        controller: [
            '$scope',
            'UserEngagementService',
            (
                $scope,
                engagementService
            ) => {
                $scope.isActive = true;
                $scope.isSaving = false;

                // Some engagements have an input.
                $scope.input = null;

                // Some engagements have a multistep process.
                $scope.step = 1;

                $scope.whitelabelling = Whitelabelling;
                $scope.getTemplateUrl = () => {
                    if (!$scope.data) {
                        return null;
                    }
                    return Evisors.config.cdnRootWhitelabel + 'asset/partials/angular-utilities/directives/userEngagements/' + $scope.data.get('label') + '.html';
                };

                $scope.markIgnored = () => {
                    engagementService.markUserEngagementIgnored($scope.data);
                    $scope.isActive = false;
                };

                $scope.markResponded = overrideValue => {
                    if (overrideValue == null) {
                        overrideValue = $scope.input;
                    }
                    $scope.isSaving = true;
                    engagementService.markUserEngagementResponded($scope.data, overrideValue).then(() => {
                        $scope.isSaving = false;
                        $scope.isActive = false;
                    });
                };
            },
        ],
    };
}
