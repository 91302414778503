import {Backbone} from 'Vertebrae';
import {AffinityGroupCategory} from 'model/affinityGroupCategory';
import {AffinityGroups} from 'collection/affinityGroups';

export const AffinityGroupCategories = Backbone.Collection.extend({
    _name: 'AffinityGroupCategories',
    model: AffinityGroupCategory,
    url: '/groups/categories/get.json',
    associations: {
        AffinityGroups,
    },
});
