import {FAIR_TYPE_CAREER, FAIR_TYPE_ENROLLMENT} from "model/userFair"

export const BoothsController = [
    '$scope', '$window', '$rootScope', '$q', '$location', '$http', '$timeout', 'VisitorService',
    function ($scope, $window, $rootScope, $q, $location, $http, $timeout, VisitorService) {
        $window.document.title = 'Booths';
        $rootScope.pageClass = 'consultations-container';
        $scope.booths = [];

        $scope.FAIR_TYPE_CAREER = FAIR_TYPE_CAREER;
        $scope.FAIR_TYPE_ENROLLMENT = FAIR_TYPE_ENROLLMENT;

        VisitorService.getVisitor().then(function(visitor) {
            if (!visitor.get('isUser')) {
                window.location.href = '/login';
                return;
            }
        });

        let allBooths = [];
        load();

        $scope.filters = {
            keyword: '',
            premier: false
        };

        Object.keys($scope.filters).forEach((key) => $scope.$watch(`filters.${key}`, applyFilters))

        $scope.$emit('controllerLoaded', true);

        function applyFilters(current, next) {
            if (current === next) {
                return;
            }

            const {keyword, premier} = $scope.filters;
            if (!Array.isArray($scope.booths)) {
                return;
            }

            $scope.booths = allBooths
                .filter((b) => !keyword
                    || b.label.toLowerCase().includes(keyword.toLowerCase())
                    || b.description.toLowerCase().includes(keyword.toLowerCase())
                )
                .filter((b) => !premier || +b.hasDistinction > 0)
            ;
        }

        function load() {
            $scope.isLoading = true;
            $http
                .get('/booths')
                .then((res) => {
                    $scope.booths = res.data.data.Booths;
                    allBooths = [...$scope.booths];
                })
                .finally(() => {
                    $scope.isLoading = false;
                });
        }
    }
]
