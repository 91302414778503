import {whitelabelling as Whitelabelling} from 'whitelabelling';
import * as _ from 'underscore';

const MAX_SELECT = 3;

export function userInputConsultTypes() {
    return {
        restrict: 'E',
        scope: {
            data: '=',
        },
        templateUrl: Evisors.config.cdnRootWhitelabel +
            'ng-template/partials/registration/directives/userInputs/userInputConsultTypes.tpl.html',
        controller: [
            '$scope', '$rootScope', 'RegistrationService', function($scope, $rootScope, RegistrationService) {
                $scope.whitelabelling = Whitelabelling;

                $scope.clearErrors = function() {
                    $scope.hasErrors = false;
                };
                $scope.checkIfInputIsOk = function() {
                    $scope.hasErrors = $scope.data.length === 0;
                };
                $scope.$on('showErrors', function() {
                    $scope.checkIfInputIsOk();
                });

                let skipHelpWith = $scope.whitelabelling.settings['customized:skipHelpWith'];

                // we want to let users click Back if they're inside a vertical without being taken back a full step
                // so we're going to override goToPreviousStep() with backToVerticals() and then set it back
                // when going backToVerticals(). Basically this just keeps our original function reference
                // This also technically violates the architecture... so be careful!!!
                var oldBackButton = $rootScope.goToPreviousStep;

                $scope.inSecondStep = false;

                var enterVertical = function(vertical) {
                    if (!skipHelpWith) {
                        $scope.inSecondStep = true;
                    }
                    $scope.filteredServices = $scope.services.where({verticalid: vertical.id});
                    $rootScope.goToPreviousStep = $scope.backToVerticals;
                    $scope.clearErrors();
                };

                RegistrationService.getRegistrationSettings().then(function(d) {
                    $scope.verticals = _.map(_.filter(d.Verticals.models, function(item) {
                        // don't include opportunities vertical
                        return item.id != 5;
                    }), function(item) {
                        item.action = enterVertical;
                        switch (item.id) {
                            case 1:
                                item.label = 'Land a job/improve my career';
                                break;
                            case 3:
                                item.label = 'Improve academically';
                                break;
                            case 2:
                                item.label = 'Get into ' + (d.WhitelabelSettings['customized:school']
                                    ? d.WhitelabelSettings['customized:school']
                                    : 'school');
                                break;
                            case 4:
                                item.label = 'Manage or start a business';
                                break;
                        }
                        return item;
                    });
                    $scope.services = d.VerticalServices;

                    // if no service types exist within the vertical, we remove it from the list
                    $scope.verticals = _.filter($scope.verticals, function(pseudoVertical) {
                        return !!$scope.services.findWhere({'verticalid': pseudoVertical.id});
                    });

                    // if we already selected consult types, it means we're returning to this step so we should start
                    // off inside the correct vertical
                    if ($scope.data.length > 0) {
                        $scope.data.each(function(vs) {
                            vs.set('isSelected', true);
                            $scope.services.get(vs.get('id')).set('isSelected', true);
                        });
                        enterVertical(_.findWhere($scope.verticals, {id: $scope.data.at(0).get('verticalid')}));
                    } else if ($scope.verticals.length === 1) {
                        // If there's only one vertical, skip the "The #1 thing" vertical selector.
                        enterVertical($scope.verticals[0]);
                    }

                    // id 3 is career conversation
                    var careerConvo = $scope.services.get(3);
                    if (careerConvo) {
                        $scope.verticals.push({
                            verticalid: 1,
                            label: 'Figure out what I should be doing',
                            action: function() {
                                $scope.selectType(careerConvo);
                                $scope.$emit('goToNextStep');
                            },
                        });
                    }
                });

                $scope.selectType = function(verticalService) {
                    if (verticalService.get('isSelected') === true) {
                        verticalService.set('isSelected', false);
                        $scope.data.remove(verticalService);
                    } else if ($scope.data.length === MAX_SELECT) {
                        // do nothing, we already selected the max
                    } else {
                        verticalService.set('isSelected', true);
                        $scope.data.add(verticalService);

                        /*
                         * Should we auto advance after they select their third?
                        if ($scope.data.length === MAX_SELECT) {
                            $scope.$emit('goToNextStep');
                        }
                        */
                    }
                    $scope.checkIfInputIsOk();
                };

                $scope.backToVerticals = function() {
                    $scope.data.each(function(service) {
                        service.set('isSelected', false);
                    });
                    $scope.data.reset();
                    $scope.inSecondStep = false;
                    $rootScope.goToPreviousStep = oldBackButton;
                    $scope.checkIfInputIsOk();
                };
            },
        ],
    };
}
